import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HookContext } from '../../../contexts/HookContext';
import { MapType } from '../interfaces';
import { MAP_PAGE } from '../../../../constants/pathnames';
import { defaultSettings } from '../../../../constants/settings';

const SettingsPage: React.FC = () => {
  const { useSettings } = useContext(HookContext);
  const { settings, fetchSettings, updateSettings } = useSettings;
  const history = useHistory();

  const [settingsForm, setSettingsForm] = useState(settings);

  useEffect(() => {
    fetchSettings();
  }, []);

  const handleChangeForm = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>): void => {
    setSettingsForm((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onSubmit = () => {
    updateSettings(settingsForm);
  };

  const onCancel = () => {
    history.push(MAP_PAGE);
  };

  const resetDefaultForm = () => {
    setSettingsForm(defaultSettings);
  };

  return (
    <div className="settings" style={{ width: '50%', margin: 'auto' }}>
      <fieldset style={{ padding: '20px' }}>
        <legend style={{ fontWeight: 'bold' }}>Fading</legend>
        <div style={{ display: 'flex' }}>
          <label htmlFor="grip_fade_time_ms">Grip</label>&nbsp;
          <input
            type="text"
            name="grip_fade_time_ms"
            value={settingsForm.grip_fade_time_ms}
            onChange={handleChangeForm}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <label htmlFor="surface_event_fade_time_ms">Surface events</label>&nbsp;
          <input
            type="text"
            name="surface_event_fade_time_ms"
            value={settingsForm.surface_event_fade_time_ms}
            onChange={handleChangeForm}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <label htmlFor="alert_fade_time_ms">Alerts</label>&nbsp;
          <input
            type="text"
            name="alert_fade_time_ms"
            value={settingsForm.alert_fade_time_ms}
            onChange={handleChangeForm}
          />
        </div>
      </fieldset>
      <fieldset style={{ padding: '20px' }}>
        <legend style={{ fontWeight: 'bold' }}>Filters</legend>
        <div style={{ display: 'flex' }}>
          <label htmlFor="surface_events_magnitude_threshold">Surface events above</label>&nbsp;
          <input
            type="text"
            name="surface_events_magnitude_threshold"
            value={settingsForm.surface_events_magnitude_threshold}
            onChange={handleChangeForm}
          />
        </div>
      </fieldset>
      <fieldset style={{ padding: '20px' }}>
        <legend style={{ fontWeight: 'bold' }}>Misc</legend>
        <div style={{ display: 'flex' }}>
          <label htmlFor="map_type">Map type</label>&nbsp;
          <select name="map_type" value={settingsForm.map_type} onChange={handleChangeForm}>
            <option value={MapType.STREET}>Street</option>
            <option value={MapType.SATELLITE}>Satellite</option>
          </select>
        </div>
      </fieldset>
      <div className="settings-actions" style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '20px' }}>
        <button style={{ width: '200px' }} onClick={onSubmit}>
          Save
        </button>
        <button style={{ width: '200px' }} onClick={onSubmit}>
          Apply
        </button>
        <button style={{ width: '200px' }} onClick={onCancel}>
          Cancel
        </button>
        <button style={{ width: '200px' }} onClick={resetDefaultForm}>
          Reset to default
        </button>
      </div>
    </div>
  );
};

export default SettingsPage;
