import React, { useEffect, useRef } from 'react';
import { getShortTimeFromTimestamp } from '../../../../../helpers/common';
import { TIME_TO_SAVE_BOOKMARK } from '../../../../../constants/common';
import { Bookmark } from '../../../bookmark/interfaces';
import './_recording-popup.scss';

interface Props {
  bookmark: Bookmark;
  onSave: (bookmark: Bookmark) => void;
  onDelete: (bookmark: Bookmark) => void;
}

const BookmarkPopup: React.FC<Props> = (props: Props) => {
  const { bookmark, onSave, onDelete } = props;

  const saveTimer = useRef<NodeJS.Timeout | null>(null);

  const clearTimer = () => {
    if (saveTimer.current) {
      clearTimeout(saveTimer.current);
      saveTimer.current = null;
    }
  };

  const onSaveHandler = () => {
    onSave(bookmark);
    clearTimer();
  };

  const onDeleteHandler = () => {
    onDelete(bookmark);
    clearTimer();
  };

  useEffect(() => {
    saveTimer.current = setTimeout(() => {
      onSaveHandler();
    }, TIME_TO_SAVE_BOOKMARK);

    return () => clearTimer();
  }, []);

  return (
    <div className="rec-popup">
      <div className="rec-popup-video">
        <img src={bookmark.thumbnail} alt="map" className="rec-video" />
        <span className="rec-video-timesheet">{getShortTimeFromTimestamp(bookmark.position_ms)}</span>
      </div>
      <div className="rec-popup-info">
        <span className="rec-title">Bookmark Saved</span>
        <span className="rec-name">{bookmark.name}</span>
        {/* TODO: fix later */}
        {/*<span className="rec-title">Grip</span>*/}
        <span className="rec-name">{bookmark.text}</span>
      </div>
      <div className="rec-popup-controls">
        <button className="rec-btn">
          <i className="rec-icon icon icon-cancel" onClick={onSaveHandler} />
        </button>
        <button className="rec-btn delete">
          <i className="rec-icon icon icon-delete" onClick={onDeleteHandler} />
        </button>
      </div>
    </div>
  );
};

export default React.memo(BookmarkPopup);
