import { SearchingArea } from '../app/modules/main/interfaces';
import { Filters, MapCoords } from '../app/modules/map/interfaces';
import { floor } from 'lodash';

export const makeFeatureMarker = (coords: { lat_deg: number; lon_deg: number }, title: string): any => {
  return {
    title: title,
    feature: {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: { lat: coords.lat_deg, lng: coords.lon_deg },
      },
      properties: {
        title: 'Mapbox',
        description: 'Washington D.C.',
      },
    },
  };
};

export const getCenterOfPolygon = (arr: number[][]): [number, number] => {
  let minX, maxX, minY, maxY;
  for (let i = 0; i < arr.length; i++) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    minX = arr[i][0] < minX || minX == null ? arr[i][0] : minX;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    maxX = arr[i][0] > maxX || maxX == null ? arr[i][0] : maxX;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    minY = arr[i][1] < minY || minY == null ? arr[i][1] : minY;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    maxY = arr[i][1] > maxY || maxY == null ? arr[i][1] : maxY;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return [(minX + maxX) / 2, (minY + maxY) / 2];
};

export const getCustomPolygonWKTString = (data: number[][]): string => {
  let res = 'POLYGON((';
  const dataLength = data.length;
  data.forEach((item, i) => {
    res += item.join(' ');
    if (i + 1 < dataLength) {
      res += ',';
    }
  });

  res += '))';
  return res;
};

export const makeFirstLatterUppercase = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const replaceUnderscore = (string: string): string => {
  return string.replaceAll('_', ' ');
};

export const convertBase64ToFile = (base64String: string, fileName: string): File => {
  const arr = base64String.split(',');

  const match = arr[0].match(/:(.*?);/);

  if (!match) {
    throw Error('Base64 file is not valid');
  }

  const mimeType = match[1];

  const fileExtension = mimeType.split('/')[1];

  const bstr = atob(arr[1]);

  let n = bstr.length;

  const uint8Array = new Uint8Array(n);

  while (n--) {
    uint8Array[n] = bstr.charCodeAt(n);
  }

  return new File([uint8Array], `${fileName}.${fileExtension}`, { type: mimeType });
};

export const getSearchingArea = (polygonCoordinates: number[][]): SearchingArea => {
  const searchingArea: SearchingArea = {
    minCoordinates: [],
    maxCoordinates: [],
  };

  polygonCoordinates.forEach((coordinate) => {
    if (!searchingArea.minCoordinates.length) {
      searchingArea.minCoordinates[0] = coordinate[0];
      searchingArea.minCoordinates[1] = coordinate[1];
      searchingArea.maxCoordinates[0] = coordinate[0];
      searchingArea.maxCoordinates[1] = coordinate[1];
    }

    if (searchingArea.minCoordinates[0] > coordinate[0]) {
      searchingArea.minCoordinates[0] = coordinate[0];
    }

    if (searchingArea.minCoordinates[1] > coordinate[1]) {
      searchingArea.minCoordinates[1] = coordinate[1];
    }

    if (searchingArea.maxCoordinates[0] < coordinate[0]) {
      searchingArea.maxCoordinates[0] = coordinate[0];
    }

    if (searchingArea.maxCoordinates[1] < coordinate[1]) {
      searchingArea.maxCoordinates[1] = coordinate[1];
    }
  });

  return searchingArea;
};

export const parseFiltersToQueryParams = (filters: Filters): string => {
  let filtersStringify = '?';

  for (const filter in filters) {
    if (filters.hasOwnProperty(filter)) {
      for (const option in filters[filter]) {
        if (filters[filter].hasOwnProperty(option) && !filters[filter][option]) {
          filtersStringify += `${filter}=${option}&`;
        }
      }
    }
  }

  return filtersStringify.length > 1 ? filtersStringify.substr(0, filtersStringify.length - 1) : '';
};

export const getPercent = (current: number, total: number): number => {
  const result = getRoundedFloat((current * 100) / total);
  return isNaN(result) ? 0 : result;
};

export const getRoundedFloat = (float: number, numberOfDecimals = 1): number => {
  return Math.round((float + Number.EPSILON) * Math.pow(10, numberOfDecimals)) / Math.pow(10, numberOfDecimals);
};

export const getDistance = (length: number): string => {
  const distance = (length / 1000).toLocaleString('en-US') + 'km';

  return distance.replace(/[.]/, ',');
};

export const getTimeFromTimestamp = (timestamp: number): string => {
  const date = new Date(timestamp);
  const hours = '0' + date.getHours();
  const minutes = '0' + date.getMinutes();
  const seconds = '0' + date.getSeconds();
  const milliseconds = date.getMilliseconds();

  return hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2) + '.' + milliseconds;
};

export const makeUrl = (rawUrl: string, replacedValues: { [key: string]: boolean | number | string }): string => {
  let url = rawUrl;

  Object.keys(replacedValues).forEach((key) => {
    url = url.replace(`:${key}`, String(replacedValues[key]));
  });

  return url;
};

export const getIntersection = <T>(arr1: T[], arr2: T[]): T[] => {
  return arr1.filter((item) => arr2.includes(item));
};

export const parseJwt = <T>(token: string): T => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const makeFloatFromInt = (number: number): string => (number % 1 !== 0 ? number.toString() : number + '.0');

export const between = (value: number, min: number, max: number): boolean => {
  return value >= min && value <= max;
};

export const getPathFromUrl = (urlWithQueryParams: string): string => {
  return urlWithQueryParams.split('?')[0];
};

// Get distance between two geo points in meters
export const getDistanceBetweenCoordinates = (a: MapCoords, b: MapCoords): number => {
  const lat1 = a.lat;
  const lat2 = b.lat;

  const lon1 = a.lng;
  const lon2 = b.lng;

  const R = 6371e3; // metres
  const fi1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const fi2 = (lat2 * Math.PI) / 180;
  const deltaFi = ((lat2 - lat1) * Math.PI) / 180;
  const deltaLambda = ((lon2 - lon1) * Math.PI) / 180;

  const midRes =
    Math.sin(deltaFi / 2) * Math.sin(deltaFi / 2) +
    Math.cos(fi1) * Math.cos(fi2) * Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
  const c = 2 * Math.atan2(Math.sqrt(midRes), Math.sqrt(1 - midRes));

  return R * c;
};

export const getShortTimeFromTimestamp = (timestamp: number): string => {
  const timeInSeconds = timestamp / 1000;

  const minutesString = `${floor(timeInSeconds / 60)}`;
  const secondsString =
    floor(timeInSeconds % 60) >= 10 ? `${floor(timeInSeconds % 60)}` : `0${floor(timeInSeconds % 60)}`;

  return `${minutesString}:${secondsString}`;
};

export const getRecordingDefaultName = (id: string, timeInMs: number): string => {
  const pauseDate = new Date(timeInMs);

  let date = pauseDate.getDate().toString();
  let month = (pauseDate.getMonth() + 1).toString();
  let year = pauseDate.getFullYear().toString();
  let hours = pauseDate.getHours().toString();
  let minutes = pauseDate.getMinutes().toString();

  date = date.length === 2 ? date : `0${date}`;
  month = month.length === 2 ? month : `0${month}`;
  year = year.slice(2);
  hours = hours.length === 2 ? hours : `0${hours}`;
  minutes = minutes.length === 2 ? minutes : `0${minutes}`;

  return `${id}_${date}${month}${year}_${hours}${minutes}`;
};

export const calculateDistanceByMapCoordsPath = (path: MapCoords[]): number => {
  return path.reduce((previousValue, currentItem, index, items) => {
    const prevItem = items[index - 1];

    if (prevItem === undefined) {
      return 0;
    }

    const distance = getDistanceBetweenCoordinates(
      { lat: currentItem.lat, lng: currentItem.lng },
      { lat: prevItem.lat, lng: prevItem.lng },
    );

    return previousValue + distance;
  }, 0);
};

export const getPercentFromNumber = (number: number, percent: number): number => {
  return (percent / 100) * number;
};

export function bound(value: number, lowerBound: number, upperBound: number): number {
  return Math.min(Math.max(value, lowerBound), upperBound);
}
