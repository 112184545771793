import 'reflect-metadata';
import { Container } from 'inversify';
import { UserService, IUserService } from '../app/modules/user/service';
import MapService, { IMapService } from '../app/modules/map/map.service';
import { ILocalStorageService, LocalStorageService } from '../app/services/LocalStorageService';
import { DataProcessingService, IDataProcessingService } from '../app/services/DataProcessingService';
import { ISocketSubscriptionService, SocketSubscriptionService } from '../app/services/SocketSubscriptionService';
import { IconClassService, IIconClassService } from '../app/services/IconClassService';
import { IPerformanceLoggerService, PerformanceLoggerService } from '../app/modules/metrics/PerformanceLoggerService';
import { IMapLayerCreationService, MapLayerCreationService } from '../app/modules/map/MapLayerCreationService';
import { IRecordingService, RecordingService } from '../app/modules/recordings/service';
import { IStreamService, StreamService } from '../app/modules/stream/service';
import { ISettingsService, SettingsService } from '../app/modules/settings/service';
import { BookmarkService, IBookmarkService } from '../app/modules/bookmark/service';

const container = new Container();
container.bind<IUserService>('UserService').to(UserService);
container.bind<IMapService>('MapService').to(MapService);
container.bind<IDataProcessingService>('DataProcessingService').to(DataProcessingService);
container.bind<ILocalStorageService>('LocalStorageService').to(LocalStorageService);
container.bind<ISocketSubscriptionService>('SocketSubscriptionService').to(SocketSubscriptionService);
container.bind<IIconClassService>('IconClassService').to(IconClassService);
container.bind<IPerformanceLoggerService>('PerformanceLoggerService').to(PerformanceLoggerService);
container.bind<IMapLayerCreationService>('MapLayerCreationService').to(MapLayerCreationService);
container.bind<IRecordingService>('RecordingService').to(RecordingService);
container.bind<IStreamService>('StreamService').to(StreamService);
container.bind<ISettingsService>('SettingsService').to(SettingsService);
container.bind<IBookmarkService>('BookmarkService').to(BookmarkService);

export { container };
