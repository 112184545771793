import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { LoggingService } from 'shared';
import {
  DEFAULT_3D_MAX_ZOOM,
  DEFAULT_3D_PITCH,
  DEFAULT_MAX_ZOOM,
  DEFAULT_BEARING,
  DEFAULT_PITCH,
  DEFAULT_ZOOM,
} from '../../../constants/map';
import { MapViewPort } from './interfaces';
import { getCenterOfPolygon, getCustomPolygonWKTString } from '../../../helpers/common';
import { injectable } from 'inversify';
import 'reflect-metadata';
import { Gps } from '../../../interfaces';

export interface IMapService {
  setMap(map: mapboxgl.Map): void;
  getMap(): mapboxgl.Map | null | undefined;
  setTheme(theme: string): void;
  enableMapControls(): void;
  rewriteDefaults(): void;
  getMapCoordinates(): MapViewPort | null;
  zoomIn(step?: number): void;
  zoomOut(step?: number): void;
  changeZoom(isIncrease: boolean): void;
  changeTo3d(): void;
  hideNonRelevantContainers(element: HTMLDivElement): void;
  navigate(viewport: MapViewPort, curve?: number): void;
  focusOnGps(gps: Gps): void;
  startCustomPolygonSelection(): void;
  removePolygonDrawing(): void;
  addPolygonDrawing(): void;
  restoreMapDefaults(coords: number[][]): void;
  getCoordinates(): MapViewPort | null;
  getCurrentScale(mapElement: HTMLElement): string;
  getCurrentScaleWidth(mapElement: HTMLDivElement): number;
}

@injectable()
class MapService implements IMapService {
  private ZOOM_STEP = 0.08;
  private DEFAULT_CURVE = 1.42;

  private map: mapboxgl.Map | null;
  private drawingControl: MapboxDraw | null;
  private isStyleIn3d = false;
  private isDuringCustomSelection = false;
  private referenceToUpdateCustomPolygon: () => void;

  public setMap(map: mapboxgl.Map): void {
    this.map = map;
  }

  public getMap(): mapboxgl.Map | null | undefined {
    return this.map;
  }

  public setTheme(theme: string): void {
    if (!this.map) return;
    this.map.setStyle(theme);
  }

  public enableMapControls(): void {
    if (!this.map) return;

    this.map.keyboard.enable();
    this.map.keyboard.enableRotation();
    this.map['boxZoom'].enable();
  }

  public rewriteDefaults(): void {
    if (!this.map) return;

    this.map.doubleClickZoom.enable();
  }

  public startCustomPolygonSelection(): void {
    if (this.isDuringCustomSelection) {
      this.removePolygonDrawing();
    } else {
      this.addPolygonDrawing();
    }
  }

  public addPolygonDrawing(): void {
    if (!this.map || this.drawingControl) return;

    const drawingControl = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
      defaultMode: 'draw_polygon',
    });

    this.drawingControl = drawingControl;
    this.map.addControl(drawingControl);
    this.isDuringCustomSelection = true;

    if (this.referenceToUpdateCustomPolygon) {
      this.map.off('draw.create', this.referenceToUpdateCustomPolygon);
      this.map.off('draw.delete', this.referenceToUpdateCustomPolygon);
      this.map.off('draw.update', this.referenceToUpdateCustomPolygon);
    }
    this.referenceToUpdateCustomPolygon = this.updateCustomPolygon.bind(this);
    this.map.on('draw.create', this.referenceToUpdateCustomPolygon);
    this.map.on('draw.delete', this.referenceToUpdateCustomPolygon);
    this.map.on('draw.update', this.referenceToUpdateCustomPolygon);
  }

  private updateCustomPolygon(): void {
    const geometry = this.drawingControl?.getAll().features[0].geometry as { coordinates: number[][] };
    const polygonString = getCustomPolygonWKTString(geometry?.coordinates);
    LoggingService.log('[DEBUG]: Selected polygon: ', polygonString);
  }

  public removePolygonDrawing(): void {
    if (!this.map || !this.drawingControl) return;
    this.map.removeControl(this.drawingControl);
    this.drawingControl = null;
    this.isDuringCustomSelection = false;
  }

  public getMapCoordinates(): MapViewPort | null {
    if (!this.map) return null;

    const lng = parseFloat(this.map.getCenter().lng.toFixed(4));
    const lat = parseFloat(this.map.getCenter().lat.toFixed(4));
    const zoom = parseFloat(this.map.getZoom().toFixed(2));
    const pitch = parseFloat(this.map.getPitch().toFixed(2));
    return {
      lng,
      lat,
      zoom,
      pitch,
    };
  }

  public zoomIn(step = this.ZOOM_STEP): void {
    if (!this.map) return;

    const zoom = this.map.getZoom();
    if (zoom) this.map.setZoom(zoom + step);
  }

  public zoomOut(step = this.ZOOM_STEP): void {
    if (!this.map) return;

    const zoom = this.map.getZoom();
    if (zoom) this.map.setZoom(zoom - step);
  }

  public changeZoom(isIncrease: boolean): void {
    if (!this.map) return;

    const currentZoom = this.map.getZoom();
    if (!currentZoom) {
      return;
    }

    this.map.flyTo({
      center: this.map.getCenter(),
      zoom: isIncrease ? currentZoom + 1 : currentZoom - 1,
    });
  }

  public changeTo3d(): void {
    if (!this.map) return;
    let newPitch: number;

    if (this.isStyleIn3d) {
      newPitch = DEFAULT_PITCH;
      this.map.setMaxZoom(DEFAULT_MAX_ZOOM);
    } else {
      newPitch = DEFAULT_3D_PITCH;
      this.map.setMaxZoom(DEFAULT_3D_MAX_ZOOM);
    }

    this.map.flyTo({
      center: this.map.getCenter(),
      pitch: newPitch,
    });

    this.isStyleIn3d = !this.isStyleIn3d;
  }

  public hideNonRelevantContainers(mapElement: HTMLDivElement): void {
    const containers = [];
    const scale = mapElement.querySelector('.mapboxgl-ctrl-scale');

    containers.push(mapElement.querySelector('.mapboxgl-ctrl-logo'));
    containers.push(mapElement.querySelector('.mapboxgl-ctrl-attrib-button'));

    scale?.parentElement?.style?.setProperty('visibility', 'hidden');
    containers.forEach((container) => {
      if (container) {
        container.parentElement?.parentElement?.remove();
      }
    });
  }

  public navigate(viewport: MapViewPort, curve = this.DEFAULT_CURVE): void {
    if (!this.map) return;
    this.map.flyTo({
      center: { lat: viewport.lat, lng: viewport.lng },
      zoom: viewport.zoom || DEFAULT_ZOOM,
      curve,
    });
  }

  public focusOnGps(gps: Gps): void {
    this.navigate({
      lng: gps.lon_deg,
      lat: gps.lat_deg,
      zoom: 15,
      pitch: DEFAULT_PITCH,
    });
  }

  public restoreMapDefaults(coords: number[][]): void {
    const [lng, lat] = getCenterOfPolygon(coords);
    this.removePolygonDrawing();
    this.map?.setPitch(DEFAULT_PITCH);
    this.map?.setZoom(DEFAULT_ZOOM);
    this.map?.setBearing(DEFAULT_BEARING);
    this.navigate({ lng, lat, zoom: DEFAULT_ZOOM, pitch: DEFAULT_PITCH });
  }

  public getCoordinates(): MapViewPort | null {
    if (!this.map) {
      return null;
    }

    const lng = parseFloat(this.map.getCenter().lng.toFixed(4));
    const lat = parseFloat(this.map.getCenter().lat.toFixed(4));
    const zoom = parseFloat(this.map.getZoom().toFixed(2));
    const pitch = parseFloat(this.map.getPitch().toFixed(2));
    return {
      lng,
      lat,
      zoom,
      pitch,
    };
  }

  public getCurrentScale(mapElement: HTMLElement): string {
    let res = '';
    const element = mapElement.querySelector('.mapboxgl-ctrl-scale');

    if (element) {
      res = element.textContent ? element.textContent : res;
    }

    return res;
  }

  public getCurrentScaleWidth(mapElement: HTMLDivElement): number {
    let res = 0;
    const element = mapElement.querySelector('.mapboxgl-ctrl-scale');

    if (element) {
      res = element.clientWidth ? element.clientWidth : res;
    }

    return res;
  }
}

export default MapService;
