import React from 'react';
import _ from 'lodash';
import './_separator.scss';

interface Props {
  count: number;
}

export const RadialSeparators: React.FC<Props> = (props: Props) => {
  const { count } = props;

  return (
    <>
      {_.range(count).map((index) => (
        <div
          key={index}
          className="separator-container"
          style={{
            position: 'absolute',
            height: '100%',
            transform: `rotate(${270 + (180 / (count - 1)) * index}deg)`,
          }}
        >
          <div className="separator" />
        </div>
      ))}
    </>
  );
};
