import React from 'react';
import ReactDOM from 'react-dom';
import mapboxgl, { Popup } from 'mapbox-gl';
import { TIME_TO_HIDE_EVENT } from '../../../constants/common';
import { Gps } from '../../../interfaces';
import MapService from './map.service';
import { VehicleInfo } from '../stream/interfaces';
import { MapTextTooltip } from './MapTextTooltip';

export class Vehicle {
  private VEHICLE_LAYER_ID = 'vehicle';
  private TRAIL_LAYER_ID = 'trail';
  private TEMP_TRAIL_LAYER_ID = 'temp-trail';

  // 24 fps animation
  private SECONDS_PER_FRAME = 41.66;

  private mapService = new MapService();

  private map: mapboxgl.Map;
  private gpsList: Gps[] = [];

  private isTrailVisible = true;

  private shouldFollowPosition = false;

  private cameraOffset = 0;

  private animationSpeed = 1;

  private animationId: NodeJS.Timeout;

  private popup: mapboxgl.Popup | null = null;

  private vehicleInfo: VehicleInfo | null = null;

  constructor(map: mapboxgl.Map) {
    this.map = map;
    this.mapService.setMap(map);

    this.init();

    this.update();
  }

  public setGpsList(gpsList: Gps[]): void {
    this.gpsList = gpsList;

    this.update();
  }

  public setTrailVisibility(state: boolean): void {
    this.isTrailVisible = state;

    this.map.setLayoutProperty(this.TRAIL_LAYER_ID, 'visibility', state ? 'visible' : 'none');
    this.map.setLayoutProperty(this.TEMP_TRAIL_LAYER_ID, 'visibility', state ? 'visible' : 'none');
  }

  public setAnimationSpeed(value: number): void {
    this.animationSpeed = value;
  }

  public clearVehicle(): void {
    clearInterval(this.animationId);

    this.setGpsList([]);
    this.updateVehicle([]);
    this.updateTempTrail([]);
    this.updateTrail();
    this.removePopup();
  }

  public setShouldFollowPosition(state: boolean): void {
    this.shouldFollowPosition = state;
  }

  public setCameraOffset(value: number): void {
    this.cameraOffset = value;
  }

  public rebuild(): void {
    this.init();
    this.update();
  }

  public setVehicleInfo(vehicleInfo: VehicleInfo | null): void {
    this.vehicleInfo = vehicleInfo;

    this.addVehicleInfoEvents();
  }

  private addVehicleInfoEvents(): void {
    let hidePopupTimer: NodeJS.Timeout | null = null;

    this.map.on('mouseenter', this.VEHICLE_LAYER_ID, (e) => {
      if (!this.vehicleInfo || this.popup) {
        if (hidePopupTimer) {
          clearInterval(hidePopupTimer);
          hidePopupTimer = null;
        }

        return;
      }

      this.popup = new Popup({ closeButton: false, closeOnClick: false });

      const popupNode = document.createElement('div');

      const { stream_id, fleet, make, model, year } = this.vehicleInfo;

      ReactDOM.render(
        React.createElement(MapTextTooltip, null, `${stream_id}, (${fleet}), ${make}, ${model}, ${year}`),
        popupNode,
      );

      this.popup
        .setLngLat({ lat: e.lngLat.lat + 0.001, lng: e.lngLat.lng + 0.001 })
        .setDOMContent(popupNode)
        .addTo(this.map);
    });

    this.map.on('mouseleave', this.VEHICLE_LAYER_ID, () => {
      this.map.getCanvas().style.cursor = '';

      hidePopupTimer = setTimeout(() => {
        this.removePopup();
      }, 500);
    });
  }

  private removePopup() {
    if (this.popup) {
      this.popup.remove();
      this.popup = null;
    }
  }

  private init(): void {
    this.map.addSource(this.VEHICLE_LAYER_ID, {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [],
        },
        properties: {},
      },
    });

    this.map.addSource(this.TRAIL_LAYER_ID, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [],
            },
            properties: {},
          },
        ],
      },
    });

    this.map.addSource(this.TEMP_TRAIL_LAYER_ID, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [],
            },
            properties: {},
          },
        ],
      },
    });

    this.map.addLayer(
      {
        id: this.TRAIL_LAYER_ID,
        source: this.TRAIL_LAYER_ID,
        type: 'line',
        paint: {
          'line-width': 5,
          'line-color': '#fd7500',
          'line-opacity': 1,
        },
        layout: {
          visibility: 'visible',
        },
      },
      this.map.getLayer('markers') ? 'markers' : undefined,
    );

    this.map.addLayer(
      {
        id: this.TEMP_TRAIL_LAYER_ID,
        source: this.TEMP_TRAIL_LAYER_ID,
        type: 'line',
        paint: {
          'line-width': 5,
          'line-color': '#fd7500',
          'line-opacity': 1,
        },
        layout: {
          visibility: 'visible',
        },
      },
      this.map.getLayer('markers') ? 'markers' : undefined,
    );

    this.map.addLayer({
      id: this.VEHICLE_LAYER_ID,
      source: this.VEHICLE_LAYER_ID,
      type: 'symbol',
      layout: {
        'icon-image': 'vehicle',
        'icon-size': 1,
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
      },
    });
  }

  private update(): void {
    if (this.gpsList.length) {
      this.updatePosition();
    }

    if (this.gpsList.length > 1) {
      this.updateTrail();
    }
  }

  private updatePosition(): void {
    clearInterval(this.animationId);

    if (this.gpsList.length === 1) {
      const currentGps = this.gpsList[this.gpsList.length - 1];
      return this.updateVehicle([currentGps.lon_deg, currentGps.lat_deg]);
    }

    const currentGps = this.gpsList[this.gpsList.length - 1];
    const previousGps = this.gpsList[this.gpsList.length - 2];

    const defaultAnimationDuration = 800;
    const animationDuration = defaultAnimationDuration / this.animationSpeed;

    const steps = Math.round(animationDuration / this.SECONDS_PER_FRAME);

    const latDif = currentGps.lat_deg - previousGps.lat_deg;
    const lngDif = currentGps.lon_deg - previousGps.lon_deg;

    let currentStep = 0;

    const animate = (): void => {
      if (currentStep >= steps) {
        clearInterval(this.animationId);

        this.updateVehicle([currentGps.lon_deg, currentGps.lat_deg]);

        return this.updateTempTrail([
          [previousGps.lon_deg, previousGps.lat_deg],
          [currentGps.lon_deg, currentGps.lat_deg],
        ]);
      }

      const newLat = previousGps.lat_deg + (latDif / steps) * currentStep;
      const newLng = previousGps.lon_deg + (lngDif / steps) * currentStep;

      this.updateVehicle([newLng, newLat]);

      if (this.popup) {
        this.popup.setLngLat({ lat: newLat + 0.001, lng: newLng + 0.001 }).addTo(this.map);
      }

      this.updateTempTrail([
        [previousGps.lon_deg, previousGps.lat_deg],
        [newLng, newLat],
      ]);

      currentStep++;
    };

    this.animationId = setInterval(animate, this.SECONDS_PER_FRAME);
  }

  private updateVehicle(coords: number[]): void {
    const vehicleSource = this.map.getSource(this.VEHICLE_LAYER_ID) as mapboxgl.GeoJSONSource;

    if (!vehicleSource) return;

    vehicleSource.setData({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: coords,
      },
      properties: {},
    });

    if (this.shouldFollowPosition && coords.length) {
      const currentZoom = this.map.getZoom();
      const currentPitch = this.map.getPitch();

      const lng = coords[0];
      const lat = coords[1] - this.cameraOffset / currentZoom;

      this.mapService.navigate({
        lat,
        lng,
        zoom: currentZoom,
        pitch: currentPitch,
      });
    }
  }

  private updateTrail(): void {
    const trailGeometry = this.mapTrailGeometryFromGpsList();

    // Remove last element not to show before car
    trailGeometry.pop();

    const source = this.map.getSource(this.TRAIL_LAYER_ID) as mapboxgl.GeoJSONSource;

    if (!source) return;

    source.setData({
      type: 'FeatureCollection',
      features: trailGeometry.map((geometry) => {
        return {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: geometry.coords,
          },
          properties: {
            timestamp: geometry.timestamp,
          },
        };
      }),
    });

    this.map.setPaintProperty(this.TRAIL_LAYER_ID, 'line-opacity', [
      '/',
      ['-', TIME_TO_HIDE_EVENT, ['-', Date.now(), ['get', 'timestamp']]],
      TIME_TO_HIDE_EVENT,
    ]);
  }

  private updateTempTrail(coords: number[][]): void {
    const tempTrailSource = this.map.getSource(this.TEMP_TRAIL_LAYER_ID) as mapboxgl.GeoJSONSource;

    if (!tempTrailSource) return;

    tempTrailSource.setData({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: coords,
      },
      properties: {},
    });
  }

  private mapTrailGeometryFromGpsList() {
    return this.gpsList.map((gps, i) => {
      let coords: number[][] = [];
      const prevGps = this.gpsList[i - 1];

      if (prevGps) {
        coords = [
          [prevGps.lon_deg, prevGps.lat_deg],
          [gps.lon_deg, gps.lat_deg],
        ];
      }

      return {
        coords,
        timestamp: gps.received_time,
      };
    });
  }
}
