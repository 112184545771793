import React from 'react';
import cn from 'classnames';
import './_toogler.scss';

interface Props {
  id: string;
  name: string;
  checked: boolean;
  disabled?: boolean;
  title?: string;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
}

export const Toggler: React.FC<Props> = (props: Props) => {
  const { id, name, disabled, title, checked, className, onClick } = props;

  return (
    <div className={`toggler ${className ? className : ''}`} data-testid={`toggler-${name}`}>
      {title && (
        <span className="toggler-text" data-testid={`toggler-title-${name}`}>
          {title}
        </span>
      )}
      <label
        className={cn('toggler-container', { isChecked: checked })}
        htmlFor={id}
        data-testid={`toggler-label-${name}`}
      >
        <input
          id={id}
          className="toggler-input"
          type="checkbox"
          name={name}
          value=""
          checked={checked}
          disabled={disabled}
          data-testid={`toggle-input-${name}`}
          onClick={onClick}
          readOnly
        />
        <div className="toggler-slider" data-testid="toggle-slider" />
      </label>
    </div>
  );
};
