import React from 'react';
import './_bookmark.scss';

interface Props {
  positionInPercent: number;
}

const Bookmark: React.FC<Props> = (props) => {
  const { positionInPercent } = props;

  return <div className="bookmark icon-bookmark-pin icon" style={{ left: `calc(${positionInPercent}% - 10px)` }} />;
};

export default Bookmark;
