import React, { useCallback } from 'react';
import { between, bound, getRoundedFloat } from '../../../../../../helpers/common';
import { Widget } from '../Widget';
import './_accelerometer.scss';
import cn from 'classnames';

export interface AccelerometerProps {
  title: string;
  value: number;
  step?: number;
  isMini?: boolean;
}

export const ACCELEROMETER_MIN_VALUE = -1.2;
export const ACCELEROMETER_MAX_VALUE = 1.2;
export const ACCELEROMETER_MIN_VALID_VALUE = -0.1;
export const ACCELEROMETER_MAX_VALID_VALUE = 0.1;

export const ACCELEROMETER_DEFAULT_STEP = 0.4;

const Accelerometer: React.FC<AccelerometerProps> = (props: AccelerometerProps) => {
  const { title, value, isMini } = props;

  const formatValue = useCallback((acceleration: number) => {
    const bounded = bound(acceleration, ACCELEROMETER_MIN_VALUE, ACCELEROMETER_MAX_VALUE);
    return getRoundedFloat(bounded, 2);
  }, []);

  const isValueInRange = between(value, ACCELEROMETER_MIN_VALID_VALUE, ACCELEROMETER_MAX_VALID_VALUE);
  const boundedValue = bound(value, ACCELEROMETER_MIN_VALUE, ACCELEROMETER_MAX_VALUE);
  const measurementSteps = getMeasuremenSteps();

  return (
    <Widget
      title={title}
      value={formatValue(value).toString()}
      afterValueText="(g)"
      className="accelerator-accelerometer"
    >
      {isMini
        ? smallAccelerometer(boundedValue, isValueInRange)
        : largeAccelerometer(boundedValue, isValueInRange, measurementSteps)}
    </Widget>
  );
};

function smallAccelerometer(value: number, isValueInRange: boolean) {
  return (
    <div className="car-dash-line" data-testid="accelerometer-mini">
      <span
        className={cn('car-dash-line-value', { inRange: isValueInRange })}
        style={{ width: 39 + value * (39 / 1.2) }}
        data-testid="acceleration-bar"
      />
    </div>
  );
}

function largeAccelerometer(value: number, isValueInRange: boolean, measurementValues: number[]) {
  return (
    <>
      <div className="accelerator-measurement" data-testid="accelerometer-full">
        <ul className="accelerator-measurement-values">
          <span
            className={cn('accelerator-measurement-range', { inRange: isValueInRange })}
            data-testid="accelerometer-full-outline"
          />
          {measurementValues.map((item) => (
            <li key={item} className="accelerator-measurement-value" data-testid="acceleration-labels">
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className="accelerator-value-bar">
        <span
          className="accelerator-pointer"
          style={{ bottom: 70 + value * (70 / 1.2) }}
          data-testid="acceleration-bar"
        />
      </div>
    </>
  );
}

function getMeasuremenSteps() {
  const res: number[] = [];
  const fullRangeDif = ACCELEROMETER_MAX_VALUE - ACCELEROMETER_MIN_VALUE;
  const fullRangeSteps = fullRangeDif / ACCELEROMETER_DEFAULT_STEP + 1;

  for (let i = 0; i < fullRangeSteps; i++) {
    res.push(getRoundedFloat(ACCELEROMETER_MAX_VALUE - ACCELEROMETER_DEFAULT_STEP * i, 1));
  }

  return res;
}

export default React.memo(Accelerometer);
