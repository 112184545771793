// 10 minutes in milliseconds (600000)
export const TIME_TO_HIDE_EVENT = 600000;

export const ALL_KEY = 'all';
export const ALL_KEY_STAR = '*';

// 4 MB
export const MAX_RECORDING_FILE_SIZE = 4000000;

export const resolution = {
  TABLET: 1024,
  SMALL_LAPTOP: 1360,
  BIG_LAPTOP: 1920,
};

export const TIME_TO_LIFE_RECORDING = 60000;
export const TIME_TO_LIFE_STREAM = 30000;

// 10 minutes timeline
export const MAX_RECORDING_TIME = 10 * 60 * 1000;

export const TIME_TO_SAVE_BOOKMARK = 5000;

/* TODO: in future we will receive this value from user config service */
export const MAX_BOOKMARKS_COUNT = 10;

/* TODO: in future we will receive this value from user config service */
export const MAX_VEHICLE_WEIGHT_ERROR_PERCENT = 14;
export const MAX_STORED_EVENTS_NUMBER = 10;
