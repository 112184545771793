import React, { useContext } from 'react';
import { HookContext } from '../../../contexts/HookContext';
import { makeFloatFromInt } from '../../../../helpers/common';
import { PlayableType } from '../../../../interfaces';

export const PlayingStreamIndicator: React.FC = () => {
  const { useRecordings } = useContext(HookContext);
  const { playingRecording } = useRecordings;

  return (
    <>
      {playingRecording ? (
        <span className="header-title">
          Opened recording {`"${playingRecording.title}"`}{' '}
          {playingRecording.type !== PlayableType.DUMP && (
            <>
              at {`"${makeFloatFromInt(playingRecording.speed)}"`}
              &nbsp;speed
            </>
          )}
        </span>
      ) : null}
    </>
  );
};
