import React from 'react';
import cn from 'classnames';

export interface AbsIndicatorProps {
  value: boolean;
  state?: boolean;
}

const AbsIndicator: React.FC<AbsIndicatorProps> = (props: AbsIndicatorProps) => {
  return <i className={cn('car-dash-abs', { isActive: props.value, new: props.state })} data-testid="abs-indicator" />;
};

export default React.memo(AbsIndicator);
