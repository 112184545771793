import { useContext, useRef } from 'react';
import { HookContext } from '../../contexts/HookContext';
import { Playable, PlayableType } from '../../../interfaces';
import { TIME_TO_LIFE_RECORDING } from '../../../constants/common';

export const useRecordingsData = (): UseRecordingsData => {
  const recordingResetTimer = useRef<NodeJS.Timer | null>(null);

  const { useRecordings } = useContext(HookContext);
  const {
    isRecordingTimelinePaused,
    playingRecording,
    removeRecording,
    setIsRecordingLoading,
    continueRecordingTimeline,
  } = useRecordings;

  const resetTimerForStoppingRunningRecording = () => {
    if (!playingRecording) {
      return;
    }

    if (playingRecording.type !== PlayableType.RECORDING) {
      return;
    }

    // If a vehicle stops we want to stop the recording
    if (recordingResetTimer.current) {
      clearTimeout(recordingResetTimer.current);
    }

    recordingResetTimer.current = setTimeout(() => {
      removeRecording();
    }, TIME_TO_LIFE_RECORDING);
  };

  const resumeRecording = () => {
    if (isRecordingTimelinePaused) {
      continueRecordingTimeline();
      setIsRecordingLoading(false);
    }
  };

  return {
    playingRecording,
    resetTimerForStoppingRunningRecording,
    resumeRecording,
  };
};

export interface UseRecordingsData {
  playingRecording: Playable | null;
  resetTimerForStoppingRunningRecording(): void;
  resumeRecording(): void;
}
