import { injectable } from 'inversify';
import 'reflect-metadata';
import { socketClient, ISocketClient } from 'shared';
import { SetOfSignals, SocketDataTypes, SocketMessage } from '../../interfaces';

export interface ISocketSubscriptionService {
  setStreamDataListener(callback: (data: SetOfSignals) => void): void;
  subscribeToStream(id: string): string;
  subscribeToAvailableStreams(callback: (message: string[]) => void): void;
  dataUnsubscribe(streamId: string): void;
}
@injectable()
export class SocketSubscriptionService implements ISocketSubscriptionService {
  private socket: ISocketClient = socketClient;
  private currentStream = '';
  private streamDataListener: ((message: SocketMessage) => void) | null = null;

  private isMessagesBlocked = false;

  public setStreamDataListener(callback: (data: SetOfSignals) => void): void {
    this.streamDataListener = (message: SocketMessage) => {
      if (message.source === this.currentStream && !this.isMessagesBlocked) {
        callback(message.data as SetOfSignals);
      }
    };
  }

  public subscribeToStream(id: string): string {
    const connection = this.socket.getConnection();

    if (connection && this.streamDataListener) {
      connection.off('message', this.streamDataListener);

      connection.emit('join', { stream_id: id });

      connection.on('message', this.streamDataListener);

      this.currentStream = id;

      connection.on('connect', () => {
        if (this.currentStream) {
          this.subscribeToStream(id);
        }
      });
    }

    setTimeout(() => {
      this.isMessagesBlocked = false;
    }, 2000);

    return id;
  }

  public subscribeToAvailableStreams(callback: (data: string[]) => void): void {
    const connection = this.socket.getConnection();

    if (connection) {
      connection.on('message', (message: SocketMessage) => {
        if (message.source === SocketDataTypes.AVAILABLE_STREAMS) {
          callback(message.data as string[]);
        }
      });
    }
  }

  public dataUnsubscribe(streamId: string): void {
    this.isMessagesBlocked = true;

    const connection = this.socket.getConnection();

    if (connection) {
      connection.emit('leave', { stream_id: streamId });
      this.currentStream = '';
    }
  }
}
