import { injectable } from 'inversify';
import { Playable, PlayableRecording } from '../../../interfaces';
import { Bookmark, UpdateBookmarkFields } from './interfaces';
import { convertBase64ToFile, makeUrl } from '../../../helpers/common';
import {
  API_BOOKMARKS,
  API_BOOKMARKS_TAPING,
  API_TAPING_BOOKMARKS_DELETE,
  API_RECORDING_BOOKMARKS_DELETE,
} from '../../../constants/api';
import { request } from '../../../helpers/request';
import RequestService from '../../services/RequestService';

export interface IBookmarkService {
  fetchTapingBookmarks(streamId: string): Promise<Bookmark[]>;
  fetchRecordingBookmarks(recording: PlayableRecording): Promise<Bookmark[]>;
  saveTapingBookmark(streamId: string, bookmark: Bookmark): Promise<void>;
  saveRecordingBookmark(recording: PlayableRecording, bookmark: Bookmark): Promise<void>;
  updateTapingBookmark(streamId: string, fields: UpdateBookmarkFields): Promise<void>;
  deleteTapingBookmark(streamId: string, name: string): Promise<void>;
  deleteRecordingBookmark(recording: Playable, bookmarkId: string): Promise<void>;
}

@injectable()
export class BookmarkService extends RequestService implements IBookmarkService {
  async fetchTapingBookmarks(streamId: string): Promise<Bookmark[]> {
    const url = makeUrl(API_BOOKMARKS_TAPING, { streamId });

    const res = await request.get<{ bookmarks: Bookmark[] }>(url);

    return res.data.bookmarks;
  }

  async fetchRecordingBookmarks(recording: PlayableRecording): Promise<Bookmark[]> {
    const url = makeUrl(API_BOOKMARKS, {
      is_private: recording.isPrivate,
      recording_name: recording.title,
      directory_name: recording.directory,
    });

    const res = await this.sendRequestWithRepeat<{ bookmarks: Bookmark[] }>('get', url, {
      timeout: this.REPEAT_REQUEST_INTERVAL,
    });

    return res.data.bookmarks;
  }

  async saveTapingBookmark(streamId: string, bookmark: Bookmark): Promise<void> {
    const url = makeUrl(API_BOOKMARKS_TAPING, { streamId });

    const thumbnailImage = convertBase64ToFile(bookmark.thumbnail, `${bookmark.name}`);

    const data = new FormData();
    data.append('name', bookmark.name);
    data.append('position_ms', (bookmark.position_ms as number).toString());
    data.append('thumbnail', thumbnailImage);
    data.append('text', bookmark.text);

    await request.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async saveRecordingBookmark(recording: PlayableRecording, bookmark: Bookmark): Promise<void> {
    const url = makeUrl(API_BOOKMARKS, {
      is_private: recording.isPrivate,
      recording_name: recording.title,
      directory_name: recording.directory,
    });

    const { name, position_ms, text } = bookmark;

    await request.post(url, {
      name,
      position_ms,
      text,
    });
  }

  async updateTapingBookmark(streamId: string, fields: UpdateBookmarkFields): Promise<void> {
    const url = makeUrl(API_BOOKMARKS_TAPING, { streamId });

    await request.patch(url, {
      name: fields.name,
      new_name: fields.new_name,
    });
  }

  async deleteTapingBookmark(streamId: string, name: string): Promise<void> {
    const url = makeUrl(API_TAPING_BOOKMARKS_DELETE, { streamId, name });
    await request.delete(url);
  }

  async deleteRecordingBookmark(recording: PlayableRecording, bookmarkId: string): Promise<void> {
    const { isPrivate, directory: directoryName, title: recordingName } = recording;

    const url = makeUrl(API_RECORDING_BOOKMARKS_DELETE, { bookmarkId, isPrivate, recordingName, directoryName });
    await request.delete(url);
  }
}
