import { createAsyncThunk } from '@reduxjs/toolkit';
import { container } from '../../../configs/inversify';
import { ISettingsService } from './service';
import { AppSettings } from './interfaces';
import { setTheme } from '../selections/store';
import { mapTypeToTheme } from '../../../constants/map';

const service: ISettingsService = container.get('SettingsService');

export const fetchSettings = createAsyncThunk('settings/fetch', async (_, thunkApi) => {
  try {
    const res = await service.fetchSettings();

    thunkApi.dispatch(setTheme(mapTypeToTheme[res.map_type]));

    return res;
  } catch (e) {
    throw new Error('Fetch settings error');
  }
});

export const updateSettings = createAsyncThunk('settings/update', async (params: AppSettings) => {
  try {
    await service.updateSettings(params);

    return params;
  } catch (e) {
    throw new Error('Update settings error');
  }
});
