import React, { ChangeEvent, FormEvent, MouseEventHandler, useContext, useEffect, useRef, useState } from 'react';
import { checkClickLocation, ErrorModal } from 'shared';
import { HookContext } from '../../../contexts/HookContext';
import { SelectableRecording } from '../../../../interfaces';
import '../RecordingModal/_recording.scss';

interface Props {
  recording: SelectableRecording;
  closeModal: () => void;
}

interface RenameRecordingForm {
  recordingName: string;
  directoryName: string;
  newRecordingName: string;
}

const initialRenameRecordingForm: RenameRecordingForm = {
  recordingName: '',
  directoryName: '',
  newRecordingName: '',
};

export const RenameRecordingModal: React.FC<Props> = (props: Props) => {
  const { closeModal, recording } = props;
  const formRef = useRef<HTMLFormElement>(null);

  const [form, setForm] = useState<RenameRecordingForm>({
    ...initialRenameRecordingForm,
    ...recording,
    newRecordingName: recording.recordingName,
  });

  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const { useRecordings } = useContext(HookContext);
  const { updateRecording } = useRecordings;

  const onHandleChangeTextInput = (e: ChangeEvent<HTMLInputElement>): void => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    updateRecording({
      recordingName: form.recordingName,
      directoryName: form.directoryName,
      newRecordingName: form.newRecordingName,
    });

    closeModal();
  };

  const close = (e: MouseEvent) => {
    if (checkClickLocation(e, formRef)) {
      closeModal();
    }
  };

  const onOkay: MouseEventHandler = (e) => {
    e.stopPropagation();

    setForm({
      ...initialRenameRecordingForm,
      ...recording,
      newRecordingName: recording.recordingName,
    });

    setValidationErrors([]);
  };

  useEffect(() => {
    document.addEventListener('click', close);

    return () => document.removeEventListener('click', close);
  }, []);

  useEffect(() => {
    const { newRecordingName, recordingName, directoryName } = form;

    const isTheSameName = recordingName === newRecordingName;

    setIsSubmitDisabled(!newRecordingName.trim() || !recordingName.trim() || !directoryName.trim() || isTheSameName);
  }, [form]);

  return (
    <>
      <form ref={formRef} className="recording" onSubmit={onSubmit}>
        <i className="recording-icon icon icon-save" />
        <span className="recording-title">Rename recording</span>
        <button className="recording-close" onClick={closeModal}>
          <i className="recording-close-icon icon icon-cancel" />
        </button>
        <div className="recording-form">
          <label htmlFor="title" className="recording-label">
            Name
          </label>
          <input
            autoFocus={true}
            autoComplete="off"
            type="text"
            id="title"
            name="newRecordingName"
            value={form.newRecordingName}
            onChange={onHandleChangeTextInput}
            className={`recording-input ${!form.newRecordingName ? 'isIncorrect' : ''}`}
            placeholder="Enter recording title"
          />
        </div>
        <div className="recording-btn-container">
          <button className="recording-btn fill" type="submit" disabled={isSubmitDisabled}>
            Update
          </button>
          <button className="recording-btn" type="button" onClick={closeModal}>
            Close
          </button>
        </div>
      </form>
      <div className="recording-back" />
      <ErrorModal errors={validationErrors} onOkay={onOkay} />
    </>
  );
};
