import { createAsyncThunk } from '@reduxjs/toolkit';
import { container } from '../../../configs/inversify';
import { IRecordingService } from './service';
import {
  RecordingPlayParams,
  RecordingRewindParams,
  RecordingUpdateParams,
  RecordingUploadParams,
  SelectableRecording,
} from '../../../interfaces';

const recordingService: IRecordingService = container.get('RecordingService');

export const getRecordings = createAsyncThunk('recordings/get', async () => {
  try {
    return recordingService.getRecordings();
  } catch (e) {
    throw new Error('Get recordings error');
  }
});

export const playRecording = createAsyncThunk('recordings/play', async (params: RecordingPlayParams) => {
  return await recordingService.playRecording(params);
});

export const dumpRecording = createAsyncThunk('recordings/dump', async (params: RecordingPlayParams) => {
  return recordingService.dumpRecording(params);
});

export const uploadRecording = createAsyncThunk(
  'recordings/upload',
  async (params: RecordingUploadParams, thunkAPI) => {
    await recordingService.uploadRecording(params);
    thunkAPI.dispatch(getRecordings());
  },
);

export const updateRecording = createAsyncThunk(
  'recordings/update',
  async (params: RecordingUpdateParams, thunkAPI) => {
    await recordingService.updateRecording(params);
    thunkAPI.dispatch(getRecordings());
  },
);

export const deleteRecording = createAsyncThunk(
  'recordings/delete',
  async (recording: SelectableRecording, thunkAPI) => {
    await recordingService.deleteRecording(recording);
    thunkAPI.dispatch(getRecordings());
  },
);

export const rewindRecording = createAsyncThunk<string, RecordingRewindParams>('recordings/rewind', async (params) => {
  return await recordingService.rewindRecording(params);
});
