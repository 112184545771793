import React, { useContext } from 'react';
import { DashboardContext } from '..';

const RoadConditionsPanel: React.FC = () => {
  const { isMini, isTablet, isLaptop } = useContext(DashboardContext);

  console.log('!!!!!!!!!!!!! isMini', isMini, 'isTablet', isTablet, 'isLaptop', isLaptop);

  return <div></div>;
};

export default React.memo(RoadConditionsPanel);
