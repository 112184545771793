import { injectable } from 'inversify';
import 'reflect-metadata';

export interface ILocalStorageService {
  setItem(key: string, value: unknown): void;
  getItem<T>(key: string): T;
  removeItem(key: string): void;
}

@injectable()
export class LocalStorageService implements ILocalStorageService {
  getItem<T>(key: string): T {
    const item = localStorage.getItem(key);
    return typeof item === 'string' ? JSON.parse(item) : null;
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  setItem(key: string, value: unknown): void {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
