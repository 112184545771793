import React from 'react';
import { ElementEditMenu } from 'shared';
import { SelectableRecording } from '../../../../../interfaces';

interface Props {
  recordingName: string;
  recordingDirectory: string;
  isSelected: boolean;
  onSelect: (recording: SelectableRecording) => void;
  onRemove?: (recording: SelectableRecording) => void;
  onRename?: (recording: SelectableRecording) => void;
  isPrivate?: boolean;
  isEditEnabled?: boolean;
}

const Recording: React.FC<Props> = (props: Props) => {
  const {
    recordingName,
    recordingDirectory,
    isSelected,
    onSelect,
    onRemove,
    onRename,
    isPrivate = false,
    isEditEnabled = false,
  } = props;

  const onSelectHandler = () => {
    onSelect({ recordingName, directoryName: recordingDirectory });
  };

  const onRemoveHandler = () => {
    onRemove && onRemove({ recordingName, directoryName: recordingDirectory });
  };

  const onRenameHandler = () => {
    onRename && onRename({ recordingName, directoryName: recordingDirectory });
  };

  return (
    <li
      className="dropdown-record-item"
      key={recordingName}
      style={{ display: 'flex', width: '100%', justifyContent: 'space-between', paddingRight: '20px' }}
    >
      <label htmlFor={`${recordingDirectory}_${recordingName}`} style={{ width: '100%', lineHeight: '20px' }}>
        <input
          type="checkbox"
          id={`${recordingDirectory}_${recordingName}`}
          className="dropdown-select-input hidden"
          onChange={onSelectHandler}
          checked={isSelected}
        />
        <span className="dropdown-record-title">{recordingName}</span>
      </label>
      {isPrivate && isEditEnabled && (
        <ElementEditMenu onClickRemove={onRemoveHandler} onClickRename={onRenameHandler} />
      )}
    </li>
  );
};

export default React.memo(Recording);
