import React, { useContext } from 'react';
import BrakeTorqueHeatBar from '../BrakeTorqueHeatBar';
import Speedometer from '../Speedometer';
import AbsIndicator from '../AbsIndicator';
import Accelerometer from '../Accelerometer';
import { HookContext } from '../../../../../contexts/HookContext';
import cn from 'classnames';
import AccControl from '../../AccPanel/AccControl';
import { AccSpeed } from '../../AccPanel/AccSpeed';

interface Props {
  isMini?: boolean;
  className?: string;
  displayBreakTorqueHeatBar?: boolean;
  displaySpeedometer?: boolean;
  displayACCSpeedometer?: boolean;
  displayAbs?: boolean;
  displayAccelerometer?: boolean;
}

const VehicleSignals: React.FC<Props> = (props: Props) => {
  const {
    isMini = false,
    className = '',
    displayBreakTorqueHeatBar = false,
    displaySpeedometer = false,
    displayACCSpeedometer = false,
    displayAbs = false,
    displayAccelerometer = false,
  } = props;

  const { useUser, useData } = useContext(HookContext);
  const { userData } = useUser;

  const {
    vehicleState: { brake_torque, acceleration, vehicle_speed },
    absStateActive,
    accStateActive,
  } = useData;

  return (
    <div className={cn('car-dash-metr', className)}>
      {displayBreakTorqueHeatBar && (
        <BrakeTorqueHeatBar value={brake_torque.brake_torque_Nm} units={userData?.units} isMini={isMini} />
      )}
      {displaySpeedometer && (
        <Speedometer value={vehicle_speed.speed_kmh} units={userData?.units} isMini={isMini}>
          {displayAbs && <AbsIndicator value={absStateActive} state={true} />}
        </Speedometer>
      )}
      {displayACCSpeedometer && (
        <AccSpeed>
          <div className="acc-speed-container widget-heading">
            <Speedometer value={vehicle_speed.speed_kmh} units={userData?.units} isMini />
          </div>
          <AccControl isActive={accStateActive} />
        </AccSpeed>
      )}
      {displayAccelerometer && (
        <>
          <Accelerometer title="Longitudinal acc." value={acceleration.lon_g} isMini={isMini} />
          <Accelerometer title="Lateral acc." value={acceleration.lat_g} isMini={isMini} />
        </>
      )}
      {isMini && displayAbs && <AbsIndicator value={absStateActive} state={true} />}
    </div>
  );
};

export default React.memo(VehicleSignals);
