import React from 'react';
import { AppType } from '../../../interfaces';
import cn from 'classnames';

interface Props {
  currentAppType: AppType;
  setCurrentAppType: (type: AppType) => void;
}

const textToAppType: Record<AppType, string> = {
  [AppType.LIVE]: 'Live Stream',
  [AppType.RECORDING]: 'Recording',
};

export const AppToggler: React.FC<Props> = (props) => {
  const { currentAppType, setCurrentAppType } = props;

  return (
    <div className="list-toggler">
      {Object.entries(textToAppType).map(([appType, text]) => (
        <button
          key={appType}
          className={cn('list-toggler-btn', { active: currentAppType === appType })}
          onClick={setCurrentAppType.bind(this, appType as AppType)}
        >
          {text}
        </button>
      ))}
    </div>
  );
};
