import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from './useForm';
import { isErrorSelector, userStateSelector, setIsError } from './store';
import { Login as LoginComponent } from 'shared';
import { FORGOT_PASSWORD, MAP_PAGE } from '../../../constants/pathnames';
import { LoginFieldErrors, LoginFormFields, LoginState } from './types';
import { StatusBar } from 'shared';
import './login.page.scss';

const initialForm: LoginFormFields = {
  username: '',
  password: '',
  saveCredentials: false,
};

const initialErrors: LoginFieldErrors = {
  username: '',
  password: '',
};

export const Login: React.FC = () => {
  const { form, errors, onChangeForm, onSubmit } = useForm(initialForm, initialErrors);
  const userState = useSelector(userStateSelector);
  const isError = useSelector(isErrorSelector);
  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    if (userState === LoginState.LOGGED_IN) history.push(MAP_PAGE);
  }, [userState]);

  const onCancelError = useCallback(() => {
    dispatch(setIsError(false));
  }, []);

  return (
    <>
      <StatusBar
        isError={isError}
        isLoading={userState === LoginState.LOGGING_IN}
        onTryAgain={onSubmit}
        onClickCancel={onCancelError}
        progressSpeed={1}
        dependencies={[userState]}
      />
      <LoginComponent
        errors={errors}
        fields={form}
        onChange={onChangeForm}
        onSubmit={onSubmit}
        forgotPasswordPath={FORGOT_PASSWORD}
      />
    </>
  );
};
