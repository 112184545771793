import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  availableRecordingsSelector,
  clearErrors,
  errorsSelector,
  isErrorSelector,
  isLoadingSelector,
  playingRecordingSelector,
  rejectedRequestSelector,
  removeRecording as remove,
  setIsError,
  snapshotSelector,
  pauseRecordingTimeline as pauseTimeline,
  isRecordingTimelinePausedSelector,
  thunkByError,
  isRecordingLoadingSelector,
  setIsRecordingLoading as setRecordingLoading,
  setIsRecordingEnded as setRecordingEnded,
  continueRecordingTimeline as continueTimeline,
  isRecordingEndedSelector,
  autoReplaySelector,
  toggleAutoReplay,
  reset,
} from './store';
import * as recordingThunks from './thunks';
import { rewindRecording } from './thunks';
import {
  AvailableRecordings,
  Playable,
  PlayableType,
  RecordingPlayParams,
  RecordingUpdateParams,
  RecordingUploadParams,
  SelectableRecording,
  SetOfSignals,
} from '../../../interfaces';
import { ServiceContext } from '../../contexts/ServiceContext';
import { AsyncThunkAction, ThunkDispatch } from '@reduxjs/toolkit';

export interface ReturnValue {
  playingRecording: Playable | null;
  isError: boolean;
  isLoading: boolean;
  isRecordingTimelinePaused: boolean;
  isRecordingEnded: boolean;
  isRecordingLoading: boolean;
  errors: string[];
  availableRecordings: AvailableRecordings;
  snapshot: { signals: SetOfSignals }[];
  autoReplay: boolean;
  playRecording: (params: RecordingPlayParams) => void;
  uploadRecording: (params: RecordingUploadParams) => void;
  updateRecording: (params: RecordingUpdateParams) => void;
  dumpRecording: (params: RecordingPlayParams) => void;
  setError: (isError: boolean) => void;
  removeRecording: () => void;
  deleteRecording: (recording: SelectableRecording) => void;
  sendRejectedRequest: () => void;
  clearUploadErrors: () => void;
  pauseRecording: () => void;
  continueRecording: (continueTime: number) => void;
  continueRecordingTimeline: () => void;
  setIsRecordingEnded: (isEnded: boolean) => void;
  setIsRecordingLoading: (isLoading: boolean) => void;
  onToggleAutoReplay: () => void;
  resetStore: () => void;
}

export const useRecordings = (): ReturnValue => {
  const playingRecording = useSelector(playingRecordingSelector);
  const isError = useSelector(isErrorSelector);
  const isLoading = useSelector(isLoadingSelector);
  const errors = useSelector(errorsSelector);
  const rejectedRequest = useSelector(rejectedRequestSelector);
  const availableRecordings = useSelector(availableRecordingsSelector);
  const snapshot = useSelector(snapshotSelector);
  const isRecordingTimelinePaused = useSelector(isRecordingTimelinePausedSelector);
  const isRecordingEnded = useSelector(isRecordingEndedSelector);
  const isRecordingLoading = useSelector(isRecordingLoadingSelector);
  const autoReplay = useSelector(autoReplaySelector);
  const [currentRecordingId, setCurrentRecordingId] = useState<string | null>(null);

  const { socketSubscriptionService } = useContext(ServiceContext);

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const pauseRecordingTimeline = () => {
    dispatch(pauseTimeline());
  };

  const continueRecordingTimeline = () => {
    dispatch(continueTimeline());
  };

  const playRecording = async (params: RecordingPlayParams) => {
    dispatch(recordingThunks.playRecording(params));
  };

  const pauseRecording = () => {
    if (currentRecordingId && playingRecording?.type === PlayableType.RECORDING) {
      socketSubscriptionService.dataUnsubscribe(currentRecordingId);
      pauseRecordingTimeline();
    }
  };

  const continueRecording = async (continueTime: number) => {
    if (!isRecordingLoading && currentRecordingId && playingRecording?.type === PlayableType.RECORDING) {
      setIsRecordingLoading(true);
      const action = await dispatch<ReturnType<AsyncThunkAction<any, any, any>>>(
        rewindRecording({
          streamId: currentRecordingId,
          speed: playingRecording.speed,
          directoryName: playingRecording.directory,
          isPrivate: playingRecording.isPrivate,
          recordingName: playingRecording.title,
          timestamp: continueTime,
        }),
      );

      if (action.payload) {
        socketSubscriptionService.subscribeToStream(action.payload);
        setCurrentRecordingId(action.payload);
      }
    }
  };

  const uploadRecording = (params: RecordingUploadParams) => {
    dispatch(recordingThunks.uploadRecording(params));
  };

  const updateRecording = (params: RecordingUpdateParams) => {
    dispatch(recordingThunks.updateRecording(params));
  };

  const dumpRecording = (params: RecordingPlayParams) => {
    dispatch(recordingThunks.dumpRecording(params));
  };

  const setError = (isError: boolean) => {
    dispatch(setIsError(isError));
  };

  const clearUploadErrors = () => {
    dispatch(clearErrors());
  };

  const removeRecording = () => {
    dispatch(remove());
  };

  const deleteRecording = (recording: SelectableRecording) => {
    dispatch(recordingThunks.deleteRecording(recording));
  };

  const sendRejectedRequest = () => {
    if (rejectedRequest.error) {
      const thunkAction = rejectedRequest.arguments
        ? thunkByError[rejectedRequest.error](rejectedRequest.arguments)
        : thunkByError[rejectedRequest.error](undefined as any);

      dispatch(thunkAction);
    }
  };

  const setIsRecordingEnded = (isEnded: boolean) => {
    dispatch(setRecordingEnded(isEnded));
  };

  const setIsRecordingLoading = (isLoading: boolean) => {
    dispatch(setRecordingLoading(isLoading));
  };

  const onToggleAutoReplay = () => {
    dispatch(toggleAutoReplay());
  };

  const resetStore = () => {
    dispatch(reset());
  };

  useEffect(() => {
    if (playingRecording && playingRecording.type === PlayableType.RECORDING) {
      setCurrentRecordingId(playingRecording.streamId);
    }
  }, [playingRecording]);

  return {
    playingRecording,
    isError,
    isLoading,
    isRecordingTimelinePaused,
    isRecordingEnded,
    isRecordingLoading,
    errors,
    availableRecordings,
    snapshot,
    autoReplay,
    playRecording,
    uploadRecording,
    updateRecording,
    dumpRecording,
    setError,
    removeRecording,
    deleteRecording,
    sendRejectedRequest,
    clearUploadErrors,
    pauseRecording,
    continueRecording,
    setIsRecordingEnded,
    setIsRecordingLoading,
    continueRecordingTimeline,
    onToggleAutoReplay,
    resetStore,
  };
};
