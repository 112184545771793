import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../../helpers/request';
import { API_FETCH_STREAMS } from '../../../constants/api';
import { AxiosResponse } from 'axios';
import { getIntersection } from '../../../helpers/common';
import { ALL_KEY, ALL_KEY_STAR } from '../../../constants/common';

interface AvailableStreamsResponse {
  streams: string[];
  status: 'FAIL' | 'SUCCESS';
}

export const fetchAvailableStreams = createAsyncThunk(
  'auth/fetchAvailableStreams',
  async (allowedStreams: string[]) => {
    let list: string[] = [];
    const res = (await request.get(API_FETCH_STREAMS)) as AxiosResponse<AvailableStreamsResponse>;
    const listOfStreams = res.data.streams;

    if (allowedStreams[0] === ALL_KEY || allowedStreams[0] === ALL_KEY_STAR) {
      list = listOfStreams;
    } else {
      list = getIntersection<string>(allowedStreams, listOfStreams);
    }

    return list;
  },
);
