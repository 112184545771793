import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { getRoundedFloat, makeFloatFromInt } from '../../../helpers/common';

interface Props {
  value: number;
  onChange: (value: number) => void;
  minValue?: number;
  maxValue?: number;
  changeStep?: number;
  isTextEditable?: boolean;
}

const SliderControl: React.FC<Props> = (props: Props) => {
  const { value, onChange, minValue = 0, maxValue, changeStep = 1, isTextEditable = false } = props;

  const [isEditable, setIsEditable] = useState(false);
  const [valueState, setValueState] = useState(1);
  const [inputState, setInputState] = useState('');

  const intervalRef = React.useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => stopCounter();
  }, []);

  useEffect(() => {
    onChange(valueState);
    setInputState(valueState.toString());
  }, [valueState]);

  const startCounter = (callback: () => void) => {
    callback();

    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      callback();
    }, 150);
  };

  const stopCounter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const onIncrement = () => {
    setValueState((prev) => {
      const newValue = (prev * 10 + changeStep * 10) / 10;

      if (validateValue(newValue)) {
        return newValue;
      }

      return prev;
    });
  };

  const onDecrement = () => {
    setValueState((prev) => {
      const newValue = (prev * 10 - changeStep * 10) / 10;

      if (validateValue(newValue)) {
        return newValue;
      }

      return prev;
    });
  };

  const toggleEditableState = (e: SyntheticEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    if (!isTextEditable) return;

    if (isEditable && inputState.length > 0) {
      const normalizedValue = getRoundedFloat(parseFloat(inputState), 1);

      if (validateValue(normalizedValue)) {
        setValueState(normalizedValue);
      }
    }

    setIsEditable((prev) => !prev);
  };

  const onTextChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    if (value.match(/^[0-9]*\.?[0-9]*$/)) {
      setInputState(value);
    }
  };

  const validateValue = (value: number): boolean => {
    if (minValue && maxValue) {
      return value >= minValue && value <= maxValue;
    }

    return true;
  };

  return (
    <div className="dropdown-pages">
      <span className="dropdown-speed">Speed</span>
      <button
        className="dropdown-pages-btn prev"
        onMouseDown={startCounter.bind(this, onDecrement)}
        onMouseUp={stopCounter}
      >
        <i className="dropdown-pages-icon icon icon-next" />
      </button>
      {isEditable ? (
        <input
          className="dropdown-pages-input"
          type="text"
          value={inputState}
          onChange={onTextChange}
          onBlur={toggleEditableState}
          autoFocus
        />
      ) : (
        <span className="dropdown-pages-value" onClick={toggleEditableState} onChange={onTextChange}>
          {makeFloatFromInt(value)}
        </span>
      )}
      <button className="dropdown-pages-btn" onMouseDown={startCounter.bind(this, onIncrement)} onMouseUp={stopCounter}>
        <i className="dropdown-pages-icon icon icon-next" />
      </button>
    </div>
  );
};

export default React.memo(SliderControl);
