import { useEffect, useRef } from 'react';
import {
  Gps,
  Grip,
  Marker,
  SetOfSignals,
  StoredAbsoluteEffectiveRollingRadius,
  StoredRelativeEffectiveRollingRadius,
  SurfaceEvent,
  StoredTireRadiiChange,
  TireRadiiConvergence,
  StoredTiresStiffness,
  VehicleState,
} from '../../../interfaces';
import { useDispatch, useSelector } from 'react-redux';
import {
  reset,
  resetCounters,
  resetDashboardStatistics,
  resetInitialData,
  resetLastDataSignalTimestamp,
  resetVehicleState,
  setAcceleration,
  setBrakeTorque,
  setIsDataReset,
  setVehicleId,
  setVehicleSpeed,
  togglePausedStatisticsCounting,
} from './store';
import { isDataFullResetSelector, isDataResetSelector, lastDataSignalTimestampSelector } from './signalsState';
import { LastLoadTransfer, LastVehicleWeight } from './state';
import { vehicleStateSelector } from './vehicleState';
import { isPausedStatisticsCountingSelector } from './dashboardStatistics';
import { dashboardStatisticsSelector } from './dashboardStatistics';
import { markersSelector } from './markers';
import { IPerformanceLoggerService } from '../metrics/PerformanceLoggerService';
import { container } from '../../../configs/inversify';
import { DashboardStatistics } from './interfaces';
import { useGpsData } from './gpsData';
import { useGripData } from './gripData';
import { useSurfaceEventsData } from './surfaceEventsData';
import { useTireStiffnessData } from './tireStiffnessData';
import { useTireRadiiData } from './tireRadiiData';
import { useSteeringAngleData } from './steeringAngleData';
import { useTemperatureData } from './temperatureData';
import { useAbsData } from './absData';
import { useAccData } from './accData';
import { useVehicleWeightData } from './vehicleWeightData';
import { useRecordingsData } from './recordingData';
import { useStreamData } from './streamData';

export interface UseData {
  gpsList: Gps[];
  gripEvents: Grip[];
  surfaceEvents: SurfaceEvent[];
  tiresRadiiChangeEvents: StoredTireRadiiChange[];
  tiresStiffnessEvents: StoredTiresStiffness[];
  steeringAngle: number | undefined;
  markers: Marker[];
  isDataReset: boolean;
  isDataFullReset: boolean;
  vehicleState: VehicleState;
  tireRadiiConvergence: TireRadiiConvergence | null;
  ambientTemperature: number;
  absStateActive: boolean;
  accStateActive: boolean;
  vehicleWeight: LastVehicleWeight;
  loadTransfer: LastLoadTransfer | null;
  dashboardStatistics: DashboardStatistics | null;
  isPausedStatisticsCounting: boolean;
  lastDataSignalTimestamp: number | undefined;
  relativeEffectiveRollingRadius: StoredRelativeEffectiveRollingRadius;
  absoluteEffectiveRollingRadius: StoredAbsoluteEffectiveRollingRadius;
  resetData: (fullReset?: boolean) => void;
  onUpdateVehicleInfo: (signals: SetOfSignals) => void;
  onResetDashboardStatistics: () => void;
  onToggleDashboardStatisticsCounting: () => void;
  resetVehicleStateAfterThirtySeconds: () => void;
  resetStore: () => void;
}

export const RESET_DELAY_MS = 100;

const performanceLoggerService: IPerformanceLoggerService = container.get('PerformanceLoggerService');

export const useData = (): UseData => {
  const vehicleResetTimer = useRef<NodeJS.Timer | null>(null);
  const streamData = useStreamData();
  const recordingData = useRecordingsData();

  const gpsData = useGpsData();
  const gripData = useGripData();
  const surfaceEventsData = useSurfaceEventsData();
  const tireStiffnessData = useTireStiffnessData();
  const tireRadiiData = useTireRadiiData();
  const steeringAngleData = useSteeringAngleData();
  const markers = useSelector(markersSelector);

  const isDataReset = useSelector(isDataResetSelector);
  const isDataFullReset = useSelector(isDataFullResetSelector);
  const vehicleState = useSelector(vehicleStateSelector);

  const temperatureData = useTemperatureData();
  const absData = useAbsData();
  const accData = useAccData();
  const vehicleWeightData = useVehicleWeightData();
  const dashboardStatistics = useSelector(dashboardStatisticsSelector);
  const isPausedStatisticsCounting = useSelector(isPausedStatisticsCountingSelector);
  const lastDataSignalTimestamp = useSelector(lastDataSignalTimestampSelector);

  const dispatch = useDispatch();

  const resetVehicleStateAfterThirtySeconds = () => {
    if (vehicleResetTimer.current) {
      clearTimeout(vehicleResetTimer.current);
    }

    vehicleResetTimer.current = setTimeout(() => {
      dispatch(resetVehicleState());
    }, 30000);
  };

  const resetData = (fullReset = false): void => {
    dispatch(resetInitialData(fullReset));

    setTimeout(() => {
      dispatch(setIsDataReset(false));
      dispatch(resetCounters());
    }, RESET_DELAY_MS);
  };

  const onUpdateVehicleInfo = (data: SetOfSignals): void => {
    performanceLoggerService.increaseMessageCounter();

    if (data.gps) {
      gpsData.updateGpsEventState(data.gps);
    }

    if (data.grip) {
      gripData.updateGripEventState(data.grip);
    }

    if (data.surface_events) {
      surfaceEventsData.updateSurfaceEventsState(data.surface_events);
    }

    if (data.abs) {
      absData.setAbsState(data.abs);
    }

    if (data.ambient_temperature) {
      temperatureData.setAmbientTemperature(data.ambient_temperature);
    }

    if (data.exceptional_tire_radii_change) {
      tireRadiiData.updateTireRadiiChangeState(data.exceptional_tire_radii_change);
    }

    if (data.tires_stiffness) {
      tireStiffnessData.updateTiresStiffness(data.tires_stiffness);
    }

    if (data.tire_radii_convergence) {
      tireRadiiData.updateTireRadiiConvergence(data.tire_radii_convergence);
    }

    if (data.vehicle_id) {
      dispatch(setVehicleId(data.vehicle_id));
    }

    if (data.vehicle_speed) {
      dispatch(setVehicleSpeed(data.vehicle_speed));
    }

    if (data.brake_torque) {
      dispatch(setBrakeTorque(data.brake_torque));
    }

    if (data.acceleration) {
      dispatch(setAcceleration(data.acceleration));
    }

    if (data.steering_angle) {
      steeringAngleData.updateSteeringAngle(data.steering_angle);
    }

    if (data.acc) {
      accData.updateAcc(data.acc);
    }

    if (data.weight) {
      vehicleWeightData.updateVehicleWeight(data.weight);
    }

    if (data.load_transfer) {
      vehicleWeightData.updateLoadTransfer(data.load_transfer);
    }

    if (data.relative_effective_rolling_radius) {
      tireRadiiData.updateRelativeRollingRadius(data.relative_effective_rolling_radius);
    }

    if (data.absolute_effective_rolling_radius) {
      tireRadiiData.updateAbsoluteRollingRadius(data.absolute_effective_rolling_radius);
    }

    recordingData.resetTimerForStoppingRunningRecording();

    streamData.resetTimerForStoppingRunningStream();
  };

  const onResetDashboardStatistics = (): void => {
    dispatch(resetDashboardStatistics());
  };

  const onToggleDashboardStatisticsCounting = (): void => {
    dispatch(togglePausedStatisticsCounting());
  };

  const resetStore = (): void => {
    dispatch(reset());
  };

  useEffect(() => {
    recordingData.resumeRecording();
  }, [lastDataSignalTimestamp]);

  useEffect(() => {
    if (streamData.selectedStream || recordingData.playingRecording) {
      dispatch(resetLastDataSignalTimestamp());
    }
  }, [streamData.selectedStream, recordingData.playingRecording]);

  return {
    gpsList: gpsData.gpsList,
    gripEvents: gripData.gripEvents,
    surfaceEvents: surfaceEventsData.surfaceEvents,
    tiresStiffnessEvents: tireStiffnessData.tiresStiffnessEvents,
    tiresRadiiChangeEvents: tireRadiiData.tiresRadiiChangeEvents,
    steeringAngle: steeringAngleData.steeringAngle,
    markers,
    isDataReset,
    isDataFullReset,
    vehicleState,
    tireRadiiConvergence: tireRadiiData.tireRadiiConvergence,
    ambientTemperature: temperatureData.ambientTemperature,
    absStateActive: absData.absStateActive,
    accStateActive: accData.accStateActive,
    vehicleWeight: vehicleWeightData.vehicleWeight,
    loadTransfer: vehicleWeightData.loadTransfer,
    dashboardStatistics,
    isPausedStatisticsCounting,
    lastDataSignalTimestamp,
    relativeEffectiveRollingRadius: tireRadiiData.relativeEffectiveRollingRadius,
    absoluteEffectiveRollingRadius: tireRadiiData.absoluteEffectiveRollingRadius,
    resetData,
    onUpdateVehicleInfo,
    resetVehicleStateAfterThirtySeconds,
    onResetDashboardStatistics,
    onToggleDashboardStatisticsCounting,
    resetStore,
  };
};
