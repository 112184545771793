import React, { PropsWithChildren } from 'react';
import { AvailableWidgets } from '../../index';
import './_dash.scss';

interface Props {
  id: AvailableWidgets;
  className?: string;
}

const DashboardCard: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const { className = '' } = props;

  // TODO: here will be list of enabled widgets from store or context
  // const dashboardWidgets = [];

  return <div className={`dash-card ${className}`}>{props.children}</div>;
};

export default DashboardCard;
