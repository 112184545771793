import React from 'react';
import '../../AccPanel/_acc-panel.scss';
import { Units } from '../../../../user/types';
import { transformTonsToLb } from '../../../../../../helpers/units';

export type WheelPosition = 'FL' | 'FR' | 'RL' | 'RR';
export interface WheelLoadProps {
  valueKg?: number;
  units: Units;
  wheelPosition: WheelPosition;
}

const WheelLoad: React.FC<WheelLoadProps> = (props: WheelLoadProps) => {
  const { valueKg, wheelPosition, units } = props;

  return (
    <span>
      {wheelPosition}:&nbsp;{valueWithUnits(valueKg, units)}
    </span>
  );
};

function valueWithUnits(valueKg: number | undefined, units: Units): string {
  if (!valueKg) {
    return '-';
  }

  if (units == Units.METRIC) {
    return `${valueKg} kg`;
  }

  const valueLbs = transformTonsToLb(valueKg / 1000);
  return `${valueLbs} lbs`;
}

export default React.memo(WheelLoad);
