import React, { ReactElement, useEffect, useState } from 'react';
import './index.scss';

export const RadioGroup = <OptionT extends RadioGroupOption>(
  props: RadioGroupProps<OptionT>,
): ReactElement<any, any> => {
  const initiallySelected = props.options[props.initialSelectedOption];
  const [selectedOption, setSelectedOption] = useState<RadioGroupOption>(initiallySelected);

  const onOptionChange: OnOptionChange = (optionId) => {
    const option = props.options.find((option) => option.id == optionId);
    if (!option) {
      throw Error(`Something went wrong, option ${optionId} is not part of ${props.options}`);
    }

    setSelectedOption(option);
    props.onOptionChange(option);
  };

  useEffect(() => {
    props.onOptionChange(initiallySelected);
  }, []);

  return (
    <div className={`tm-radio-group ${props.className || ''}`}>
      {props.options.map((option) => (
        <RadioButton
          key={option.id}
          id={option.id}
          label={option.label}
          checked={selectedOption.id == option.id}
          onChange={onOptionChange}
        />
      ))}
    </div>
  );
};

export const RadioButton: React.FC<RadioButtonProps> = (props) => {
  const onChange = () => {
    props.onChange(props.id);
  };

  const onLabelClick = (e: React.MouseEvent) => {
    // The label is bound to the radio input.
    // When clicking on the label, the label triggers a click on the input which propagates to the div.
    // If we don't stop this propagation,
    // the label will also propagate the click to the div and will cause another click event to happen.
    e.stopPropagation();
  };

  return (
    <div data-testid={props.id} className="tm-radio-group-button" onClick={onChange}>
      {/* The input is readonly because it doesn't have onChange event (it's on the parent div) */}
      <input id={props.id} type="radio" checked={props.checked} readOnly />
      <label htmlFor={props.id} onClick={onLabelClick}>
        {props.label}
      </label>
    </div>
  );
};

export interface RadioGroupProps<OptionT extends RadioGroupOption = RadioGroupOption> {
  options: OptionT[];
  initialSelectedOption: number;
  onOptionChange: (option: OptionT) => void;
  className?: string;
}

export interface RadioButtonProps {
  id: string;
  label: string;
  checked: boolean;
  onChange: OnOptionChange;
}

type OnOptionChange = (id: string) => void;

export interface RadioGroupOption {
  id: string;
  label: string;
}
