import React, { MouseEvent as ReactMouseEvent, PropsWithChildren, useEffect, useRef, useState } from 'react';

interface Dimensions {
  width: number;
  height: number;
}

interface CursorPosition {
  x: number;
  y: number;
}

interface Props {
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
  className?: string;
}

const ResizableBox: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {
  const { className = '', maxHeight, minHeight, maxWidth, minWidth, children } = props;

  const [dimensions, setDimensions] = useState<Dimensions>({
    width: minWidth || 0,
    height: minHeight || 0,
  });

  const [cursor, setCursor] = useState<CursorPosition>({
    x: 0,
    y: 0,
  });

  const [isResizeInProgress, setIsResizeInProgress] = useState<boolean>(false);

  const resizableBlock = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!resizableBlock.current) return;

    const { width, height } = resizableBlock.current.getBoundingClientRect();

    setDimensions({ width, height });
  }, [resizableBlock]);

  useEffect(() => {
    if (isResizeInProgress) {
      document.documentElement.addEventListener('mousemove', doDrag);
      document.documentElement.addEventListener('mouseup', stopDrag);
    }
  }, [isResizeInProgress]);

  const startDrag = (e: ReactMouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsResizeInProgress(true);

    const { clientX: x, clientY: y } = e;
    setCursor({ x, y });
  };

  const stopDrag = (): void => {
    setIsResizeInProgress(false);
    document.documentElement.removeEventListener('mousemove', doDrag);
    document.documentElement.removeEventListener('mouseup', stopDrag);
  };

  const doDrag = (e: MouseEvent): void => {
    if (!resizableBlock.current || !isResizeInProgress) return;
    let isHeightValid = true;
    let isWidthValid = true;

    const { clientX: x, clientY: y } = e;
    setCursor({ x, y });

    const newWidth = dimensions.width + e.clientX - cursor.x;
    const newHeight = dimensions.height + e.clientY - cursor.y;

    if (minWidth) {
      isWidthValid = newWidth >= minWidth;
    }

    if (maxWidth && isWidthValid) {
      isWidthValid = newWidth <= maxWidth;
    }

    if (minHeight) {
      isHeightValid = newHeight >= minHeight;
    }

    if (maxHeight && isHeightValid) {
      isHeightValid = newHeight <= maxHeight;
    }

    setDimensions((prev) => ({
      width: isWidthValid ? newWidth : prev.width,
      height: isHeightValid ? newHeight : prev.height,
    }));
  };

  return (
    <div
      className={className}
      ref={resizableBlock}
      style={{
        position: 'relative',
        width: dimensions.width || 'auto',
        height: dimensions.height || 'auto',
        background: '#262626',
        borderRadius: '6px',
        resize: 'both',
      }}
    >
      {children}
      <div
        className="resizer"
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          width: '12px',
          height: '12px',
          background: '#ff7727',
          cursor: 'pointer',
          borderRadius: '6px 0 0 0',
        }}
        onMouseDown={startDrag}
      />
    </div>
  );
};

export default React.memo(ResizableBox);
