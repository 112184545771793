import React, { useContext } from 'react';
import { ControlExpander } from 'shared';
import ResizableBox from '../../../../components/ResizableBox';
import VideoPlayer from '../../../../components/VideoPlayer';
import { getPathFromUrl } from '../../../../../helpers/common';
import { HookContext } from '../../../../contexts/HookContext';
import { PlayableType } from '../../../../../interfaces';

const VideoControl: React.FC = () => {
  const { useRecordings } = useContext(HookContext);
  const { playingRecording } = useRecordings;

  let videoPath: string | null = null;
  let speed = 1;
  const videoType = 'video/mp4';

  if (playingRecording && playingRecording.type === PlayableType.RECORDING && playingRecording.videoPath) {
    videoPath = playingRecording.videoPath;
    speed = playingRecording.speed;
  }

  return (
    <ControlExpander iconClass="icon-video" disable={!videoPath}>
      <ResizableBox minHeight={200} minWidth={250} maxHeight={400} maxWidth={550}>
        {videoPath && (
          <VideoPlayer
            options={{
              autoplay: true,
              muted: true,
              playbackRates: [speed],
              sources: [
                {
                  src: getPathFromUrl(videoPath),
                  type: videoType,
                },
              ],
            }}
          />
        )}
      </ResizableBox>
    </ControlExpander>
  );
};

export default React.memo(VideoControl);
