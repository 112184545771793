import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import { setTheme, themeSelector } from '../selections/store';
import MapControls from './MapControls';
import { Map as MapComponent } from 'shared';
import { Vehicle } from './Vehicle';
import { Markers } from './Markers';
import { HookContext } from '../../contexts/HookContext';
import { MapViewPort, Theme } from './interfaces';
import { IMapService } from './map.service';
import { MarkerType } from '../data/interfaces';
import { DashboardName } from '../selections/interfaces';
import { PlayableType } from '../../../interfaces';
import { eventIconNameToImage } from '../../../constants/symbology';
import { DEFAULT_MAX_3D_PITCH, DEFAULT_MAX_ZOOM, DEFAULT_MIN_ZOOM, themeToMapType } from '../../../constants/map';
import vehicleImage from '../../../assets/img/vehicle.png';
import { ILocalStorageService } from '../../services/LocalStorageService';
import { container } from '../../../configs/inversify';
import './style.scss';

interface Props {
  width: string;
  height: string;
  mapService: IMapService;
  onStyleLoad?: (map: mapboxgl.Map) => void;
  defaultZoom?: number;
  minZoom?: number;
  maxZoom?: number;
}

const localStorageService: ILocalStorageService = container.get('LocalStorageService');

const Map: React.FC<Props> = (props: Props) => {
  const { width, height, mapService, onStyleLoad } = props;

  const [isVehicleShowed, setIsVehicleShowed] = useState(false);
  const [mapViewport, setMapViewport] = useState<MapViewPort>({
    lat: 40.866667,
    lng: 34.566667,
    zoom: 0,
    pitch: 0,
  });
  const [mapInstance, setMapInstance] = useState<mapboxgl.Map | null>(null);
  const [shouldFollowVehicle, setShouldFollowVehicle] = useState<boolean>(false);

  const { useRecordings, useFilters, useData, useSelections, useStream, useSettings } = useContext(HookContext);
  const { gpsList, markers } = useData;
  const { filtersState } = useFilters;
  const { playingRecording } = useRecordings;
  const { vehicleInfoList } = useStream;
  const { selectedStream, selectedDashboard, selectedRecording } = useSelections;
  const { settings } = useSettings;

  const vehicleRef = useRef<Vehicle | null>(null);
  const markersRef = useRef<Markers | null>(null);

  const selectedTheme = useSelector(themeSelector);

  const dispatch = useDispatch();

  const toggleFollow = useCallback(() => {
    setShouldFollowVehicle(!shouldFollowVehicle);
  }, [shouldFollowVehicle, setShouldFollowVehicle]);

  const onClickZoom = useCallback(
    (isIncrease: boolean) => {
      mapService.changeZoom(isIncrease);
    },
    [mapService],
  );

  const onClickTarget = useCallback(() => {
    if (!gpsList.length) return;

    const lastGps = gpsList[gpsList.length - 1];
    const zoom = mapService.getMap()?.getZoom() || 15;

    mapService.navigate({ lng: lastGps.lon_deg, lat: lastGps.lat_deg, zoom, pitch: 0 }, 0);

    toggleFollow();
  }, [mapService, gpsList]);

  const zoomEvent = useCallback((e: WheelEventInit) => {
    const { deltaY } = e;

    if (deltaY && deltaY > 0) mapService.zoomOut();
    if (deltaY && deltaY < 0) mapService.zoomIn();
  }, []);

  const dragEvent = useCallback(() => {
    setShouldFollowVehicle(false);
  }, []);

  const addDragEvent = useCallback(() => {
    window.addEventListener('mousemove', dragEvent);
  }, []);

  const removeDragEvent = useCallback(() => {
    window.removeEventListener('mousemove', dragEvent);
  }, []);

  const toggleLightTheme = () => {
    dispatch(setTheme(selectedTheme === Theme.DARK ? Theme.LIGHT : Theme.DARK));
  };

  const onMapStyleLoadHandler = (map: mapboxgl.Map): void => {
    loadImagesToMap(map);

    vehicleRef.current?.rebuild();
    markersRef.current?.rebuild();
  };

  const onMapLoadHandler = (map: mapboxgl.Map): void => {
    setMapInstance(map);
    onStyleLoad && onStyleLoad(map);

    map.on('move', () => {
      const newCoords = mapService.getCoordinates();

      if (newCoords) {
        setMapViewport(newCoords);
      }
    });
  };

  const loadImagesToMap = (map: mapboxgl.Map): void => {
    map.loadImage(vehicleImage, (error, image) => {
      if (!error && image) {
        map.addImage('vehicle', image);
      }
    });

    Object.keys(eventIconNameToImage).forEach((key) => {
      const marker = eventIconNameToImage[key];

      map.loadImage(marker, (error, image) => {
        if (!error && image) {
          map.addImage(key, image);
        }
      });
    });
  };

  useEffect(() => {
    if (!mapInstance) return;

    setTimeout(() => {
      if (!vehicleRef.current) {
        vehicleRef.current = new Vehicle(mapInstance);
      }

      if (!markersRef.current) {
        markersRef.current = new Markers(mapInstance);
      }
    }, 2000);
  }, [mapInstance, vehicleRef.current, markersRef.current]);

  useEffect(() => {
    if (gpsList.length && !isVehicleShowed) {
      setIsVehicleShowed(true);
      mapService.focusOnGps(gpsList[0]);
    }
  }, [gpsList]);

  useEffect(() => {
    if (!selectedStream) return;

    const vehicleInfo = vehicleInfoList.find((item) => item.stream_id === selectedStream);

    if (vehicleInfo) {
      vehicleRef.current?.setVehicleInfo(vehicleInfo);
    } else {
      vehicleRef.current?.setVehicleInfo(null);
    }
  }, [vehicleRef.current, vehicleInfoList, selectedStream]);

  useEffect(() => {
    setIsVehicleShowed(false);
  }, [selectedStream, selectedRecording]);

  useEffect(() => {
    mapService.getMap()?.resize();
  }, [selectedDashboard]);

  useEffect(() => {
    if (mapInstance) {
      mapInstance.setStyle(themeToMapType[selectedTheme]);
    }
  }, [mapInstance, selectedTheme]);

  useEffect(() => {
    if (!gpsList.length) {
      vehicleRef.current?.clearVehicle();
    }

    vehicleRef.current?.setGpsList(gpsList);
  }, [gpsList, vehicleRef.current]);

  useEffect(() => {
    if (!markers.length) {
      markersRef.current?.closePopups();
    }

    markersRef.current?.setMarkerList(markers);
  }, [markers, markersRef.current]);

  useEffect(() => {
    markersRef.current?.setSettings({
      grip_fade_time_ms: settings.grip_fade_time_ms,
      surface_event_fade_time_ms: settings.surface_event_fade_time_ms,
      alert_fade_time_ms: settings.alert_fade_time_ms,
    });
  }, [settings, markersRef.current]);

  useEffect(() => {
    mapInstance?.scrollZoom?.enable();
    window.removeEventListener('mousewheel', zoomEvent);
    mapInstance?.off('mousedown', addDragEvent);

    if (shouldFollowVehicle) {
      mapService.getMap()?.scrollZoom?.disable();
      window.addEventListener('mousewheel', zoomEvent);
      mapInstance?.on('mousedown', addDragEvent);
      mapInstance?.off('mouseup', removeDragEvent);
      mapInstance?.on('mouseup', removeDragEvent);
    }
  }, [shouldFollowVehicle, mapInstance]);

  useEffect(() => {
    vehicleRef.current?.clearVehicle();
    markersRef.current?.closePopups();
  }, [selectedStream, playingRecording, selectedRecording]);

  useEffect(() => {
    if (playingRecording?.type === PlayableType.RECORDING) {
      vehicleRef.current?.setAnimationSpeed(playingRecording.speed);
    }

    vehicleRef.current?.setShouldFollowPosition(shouldFollowVehicle);
  }, [shouldFollowVehicle, playingRecording]);

  useEffect(() => {
    vehicleRef.current?.setTrailVisibility(filtersState.locationTrail);

    markersRef.current?.setMarkerTypeVisibility(MarkerType.TM_GRIP, filtersState.tm_grip);
    markersRef.current?.setMarkerTypeVisibility(MarkerType.OEM_GRIP, filtersState.oem_grip);
    markersRef.current?.setMarkerTypeVisibility(MarkerType.ARBITRATED_GRIP, filtersState.arbitrated_grip);
    markersRef.current?.setMarkerTypeVisibility(MarkerType.SURFACE_EVENT, filtersState.surface_events);
    markersRef.current?.setMarkerTypeVisibility(MarkerType.ALERT, filtersState.alerts);
  }, [vehicleRef.current, markersRef.current, filtersState]);

  return (
    <div>
      <MapComponent
        accessToken={process.env.REACT_APP_MAP_BOX_KEY || ''}
        authToken={localStorageService.getItem('Authorization') || ''}
        height={height}
        width={width}
        style={themeToMapType[selectedTheme]}
        center={{ lng: mapViewport.lng, lat: mapViewport.lat }}
        zoom={mapViewport.zoom}
        maxZoom={DEFAULT_MAX_ZOOM}
        minZoom={DEFAULT_MIN_ZOOM}
        maxPitch={DEFAULT_MAX_3D_PITCH}
        onMapLoad={onMapLoadHandler}
        onStyleLoad={onMapStyleLoadHandler}
      >
        <MapControls
          onClickTarget={onClickTarget}
          onChangeTo3d={mapService.changeTo3d}
          onZoomIn={shouldFollowVehicle ? mapService.zoomIn.bind(mapService, 1) : onClickZoom.bind(this, true)}
          onZoomOut={shouldFollowVehicle ? mapService.zoomOut.bind(mapService, 1) : onClickZoom.bind(this, false)}
          onToggleLightTheme={toggleLightTheme}
          className={selectedDashboard === DashboardName.NEW ? 'isDashOpen' : ''}
          followIsEnable={shouldFollowVehicle}
          currentTheme={selectedTheme}
        />
      </MapComponent>
    </div>
  );
};

export default Map;
