import React, { useContext, useEffect, useRef, useState } from 'react';
import { HookContext } from '../../../contexts/HookContext';
import { MAX_BOOKMARKS_COUNT } from '../../../../constants/common';
import { MapContext } from '../../../contexts/MapContext';
import BookmarkPopupList from '../BookmarkPopupList';
import { PlayableRecording, PlayableType } from '../../../../interfaces';
import { BarTimeline } from '../../../components/BarTimeline';
import BarControls from './BarControls';
import PlayingBarActions from './PlayingBarActions';
import BarTime from './BarTime';
import { ReplaySwitch } from '../../../components/ReplaySwitch';
import '../RecordingBar/style.scss';
import { getBookmarkText } from '../bookmarkText';

const TIME_LINE_PROGRESS_INTERVAL = 500;

interface Props {
  recording: PlayableRecording;
}

const PlayingBar: React.FC<Props> = (props) => {
  const { recording } = props;

  const [isAddingBookmarkBlocked, setIsAddingBookmarkBlocked] = useState(false);
  const [currentPlayTimeMilliseconds, setCurrentPlayTimeMilliseconds] = useState(0);
  const [durationMilliseconds, setDurationMilliseconds] = useState<number | undefined>(undefined);

  const { mapInstance } = useContext(MapContext);
  const { useSelections, useUser, useRecordings, useData, useBookmark } = useContext(HookContext);

  const {
    pauseRecording,
    continueRecording,
    setIsRecordingEnded,
    onToggleAutoReplay,
    isRecordingTimelinePaused,
    isRecordingEnded,
    isRecordingLoading,
    autoReplay,
    playingRecording,
  } = useRecordings;
  const { bookmarks, temporaryBookmarks, fetchRecordingBookmarks, addBookmark, deleteRecordingBookmark } = useBookmark;
  const { selectedRecordingSpeed } = useSelections;
  const { resetData } = useData;

  const timer = useRef<NodeJS.Timeout | null>(null);
  const bookmarksCount = bookmarks.length + temporaryBookmarks.length;

  const clearTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
  };

  const onTogglePause = () => {
    if (isRecordingTimelinePaused) {
      continueRecording(currentPlayTimeMilliseconds);
    } else {
      pauseRecording();
    }
  };

  const addBookmarkHandler = () => {
    if (
      isAddingBookmarkBlocked ||
      !mapInstance ||
      bookmarksCount >= MAX_BOOKMARKS_COUNT ||
      isRecordingEnded ||
      isRecordingLoading
    ) {
      return;
    }

    let bookmarksCounter = bookmarksCount + 1;
    bookmarks.forEach((bookmark) => {
      const bookmarkNumber = Number(bookmark.name.split('_')[1]);
      if (bookmarkNumber >= bookmarksCounter) {
        bookmarksCounter = bookmarkNumber + 1;
      }
    });

    const bookmarkName = `Bookmark_${bookmarksCounter}`;
    const currentTime = currentPlayTimeMilliseconds;
    const imageInDataUrl = mapInstance.getCanvas().toDataURL('image/jpeg', 0.3);
    const bookmarkText = getBookmarkText(useSelections, useData, useUser);

    addBookmark({
      id: Date.now().toString(),
      name: bookmarkName,
      position_ms: currentTime,
      text: bookmarkText,
      thumbnail: imageInDataUrl,
    });
  };

  const rewindToNextBookmark = () => {
    const nextBookmark = bookmarks.find((item) => item.position_ms > currentPlayTimeMilliseconds);

    if (!nextBookmark) return;

    pauseRecording();
    resetData(true);
    continueRecording(nextBookmark.position_ms);
    setCurrentPlayTimeMilliseconds(nextBookmark.position_ms);
  };

  const onDeleteBookmark = (bookmarkName: string) => {
    const bookmark = bookmarks.find((item) => item.name === bookmarkName);

    if (bookmark && playingRecording && playingRecording.type === 'recording') {
      deleteRecordingBookmark({
        recording: playingRecording,
        bookmarkId: bookmark.id,
      });
    }
  };

  useEffect(() => {
    if (!durationMilliseconds || isRecordingTimelinePaused) {
      clearTimer();
      return;
    }

    timer.current = setInterval(() => {
      setCurrentPlayTimeMilliseconds((prev) => {
        let newTime = prev + TIME_LINE_PROGRESS_INTERVAL * selectedRecordingSpeed;

        if (newTime >= durationMilliseconds) {
          clearTimer();
          setIsRecordingEnded(true);
          newTime = durationMilliseconds;
        }

        return newTime;
      });
    }, TIME_LINE_PROGRESS_INTERVAL);

    return () => clearTimer();
  }, [durationMilliseconds, isRecordingTimelinePaused]);

  useEffect(() => {
    if (playingRecording && playingRecording.type === PlayableType.RECORDING) {
      setDurationMilliseconds(playingRecording.duration);
    }
  }, [playingRecording]);

  useEffect(() => {
    const nearBookmark = [...bookmarks, ...temporaryBookmarks].find(
      (bookmark) =>
        currentPlayTimeMilliseconds > bookmark.position_ms - 5000 &&
        currentPlayTimeMilliseconds < bookmark.position_ms + 5000,
    );

    const isBlocked = !!nearBookmark || isRecordingEnded;

    setIsAddingBookmarkBlocked(isBlocked);
  }, [currentPlayTimeMilliseconds, isRecordingEnded]);

  useEffect(() => {
    if (isRecordingEnded && autoReplay) {
      pauseRecording();
      resetData(true);
      continueRecording(1);
      setCurrentPlayTimeMilliseconds(0);
      setIsRecordingEnded(false);
    }
  }, [isRecordingEnded, autoReplay]);

  useEffect(() => {
    fetchRecordingBookmarks(recording);
    setIsRecordingEnded(false);
  }, []);

  return (
    <>
      <div className="playbar-wrapper">
        <div className="playbar-bar">
          <PlayingBarActions
            isActive={isRecordingTimelinePaused || isRecordingEnded}
            isToggleDisabled={isRecordingLoading}
            isRewindDisabled={isRecordingLoading || isRecordingEnded || !bookmarks.length}
            onTogglePause={onTogglePause}
            onRewind={rewindToNextBookmark}
          />
          {durationMilliseconds && (
            <>
              <BarTimeline
                durationMilliseconds={durationMilliseconds}
                timeLeftMilliseconds={currentPlayTimeMilliseconds}
                bookmarks={[...temporaryBookmarks, ...bookmarks]}
              />
              <BarTime timeLeftMilliseconds={currentPlayTimeMilliseconds} durationMilliseconds={durationMilliseconds} />
            </>
          )}
          <BarControls
            bookmarks={[...temporaryBookmarks, ...bookmarks]}
            isAddBookmarkDisabled={
              isAddingBookmarkBlocked || bookmarksCount >= MAX_BOOKMARKS_COUNT || isRecordingEnded || isRecordingLoading
            }
            onAddBookmark={addBookmarkHandler}
            onDeleteBookmark={onDeleteBookmark}
          />
          <ReplaySwitch onToggle={onToggleAutoReplay} isToggled={autoReplay} />
        </div>
      </div>
      <BookmarkPopupList />
    </>
  );
};

export default React.memo(PlayingBar);
