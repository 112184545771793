import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { TapedStream, TapingStatus, VehicleInfo } from './interfaces';
import { fetchVehicleInfoList, startTaping, stopTaping, fetchTapedStreams, saveTaping } from './thunks';
import { setSelectedStream } from '../selections/store';
import { playRecording } from '../recordings/thunks';

export interface StreamState {
  isLoading: boolean;
  isError: boolean;
  vehicleInfoList: VehicleInfo[];
  startTapingTime: number | null;
  pauseTapingTime: number | null;
  tapingStatus: TapingStatus | null;
  tapedStreams: TapedStream[];
  streamUrl: string | null;
}

export const initialState: StreamState = {
  isLoading: false,
  isError: false,
  tapingStatus: null,
  startTapingTime: null,
  pauseTapingTime: null,
  vehicleInfoList: [],
  tapedStreams: [],
  // TODO: this will be used for streaming from vehicle cameras
  streamUrl:
    'https://15595674c0604ed6.mediapackage.eu-central-1.amazonaws.com/out/v1/cfeae6f26b7447a783b06b3157029a54/index.m3u8',
};

export const streamSlice = createSlice({
  name: 'stream',
  initialState,
  reducers: {
    setStartTapingTime: (state, action: PayloadAction<number | null>) => {
      state.startTapingTime = action.payload;
    },
    setPauseTapingTime: (state, action: PayloadAction<number | undefined>) => {
      state.pauseTapingTime = action.payload || Date.now();
    },
    deletePauseTapingTime: (state) => {
      state.pauseTapingTime = null;
    },
    setTapingStatus: (state, action: PayloadAction<TapingStatus>) => {
      state.tapingStatus = action.payload;
    },
    reset: (state) => {
      state = initialState;
      return state;
    },
  },
  extraReducers: {
    [fetchVehicleInfoList.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchVehicleInfoList.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.vehicleInfoList = action.payload;
    },
    [fetchVehicleInfoList.rejected.type]: (state) => {
      state.isLoading = false;
    },
    [startTaping.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [startTaping.fulfilled.type]: (state, action: PayloadAction<{ start_time_ms: number }>) => {
      state.isLoading = false;
      state.startTapingTime = action.payload.start_time_ms;
      state.tapingStatus = TapingStatus.STARTED;
    },
    [startTaping.rejected.type]: (state) => {
      state.isError = true;
    },
    [stopTaping.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [stopTaping.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.pauseTapingTime = Date.now();
      state.tapingStatus = TapingStatus.STOPPED;
    },
    [stopTaping.rejected.type]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    [saveTaping.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [saveTaping.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.pauseTapingTime = null;
      state.startTapingTime = null;
      state.tapingStatus = null;
    },
    [saveTaping.rejected.type]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    [fetchTapedStreams.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [fetchTapedStreams.fulfilled.type]: (state, action: PayloadAction<TapedStream[]>) => {
      state.isLoading = false;
      state.tapedStreams = action.payload;
    },
    [fetchTapedStreams.rejected.type]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    [playRecording.pending.type]: (state) => {
      state.tapingStatus = null;
      state.startTapingTime = null;
      state.pauseTapingTime = null;
    },
    [setSelectedStream.type]: (state) => {
      state.tapingStatus = null;
      state.startTapingTime = null;
      state.pauseTapingTime = null;
    },
  },
});

export const { setPauseTapingTime, deletePauseTapingTime, setStartTapingTime, setTapingStatus, reset } =
  streamSlice.actions;

export function vehicleInfoListSelector(state: RootState): VehicleInfo[] {
  return state.stream.vehicleInfoList;
}

export function startTapingTimeSelector(state: RootState): number | null {
  return state.stream.startTapingTime;
}

export function pauseTapingTimeSelector(state: RootState): number | null {
  return state.stream.pauseTapingTime;
}

export function tapedStreamsSelector(state: RootState): TapedStream[] {
  return state.stream.tapedStreams;
}

export function tapingStatusSelector(state: RootState): TapingStatus | null {
  return state.stream.tapingStatus;
}

export function streamUrlSelector(state: RootState): string | null {
  return state.stream.streamUrl;
}

export default streamSlice.reducer;
