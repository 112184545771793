import React, { useContext, useState } from 'react';
import { ConfirmModal } from 'shared';
import { HookContext } from '../../../contexts/HookContext';
import { RecordingDirectory } from '../RecordingDirectory';
import { RecordingPlayParams, SelectableRecording } from '../../../../interfaces';
import { RecordingModal } from '../RecordingModal';
import SliderControl from '../../../components/SliderControl';
import { RenameRecordingModal } from '../RenameRecordingModal';
import { Feature } from '../../../../constants/featureConfig';

interface Props {
  closeDropdown: () => void;
}

export const Recordings: React.FC<Props> = (props: Props) => {
  const { closeDropdown } = props;
  const [selectedRecording, setSelectedRecording] = useState<SelectableRecording | null>(null);

  const [uploadRecordingModalIsOpen, setUploadRecordingModalIsOpen] = useState<boolean>(false);
  const [recordingToRemove, setRecordingToRemove] = useState<SelectableRecording | null>(null);
  const [recordingToRename, setRecordingToRename] = useState<SelectableRecording | null>(null);

  const { useRecordings, useUser, useSelections } = useContext(HookContext);
  const { selectedRecordingSpeed, selectRecordingSpeed, selectRecording } = useSelections;
  const { availableRecordings, playRecording, dumpRecording, deleteRecording } = useRecordings;
  const { isFeatureEnabled } = useUser;

  const getPlayRecordingParams = (selectedRecording: SelectableRecording): RecordingPlayParams => {
    const isPrivate =
      !!availableRecordings.private[selectedRecording.directoryName] &&
      !!availableRecordings.private[selectedRecording.directoryName][selectedRecording.recordingName];

    return {
      recordingName: selectedRecording.recordingName,
      directoryName: selectedRecording.directoryName,
      speed: selectedRecordingSpeed,
      isPrivate,
    };
  };

  const play = () => {
    if (!selectedRecording) return;

    selectRecording(selectedRecording);

    const params = getPlayRecordingParams(selectedRecording);

    playRecording(params);
    closeDropdown();
  };

  const dump = () => {
    if (!selectedRecording) return;

    selectRecording(selectedRecording);

    const params = getPlayRecordingParams(selectedRecording);

    dumpRecording(params);
    closeDropdown();
  };

  const onCancelConfirmation = (): void => {
    setRecordingToRemove(null);
  };

  const onConfirmRemove = (): void => {
    if (recordingToRemove) {
      deleteRecording(recordingToRemove);
    }
    onCancelConfirmation();
  };

  return (
    <>
      <ConfirmModal
        title={`You are about to delete "${recordingToRemove?.recordingName || ''}"`}
        isOpen={!!recordingToRemove}
        cancelBtnText="Cancel"
        confirmBtnText="OK"
        onCancel={onCancelConfirmation}
        onConfirm={onConfirmRemove}
      />
      <div className="dropdown-settings">
        <div className="dropdown-settings-body">
          <span className="dropdown-list-title subtitle">Public recordings</span>
          {availableRecordings?.public &&
            Object.entries(availableRecordings.public).map(([title, recordings]) => (
              <RecordingDirectory
                key={title}
                title={title}
                recordings={recordings}
                selectedRecording={selectedRecording}
                onSelectRecording={setSelectedRecording}
              />
            ))}
          <span className="dropdown-list-title subtitle">
            My recordings
            {isFeatureEnabled(Feature.UPLOAD_RECORDINGS) && (
              <button
                className="dropdown-list-upload dropdown-footer-btn"
                onClick={setUploadRecordingModalIsOpen.bind(this, true)}
              >
                Upload
              </button>
            )}
          </span>
          {availableRecordings?.private &&
            Object.entries(availableRecordings?.private).map(([title, recordings]) => (
              <RecordingDirectory
                key={title}
                title={title}
                recordings={recordings}
                selectedRecording={selectedRecording}
                onSelectRecording={setSelectedRecording}
                onRemoveRecording={setRecordingToRemove}
                onRenameRecording={setRecordingToRename}
                isPrivate
                isEditEnabled={
                  isFeatureEnabled(Feature.UPDATE_RECORDINGS) && isFeatureEnabled(Feature.DELETE_RECORDINGS)
                }
              />
            ))}
        </div>
      </div>
      <div className="dropdown-footer">
        <button className="dropdown-footer-item dropdown-footer-btn j-center" onClick={dump}>
          Open
        </button>
        <div className="dropdown-footer-item">
          <button className="dropdown-footer-btn" onClick={play}>
            Play
          </button>
          <SliderControl
            value={selectedRecordingSpeed}
            onChange={selectRecordingSpeed}
            minValue={1}
            maxValue={20}
            changeStep={0.1}
            isTextEditable
          />
        </div>
      </div>
      {uploadRecordingModalIsOpen && <RecordingModal closeModal={setUploadRecordingModalIsOpen.bind(this, false)} />}
      {recordingToRename && (
        <RenameRecordingModal recording={recordingToRename} closeModal={setRecordingToRename.bind(this, null)} />
      )}
    </>
  );
};
