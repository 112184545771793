import React, { useContext } from 'react';
import DashboardCard from '../widgets/DashboardCard';
import { AvailableWidgets, DashboardContext } from '../index';
import GripDataWidget from '../widgets/GripDataWidget';
import { HookContext } from '../../../../contexts/HookContext';
import VehicleSignals from '../widgets/VehicleSignals';
import { filterGrips } from '../../../filters/gripFilter';
import CenterOfGravity from '../widgets/CenterOfGravity';
import VehicleWeightWidget from '../widgets/VehicleWeightWidget';

const WeightPanel: React.FC = () => {
  const { isMini, isTablet, isLaptop } = useContext(DashboardContext);

  const { useUser, useData, useFilters } = useContext(HookContext);
  const { userData } = useUser;

  const { isDataReset, gripEvents, vehicleWeight, loadTransfer } = useData;

  const { filtersState } = useFilters;

  return (
    <>
      {!isTablet && (
        <DashboardCard
          id={AvailableWidgets.VEHICLE_SIGNALS_PANEL}
          className="col col-lg col-xl-4 col-xxl-8 dash-vehicle-panel"
        >
          <VehicleSignals
            isMini={isMini || isLaptop}
            displayBreakTorqueHeatBar
            displayAccelerometer
            displaySpeedometer
            displayAbs
          />
        </DashboardCard>
      )}
      <DashboardCard id={AvailableWidgets.VEHICLE_SIGNALS_PANEL} className="col dash-acc-panel">
        {/* TODO: find out name of signal from vehicle */}
        <div className="acc-speed widget weight-widget">
          <VehicleWeightWidget weight={vehicleWeight} units={userData?.units}></VehicleWeightWidget>
          <CenterOfGravity loadTransfer={loadTransfer} units={userData?.units}></CenterOfGravity>
        </div>
      </DashboardCard>
      <DashboardCard id={AvailableWidgets.GRIP_DATA} className="col dash-graph-panel">
        {/* TODO: 0.3(30% from screen width) and 0.35(35% from grip data widget width) need to change on needed percents; */}
        <GripDataWidget
          key={isMini ? window.innerWidth + 1 : window.innerWidth}
          gripEvents={filterGrips(gripEvents, filtersState)}
          width={isMini ? window.innerWidth * 0.15 : isLaptop ? window.innerWidth * 0.28 : window.innerWidth * 0.2}
          height={isMini ? 60 : window.innerWidth * 0.3 * 0.35}
          linesCount={isMini ? 5 : 8}
          timeOfObserve={35}
          gripWidth={10}
          isDataReset={isDataReset}
          isMini={isMini}
        />
      </DashboardCard>
    </>
  );
};

export default React.memo(WeightPanel);
