import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

interface Props {
  name: string;
  onRename: (newName: string) => void;
  onCancel: () => void;
}

const BookmarkEditName: React.FC<Props> = (props: Props) => {
  const { name, onRename, onCancel } = props;

  const [newName, setNewName] = useState(name);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef.current]);

  const onConfirm = () => {
    const trimmedName = newName.trim();

    if (trimmedName.length && trimmedName !== name) {
      onRename(trimmedName);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
  };

  return (
    <div className="rec-dialog-form">
      <input type="text" className="rec-dialog-input" value={newName} onChange={onChange} ref={inputRef} />
      <button className="rec-dialog-btn" onClick={onConfirm}>
        <i className="rec-dialog-icon icon icon-checkmark" />
      </button>
      <button className="rec-dialog-btn" onClick={onCancel}>
        <i className="rec-dialog-icon icon icon-rotate" />
      </button>
    </div>
  );
};

export default React.memo(BookmarkEditName);
