import { Theme } from '../app/modules/map/interfaces';

export const DEFAULT_MIN_ZOOM = 1;
export const DEFAULT_MAX_ZOOM = 22;
export const DEFAULT_ZOOM = 1;
export const DEFAULT_PITCH = 0;
export const DEFAULT_3D_PITCH = 45;
export const DEFAULT_MAX_3D_PITCH = 60;
export const DEFAULT_3D_MAX_ZOOM = 25;
export const DEFAULT_BEARING = 0;
export const MAP_THEME = 'mapbox://styles/benz-tactile/ck70azee434jh1iodufvdvakx?optimize=true';

export enum MapType {
  STREET = 'street',
  SATELLITE = 'satellite',
}

export const mapTypes = {
  [MapType.SATELLITE]: 'mapbox://styles/mapbox/satellite-streets-v11?optimize=true',
  [MapType.STREET]: 'mapbox://styles/benz-tactile/ck70azee434jh1iodufvdvakx?optimize=true',
};

export const themeToMapType = {
  [Theme.LIGHT]: mapTypes[MapType.SATELLITE],
  [Theme.DARK]: mapTypes[MapType.STREET],
};

export const mapTypeToTheme = {
  [MapType.SATELLITE]: Theme.LIGHT,
  [MapType.STREET]: Theme.DARK,
};
