import React from 'react';
import '../_acc-panel.scss';

interface Props {
  children?: React.ReactNode;
}

export const AccSpeed: React.FC<Props> = (props: Props) => {
  const { children } = props;

  return <div className="acc-speed widget">{children}</div>;
};
