import React, { Ref, useRef, useState, useEffect, PropsWithChildren } from 'react';
import cn from 'classnames';
import IconButton from '../IconButton';

interface Props {
  iconClass: string;
  disable?: boolean;
}

export const ControlExpander: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {
  const { iconClass, disable = false, children } = props;

  const [isOpen, setIsOpen] = useState(false);

  const wrapper: Ref<HTMLDivElement> = useRef(null);

  const toggleHandler = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleClickAround = (e: MouseEvent) => {
      if (!wrapper.current) return;

      const { target } = e;
      if (target instanceof Element && !wrapper.current.contains(target)) {
        toggleHandler();
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleClickAround);

      return () => document.removeEventListener('click', handleClickAround);
    }
  }, [isOpen]);

  return (
    <div className="controls-group" ref={wrapper}>
      <IconButton
        buttonClassList={`controls-btn ${isOpen && 'active'}`}
        iconClassList={`controls-icon icon ${iconClass}`}
        onClickEvent={toggleHandler}
        testId="button"
        disabled={disable}
      />
      <div
        data-testid="controls-container"
        className={cn('controls-container', {
          isVisible: isOpen,
          isHidden: !isOpen,
        })}
      >
        {children}
      </div>
    </div>
  );
};
