import { useDispatch, useSelector } from 'react-redux';
import { setAbsStateActive, resetAbsStateActive } from './store';
import { EventWithCounter, DataState } from './state';
import { ABS } from '../../../interfaces';
import { useRef } from 'react';
import { Draft, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { setLastDataSignalTimeAsNow } from './signalsState';
import { RootState } from '../../store';

export const ABS_RESET_INTERVAL_MS = 2000;

export const useAbsData = (): UseAbsData => {
  const absStateActive = useSelector(absStateActiveSelector);
  const absResetTimeout = useRef<null | NodeJS.Timeout>(null);

  const dispatch = useDispatch();

  const setAbsState = (signal: ABS) => {
    dispatch(setAbsStateActive({ value: signal.is_on, counter: signal.counter }));

    if (absResetTimeout.current) {
      clearTimeout(absResetTimeout.current);
    }
    absResetTimeout.current = setTimeout(() => dispatch(resetAbsStateActive()), ABS_RESET_INTERVAL_MS);
  };

  return {
    absStateActive,
    setAbsState,
  };
};

export interface UseAbsData {
  absStateActive: boolean;
  setAbsState(signal: ABS): void;
}

export const initialAbsState: Pick<DataState, 'absStateActive'> = {
  absStateActive: { counter: 0, value: false },
};

export const absReducers: ValidateSliceCaseReducers<DataState, AbsReducers> = {
  setAbsStateActive: (state, action: PayloadAction<EventWithCounter<boolean>>) => {
    const { value, counter } = action.payload;

    if (state.absStateActive.counter >= counter) return;

    setLastDataSignalTimeAsNow(state);
    state.absStateActive = { value, counter };
  },
  resetAbsStateActive: (state) => {
    resetAbsStateToInitialValue(state);
  },
};

export interface AbsReducers extends SliceCaseReducers<DataState> {
  setAbsStateActive(state: Draft<DataState>, action: PayloadAction<EventWithCounter<boolean>>): void;
  resetAbsStateActive(state: Draft<DataState>): void;
}

export function resetAbsStateToInitialValue(state: Draft<DataState>): void {
  state.absStateActive = initialAbsState.absStateActive;
}

export function resetAbsStateCounter(state: Draft<DataState>): void {
  state.absStateActive.counter = -1;
}

export function absStateActiveSelector(state: RootState): boolean {
  return state.data.absStateActive.value;
}
