import React, { useEffect, useRef, useState } from 'react';
import { Grip } from '../../../../../../interfaces';
import { useFilters } from '../../../../filters/useFilters';
import { getRoundedFloat } from '../../../../../../helpers/common';
import './_grip.scss';
import { FiltersState } from '../../../../filters/store';

interface Props {
  gripEvents: Grip[];
  width: number;
  height: number;
  linesCount: number;
  timeOfObserve: number;
  gripWidth: number;
  isDataReset: boolean;
  isMini?: boolean;
}

type AnimatedGrip = {
  counter: number;
  received_time: number;
  quality: number;
  point: { start: number; end: number };
  mean_g: number;
  error_g: number;
};

const GripDataWidget: React.FC<Props> = (props: Props) => {
  const { gripEvents, width, height, linesCount, timeOfObserve, gripWidth, isDataReset, isMini } = props;
  const lineGap = Math.floor(width) / (linesCount - 1);

  const contentRef = useRef<HTMLCanvasElement>(null);
  const backgroundRef = useRef<HTMLCanvasElement>(null);
  const timelineRef = useRef<HTMLCanvasElement>(null);
  const animationRequestRef = useRef<number>();

  const [actualGrip, setActualGrip] = useState<Grip | null>(null);
  const [animatedGrips, setAnimatedGrips] = useState<AnimatedGrip[]>([]);
  const timeoutId = useRef<null | NodeJS.Timeout>(null);
  const [isAnimationEnabled, setIsAnimationEnabled] = useState<boolean>(false);
  const [gripInTimeline, setGripInTimeline] = useState<AnimatedGrip | null>();
  const [timeline, setTimeline] = useState<number>(timeOfObserve + 1);
  const { filtersState } = useFilters();

  const convertSecondsToPx = (seconds: number) => (lineGap / 5) * seconds;

  const paintLines = (count: number, lineGap: number) => paintLinesOnCanvas(backgroundRef, width, count, lineGap);

  const getEventHorizontalPosition = (timeOfEventReceipt: number): number => {
    const timeDifference = Math.abs((timeOfEventReceipt - Date.now()) / 1000);

    return width - convertSecondsToPx(timeDifference);
  };

  const paintRectangle = (x: number, y: number, width: number, height: number) =>
    paintRectangleOnCanvas(contentRef, x, y, width, height);

  const clearPaintedGrips = () => contentRef.current?.getContext('2d')?.clearRect(0, 0, width, height);

  const checkGripsOnTimeline = () => {
    const currentTimestamp = Date.now();
    const timelineInMs = timeline * 1000;
    const timelineGrips: AnimatedGrip[] = [];

    animatedGrips.forEach((grip) => {
      if (
        currentTimestamp - grip.received_time > timelineInMs &&
        currentTimestamp - grip.received_time < timelineInMs + gripWidth * 1000
      ) {
        timelineGrips.push(grip);
      }
    });

    if (timelineGrips.length) {
      const latestGrip = timelineGrips.reduce((prev, curr) => {
        if (prev) {
          return prev.counter > curr.counter ? prev : curr;
        } else {
          return curr;
        }
      });

      setGripInTimeline(latestGrip);
    } else {
      setGripInTimeline(null);
    }
  };

  const getValueElement = (mean_g: number, error_g: number): JSX.Element | null => {
    return (
      <>
        {getRoundedFloat(mean_g, 2)}&nbsp;(&#177;{getRoundedFloat(error_g, 2)})
      </>
    );
  };

  const setActualGripAsLastGripEvent = () => {
    if (gripEvents.length) {
      setActualGrip(gripEvents[gripEvents.length - 1]);
    } else {
      setActualGrip(null);
    }
  };
  useEffect(setActualGripAsLastGripEvent, [gripEvents]);

  useEffect(() => {
    if (timeoutId.current) {
      clearInterval(timeoutId.current);
    }

    if (isAnimationEnabled) {
      timeoutId.current = setInterval(checkGripsOnTimeline, 100);
    } else {
      setGripInTimeline(null);
    }
  }, [animatedGrips, timeline, isAnimationEnabled]);

  const enableAnimationIfAnyPainted = () => {
    if (!animatedGrips.length) {
      setIsAnimationEnabled(false);
    } else {
      setIsAnimationEnabled(true);
    }
  };
  useEffect(enableAnimationIfAnyPainted, [animatedGrips]);

  const isTooOldToPaint = (grip: Grip | AnimatedGrip) => {
    return !grip.received_time || grip.received_time <= Date.now() - timeOfObserve * 2 * 1000;
  };

  const repaintAnimatedGrips = (): AnimatedGrip[] => {
    clearPaintedGrips();
    const sortedGrips = [...animatedGrips].sort((a, b) => a.counter - b.counter);

    const notPainted: AnimatedGrip[] = [];

    sortedGrips.forEach((animatedGrip: AnimatedGrip) => {
      if (isTooOldToPaint(animatedGrip)) {
        notPainted.push(animatedGrip);
        return;
      }

      const x = getEventHorizontalPosition(animatedGrip.received_time);
      if (x <= -width) {
        notPainted.push(animatedGrip);
        return;
      }

      const percentHeight = (animatedGrip.point.end - animatedGrip.point.start) * 71.43;
      const percentY = animatedGrip.point.start * 71.43;
      const gripHeight = (height / 100) * percentHeight;
      const y = height - (height / 100) * percentY - gripHeight;

      paintRectangle(x, Math.round(y), convertSecondsToPx(gripWidth), Math.round(gripHeight));
    });

    return notPainted;
  };

  const removeAnimatedGripsByCounters = (counters: number[]) => {
    if (counters.length > 0) {
      setAnimatedGrips((prev) => prev.filter((g) => counters.indexOf(g.counter) < 0));
    }
  };

  const animateGripsRemovingUnpainted = () => {
    const notPainted = repaintAnimatedGrips();

    const countersToRemove = notPainted.map((g) => g.counter);
    removeAnimatedGripsByCounters(countersToRemove);
  };

  const replaceGripAnimation = () => {
    let animationRequestId: number | undefined;

    const animationLoop = () => {
      if (animationRequestRef.current !== animationRequestId) {
        return;
      }

      animateGripsRemovingUnpainted();

      animationRequestId = requestAnimationFrame(animationLoop);
      animationRequestRef.current = animationRequestId;
    };

    animationRequestId = requestAnimationFrame(animationLoop);
    animationRequestRef.current = animationRequestId;

    return () => {
      if (animationRequestRef.current) {
        cancelAnimationFrame(animationRequestRef.current);
        animationRequestRef.current = undefined;
      }
    };
  };
  useEffect(replaceGripAnimation, [animatedGrips]);

  const stopAnimatingRemovedGrips = () => {
    const gripCounters = gripEvents.map((g) => g.counter);
    const animatedCounters = animatedGrips.map((g) => g.counter);
    const countersToRemove = animatedCounters.filter((c) => gripCounters.indexOf(c) < 0);
    removeAnimatedGripsByCounters(countersToRemove);
  };

  const addGripToAnimationList = (event: Grip) => {
    if (event.received_time && !isTooOldToPaint(event)) {
      const point = startEndPoint(event);
      const animatedGrip: AnimatedGrip = {
        received_time: event.received_time,
        point,
        quality: event.quality,
        counter: event.counter,
        mean_g: event.mean_g,
        error_g: event.error_g,
      };

      const hasNewerGripOnSamePoint = animatedGrips.find(
        (grip) => grip.counter > event.counter && grip.point.start === point.start && grip.point.end === point.end,
      );

      if (hasNewerGripOnSamePoint) {
        return;
      }

      setAnimatedGrips((prev) => [...prev, animatedGrip]);
    }
  };

  useEffect(() => {
    stopAnimatingRemovedGrips();

    gripEvents.forEach((event) => {
      const isAlreadyAnimated = !!animatedGrips.find((grip) => grip.counter === event.counter);

      if (!isAlreadyAnimated && !isTooOldToPaint(event)) {
        addGripToAnimationList(event);
      }
    });
  }, [gripEvents]);

  useEffect(() => {
    paintLines(linesCount, lineGap);

    const timeline = timelineRef.current;

    if (timeline) {
      timeline.onmousemove = (e) => {
        const newTimeline = (width - e.offsetX) / (lineGap / 5);

        setTimeline(newTimeline);
      };

      timeline.onmouseleave = () => {
        setTimeline(timeOfObserve + 999);
      };
    }

    window.onfocus = () => {
      const context = contentRef.current?.getContext('2d');

      if (context) context.clearRect(0, 0, width, height);
    };
  }, []);

  useEffect(() => {
    const context = timelineRef.current?.getContext('2d');
    const timelineInPx = width - convertSecondsToPx(timeline);

    if (context) {
      context.clearRect(0, 0, width, height);
      context.beginPath();
      context['strokeStyle'] = 'white';
      context.moveTo(timelineInPx, 0);
      context.lineTo(timelineInPx, width as number);
      context.stroke();
    }
  }, [timeline]);

  useEffect(() => {
    setAnimatedGrips([]);
  }, [isDataReset]);

  return (
    <>
      <div className="grip-title-container">
        <span className="grip-title">{gripTypeTitle(filtersState)}</span>
        <span className="grip-subtitle">
          {actualGrip ? <>{getValueElement(actualGrip.mean_g, actualGrip.error_g)}&nbsp;</> : '-'}
        </span>
      </div>
      <div className="grip-container">
        <div style={{ height: height, width: width, position: 'relative' }} className="grip-dash-container">
          <>
            <canvas key={width + 1} ref={backgroundRef} width={width} height={height} className="grip-area" />
            {!isDataReset && (
              <canvas key={width + 2} ref={contentRef} width={width} height={height} className="grip-area" />
            )}
            <canvas key={width + 3} ref={timelineRef} width={width} height={height} className="grip-area" />
          </>
          {isMini ? (
            <>
              <ul className="grip-vertical">
                <li className="grip-line-val">1.4</li>
                <li className="grip-line-val">0.7</li>
                <li className="grip-line-val">0</li>
              </ul>
              <ul className="grip-line">
                <li className="grip-line-val">20 s</li>
                <li className="grip-line-val">15 s</li>
                <li className="grip-line-val">10 s</li>
                <li className="grip-line-val">5 s</li>
                <li className="grip-line-val">0 s</li>
              </ul>
            </>
          ) : (
            <>
              <ul className="grip-vertical">
                <li className="grip-line-val">1.4</li>
                <li className="grip-line-val">1.05</li>
                <li className="grip-line-val">0.7</li>
                <li className="grip-line-val">0.35</li>
                <li className="grip-line-val">0</li>
              </ul>
              <ul className="grip-line">
                <li className="grip-line-val">35 s</li>
                <li className="grip-line-val">30 s</li>
                <li className="grip-line-val">25 s</li>
                <li className="grip-line-val">20 s</li>
                <li className="grip-line-val">15 s</li>
                <li className="grip-line-val">10 s</li>
                <li className="grip-line-val">5 s</li>
                <li className="grip-line-val">0 s</li>
              </ul>
            </>
          )}
          {/* TODO: should appear above the cursor, cursor line visible on hover state*/}
          <span
            className="grip-dash-val"
            style={{
              position: 'absolute',
              left: width - convertSecondsToPx(timeline),
              display: timeline <= timeOfObserve ? 'block' : 'none',
            }}
          >
            {gripInTimeline ? (
              <>
                {getValueElement(gripInTimeline.mean_g, gripInTimeline.error_g)}&nbsp;<span>Mu</span>
              </>
            ) : (
              '-'
            )}
          </span>
        </div>
      </div>
    </>
  );
};

export default GripDataWidget;

function gripTypeTitle(filtersState: FiltersState): string {
  const sources = [];

  if (filtersState.oem_grip) {
    sources.push('OEM');
  }

  if (filtersState.tm_grip) {
    sources.push('TM');
  }

  if (filtersState.arbitrated_grip) {
    sources.push('Arbitrated');
  }

  const source_string = sources.length === 3 ? 'ALL' : sources.length === 0 ? 'None' : sources.join(', ');

  return `Grip (source: ${source_string})`;
}

function startEndPoint(grip: Grip | AnimatedGrip) {
  const { mean_g, error_g } = grip;
  const start = getRoundedFloat(mean_g - error_g, 2);
  const end = getRoundedFloat(mean_g + error_g, 2);

  return { start, end };
}

function paintLinesOnCanvas(canvas: React.RefObject<HTMLCanvasElement>, width: number, count: number, lineGap: number) {
  const context = canvas.current?.getContext('2d');

  if (context) {
    for (let i = 1, x = 0, y = 0; i <= count + 1; i++) {
      context.beginPath();
      context.moveTo(x, y);
      context.lineTo(x, width as number);
      context.stroke();
      x = x + lineGap;
    }
  }
}

function paintRectangleOnCanvas(
  canvas: React.RefObject<HTMLCanvasElement>,
  x: number,
  y: number,
  width: number,
  height: number,
) {
  const context = canvas.current?.getContext('2d');

  if (context) {
    const gradient = context.createLinearGradient(x, x / 2, x + width, x / 2);
    gradient.addColorStop(0, 'rgba(218, 101, 0, 1)');
    gradient.addColorStop(0.2, 'rgba(218, 101, 0, 0.8)');
    gradient.addColorStop(0.5, 'rgba(218, 101, 0, 0.5)');
    gradient.addColorStop(0.8, 'rgba(218, 101, 0, 0.2)');
    gradient.addColorStop(1, 'rgba(218, 101, 0, 0)');

    context.clearRect(x + 1, 0, width, 999);
    context['fillStyle'] = gradient;

    for (let i = 0; i <= 20; ++i) {
      context.fillRect(x + (width / 20) * i, y, width / 20 - 1, height);
    }
  }
}
