import { getRoundedFloat } from '../../../helpers/common';
import 'reflect-metadata';
import { injectable } from 'inversify';
import { round } from 'lodash';

export interface IPerformanceLoggerService {
  enableLoggingMemoryMetric: () => void;
  disableLoggingMemoryMetric: () => void;
  enableLoggingAverageAmountOfMessages: () => void;
  increaseMessageCounter: () => void;
}

@injectable()
export class PerformanceLoggerService implements IPerformanceLoggerService {
  // TODO: here must be 30000 (30 sec)
  private loggingDelay = 30000;

  private memoryLogTimer: NodeJS.Timeout | undefined;
  private messageLogTimer: NodeJS.Timeout | undefined;

  private messagesPerPeriodChecks: number[] = [];
  private tempMessageCounter = 0;
  private messageCounter = 0;

  static logMemoryMetric(): void {
    const performance = window.performance;
    if ('memory' in performance) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { jsHeapSizeLimit, totalJSHeapSize, usedJSHeapSize } = window.performance.memory;
      const roundedJsHeapSizeLimit = getRoundedFloat(jsHeapSizeLimit / 1024 / 1024, 2);
      const roundedTotalJSHeapSize = getRoundedFloat(totalJSHeapSize / 1024 / 1024, 2);
      const roundedUsedJSHeapSize = getRoundedFloat(usedJSHeapSize / 1024 / 1024, 2);

      let output = `*********\n\n`;
      output += `Heap size limit: ${jsHeapSizeLimit} bytes (${roundedJsHeapSizeLimit} mb),\n`;
      output += `Total heap size: ${totalJSHeapSize} bytes (${roundedTotalJSHeapSize} mb),\n`;
      output += `Used heap size: ${usedJSHeapSize} bytes (${roundedUsedJSHeapSize} mb)\n\n`;
      output += `*********`;

      console.log(output);
    }
  }

  public enableLoggingMemoryMetric(): void {
    this.memoryLogTimer = setInterval(() => {
      PerformanceLoggerService.logMemoryMetric();
    }, this.loggingDelay);
  }

  public disableLoggingMemoryMetric(): void {
    if (this.memoryLogTimer) {
      clearInterval(this.memoryLogTimer);
      this.memoryLogTimer = undefined;
    }
  }

  private logAverageAmountOfMessages(): void {
    this.messagesPerPeriodChecks = [...this.messagesPerPeriodChecks, this.tempMessageCounter];

    const averageMessagesNumber = round(
      this.messagesPerPeriodChecks.reduce((a, b) => a + b, 0) / this.messagesPerPeriodChecks.length,
    );

    this.tempMessageCounter = 0;

    console.log(`*******\nAverage amount of messages per 30 sec: ${averageMessagesNumber}\n********`);
  }

  public enableLoggingAverageAmountOfMessages(): void {
    this.messageLogTimer = setInterval(() => {
      this.logAverageAmountOfMessages();
    }, this.loggingDelay);
  }

  public disableLoggingAverageAmountOfMessages(): void {
    if (this.messageLogTimer) {
      clearInterval(this.messageLogTimer);
      this.messageLogTimer = undefined;
    }
  }

  public increaseMessageCounter(): void {
    if (process.env.NODE_ENV !== 'test') {
      console.log(`Received messages: ${this.messageCounter + 1}`);
    }
    this.tempMessageCounter += 1;
    this.messageCounter += 1;
  }
}
