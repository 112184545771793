import React from 'react';

interface Props {
  isActive: boolean;
  isDisabled: boolean;
  onToggle: () => void;
}

const RecordingBarActions: React.FC<Props> = (props: Props) => {
  const { isActive, isDisabled, onToggle } = props;

  return (
    <div className="playbar-actions">
      <button className={`playbar-btn-rec ${isActive ? 'end' : 'start'}`} onClick={onToggle} disabled={isDisabled}>
        {isActive ? 'End rec.' : 'Start rec.'}
      </button>
    </div>
  );
};

export default React.memo(RecordingBarActions);
