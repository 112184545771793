import React, { ChangeEvent } from 'react';

interface Props {
  name: string;
  onChange: (value: string) => void;
}

const RecordingEditName = React.forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const { name, onChange } = props;

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div>
      <label htmlFor="recName" className="rec-dialog-label">
        Recording Name:
      </label>
      <input type="text" id="recName" className="rec-dialog-input" value={name} onChange={onChangeHandler} ref={ref} />
    </div>
  );
});

RecordingEditName.displayName = 'RecordingEditName';

export default React.memo(RecordingEditName);
