import React from 'react';
import { Bookmark as BookmarkType } from '../../modules/bookmark/interfaces';
import Bookmark from '../../modules/recordings/PlayingBar/Bookmark';

interface Props {
  durationMilliseconds: number;
  timeLeftMilliseconds: number;
  bookmarks: BookmarkType[];
}

export const BarTimeline: React.FC<Props> = (props) => {
  const { durationMilliseconds, timeLeftMilliseconds, bookmarks } = props;

  const progress = (100 / durationMilliseconds) * timeLeftMilliseconds;

  return (
    <div className="playbar-timeline">
      <div className="playbar-timeline-fill" style={{ width: `${progress}%` }} />
      {bookmarks.map(({ name, position_ms }) => (
        <Bookmark key={name} positionInPercent={(100 / durationMilliseconds) * position_ms} />
      ))}
    </div>
  );
};
