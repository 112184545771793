import React, { PropsWithChildren } from 'react';
import './_widget.scss';

interface Props {
  title: string;
  value?: string;
  afterValueText?: string;
  className?: string;
}

export const Widget: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {
  const { title, value = '', afterValueText = '', className = '' } = props;

  return (
    <div className={`widget ${className}`}>
      <div className="widget-heading">
        <p className="widget-title">{title}</p>
        {value && (
          <p className="widget-value">
            <span className="value-big">{Math.round(+value * 100) / 100}</span>
            &nbsp;
            <span className="value-small">{afterValueText}</span>
          </p>
        )}
      </div>
      <div className="widget-body">{props.children}</div>
    </div>
  );
};
