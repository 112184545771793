import React, { useState } from 'react';
import { SelectableRecording } from '../../../../interfaces';
import Recording from './Recording';

interface Props {
  title: string;
  recordings: { [key: string]: string[] };
  selectedRecording: SelectableRecording | null;
  onSelectRecording: (recording: SelectableRecording) => void;
  onRemoveRecording?: (recording: SelectableRecording) => void;
  onRenameRecording?: (recording: SelectableRecording) => void;
  disabled?: boolean;
  isPrivate?: boolean;
  isEditEnabled?: boolean;
}

export const RecordingDirectory: React.FC<Props> = (props: Props) => {
  const {
    title,
    recordings,
    selectedRecording,
    onSelectRecording,
    onRemoveRecording,
    onRenameRecording,
    disabled,
    isPrivate = false,
    isEditEnabled = false,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const onSelectRecordingHandler = (recording: SelectableRecording): void => {
    onSelectRecording(recording);
  };

  const onRemoveRecordingHandler = (recording: SelectableRecording): void => {
    onRemoveRecording && onRemoveRecording(recording);
  };

  const onRenameRecordingHandler = (recording: SelectableRecording): void => {
    onRenameRecording && onRenameRecording(recording);
  };

  return (
    <>
      <div className="dropdown-list-container" key={title}>
        <div className="d-flex a-center" onClick={() => setIsExpanded(!isExpanded)}>
          <button
            className={`dropdown-list-btn ${isExpanded ? 'isActive' : ''}`}
            {...(disabled ? { disabled: false } : '')}
          >
            +
          </button>
          <span className="dropdown-list-title">
            <span className="dropdown-record-title">{title}</span>
          </span>
        </div>
        <ul className="dropdown-list" style={!isExpanded ? { display: 'none' } : { display: 'block' }}>
          {Object.keys(recordings).map((recordingName) => (
            <Recording
              key={recordingName}
              recordingName={recordingName}
              recordingDirectory={title}
              isSelected={
                title === selectedRecording?.directoryName && recordingName === selectedRecording.recordingName
              }
              onSelect={onSelectRecordingHandler}
              onRemove={onRemoveRecordingHandler}
              onRename={onRenameRecordingHandler}
              isPrivate={isPrivate}
              isEditEnabled={isEditEnabled}
            />
          ))}
        </ul>
      </div>
    </>
  );
};
