import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearState, userStateSelector } from './store';
import { loginUser } from './thunks';
import { IUserService } from './service';
import { container } from '../../../configs/inversify';
import { ILocalStorageService } from '../../services/LocalStorageService';
import { LoginFieldErrors, LoginFormFields, LoginState } from './types';

interface ReturnValue {
  form: LoginFormFields;
  errors: LoginFieldErrors;
  onChangeForm: (field: string, value: string | boolean) => void;
  onSubmit: () => void;
}

const userService: IUserService = container.get('UserService');
const localStorageService: ILocalStorageService = container.get('LocalStorageService');

export const useForm = (initForm: LoginFormFields, initErrors: LoginFieldErrors): ReturnValue => {
  const [form, setForm] = useState<LoginFormFields>(initForm);
  const [errors, setErrors] = useState<LoginFieldErrors>(initErrors);
  const userState = useSelector(userStateSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    setForm(localStorageService.getItem('login-credentials') || initForm);
    dispatch(clearState());
  }, []);

  useEffect(() => {
    if (userState === LoginState.FAILED_LOGGING_IN) {
      setErrors({
        username: 'Field is not valid',
        password: 'Field is not valid',
      });
    }
  }, [userState]);

  const onChangeForm = (field: string, value: string | boolean) => {
    setErrors((prev) => ({
      ...prev,
      [field]: '',
    }));

    setForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const onSubmit = () => {
    if (!userService.validateFields(form)) {
      setErrors({
        username: 'Field is not valid',
        password: 'Field is not valid',
      });
    } else {
      dispatch(loginUser(form));
    }
  };

  return { form, errors, onChangeForm, onSubmit };
};
