import { Draft, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { Acceleration, BrakeTorque, VehicleSpeed, VehicleState } from '../../../interfaces';
import { DataState } from './state';
import { RootState } from '../../store';
import { setLastDataSignalTimeAsNow } from './signalsState';

export const initVehicleState: Pick<DataState, 'vehicleState'> = {
  vehicleState: {
    vehicle_id: '1',
    vehicle_speed: {
      counter: 1,
      speed_kmh: 0,
    },
    brake_torque: {
      counter: 1,
      brake_torque_Nm: 0,
    },
    acceleration: {
      counter: 0,
      lon_g: 0,
      lat_g: 0,
    },
  },
};

export const vehicleStateReducers: ValidateSliceCaseReducers<DataState, VehicleStateReducers> = {
  setVehicleId: (state: Draft<DataState>, action: PayloadAction<string>) => {
    state.vehicleState.vehicle_id = action.payload;
  },
  setVehicleSpeed: (state: Draft<DataState>, action: PayloadAction<VehicleSpeed>) => {
    if (state.vehicleState.vehicle_speed.counter >= action.payload.counter) return;

    setLastDataSignalTimeAsNow(state);
    state.vehicleState.vehicle_speed = action.payload;
  },
  setBrakeTorque: (state: Draft<DataState>, action: PayloadAction<BrakeTorque>) => {
    if (state.vehicleState.brake_torque.counter >= action.payload.counter) return;

    setLastDataSignalTimeAsNow(state);
    state.vehicleState.brake_torque = action.payload;
  },
  setAcceleration: (state: Draft<DataState>, action: PayloadAction<Acceleration>) => {
    if (state.vehicleState.acceleration.counter >= action.payload.counter) return;

    setLastDataSignalTimeAsNow(state);
    state.vehicleState.acceleration = action.payload;
  },
};

export interface VehicleStateReducers extends SliceCaseReducers<DataState> {
  setVehicleId(state: Draft<DataState>, action: PayloadAction<string>): void;
  setVehicleSpeed(state: Draft<DataState>, action: PayloadAction<VehicleSpeed>): void;
  setBrakeTorque(state: Draft<DataState>, action: PayloadAction<BrakeTorque>): void;
  setAcceleration(state: Draft<DataState>, action: PayloadAction<Acceleration>): void;
}

export function resetVehicleStateToInitialValue(state: Draft<DataState>): void {
  state.vehicleState = initVehicleState.vehicleState;
}

export function resetVehicleStateCounters(state: Draft<DataState>): void {
  state.vehicleState.vehicle_speed.counter = -1;
  state.vehicleState.acceleration.counter = -1;
  state.vehicleState.brake_torque.counter = -1;
}

export function vehicleStateSelector(state: RootState): VehicleState {
  return state.data.vehicleState;
}
