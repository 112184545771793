import { useDispatch, useSelector } from 'react-redux';
import { PlayableRecording } from '../../../interfaces';
import {
  Bookmark,
  DeleteRecordingBookmarkParams,
  DeleteTapingBookmarkParams,
  SaveRecordingBookmarkParams,
  SaveTapingBookmarkParams,
  UpdateTapingBookmarkParams,
} from './interfaces';
import { addTemporaryBookmark, bookmarksSelector, deleteTemporaryBookmark, temporaryBookmarksSelector } from './store';
import * as bookmarkThunks from './thunks';

export interface ReturnValue {
  temporaryBookmarks: Bookmark[];
  bookmarks: Bookmark[];
  fetchTapingBookmarks: (streamId: string) => void;
  fetchRecordingBookmarks: (recording: PlayableRecording) => void;
  addBookmark: (bookmark: Bookmark) => void;
  saveTapingBookmark: (params: SaveTapingBookmarkParams) => void;
  saveRecordingBookmark: (params: SaveRecordingBookmarkParams) => void;
  updateTapingBookmark: (params: UpdateTapingBookmarkParams) => void;
  deleteTapingBookmark: (params: DeleteTapingBookmarkParams) => void;
  deleteRecordingBookmark: (params: DeleteRecordingBookmarkParams) => void;
  deleteTempBookmark: (bookmark: Bookmark) => void;
}

export const useBookmark = (): ReturnValue => {
  const bookmarks = useSelector(bookmarksSelector);
  const temporaryBookmarks = useSelector(temporaryBookmarksSelector);

  const dispatch = useDispatch();

  const addBookmark = (bookmark: Bookmark) => {
    dispatch(addTemporaryBookmark(bookmark));
  };

  const deleteTempBookmark = (bookmark: Bookmark) => {
    dispatch(deleteTemporaryBookmark(bookmark));
  };

  const fetchTapingBookmarks = (streamId: string) => {
    dispatch(bookmarkThunks.fetchTapingBookmarks(streamId));
  };

  const fetchRecordingBookmarks = (recording: PlayableRecording) => {
    dispatch(bookmarkThunks.fetchRecordingBookmarks(recording));
  };

  const saveTapingBookmark = (params: SaveTapingBookmarkParams) => {
    dispatch(bookmarkThunks.saveTapingBookmark(params));
  };

  const saveRecordingBookmark = (params: SaveRecordingBookmarkParams) => {
    dispatch(bookmarkThunks.saveRecordingBookmark(params));
  };

  const updateTapingBookmark = (params: UpdateTapingBookmarkParams) => {
    dispatch(bookmarkThunks.updateTapingBookmark(params));
  };

  const deleteTapingBookmark = (params: DeleteTapingBookmarkParams) => {
    dispatch(bookmarkThunks.deleteTapingBookmark(params));
  };

  const deleteRecordingBookmark = (params: DeleteRecordingBookmarkParams) => {
    dispatch(bookmarkThunks.deleteRecordingBookmark(params));
  };

  return {
    bookmarks,
    temporaryBookmarks,
    addBookmark,
    deleteTempBookmark,
    fetchTapingBookmarks,
    fetchRecordingBookmarks,
    saveTapingBookmark,
    saveRecordingBookmark,
    updateTapingBookmark,
    deleteTapingBookmark,
    deleteRecordingBookmark,
  };
};
