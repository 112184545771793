import React from 'react';
import { UpdatePassword as UpdatePasswordComponent, RequestPasswordUpdate, RequestPasswordUpdateFields } from 'shared';
import { LOGIN } from '../../../constants/pathnames';
import { container } from '../../../configs/inversify';
import { IUserService } from './service';
import './updatePassword.page.scss';

const userService: IUserService = container.get('UserService');

export const UpdatePassword: React.FC = () => {
  const requestPasswordUpdate: RequestPasswordUpdate = async (fields: RequestPasswordUpdateFields) => {
    return userService.updatePassword({ password: fields.newPassword, token: fields.resetToken });
  };

  return (
    <UpdatePasswordComponent loginPath={LOGIN} requestPasswordUpdate={requestPasswordUpdate}></UpdatePasswordComponent>
  );
};
