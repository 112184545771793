import React from 'react';
import { Bookmark } from '../../../bookmark/interfaces';
import BookmarkItem from './BookmarkItem';

interface Props {
  bookmarks: Bookmark[];
  onRename: (oldName: string, newName: string) => void;
  onDelete: (name: string) => void;
}

const BookmarkList: React.FC<Props> = (props: Props) => {
  const { bookmarks, onDelete, onRename } = props;

  return (
    <div className="rec-dialog-bookmarks">
      <span className="rec-dialog-label">Bookmarks ({bookmarks.length}):</span>
      {bookmarks.map((item) => (
        <BookmarkItem bookmark={item} key={item.name} onDelete={onDelete} onRename={onRename} />
      ))}
    </div>
  );
};

export default React.memo(BookmarkList);
