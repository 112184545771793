import React, { useContext } from 'react';
import cn from 'classnames';
import { DashboardContext, DashboardTab } from '../../../modules/main/CarDashboard';
import './_tabs.scss';

export interface TabItem {
  id: DashboardTab;
  title: string;
  class?: string;
  disabled?: boolean;
}

interface Props {
  tabs: TabItem[];
  activeTab: DashboardTab;
  onChange: (id: DashboardTab) => void;
}

const Tabs: React.FC<Props> = (props: Props) => {
  const { tabs, activeTab, onChange } = props;

  const { toggleStatistics, isStatisticsShowed } = useContext(DashboardContext);

  const onClickTabHandler = (tabName: DashboardTab) => {
    onChange(tabName);
  };

  return (
    <div className="tabs">
      <ul className="tabs-list">
        {tabs.map((item) => {
          return activeTab === item.id ? (
            <li className={itemClass(item)} key={item.id}>
              <button
                className="tabs-btn active"
                value={item.title}
                disabled={item.disabled}
                onClick={onClickTabHandler.bind(this, item.id)}
              >
                {item.title}
              </button>
            </li>
          ) : (
            <li className={itemClass(item)} key={item.id}>
              <button
                className="tabs-btn"
                value={item.title}
                disabled={item.disabled}
                onClick={!item.disabled ? onClickTabHandler.bind(this, item.id) : undefined}
              >
                {item.title}
              </button>
            </li>
          );
        })}
      </ul>
      <button className={cn('tabs-btn single-btn', { active: isStatisticsShowed })} onClick={toggleStatistics}>
        <i className="tabs-icon icon icon-statistics isLight" />
      </button>
    </div>
  );
};

export default React.memo(Tabs);

function itemClass(tabItem: TabItem): string {
  const providedClass = tabItem.class ? ` ${tabItem.class}` : '';
  return 'tabs-item' + providedClass;
}
