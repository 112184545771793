import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { Dropdown, DropdownItem, ErrorModal, StatusBar } from 'shared';
import { useHistory } from 'react-router-dom';
import { HookContext } from '../../../contexts/HookContext';
import { StreamSelector } from '../../selections/StreamSelector';
import { getRecordings } from '../../recordings/thunks';
import { Recordings } from '../../recordings/Recordings';
import { PlayingStreamIndicator } from '../../selections/PlayingStreamIndicator';
import { Feature } from '../../../../constants/featureConfig';
import tactile from '../../../../assets/img/tactile.png';
import HeaderTapingControls from '../../recordings/HeaderTapingControls';
import { AppToggler } from '../../../components/AppToggler';
import { AppType } from '../../../../interfaces';
import { useResetStore } from '../../../store/useResetStore';
import { MAP_PAGE, SETTINGS_PAGE } from '../../../../constants/pathnames';
import './_header.scss';

const isProd = process.env.REACT_APP_ENV === 'production';

const headerList = [
  {
    name: 'Map',
    icon: 'map',
    active: true,
    disabled: false,
    url: MAP_PAGE,
  },
  {
    name: 'Settings',
    icon: 'gear',
    active: false,
    disabled: isProd,
    url: SETTINGS_PAGE,
  },
];

interface Props {
  currentAppType: AppType;
  setCurrentAppType: (appType: AppType) => void;
}

const Header: React.FC<Props> = (props) => {
  const { currentAppType, setCurrentAppType } = props;
  const [recordingsIsOpen, setRecordingsIsOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const resetStore = useResetStore();

  const { useRecordings, useUser, useSelections, useStream } = useContext(HookContext);
  const { setError, sendRejectedRequest, clearUploadErrors, isError, isLoading, playingRecording, errors } =
    useRecordings;
  const { isFeatureEnabled } = useUser;
  const { selectedStream } = useSelections;
  const { tapingStatus } = useStream;

  const onLogout = useCallback(() => {
    resetStore();
  }, []);

  // TODO: change after receiving requirements on full Link support or delete
  const navigate = (url: string, isDisabled: boolean): void => {
    !isDisabled && history.push(url);
  };

  useEffect(() => {
    isFeatureEnabled(Feature.PLAY_RECORDINGS) && dispatch(getRecordings());
  }, []);

  return (
    <>
      <header className="header">
        <div className="d-flex">
          <img src={tactile} alt="tactile" className="header-logo-tactile" />
        </div>
        <ul className="header-list full-height ">
          {/* TODO: change after receiving requirements on full Link support or delete */}
          {headerList.map((item) => (
            <li
              key={item.name}
              className={cn('header-list-item full-height', { active: item.active, disabled: item.disabled })}
              onClick={navigate.bind(this, item.url, item.disabled)}
            >
              <i className={cn(`header-list-icon icon icon-${item.icon}`, { isLight: item.active })} />
              <span className="header-list-text">{item.name}</span>
            </li>
          ))}
        </ul>
        <div className="header-dropdown-container">
          <AppToggler currentAppType={currentAppType} setCurrentAppType={setCurrentAppType} />
          {isFeatureEnabled(Feature.PLAY_RECORDINGS) && currentAppType === AppType.RECORDING && (
            <>
              <Dropdown
                className="header-dropdown"
                name="Recording"
                closeOnClick={false}
                isOpen={recordingsIsOpen}
                setIsOpen={setRecordingsIsOpen}
              >
                <Recordings closeDropdown={setRecordingsIsOpen.bind(this, false)} />
              </Dropdown>
              <PlayingStreamIndicator />
            </>
          )}
          {isFeatureEnabled(Feature.LIVE_STREAM) && currentAppType === AppType.LIVE && <StreamSelector />}
        </div>
        {isFeatureEnabled(Feature.STREAM_TAPING) && tapingStatus && currentAppType === AppType.LIVE && (
          <HeaderTapingControls />
        )}
        <div className="header-controls d-flex">
          <Dropdown icon="header-user-icon icon-user isLight" className="header-user">
            <DropdownItem onClick={onLogout}>
              Log out <i className="header-logout icon icon-logout isLight" />
            </DropdownItem>
          </Dropdown>
        </div>
      </header>
      <StatusBar
        isLoading={isLoading}
        isError={isError}
        onTryAgain={sendRejectedRequest}
        onClickCancel={setError.bind(this, false)}
        progressSpeed={1}
        dependencies={[playingRecording, selectedStream]}
      />
      {errors.length ? <ErrorModal errors={errors} onOkay={clearUploadErrors} /> : <></>}
    </>
  );
};

export default Header;
