import { Grip, isArbitratedGrip, isOEMGrip, isTmGrip } from '../../../interfaces';
import { FiltersState } from './store';

export function filterGrips(grips: Grip[], filters: FiltersState): Grip[] {
  return grips.filter((grip) => shouldShowGrip(grip, filters));
}

function shouldShowGrip(grip: Grip, filters: FiltersState): boolean {
  return (
    (isTmGrip(grip) && filters.tm_grip) ||
    (isOEMGrip(grip) && filters.oem_grip) ||
    (isArbitratedGrip(grip) && filters.arbitrated_grip)
  );
}
