import React from 'react';

interface Props {
  name: string;
  onEdit: () => void;
  editable?: boolean;
}

const BookmarkName: React.FC<Props> = (props: Props) => {
  const { name, onEdit, editable } = props;

  return (
    <div className="rec-dialog-title">
      <span className="rec-dialog-name">{name}</span>
      {editable && (
        <button className="rec-dialog-btn">
          <i className="rec-dialog-icon icon icon-edit" onClick={onEdit} />
        </button>
      )}
    </div>
  );
};

export default React.memo(BookmarkName);
