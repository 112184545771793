import { useDispatch, useSelector } from 'react-redux';
import { setAmbientTemperature as setAmbientTemperatureInStore } from './store';
import { AmbientTemperature } from '../../../interfaces';
import { DataState, EventWithCounter } from './state';
import { Draft, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { setLastDataSignalTimeAsNow } from './signalsState';

export const useTemperatureData = (): UseTemperatureData => {
  const ambientTemperature = useSelector(ambientTemperatureSelector);

  const dispatch = useDispatch();

  const setAmbientTemperature = (ambientTemperature: AmbientTemperature) => {
    const { temperature_celsius, counter } = ambientTemperature;
    dispatch(setAmbientTemperatureInStore({ value: temperature_celsius, counter }));
  };

  return {
    ambientTemperature,
    setAmbientTemperature,
  };
};

export interface UseTemperatureData {
  ambientTemperature: number;
  setAmbientTemperature(ambientTemperature: AmbientTemperature): void;
}

export const initialTemperatureState: Pick<DataState, 'ambientTemperature'> = {
  ambientTemperature: { counter: 0, value: 0 },
};

export const temperatureReducers: ValidateSliceCaseReducers<DataState, TemperatureReducers> = {
  setAmbientTemperature: (state: Draft<DataState>, action: PayloadAction<EventWithCounter<number>>) => {
    const { value, counter } = action.payload;

    if (state.ambientTemperature.counter >= counter) return;

    setLastDataSignalTimeAsNow(state);
    state.ambientTemperature = { value, counter };
  },
};

export interface TemperatureReducers extends SliceCaseReducers<DataState> {
  setAmbientTemperature(state: Draft<DataState>, action: PayloadAction<EventWithCounter<number>>): void;
}

export function resetTemperatureToInitialValue(state: Draft<DataState>): void {
  state.ambientTemperature = initialTemperatureState.ambientTemperature;
}

export function resetTemperatureCounter(state: Draft<DataState>): void {
  state.ambientTemperature.counter = -1;
}

export function ambientTemperatureSelector(state: RootState): number {
  return state.data.ambientTemperature.value;
}
