import { createAsyncThunk } from '@reduxjs/toolkit';
import { container } from '../../../configs/inversify';
import { ILocalStorageService } from '../../services/LocalStorageService';
import { IUserService } from './service';
import { LoginFormFields } from './types';
import { parseJwt } from '../../../helpers/common';

const userService: IUserService = container.get('UserService');
const localStorageService: ILocalStorageService = container.get('LocalStorageService');

export const loginUser = createAsyncThunk('user/login', async (credentials: LoginFormFields, thunkApi) => {
  try {
    const auth = await userService.login(credentials);

    if (credentials.saveCredentials) {
      localStorageService.setItem('login-credentials', credentials);
    }

    localStorageService.setItem('Authorization', auth.access_token);
    localStorageService.setItem('refresh-token', auth.refresh_token);

    const { sub, account_id } = parseJwt(auth.access_token);

    await thunkApi.dispatch(fetchUserConfig());

    return { userId: sub, accountId: account_id };
  } catch (error) {
    throw error;
  }
});

export const fetchUserConfig = createAsyncThunk('user/fetchUserConfig', async (_, { dispatch }) => {
  const userConfig = await userService.fetchUserConfig();
  await dispatch(fetchAccountConfig());

  return userConfig;
});

export const fetchAccountConfig = createAsyncThunk('user/fetchAccountConfig', async () => {
  return await userService.fetchAccountConfig();
});
