import React, { useCallback } from 'react';
import classNames from 'classnames';
import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { Widget } from '../Widget';
import { between } from '../../../../../../helpers/common';
import { RadialSeparators } from './RadialSeparators';
import { Units } from '../../../../user/types';
import flare from '../../../../../../assets/img/flare.png';
import light from '../../../../../../assets/img/light.png';
import { transformKphToMph } from '../../../../../../helpers/units';
import 'react-circular-progressbar/dist/styles.css';
import './_speedometer.scss';

interface Props {
  value: number;
  minValidValue?: number;
  maxValidValue?: number;
  children?: React.ReactNode;
  isMini?: boolean;
  units?: Units;
}

const MAX_VALID_VALUE = 200;
const MIN_VALID_VALUE = 50;

const unitNames = {
  [Units.METRIC]: 'Kph',
  [Units.IMPERIAL]: 'Mph',
};

const Speedometer: React.FC<Props> = (props: Props) => {
  const {
    value,
    minValidValue = MIN_VALID_VALUE,
    maxValidValue = MAX_VALID_VALUE,
    isMini,
    units = Units.METRIC,
    children,
  } = props;

  const MIN_VALUE = 0;
  const MAX_VALUE = 240;

  const getValidValue = (value: number) => {
    if (value < MIN_VALUE) return MIN_VALUE;
    if (value > MAX_VALUE) return MAX_VALUE;
    return Math.round(value);
  };

  const formatValue = useCallback((speed: number): number => {
    const validValue = getValidValue(Math.abs(speed));

    return Math.round(units === Units.METRIC ? validValue : transformKphToMph(validValue));
  }, []);

  const getValueGraphPercentage = () => {
    const diff = value - MIN_VALUE;
    return (diff / MAX_VALUE) * 100;
  };

  const getValidGraphValuePercentage = () => {
    const diff = maxValidValue - minValidValue;
    return (diff / MAX_VALUE) * 100;
  };

  const getValidGraphOffsetPercentage = () => {
    const diff = minValidValue - MIN_VALUE;
    return (diff / MAX_VALUE) * 100;
  };

  const isValueInRange = between(value, minValidValue, maxValidValue);

  return (
    <>
      {isMini ? (
        <Widget
          title="Speed"
          className="speedometer-widget"
          value={formatValue(value).toString()}
          afterValueText={`(${unitNames[units]})`}
        >
          <div className="car-dash-line">
            <span
              className={classNames('car-dash-line-value', { inRange: isValueInRange })}
              style={{ width: value * (78 / 240) }}
            />
          </div>
        </Widget>
      ) : (
        <Widget title="Speed" className="speedometer-widget">
          <div className="speedometer">
            <div className="speedometer-container">
              <CircularProgressbar
                value={getValidGraphValuePercentage()}
                strokeWidth={2}
                circleRatio={0.5}
                styles={buildStyles({
                  strokeLinecap: 'butt',
                  pathColor: isValueInRange ? '#fd7500' : '#fff',
                  trailColor: 'none',
                  rotation: -0.355 + getValidGraphOffsetPercentage() / 100,
                })}
              />
            </div>
            <img src={flare} alt="flare" className="speedometer-flare" draggable={false} />
            <img src={light} alt="light" className="speedometer-light" draggable={false} />
            <span className="speedometer-shadow" />
            <CircularProgressbarWithChildren
              value={getValueGraphPercentage()}
              circleRatio={0.5}
              strokeWidth={15}
              styles={buildStyles({
                strokeLinecap: 'butt',
                pathColor: '#208227',
                trailColor: '#1e1e1e',
                rotation: -0.25,
              })}
            >
              <RadialSeparators count={11} />
              <div className="speedometer-value-container">
                <span className="speedometer-value">0</span>
                <span className="speedometer-value">50</span>
                <span className="speedometer-value">100</span>
                <span className="speedometer-value">150</span>
                <span className="speedometer-value">200</span>
                <span className="speedometer-value">250</span>
              </div>
              <div className="speedometer-text">
                {formatValue(value)}
                <span className="speedometer-text unit">({unitNames[units]})</span>
              </div>
              {children}
            </CircularProgressbarWithChildren>
          </div>
        </Widget>
      )}
    </>
  );
};

export default React.memo(Speedometer);
