import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { Theme } from '../map/interfaces';
import { fetchAvailableStreams } from './thunks';
import { dumpRecording, playRecording } from '../recordings/thunks';
import { DashboardName } from './interfaces';
import { SelectableRecording } from '../../../interfaces';
import { DashboardTab } from '../main/CarDashboard';

interface SelectionState {
  availableStreams: string[];
  selectedStream: string | null;
  selectedRecording: SelectableRecording | null;
  selectedRecordingSpeed: number;
  latestStreams: string[];
  theme: Theme;
  selectedDashboard: DashboardName | null;
  selectedDashboardTab: DashboardTab;
}

export const initialState: SelectionState = {
  availableStreams: [],
  selectedStream: null,
  selectedRecording: null,
  selectedRecordingSpeed: 1,
  latestStreams: [],
  theme: Theme.DARK,
  selectedDashboard: null,
  selectedDashboardTab: DashboardTab.TIRES,
};

export const selectionsSlice = createSlice({
  name: 'selections',
  initialState,
  reducers: {
    setSelectedStream: (state, action: PayloadAction<string>) => {
      state.selectedStream = action.payload;
    },
    clearSelectedStream: (state) => {
      state.selectedStream = null;
    },
    setTheme: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload;
    },
    setAvailableStreams: (state, action: PayloadAction<string[]>) => {
      const latestStreams = action.payload.filter(
        (stream) => !state.availableStreams.includes(stream) && !stream.includes('rec'),
      );

      const except = latestStreams.filter((stream) => !state.latestStreams.includes(stream));

      if (except.length) {
        state.latestStreams = except;
      }

      state.availableStreams = action.payload.filter((stream) => !stream.includes('rec'));
    },
    setSelectedRecording: (state, action: PayloadAction<SelectableRecording>) => {
      state.selectedRecording = action.payload;
    },
    setSelectedRecordingSpeed: (state, action: PayloadAction<number>) => {
      state.selectedRecordingSpeed = action.payload;
    },
    toggleDashboardState: (state, action: PayloadAction<DashboardName>) => {
      state.selectedDashboard === action.payload
        ? (state.selectedDashboard = null)
        : (state.selectedDashboard = action.payload);
    },
    setSelectedDashboardTab: (state, action: PayloadAction<DashboardTab>) => {
      state.selectedDashboardTab = action.payload;
    },
    reset: (state) => {
      state = initialState;
      return state;
    },
  },
  extraReducers: {
    [fetchAvailableStreams.fulfilled.type]: (state, action: PayloadAction<string[]>) => {
      state.availableStreams = action.payload;
    },
    [playRecording.pending.type]: (state) => {
      state.selectedStream = null;
    },
    [playRecording.fulfilled.type]: (state) => {
      state.selectedStream = null;
    },
    [dumpRecording.fulfilled.type]: (state) => {
      state.selectedStream = null;
    },
  },
});

export const {
  setSelectedStream,
  clearSelectedStream,
  setTheme,
  setAvailableStreams,
  setSelectedRecording,
  setSelectedRecordingSpeed,
  toggleDashboardState,
  setSelectedDashboardTab,
  reset,
} = selectionsSlice.actions;

export function availableStreamsSelector(state: RootState): string[] {
  return state.selections.availableStreams;
}

export function selectedStreamSelector(state: RootState): string | null {
  return state.selections.selectedStream;
}

export function selectedRecordingSelector(state: RootState): SelectableRecording | null {
  return state.selections.selectedRecording;
}

export function selectedRecordSpeedSelector(state: RootState): number {
  return state.selections.selectedRecordingSpeed;
}

export function latestStreamsSelector(state: RootState): string[] {
  return state.selections.latestStreams;
}

export function themeSelector(state: RootState): Theme {
  return state.selections.theme;
}

export function selectedDashboardSelector(state: RootState): DashboardName | null {
  return state.selections.selectedDashboard;
}

export function selectedDashboardTabSelector(state: RootState): DashboardTab {
  return state.selections.selectedDashboardTab;
}

export default selectionsSlice.reducer;
