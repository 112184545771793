import React, { useContext } from 'react';
import { HookContext } from '../../../contexts/HookContext';
import { PlayableType } from '../../../../interfaces';
import BookmarkPopup from './BookmarkPopup';
import { Bookmark } from '../../bookmark/interfaces';
import './style.scss';

const BookmarkPopupList: React.FC = () => {
  const { useRecordings, useSelections, useBookmark } = useContext(HookContext);
  const { playingRecording } = useRecordings;
  const { selectedStream } = useSelections;
  const { temporaryBookmarks, deleteTempBookmark, saveTapingBookmark, saveRecordingBookmark } = useBookmark;

  const onSaveHandler = (bookmark: Bookmark) => {
    if (selectedStream) {
      saveTapingBookmark({
        streamId: selectedStream,
        fields: bookmark,
      });
    }

    if (playingRecording?.type === PlayableType.RECORDING) {
      saveRecordingBookmark({
        recording: playingRecording,
        fields: bookmark,
      });
    }
  };

  return (
    <>
      {temporaryBookmarks.map((item) => (
        <BookmarkPopup key={item.name} bookmark={item} onDelete={deleteTempBookmark} onSave={onSaveHandler} />
      ))}
    </>
  );
};

export default React.memo(BookmarkPopupList);
