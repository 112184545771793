import React from 'react';
import WheelLoad from './wheel_load';
import gravitySide from '../../../../../../assets/img/gravity-side.png';
import gravityFront from '../../../../../../assets/img/gravity-front.png';
import '../../AccPanel/_acc-panel.scss';
import './_center_of_gravity.scss';
import { LastLoadTransfer } from '../../../../data/state';
import { Units } from '../../../../user/types';

export interface CenterOfGravityProps {
  loadTransfer: LastLoadTransfer | null;
  units?: Units;
}

const CenterOfGravity: React.FC<CenterOfGravityProps> = (props: CenterOfGravityProps) => {
  const { loadTransfer, units = Units.METRIC } = props;
  const cogs = calculateCogs(loadTransfer);

  return (
    <div className="weight-distribution-container">
      <span className="widget-title">Center of Gravity</span>
      <div className="acc-cog-container">
        <div className="acc-gravity">
          <img className="acc-gravity-img" src={gravitySide} alt="gravity" draggable={false} />
          <div className="acc-gravity-cog" style={{ marginLeft: `${cogs.side}%` }} data-testid="side-cog"></div>
        </div>
        <div className="acc-gravity">
          <img className="acc-gravity-img" src={gravityFront} alt="gravity" draggable={false} />
          <div className="acc-gravity-cog" style={{ marginLeft: `${cogs.front}%` }} data-testid="front-cog"></div>
        </div>
      </div>
      <div className="wheel-load-panel" data-testid="wheel-load-panel">
        <WheelLoad valueKg={loadTransfer?.frontLeftKg} units={units} wheelPosition={'FL'}></WheelLoad>
        <WheelLoad valueKg={loadTransfer?.frontRightKg} units={units} wheelPosition={'FR'}></WheelLoad>
        <WheelLoad valueKg={loadTransfer?.rearLeftKg} units={units} wheelPosition={'RL'}></WheelLoad>
        <WheelLoad valueKg={loadTransfer?.rearRightKg} units={units} wheelPosition={'RR'}></WheelLoad>
      </div>
    </div>
  );
};

export default React.memo(CenterOfGravity);

function calculateCogs(loadTransfer: LastLoadTransfer | null): COGs {
  if (!loadTransfer) {
    return { front: 50, side: 50 };
  }

  const { frontLeftKg, frontRightKg, rearLeftKg, rearRightKg } = loadTransfer;

  const total_weight = frontLeftKg + frontRightKg + rearLeftKg + rearRightKg;
  const front_offset = total_weight ? ((rearLeftKg + rearRightKg) / total_weight) * 100 : 50;
  const left_offset = total_weight ? ((frontLeftKg + rearLeftKg) / total_weight) * 100 : 50;

  return {
    side: front_offset,
    front: left_offset,
  };
}

interface COGs {
  side: number;
  front: number;
}
