import { useDispatch, useSelector } from 'react-redux';
import {
  availableStreamsSelector,
  clearSelectedStream,
  selectedDashboardSelector,
  selectedDashboardTabSelector,
  selectedRecordingSelector,
  selectedRecordSpeedSelector,
  selectedStreamSelector,
  setSelectedDashboardTab,
  setSelectedRecording,
  setSelectedRecordingSpeed,
  setSelectedStream,
  toggleDashboardState,
  reset,
} from './store';
import { SelectableRecording } from '../../../interfaces';
import { fetchTapedStreams } from '../stream/thunks';
import { DashboardName } from './interfaces';
import { DashboardTab } from '../main/CarDashboard';

export interface UseSelections {
  availableStreams: string[];
  selectedStream: string | null;
  selectedRecording: SelectableRecording | null;
  selectedRecordingSpeed: number;
  selectedDashboard: DashboardName | null;
  selectedDashboardTab: DashboardTab;
  selectStream: (streamId: string) => void;
  clearStream: () => void;
  selectRecordingSpeed: (speed: number) => void;
  toggleDashboard: (name: DashboardName) => void;
  selectDashboardTab: (tabName: DashboardTab) => void;
  selectRecording: (recording: SelectableRecording) => void;
  resetStore: () => void;
}

export const useSelections = (): UseSelections => {
  const availableStreams = useSelector(availableStreamsSelector);
  const selectedStream = useSelector(selectedStreamSelector);
  const selectedRecording = useSelector(selectedRecordingSelector);
  const selectedRecordingSpeed = useSelector(selectedRecordSpeedSelector);
  const selectedDashboard = useSelector(selectedDashboardSelector);
  const selectedDashboardTab = useSelector(selectedDashboardTabSelector);

  const dispatch = useDispatch();

  const selectStream = (streamId: string) => {
    dispatch(setSelectedStream(streamId));
    dispatch(fetchTapedStreams());
  };

  const clearStream = () => {
    dispatch(clearSelectedStream());
  };

  const selectRecordingSpeed = (speed: number) => {
    dispatch(setSelectedRecordingSpeed(speed));
  };

  const toggleDashboard = (name: DashboardName) => {
    if (name === DashboardName.NEW) {
      selectDashboardTab(DashboardTab.TIRES);
    }

    dispatch(toggleDashboardState(name));
  };

  const selectDashboardTab = (tabName: DashboardTab) => {
    dispatch(setSelectedDashboardTab(tabName));
  };

  const selectRecording = (recording: SelectableRecording) => {
    dispatch(setSelectedRecording(recording));
  };

  const resetStore = () => {
    dispatch(reset());
  };

  return {
    availableStreams,
    selectedStream,
    selectedRecording,
    selectedRecordingSpeed,
    selectedDashboard,
    selectedDashboardTab,
    selectStream,
    clearStream,
    selectRecordingSpeed,
    toggleDashboard,
    selectDashboardTab,
    selectRecording,
    resetStore,
  };
};
