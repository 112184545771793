import React, { useContext, useEffect, useState } from 'react';
import { HookContext } from '../../../../contexts/HookContext';
import { getRoundedFloat } from '../../../../../helpers/common';
import './_statistics.scss';

export const DashboardStatistics: React.FC = () => {
  const [gripDataPointsCounter, setGripDataPointsCounter] = useState(0);
  const [estimationPerSecond, setEstimationPerSecond] = useState(0);
  const [averageDistanceBetweenPoints, setAverageDistanceBetweenPoints] = useState(0);

  const { useData, useSelections } = useContext(HookContext);
  const {
    dashboardStatistics,
    isPausedStatisticsCounting,
    onResetDashboardStatistics,
    onToggleDashboardStatisticsCounting,
  } = useData;
  const { selectedRecordingSpeed } = useSelections;

  useEffect(() => {
    updateStatistics();
  }, [dashboardStatistics]);

  const onTogglePauseHandler = () => {
    if (isPausedStatisticsCounting) {
      onResetDashboardStatistics();
    }

    onToggleDashboardStatisticsCounting();
  };

  const updateStatistics = () => {
    if (dashboardStatistics !== null) {
      setGripDataPointsCounter(dashboardStatistics.gripDataPointsCounter);
      setEstimationPerSecond(getRoundedFloat(dashboardStatistics.estimationsPerSecond / selectedRecordingSpeed, 1));
      setAverageDistanceBetweenPoints(getRoundedFloat(dashboardStatistics.averageDistanceBetweenPointsInMeters, 1));
    }
  };

  return (
    <div className="statistics">
      <div className="statistics-block">
        <span className="statistics-title">Statistics</span>
        <div className="statistics-btn-container">
          <button className="statistics-btn" disabled={dashboardStatistics === null} onClick={onTogglePauseHandler}>
            <i className={`playbar-icon icon ${isPausedStatisticsCounting ? 'icon-play' : 'icon-pause'}`} />
          </button>
          <button
            className="statistics-btn"
            disabled={dashboardStatistics === null}
            onClick={onResetDashboardStatistics}
          >
            <i className="playbar-icon icon icon-rotate" />
          </button>
        </div>
      </div>
      <div className="statistics-block">
        <span className="statistics-subtitle">Grip Data Points</span>
        <span className="statistics-value">{gripDataPointsCounter || '-'}</span>
      </div>
      <div className="statistics-block">
        <span className="statistics-subtitle">Estimation per sec.</span>
        <span className="statistics-value">{estimationPerSecond || '-'}</span>
      </div>
      <div className="statistics-block">
        <span className="statistics-subtitle">Avg. Distance btw. Points</span>
        <span className="statistics-value">
          {averageDistanceBetweenPoints ? `${averageDistanceBetweenPoints}m` : '-'}
        </span>
      </div>
    </div>
  );
};
