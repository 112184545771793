import React, { useState } from 'react';
import { getShortTimeFromTimestamp } from '../../../../../../helpers/common';
import BookmarkEditName from './BookmarkEditName';
import BookmarkName from './BookmarkName';
import { Bookmark } from '../../../../bookmark/interfaces';

interface Props {
  bookmark: Bookmark;
  preview?: boolean;
  editable?: boolean;
  onRename?: (oldName: string, newName: string) => void;
  onDelete?: (name: string) => void;
}

const BookmarkItem: React.FC<Props> = (props: Props) => {
  const { bookmark, onRename, onDelete, preview = true, editable = true } = props;

  const [isEdited, setIsEdited] = useState(false);

  const onDeleteHandler = () => {
    onDelete && onDelete(bookmark.name);
  };

  const onRenameHandler = (newName: string) => {
    onRename && onRename(bookmark.name, newName.trim());
    toggleEdit();
  };

  const toggleEdit = () => {
    setIsEdited(!isEdited);
  };

  return (
    <div className="rec-dialog-bookmark">
      {preview && (
        <div className="rec-popup-video">
          <img src={bookmark.thumbnail} alt="map" className="rec-video" />
          {bookmark.position_ms && (
            <span className="rec-video-timesheet">{getShortTimeFromTimestamp(bookmark.position_ms)}</span>
          )}
        </div>
      )}
      <div className="rec-popup-info">
        {isEdited ? (
          <BookmarkEditName name={bookmark.name} onRename={onRenameHandler} onCancel={toggleEdit} />
        ) : (
          <BookmarkName name={bookmark.name} onEdit={toggleEdit} editable={editable} />
        )}
        <span className="rec-title">Grip</span>
        <span className="rec-name">0.74-0.85 Mu</span>
      </div>
      <div className="rec-popup-controls">
        {!isEdited && (
          <button className="rec-btn delete" onClick={onDeleteHandler}>
            <i className="rec-icon icon icon-delete" />
          </button>
        )}
      </div>
    </div>
  );
};

export default React.memo(BookmarkItem);
