import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { latestStreamsSelector } from '../../modules/selections/store';
import './_notif.scss';

export const NotifModal: React.FC = () => {
  const latestStreams = useSelector(latestStreamsSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [titles, setTitles] = useState<string[]>([]);
  const timer = useRef<null | NodeJS.Timeout>(null);

  const closePopup = () => {
    setIsOpen(false);
    if (timer.current) clearTimeout(timer.current);
  };

  const getPopupTitleByStreamsCount = (streamsCount: number): string => {
    if (streamsCount > 1) {
      return 'New streams are available';
    } else {
      return 'A new stream is available';
    }
  };

  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);
    if (latestStreams[0]) {
      setIsOpen(true);
      setTitles(latestStreams);

      timer.current = setTimeout(() => {
        setIsOpen(false);
      }, 10000);
    }
  }, [latestStreams]);

  return isOpen ? (
    <div className="notif">
      <button className="notif-btn" onClick={closePopup}>
        <i className="notif-icon icon icon-cancel" />
      </button>
      <i className="notif-img icon icon-info success" />
      <div>
        <span className="notif-title">{getPopupTitleByStreamsCount(titles.length)}</span>
        {titles.map((title) => (
          <span key={title} className="notif-subtitle">
            {title}
          </span>
        ))}
      </div>
    </div>
  ) : null;
};
