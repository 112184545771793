import { Draft, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { DataState } from './state';
import { RootState } from '../../store';

export const initialSignalsState: Pick<DataState, 'isDataReset' | 'isDataFullReset' | 'lastDataSignalTimestamp'> = {
  isDataReset: false,
  isDataFullReset: false,
  lastDataSignalTimestamp: undefined,
};

export function setLastDataSignalTimeAsNow(state: Draft<DataState>): void {
  state.lastDataSignalTimestamp = Date.now();
}

export const signalsStateReducers: ValidateSliceCaseReducers<DataState, SignalsStateReducers> = {
  resetLastDataSignalTimestamp: (state: Draft<DataState>) => {
    state.lastDataSignalTimestamp = undefined;
  },
  setIsDataReset: (state: Draft<DataState>, action: PayloadAction<boolean>) => {
    state.isDataReset = action.payload;
  },
};

export interface SignalsStateReducers extends SliceCaseReducers<DataState> {
  resetLastDataSignalTimestamp(state: Draft<DataState>): void;
  setIsDataReset(state: Draft<DataState>, action: PayloadAction<boolean>): void;
}

export function lastDataSignalTimestampSelector(state: RootState): number | undefined {
  return state.data.lastDataSignalTimestamp;
}

export function markDataAsReset(state: Draft<DataState>, { full }: ResetOptions): void {
  state.isDataReset = true;
  state.isDataFullReset = full;
}

export interface ResetOptions {
  full: boolean;
}

export function isDataResetSelector(state: RootState): boolean {
  return state.data.isDataReset;
}

export function isDataFullResetSelector(state: RootState): boolean {
  return state.data.isDataFullReset;
}
