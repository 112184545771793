import React from 'react';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const RecordingStatusModal: React.FC<Props> = (props: Props) => {
  const { isOpen, onConfirm, onCancel } = props;

  if (!isOpen) return null;

  return (
    <div className="recording">
      <i className="recording-icon icon icon-info disable" />
      <h1 className="recording-title recording-popup-title">Are you sure you want to end recording?</h1>
      <div className="recording-btn-container">
        <button className="recording-btn fill fill" onClick={onCancel}>
          Continue recording
        </button>
        <button className="recording-btn" onClick={onConfirm}>
          End recording
        </button>
      </div>
    </div>
  );
};
