import React, { useContext, useState } from 'react';
import DashboardCard from '../widgets/DashboardCard';
import { AvailableWidgets, DashboardContext } from '../index';
import RollRadiiWidget from '../widgets/RollRadiiWidget';
import { HookContext } from '../../../../contexts/HookContext';
import {
  ApplicableWheel,
  StoredAbsoluteEffectiveRollingRadiusForWheel,
  StoredRelativeEffectiveRollingRadiusForWheel,
  isOemRadiusProvider,
  isTmRadiusProvider,
} from '../../../../../interfaces';
import { Toggler } from '../../../../components/Toggler';

const MIN_AGE_SECONDS = 0;
const MAX_AGE_SECONDS = 200;
const MAX_AGE_SECONDS_MOBILE = 100;
const STEP_AGE_SECONDS = 5;

const MIN_VALUE_PERCENTS = -3;
const MAX_VALUE_PERCENTS = 3;
const STEP_VALUE_PERCENTS = 1.5;

const RELATIVE_VALUES_RANGE: ValuesRange = {
  min: MIN_VALUE_PERCENTS,
  max: MAX_VALUE_PERCENTS,
  step: STEP_VALUE_PERCENTS,
};

interface ValuesRange {
  min: number;
  max: number;
  step: number;
}

type RadiusProviderGroup = 'TM' | 'OEM';
type RollingRadius = StoredRelativeEffectiveRollingRadiusForWheel | StoredAbsoluteEffectiveRollingRadiusForWheel;

const RollRadiiPanel: React.FC = () => {
  const { isLaptop } = useContext(DashboardContext);
  const { useData } = useContext(HookContext);
  const { relativeEffectiveRollingRadius, absoluteEffectiveRollingRadius } = useData;

  const [selectedProviderGroup, changeProviderGroup] = useState<RadiusProviderGroup>('TM');
  const [isRelativeRadius, changeIsRelativeRadius] = useState<boolean>(true);

  const isTmProviderGroup = selectedProviderGroup == 'TM';

  const isSelectedProvider = (wheel: RollingRadius) => {
    return (
      (isTmRadiusProvider(wheel.provider) && isTmProviderGroup) ||
      (isOemRadiusProvider(wheel.provider) && !isTmProviderGroup)
    );
  };

  const onProviderChange = () => {
    const otherProviderGroup: RadiusProviderGroup = isTmProviderGroup ? 'OEM' : 'TM';

    changeProviderGroup(otherProviderGroup);
  };

  const onRadiusTypeChange = () => {
    changeIsRelativeRadius(!isRelativeRadius);
  };

  const radius = isRelativeRadius ? relativeEffectiveRollingRadius : absoluteEffectiveRollingRadius;

  const valuesRange = isRelativeRadius ? RELATIVE_VALUES_RANGE : null;

  const frontLeft = isRelativeRadius
    ? null
    : absoluteEffectiveRollingRadius[ApplicableWheel.FRONT_LEFT].filter(isSelectedProvider);
  const frontRight = radius[ApplicableWheel.FRONT_RIGHT].filter(isSelectedProvider);
  const rearLeft = radius[ApplicableWheel.REAR_LEFT].filter(isSelectedProvider);
  const rearRight = radius[ApplicableWheel.REAR_RIGHT].filter(isSelectedProvider);

  return (
    <DashboardCard id={AvailableWidgets.GRIP_DATA} className="col dash-graph-panel roll-radii-tab">
      <RollRadiiWidget
        frontLeft={frontLeft}
        frontRight={frontRight}
        rearLeft={rearLeft}
        rearRight={rearRight}
        width={window.innerWidth}
        height={200}
        minAgeSeconds={MIN_AGE_SECONDS}
        maxAgeSeconds={isLaptop ? MAX_AGE_SECONDS_MOBILE : MAX_AGE_SECONDS}
        stepAgeSeconds={STEP_AGE_SECONDS}
        minValue={valuesRange?.min}
        maxValue={valuesRange?.max}
        stepValue={valuesRange?.step}
        yAxisUnit={isRelativeRadius ? '%' : 'mm'}
      />
      <div className="radius-options">
        <Toggler
          id="radius_type"
          name="radius_type"
          checked={isRelativeRadius}
          title={isRelativeRadius ? 'Relative' : 'Absolute'}
          onClick={onRadiusTypeChange}
          className="radius-type-toggler"
        />
        <Toggler
          id="radius_provider"
          name="provider"
          checked={isTmProviderGroup}
          title={isTmProviderGroup ? 'TM' : 'OEM'}
          onClick={onProviderChange}
          className="radius-provider-toggler"
        />
      </div>
    </DashboardCard>
  );
};

export default React.memo(RollRadiiPanel);
