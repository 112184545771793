import React from 'react';
import wheel from '../../../../../../assets/img/wheel-active.png';
import multiwheel from '../../../../../../assets/img/multiwheel-active.png';
import cn from 'classnames';
import './_tire.scss';

export enum ConvergenceType {
  ABSOLUTE = 'absolute',
  RELATIVE = 'relative',
}

export interface TireRadiiConvergenceProps {
  convergenceType: ConvergenceType;
  convergenceValue: boolean;
}

const TireRadiiConvergenceWidget: React.FC<TireRadiiConvergenceProps> = (props: TireRadiiConvergenceProps) => {
  const { convergenceType, convergenceValue } = props;

  return <>{insertWheelPicture(convergenceType, convergenceValue)}</>;
};

function insertWheelPicture(convergenceType: ConvergenceType, isConverged: boolean) {
  if (convergenceType === ConvergenceType.ABSOLUTE) {
    return singleWheel(isConverged);
  } else if (convergenceType === ConvergenceType.RELATIVE) {
    return multiWheel(isConverged);
  }
}

function singleWheel(isConverged: boolean) {
  return (
    <div className={cn('tire-container', { isActive: isConverged })} data-testid="tire-convergence-container">
      <img src={wheel} alt="wheel" className="tire-wheel" draggable={false} data-testid="tire-convergence-image" />
    </div>
  );
}

function multiWheel(isConverged: boolean) {
  return (
    <div className={cn('tire-container', { isActive: isConverged })} data-testid="tire-convergence-container">
      <img
        src={multiwheel}
        alt="wheel"
        className="tire-multiwheel"
        draggable={false}
        data-testid="tire-convergence-image"
      />
    </div>
  );
}

export default TireRadiiConvergenceWidget;
