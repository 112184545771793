import { injectable } from 'inversify';
import { AppSettings } from './interfaces';
import { request } from '../../../helpers/request';
import { API_SETTINGS } from '../../../constants/api';
import 'reflect-metadata';

export interface ISettingsService {
  fetchSettings(): Promise<AppSettings>;
  updateSettings(params: AppSettings): Promise<void>;
}

@injectable()
export class SettingsService implements ISettingsService {
  async fetchSettings(): Promise<AppSettings> {
    const res = await request.get<AppSettings>(API_SETTINGS);

    return res.data;
  }

  async updateSettings(params: AppSettings): Promise<void> {
    const data = new FormData();
    data.append('grip_fade_time_ms', params.grip_fade_time_ms.toString());
    data.append('surface_event_fade_time_ms', params.surface_event_fade_time_ms.toString());
    data.append('alert_fade_time_ms', params.alert_fade_time_ms.toString());
    data.append('map_type', params.map_type);
    data.append('surface_events_magnitude_threshold', params.surface_events_magnitude_threshold.toString());

    await request.put(API_SETTINGS, data);
  }
}
