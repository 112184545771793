import React from 'react';
import tire from '../../../../../../../assets/img/tire.png';
import shape from '../../../../../../../assets/img/shape.png';
import cn from 'classnames';

export interface WheelPictureProps {
  steeringAngle: number | undefined;
  isActive: boolean;
  extraClasses?: string;
}

const WheelPicture: React.FC<WheelPictureProps> = (props: WheelPictureProps) => {
  const { steeringAngle, isActive, extraClasses } = props;

  return (
    <>
      <span className={cn('wheel-tire-container', extraClasses)} data-testid="wheel-picture-container">
        <img
          src={tire}
          alt="tire"
          draggable={false}
          className={cn('wheel-tire ', { active: !!isActive })}
          style={{ transform: `rotate(${steeringAngle || 0}deg)` }}
          data-testid="wheel-picture"
        />
        <img src={shape} alt="shape" className="wheel-shape" />
      </span>
    </>
  );
};

export default React.memo(WheelPicture);
