import React, { useEffect, useRef, useState } from 'react';
import {
  StoredAbsoluteEffectiveRollingRadiusForWheel,
  StoredRelativeEffectiveRollingRadiusForWheel,
} from '../../../../../../interfaces';
import './index.scss';

interface Props {
  width: number;
  height: number;
  minAgeSeconds: number;
  maxAgeSeconds: number;
  stepAgeSeconds: number;
  minValue?: number;
  maxValue?: number;
  stepValue?: number;
  frontLeft: StoredRollingRadiusForWheel[] | null;
  frontRight: StoredRollingRadiusForWheel[];
  rearLeft: StoredRollingRadiusForWheel[];
  rearRight: StoredRollingRadiusForWheel[];
  yAxisUnit: string;
}

type StoredRollingRadiusForWheel =
  | StoredRelativeEffectiveRollingRadiusForWheel
  | StoredAbsoluteEffectiveRollingRadiusForWheel;

interface ValueTimestampPoint {
  value: number;
  timestamp: number;
}

interface IntermediateValues {
  frontLeft: ValueTimestampPoint[];
  frontRight: ValueTimestampPoint[];
  rearLeft: ValueTimestampPoint[];
  rearRight: ValueTimestampPoint[];
}

const rollingRadiusToColor = {
  frontLeft: '255, 247, 0',
  frontRight: '64, 160, 220',
  rearLeft: '251, 116, 0',
  rearRight: '55, 141, 81',
};

interface ValuesRange {
  min: number;
  max: number;
  step: number;
}

const MAX_GRAPH_TIME_GAP_IN_SEC = 10;

const RollRadiiWidget: React.FC<Props> = (props: Props) => {
  const {
    width,
    height,
    minAgeSeconds,
    maxAgeSeconds,
    stepAgeSeconds,
    frontLeft,
    frontRight,
    rearRight,
    rearLeft,
    yAxisUnit,
  } = props;

  const hasFrontLeftData = !!frontLeft;

  const [intermediateValues, setIntermediateValues] = useState<IntermediateValues>({
    frontLeft: [],
    frontRight: [],
    rearLeft: [],
    rearRight: [],
  });

  const getMinValue = (values: number[]) => {
    if (values.length === 0) {
      return 0;
    }

    return Math.min(...values) - 1;
  };

  const getMaxValue = (values: number[]) => {
    if (values.length === 0) {
      return 1;
    }

    return Math.max(...values) + 1;
  };

  const getStepValue = (min: number, max: number) => {
    const step = (max - min) / 4;

    return Math.max(1, step);
  };

  const getValuesRange = (): ValuesRange => {
    const allWheels = [
      ...intermediateValues.frontLeft,
      ...intermediateValues.frontRight,
      ...intermediateValues.rearRight,
      ...intermediateValues.rearLeft,
    ];
    const allWheelValues = allWheels.map((w) => w.value);

    const min = props.minValue !== undefined ? props.minValue : getMinValue(allWheelValues);
    const max = props.maxValue !== undefined ? props.maxValue : getMaxValue(allWheelValues);
    const step = props.stepValue === undefined ? getStepValue(min, max) : props.stepValue;

    return { min, max, step };
  };

  const DPI_WIDTH = width * 2;
  const DPI_HEIGHT = height * 2;

  const animationTimeoutMs = 24; // 30 fps animation
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const animationRef = useRef<NodeJS.Timeout | null>(null);

  // Graph initialization
  useEffect(() => {
    const canvas = chartRef.current;
    const container = containerRef.current;

    if (!canvas || !container) return;

    canvas.style.width = container.getBoundingClientRect().width + 'px';
    canvas.style.height = container.getBoundingClientRect().height + 'px';
    canvas.width = DPI_WIDTH;
    canvas.height = DPI_HEIGHT;

    const ctx = canvas.getContext('2d');

    if (ctx) {
      paintXAxis(ctx);
    }
  }, [chartRef.current, containerRef.current]);

  // Update intermediate values to draw graph
  useEffect(() => {
    const normalizedFrontLeft = frontLeft?.map((item) => ({
      value: item.value,
      error: item.error,
      timestamp: item.timestamp,
    }));

    const normalizedFrontRight = frontRight.map((item) => ({
      value: item.value,
      error: item.error,
      timestamp: item.timestamp,
    }));

    const normalizedRearLeft = rearLeft.map((item) => ({
      value: item.value,
      error: item.error,
      timestamp: item.timestamp,
    }));

    const normalizedRearRight = rearRight.map((item) => ({
      value: item.value,
      error: item.error,
      timestamp: item.timestamp,
    }));

    setIntermediateValues({
      frontLeft: normalizedFrontLeft || [],
      frontRight: normalizedFrontRight,
      rearLeft: normalizedRearLeft,
      rearRight: normalizedRearRight,
    });
  }, [frontLeft, frontRight, rearLeft, rearRight]);

  // Add painting graph animation based on intermediateValues
  useEffect(() => {
    const canvas = chartRef.current;

    if (!canvas) return;

    const ctx = canvas.getContext('2d');

    if (!ctx) return;

    if (animationRef.current) {
      clearInterval(animationRef.current);
    }

    // Update graphs every 1 second and move in along timeline
    animationRef.current = setInterval(() => {
      ctx.clearRect(0, 0, DPI_WIDTH, DPI_HEIGHT);

      paintXAxis(ctx);

      if (hasFrontLeftData && intermediateValues.frontLeft.length) {
        paintGraph(ctx, intermediateValues.frontLeft, rollingRadiusToColor.frontLeft);
      }

      if (intermediateValues.frontRight.length) {
        paintGraph(ctx, intermediateValues.frontRight, rollingRadiusToColor.frontRight);
      }

      if (intermediateValues.rearLeft.length) {
        paintGraph(ctx, intermediateValues.rearLeft, rollingRadiusToColor.rearLeft);
      }

      if (intermediateValues.rearRight.length) {
        paintGraph(ctx, intermediateValues.rearRight, rollingRadiusToColor.rearRight);
      }
    }, animationTimeoutMs);

    return () => {
      if (animationRef.current) {
        clearInterval(animationRef.current);
      }
    };
  }, [intermediateValues, chartRef.current]);

  // Paint lines on X axis
  const paintXAxis = (ctx: CanvasRenderingContext2D) => {
    const linesNumber = (maxAgeSeconds - minAgeSeconds) / stepAgeSeconds;
    const stepWidth = DPI_WIDTH / linesNumber;

    ctx.lineWidth = 1;
    ctx.strokeStyle = 'black';

    ctx.beginPath();
    for (let i = 0; i <= linesNumber; i++) {
      ctx.moveTo(i * stepWidth, 0);
      ctx.lineTo(i * stepWidth, DPI_HEIGHT);
    }
    ctx.stroke();
    ctx.closePath();
  };

  // Paint single graph by points
  const paintGraph = (ctx: CanvasRenderingContext2D, points: ValueTimestampPoint[], color: string) => {
    const oneSecondStepWidth = DPI_WIDTH / (maxAgeSeconds - minAgeSeconds);

    const valuesRange = getValuesRange();
    const oneValueStepWidth = DPI_HEIGHT / (valuesRange.max - valuesRange.min);

    // main graph line
    ctx.beginPath();

    points.forEach((point, idx) => {
      const prevPoint = points[idx - 1];

      if (prevPoint && prevPoint.timestamp + MAX_GRAPH_TIME_GAP_IN_SEC * 1000 <= point.timestamp) {
        // if we have a gap more than 10 sec then just fade line without connecting to next point
        ctx.stroke();
        ctx.closePath();

        ctx.beginPath();

        const prevValue = getAbsoluteValueForGraph(prevPoint.value, valuesRange);
        const prevTimestamp = prevPoint.timestamp;
        const prevTimeDiffInSeconds = (Date.now() - prevTimestamp) / 1000;
        const currentTimeDiffInSeconds = (Date.now() - point.timestamp) / 1000;
        const timeDiffInSecondsFromCurrentValue =
          prevTimeDiffInSeconds - currentTimeDiffInSeconds > MAX_GRAPH_TIME_GAP_IN_SEC
            ? MAX_GRAPH_TIME_GAP_IN_SEC
            : prevTimeDiffInSeconds - currentTimeDiffInSeconds;

        const gradient = ctx.createLinearGradient(
          DPI_WIDTH - prevTimeDiffInSeconds * oneSecondStepWidth,
          DPI_HEIGHT - prevValue * oneValueStepWidth,
          DPI_WIDTH -
            (prevTimeDiffInSeconds * oneSecondStepWidth - timeDiffInSecondsFromCurrentValue * oneSecondStepWidth),
          DPI_HEIGHT - prevValue * oneValueStepWidth,
        );

        gradient.addColorStop(0, `rgba(${color}, 1)`);
        gradient.addColorStop(0.2, `rgba(${color}, 0.5)`);
        gradient.addColorStop(0.5, `rgba(${color}, 0.3)`);
        gradient.addColorStop(0.8, `rgba(${color}, 0.1)`);
        gradient.addColorStop(1, `rgba(${color}, 0)`);

        ctx.strokeStyle = gradient;

        ctx.lineTo(DPI_WIDTH - prevTimeDiffInSeconds * oneSecondStepWidth, DPI_HEIGHT - prevValue * oneValueStepWidth);
        ctx.lineTo(
          DPI_WIDTH -
            (prevTimeDiffInSeconds * oneSecondStepWidth - timeDiffInSecondsFromCurrentValue * oneSecondStepWidth),
          DPI_HEIGHT - prevValue * oneValueStepWidth,
        );

        ctx.stroke();
        ctx.closePath();

        ctx.beginPath();
      } else {
        const prevPoint = points[idx - 1];

        // draw line between 2 points
        ctx.lineWidth = 4;
        ctx.strokeStyle = `rgba(${color}, 1)`;

        if (prevPoint) {
          const prevValue = getAbsoluteValueForGraph(prevPoint.value, valuesRange);
          const prevTimeDiffInSeconds = (Date.now() - prevPoint.timestamp) / 1000;

          ctx.lineTo(
            DPI_WIDTH - prevTimeDiffInSeconds * oneSecondStepWidth,
            DPI_HEIGHT - prevValue * oneValueStepWidth,
          );
        }

        const value = getAbsoluteValueForGraph(point.value, valuesRange);
        const timeDiffInSeconds = (Date.now() - point.timestamp) / 1000;

        ctx.lineTo(DPI_WIDTH - timeDiffInSeconds * oneSecondStepWidth, DPI_HEIGHT - value * oneValueStepWidth);
      }
    });

    ctx.stroke();
    ctx.closePath();

    // last line with gradient
    ctx.beginPath();

    const lastValue = getAbsoluteValueForGraph(points[points.length - 1].value, valuesRange);
    const lastTimestamp = points[points.length - 1].timestamp;
    const timeDiffInSeconds = (Date.now() - lastTimestamp) / 1000;

    const gradient = ctx.createLinearGradient(
      DPI_WIDTH - timeDiffInSeconds * oneSecondStepWidth,
      DPI_HEIGHT - lastValue * oneValueStepWidth,
      DPI_WIDTH - timeDiffInSeconds * oneSecondStepWidth + MAX_GRAPH_TIME_GAP_IN_SEC * oneSecondStepWidth,
      DPI_HEIGHT - lastValue * oneValueStepWidth,
    );

    gradient.addColorStop(0, `rgba(${color}, 1)`);
    gradient.addColorStop(0.2, `rgba(${color}, 0.5)`);
    gradient.addColorStop(0.5, `rgba(${color}, 0.3)`);
    gradient.addColorStop(0.8, `rgba(${color}, 0.1)`);
    gradient.addColorStop(1, `rgba(${color}, 0)`);

    ctx.strokeStyle = gradient;

    ctx.lineTo(DPI_WIDTH - timeDiffInSeconds * oneSecondStepWidth, DPI_HEIGHT - lastValue * oneValueStepWidth);

    ctx.lineTo(
      DPI_WIDTH - timeDiffInSeconds * oneSecondStepWidth + MAX_GRAPH_TIME_GAP_IN_SEC * oneSecondStepWidth,
      DPI_HEIGHT - lastValue * oneValueStepWidth,
    );

    ctx.stroke();
    ctx.closePath();
  };

  const getAbsoluteValueForGraph = (value: number, valuesRange: ValuesRange): number => {
    return value - valuesRange.min;
  };

  const getXAxisLabels = (): JSX.Element[] => {
    const labels: number[] = [];

    for (let i = minAgeSeconds; i <= maxAgeSeconds; i += stepAgeSeconds) {
      labels.push(i);
    }

    return labels.reverse().map((item) => (
      <li key={item} className="grip-line-val">
        {item}s
      </li>
    ));
  };

  const getYAxisLabels = (): JSX.Element[] => {
    const valuesRange = getValuesRange();

    const labels: number[] = [];

    for (let i = valuesRange.min; i <= valuesRange.max; i += valuesRange.step) {
      const value = Math.round(i * 10) / 10;
      labels.push(value);
    }

    return labels.reverse().map((item) => (
      <li key={item} className="grip-line-val">
        {item}
        {yAxisUnit}
      </li>
    ));
  };

  return (
    <>
      <div className="rolling-radius-container">
        {hasFrontLeftData && (
          <div className="rolling-radius-indicator rolling-indicator-fl">
            <div className="rolling-radius-title">FL</div>
            <div className="rolling-radius-value">
              {frontLeft.length ? (
                <>
                  {frontLeft[frontLeft.length - 1].value}&nbsp;&#177;&nbsp;{frontLeft[frontLeft.length - 1].error}
                  {yAxisUnit}
                </>
              ) : (
                '-'
              )}
            </div>
          </div>
        )}
        <div className="rolling-radius-indicator rolling-indicator-fr">
          <div className="rolling-radius-title">FR</div>
          <div className="rolling-radius-value">
            {frontRight.length ? (
              <>
                {frontRight[frontRight.length - 1].value}&nbsp;&#177;&nbsp;{frontRight[frontRight.length - 1].error}
                {yAxisUnit}
              </>
            ) : (
              '-'
            )}
          </div>
        </div>
        <div className="rolling-radius-indicator rolling-indicator-rl">
          <div className="rolling-radius-title">RL</div>
          <div className="rolling-radius-value">
            {rearLeft.length ? (
              <>
                {rearLeft[rearLeft.length - 1].value}&nbsp;&#177;&nbsp;{rearLeft[rearLeft.length - 1].error}
                {yAxisUnit}
              </>
            ) : (
              '-'
            )}
          </div>
        </div>
        <div className="rolling-radius-indicator rolling-indicator-rr">
          <div className="rolling-radius-title">RR</div>
          <div className="rolling-radius-value">
            {rearRight.length ? (
              <>
                {rearRight[rearRight.length - 1].value}&nbsp;&#177;&nbsp;{rearRight[rearRight.length - 1].error}
                {yAxisUnit}
              </>
            ) : (
              '-'
            )}
          </div>
        </div>
      </div>
      <div className="grip-container grip-rolling rolling-radius-graph">
        <div
          ref={containerRef}
          style={{ height: 'calc(30vh - 50px)', width: '100%', position: 'relative' }}
          className="grip-dash-container"
        >
          <canvas ref={chartRef} />
          <ul className="grip-vertical">{getYAxisLabels()}</ul>
          <ul className="grip-line">{getXAxisLabels()}</ul>
        </div>
      </div>
    </>
  );
};

export default React.memo(RollRadiiWidget);
