import { useDispatch, useSelector } from 'react-redux';
import { setAccStateActive } from './store';
import { Acc } from '../../../interfaces';
import { Draft, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { DataState, EventWithCounter } from './state';
import { RootState } from '../../store';
import { setLastDataSignalTimeAsNow } from './signalsState';

export const useAccData = (): UseAccData => {
  const accStateActive = useSelector(accStateActiveSelector);

  const dispatch = useDispatch();

  const updateAcc = (e: Acc) => {
    dispatch(
      setAccStateActive({
        counter: e.counter,
        value: e.state,
      }),
    );
  };

  return {
    accStateActive,
    updateAcc,
  };
};

export interface UseAccData {
  accStateActive: boolean;
  updateAcc(e: Acc): void;
}

export const initialAccState: Pick<DataState, 'accStateActive'> = {
  accStateActive: { counter: 0, value: false },
};

export const accReducers: ValidateSliceCaseReducers<DataState, AccReducers> = {
  setAccStateActive: (state: Draft<DataState>, action: PayloadAction<EventWithCounter<boolean>>) => {
    const { value, counter } = action.payload;

    if (state.accStateActive.counter >= counter) return;

    setLastDataSignalTimeAsNow(state);
    state.accStateActive = { value, counter };
  },
};

export interface AccReducers extends SliceCaseReducers<DataState> {
  setAccStateActive(state: Draft<DataState>, action: PayloadAction<EventWithCounter<boolean>>): void;
}

export function resetAccCounter(state: Draft<DataState>): void {
  state.accStateActive.counter = -1;
}

export function accStateActiveSelector(state: RootState): boolean {
  return state.data.accStateActive.value;
}
