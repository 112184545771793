import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { LOGIN, MAP_PAGE, SETTINGS_PAGE, FORGOT_PASSWORD, PASSWORD_RESET } from '../constants/pathnames';
import { ForgotPassword, UpdatePassword, Login } from '../app/modules/user';
import MainPage from '../app/modules/main/main.component';
import AuthenticatedRoute from '../app/components/AuthenticatedRoute';
import SettingsPage from '../app/modules/settings/SettingsPage';

const Routing: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route exact path={FORGOT_PASSWORD} component={ForgotPassword} />
        <Route exact path={PASSWORD_RESET} component={UpdatePassword} />
        <Route exact path={LOGIN} component={Login} />
        <AuthenticatedRoute exact path={MAP_PAGE} component={MainPage} />
        <AuthenticatedRoute exact path={SETTINGS_PAGE} component={SettingsPage} />
      </Switch>
    </Router>
  );
};

export default Routing;
