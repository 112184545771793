import React, { useEffect, useState } from 'react';
import IconButton from '../../../components/IconButton';
import { Tooltip, TooltipSide } from 'shared';
import { Theme } from '../interfaces';
import { resolution } from '../../../../constants/common';

interface Props {
  followIsEnable: boolean;
  currentTheme: Theme;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  onClickTarget?: () => void;
  onChangeTo3d?: () => void;
  onToggleLightTheme: () => void;
  className?: string;
}

enum ScreenType {
  DESKTOP = 'desktop',
  BIG_LAPTOP = 'small_laptop',
  TABLET = 'tablet',
}

const getScreenType = (width: number): ScreenType => {
  if (width < resolution.TABLET) {
    return ScreenType.TABLET;
  } else if (width < resolution.BIG_LAPTOP) {
    return ScreenType.BIG_LAPTOP;
  }

  return ScreenType.DESKTOP;
};

const MapControls: React.FC<Props> = (props: Props) => {
  const {
    onZoomIn,
    onZoomOut,
    onClickTarget,
    onToggleLightTheme,
    followIsEnable,
    currentTheme,
    className = '',
  } = props;

  const [screenType, setScreenType] = useState<ScreenType>(getScreenType(window.innerWidth));

  const onWindowResize = (): void => {
    setScreenType(getScreenType(window.innerWidth));
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    return () => window.removeEventListener('resize', onWindowResize);
  }, []);

  // TODO: remove inline style after adding to SCSS
  return (
    <div className={`controls-btn-container map-controls ${className}`}>
      <Tooltip text="Center vehicle" side={TooltipSide.LEFT}>
        <IconButton
          buttonClassList={`controls-btn controls-btn-cursor ${followIsEnable && 'active'}`}
          iconClassList="controls-icon icon icon-pin"
          onClickEvent={onClickTarget}
          testId="map-cursor"
        />
      </Tooltip>
      {screenType !== ScreenType.TABLET && (
        <Tooltip text="Zoom in/out" side={TooltipSide.LEFT}>
          <div className="controls-solid">
            <IconButton
              buttonClassList="controls-solid-btn"
              iconClassList="controls-icon icon icon-plus"
              onClickEvent={onZoomIn}
              id="map-zoomIn"
              testId="map-zoomIn"
            />
            <IconButton
              buttonClassList="controls-solid-btn"
              iconClassList="controls-icon icon icon-minus"
              onClickEvent={onZoomOut}
              id="map-zoomOut"
              testId="map-zoomOut"
            />
          </div>
        </Tooltip>
      )}
      <Tooltip text="Map type" side={TooltipSide.LEFT}>
        <IconButton
          buttonClassList={`controls-btn controls-btn-cursor ${currentTheme === Theme.LIGHT && 'active'}`}
          iconClassList="controls-icon icon-terrain icon"
          onClickEvent={onToggleLightTheme}
          testId="map-toggle-light-theme"
        />
      </Tooltip>
    </div>
  );
};

export default React.memo(MapControls);
