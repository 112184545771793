import React from 'react';
import { ForgotPassword as ForgotPasswordComponent, RequestPasswordReset, RequestPasswordResetFields } from 'shared';
import { LOGIN } from '../../../constants/pathnames';
import { container } from '../../../configs/inversify';
import { IUserService } from './service';
import './forgotPassword.page.scss';

const userService: IUserService = container.get('UserService');

export const ForgotPassword: React.FC = () => {
  const requestPasswordReset: RequestPasswordReset = async (fields: RequestPasswordResetFields) => {
    return userService.requestPasswordReset(fields.email);
  };

  return (
    <ForgotPasswordComponent requestPasswordReset={requestPasswordReset} loginPath={LOGIN}></ForgotPasswordComponent>
  );
};
