import { injectable } from 'inversify';
import 'reflect-metadata';

export interface IDataProcessingService {
  getCoordinatesFromPolygonGeometry(geometry: string): number[][];
}

@injectable()
export class DataProcessingService implements IDataProcessingService {
  public getCoordinatesFromPolygonGeometry(geometry: string): number[][] {
    const arr = [];
    const coordsString = geometry.slice(9, geometry.length - 1);
    const dividedCoords = coordsString.split(',');
    arr.push(...dividedCoords.map((item: string) => item.split(' ').map((item: string) => parseFloat(item))));
    return arr;
  }
}
