import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  fetchRecordingBookmarks,
  fetchTapingBookmarks,
  saveTapingBookmark,
  saveRecordingBookmark,
  updateTapingBookmark,
  deleteRecordingBookmark,
  deleteTapingBookmark,
} from './thunks';
import {
  Bookmark,
  DeleteRecordingBookmarkParams,
  DeleteTapingBookmarkParams,
  SaveRecordingBookmarkParams,
  SaveTapingBookmarkParams,
  UpdateTapingBookmarkParams,
} from './interfaces';

export interface RecordingsState {
  isLoading: boolean;
  isError: boolean;
  temporaryBookmarks: Bookmark[];
  bookmarks: Bookmark[];
}

export const initialState: RecordingsState = {
  isLoading: false,
  isError: false,
  temporaryBookmarks: [],
  bookmarks: [],
};

export const recordingsSlice = createSlice({
  name: 'bookmark',
  initialState,
  reducers: {
    addTemporaryBookmark: (state, action: PayloadAction<Bookmark>) => {
      state.temporaryBookmarks = [...state.temporaryBookmarks, action.payload];
    },
    deleteTemporaryBookmark: (state, action: PayloadAction<Bookmark>) => {
      state.temporaryBookmarks = state.temporaryBookmarks.filter((item) => item.name !== action.payload.name);
    },
    reset: (state) => {
      state = initialState;
      return state;
    },
  },
  extraReducers: {
    [fetchTapingBookmarks.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [fetchTapingBookmarks.fulfilled.type]: (state, action: PayloadAction<Bookmark[]>) => {
      state.isLoading = false;
      state.bookmarks = action.payload;
    },
    [fetchTapingBookmarks.rejected.type]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    [fetchRecordingBookmarks.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [fetchRecordingBookmarks.fulfilled.type]: (state, action: PayloadAction<Bookmark[]>) => {
      state.isLoading = false;
      state.bookmarks = action.payload;
    },
    [fetchRecordingBookmarks.rejected.type]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    [saveTapingBookmark.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [saveTapingBookmark.fulfilled.type]: (state, action: PayloadAction<SaveTapingBookmarkParams>) => {
      const { fields } = action.payload;

      state.temporaryBookmarks = state.temporaryBookmarks.filter((item) => item.name !== fields.name);
      state.bookmarks = [...state.bookmarks, fields];
      state.isLoading = false;
    },
    [saveTapingBookmark.rejected.type]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    [saveRecordingBookmark.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [saveRecordingBookmark.fulfilled.type]: (state, action: PayloadAction<SaveRecordingBookmarkParams>) => {
      const { fields } = action.payload;

      state.temporaryBookmarks = state.temporaryBookmarks.filter((item) => item.name !== fields.name);
      state.bookmarks = [...state.bookmarks, fields];
      state.isLoading = false;
    },
    [saveRecordingBookmark.rejected.type]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    [updateTapingBookmark.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [updateTapingBookmark.fulfilled.type]: (state, action: PayloadAction<UpdateTapingBookmarkParams>) => {
      state.isLoading = false;

      const { name, new_name } = action.payload.fields;
      const bookmark = state.bookmarks.find((item) => item.name === name);

      if (!bookmark) return;

      bookmark.name = new_name;
    },
    [updateTapingBookmark.rejected.type]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    [deleteTapingBookmark.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [deleteTapingBookmark.fulfilled.type]: (state, action: PayloadAction<DeleteTapingBookmarkParams>) => {
      state.isLoading = false;
      state.bookmarks = state.bookmarks.filter((item) => item.name !== action.payload.name);
    },
    [deleteTapingBookmark.rejected.type]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    [deleteRecordingBookmark.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [deleteRecordingBookmark.fulfilled.type]: (state, action: PayloadAction<DeleteRecordingBookmarkParams>) => {
      state.isLoading = false;
      state.bookmarks = state.bookmarks.filter((item) => item.id !== action.payload.bookmarkId);
    },
    [deleteRecordingBookmark.rejected.type]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
  },
});

export const { addTemporaryBookmark, deleteTemporaryBookmark, reset } = recordingsSlice.actions;

export function isLoadingSelector(state: RootState): boolean {
  return state.bookmark.isLoading;
}

export function isErrorSelector(state: RootState): boolean {
  return state.bookmark.isError;
}

export function temporaryBookmarksSelector(state: RootState): Bookmark[] {
  return state.bookmark.temporaryBookmarks;
}

export function bookmarksSelector(state: RootState): Bookmark[] {
  return state.bookmark.bookmarks;
}

export default recordingsSlice.reducer;
