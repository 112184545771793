import mapboxgl from 'mapbox-gl';
import { Gps } from '../../../interfaces';
import { TIME_TO_HIDE_EVENT } from '../../../constants/common';
import { injectable } from 'inversify';
import 'reflect-metadata';

interface HidingLineProps {
  coords: number[][];
  timestamp: number;
}

export interface IMapLayerCreationService {
  createVehicleTrailLayer(gpsList: Gps[], name?: string): mapboxgl.LineLayer;
  createSingleLineLayer(coords: number[][], layerName: string, color: string, width: number): mapboxgl.LineLayer;
}

@injectable()
export class MapLayerCreationService implements IMapLayerCreationService {
  private SINGLE_LINE_LAYER_NAME = 'line';
  private MULTI_LINE_LAYER_NAME = 'multi-line';

  private VEHICLE_PATH_LAYER_NAME = 'vehiclePath';
  private VEHICLE_PATH_COLOR = '#fd7500';
  private VEHICLE_PATH_LINE_WIDTH = 5;

  public createVehicleTrailLayer(gpsList: Gps[], name?: string): mapboxgl.LineLayer {
    const coords = gpsList.map((gps, i) => {
      let coords: number[][];
      const prevGps = gpsList[i - 1];

      if (prevGps) {
        coords = [
          [prevGps.lon_deg, prevGps.lat_deg],
          [gps.lon_deg, gps.lat_deg],
        ];
      } else {
        coords = [];
      }

      return {
        coords,
        timestamp: gps.received_time,
      };
    });

    return this.createMultiLineLayerWithHide(
      coords,
      name || this.VEHICLE_PATH_LAYER_NAME,
      this.VEHICLE_PATH_COLOR,
      this.VEHICLE_PATH_LINE_WIDTH,
    );
  }

  public createMultiLineLayerWithHide(
    lines: HidingLineProps[],
    layerName: string,
    color: string,
    width: number,
  ): mapboxgl.LineLayer {
    const layerId = `${this.MULTI_LINE_LAYER_NAME} ${layerName}`;

    return {
      id: layerId,
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: lines.map((item) => ({
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: item.coords,
            },
            properties: {
              layerId,
              timestamp: item.timestamp,
              coordinates: item.coords,
            },
          })),
        },
      },
      paint: {
        'line-width': width,
        'line-color': color,
        'line-opacity': ['/', ['-', TIME_TO_HIDE_EVENT, ['-', Date.now(), ['get', 'timestamp']]], TIME_TO_HIDE_EVENT],
      },
    };
  }

  public createSingleLineLayer(
    coords: number[][],
    layerName: string,
    color: string,
    width: number,
  ): mapboxgl.LineLayer {
    const layerId = `${this.SINGLE_LINE_LAYER_NAME} ${layerName}`;
    return {
      id: layerId,
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: coords,
          },
          properties: {
            layerId,
          },
        },
      },
      paint: {
        'line-width': width,
        'line-color': color,
      },
    };
  }
}
