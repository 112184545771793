import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface MapState {
  isLoading: boolean;
  isError: boolean;
}

export const initialState: MapState = {
  isLoading: false,
  isError: false,
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
    },
  },
});

export const { setIsLoading, setIsError } = mapSlice.actions;

export function isLoadingSelector(state: RootState): boolean {
  return state.map.isLoading;
}

export function isErrorSelector(state: RootState): boolean {
  return state.map.isError;
}

export default mapSlice.reducer;
