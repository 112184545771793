import React, { useContext, useEffect, useState } from 'react';
import { HookContext } from '../../../contexts/HookContext';
import { TapingStatus } from '../../stream/interfaces';
import SaveRecordingDialog from '../SaveRecordingDialog';

const HeaderTapingControls: React.FC = () => {
  const [isSaveRecordingDialogOpen, setIsSaveRecordingDialogOpen] = useState(false);

  const { useBookmark, useStream } = useContext(HookContext);
  const { bookmarks, temporaryBookmarks } = useBookmark;
  const { pauseStreamTaping, tapingStatus } = useStream;

  const onSaveRecordingHandler = () => {
    if (tapingStatus === TapingStatus.STARTED) {
      pauseStreamTaping();
    }

    if (tapingStatus === TapingStatus.STOPPED) {
      toggleIsSaveRecordingDialogOpen();
    }
  };

  const toggleIsSaveRecordingDialogOpen = () => {
    setIsSaveRecordingDialogOpen(!isSaveRecordingDialogOpen);
  };

  useEffect(() => {
    if (tapingStatus === TapingStatus.STOPPED) {
      toggleIsSaveRecordingDialogOpen();
    }
  }, [tapingStatus]);

  return (
    <>
      {tapingStatus === TapingStatus.STARTED && (
        <button className="header-rec-stream">
          <span className="header-rec-btn">Recording Stream</span>
        </button>
      )}
      <button className="header-rec-save" data-recording={bookmarks.length + temporaryBookmarks.length}>
        <span className="header-rec-btn" onClick={onSaveRecordingHandler}>
          Save Recording
        </span>
      </button>
      <SaveRecordingDialog isOpen={isSaveRecordingDialogOpen} onCancel={toggleIsSaveRecordingDialogOpen} />
    </>
  );
};

export default React.memo(HeaderTapingControls);
