import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SaveTapingParams, TapedStream, TapingStatus, VehicleInfo } from './interfaces';
import {
  deletePauseTapingTime,
  pauseTapingTimeSelector,
  setPauseTapingTime,
  setStartTapingTime,
  setTapingStatus,
  startTapingTimeSelector,
  streamUrlSelector,
  tapedStreamsSelector,
  tapingStatusSelector,
  vehicleInfoListSelector,
} from './store';
import * as streamThunks from './thunks';
import { selectedStreamSelector } from '../selections/store';
import { reset } from './store';

interface ReturnValue {
  vehicleInfoList: VehicleInfo[];
  startTapingTime: number | null;
  pauseTapingTime: number | null;
  tapingStatus: TapingStatus | null;
  tapedStreams: TapedStream[];
  streamUrl: string | null;
  fetchStreamInfo: () => void;
  startStreamTaping: (streamId: string) => void;
  pauseStreamTaping: () => void;
  resumeStreamTaping: () => void;
  stopStreamTaping: (streamId: string) => void;
  saveTaping: (params: SaveTapingParams) => void;
  fetchTapedStreams: () => void;
  resetStore: () => void;
}

export const useStream = (): ReturnValue => {
  const vehicleInfoList = useSelector(vehicleInfoListSelector);
  const startTapingTime = useSelector(startTapingTimeSelector);
  const pauseTapingTime = useSelector(pauseTapingTimeSelector);
  const tapedStreams = useSelector(tapedStreamsSelector);
  const selectedStream = useSelector(selectedStreamSelector);
  const tapingStatus = useSelector(tapingStatusSelector);
  const streamUrl = useSelector(streamUrlSelector);

  const dispatch = useDispatch();

  const fetchStreamInfo = (): void => {
    dispatch(streamThunks.fetchVehicleInfoList());
  };

  const startStreamTaping = (streamId: string): void => {
    dispatch(streamThunks.startTaping(streamId));
  };

  const pauseStreamTaping = (): void => {
    dispatch(setPauseTapingTime());
    dispatch(setTapingStatus(TapingStatus.PAUSED));
  };

  const resumeStreamTaping = (): void => {
    dispatch(setTapingStatus(TapingStatus.STARTED));
    dispatch(deletePauseTapingTime());
  };

  const stopStreamTaping = (streamId: string): void => {
    dispatch(streamThunks.stopTaping(streamId));
  };

  const saveTaping = (params: SaveTapingParams): void => {
    dispatch(streamThunks.saveTaping(params));
  };

  const resetStore = () => {
    dispatch(reset());
  };

  const fetchTapedStreams = () => {
    dispatch(streamThunks.fetchTapedStreams());
  };

  useEffect(() => {
    const stream = tapedStreams?.find((item) => item.name === selectedStream);

    if (!stream) return;

    if (stream.start_time_ms) {
      dispatch(setStartTapingTime(stream.start_time_ms));
    }

    if (stream.duration_ms) {
      dispatch(setPauseTapingTime(stream.start_time_ms + stream.duration_ms));
    }

    dispatch(setTapingStatus(stream.duration_ms ? TapingStatus.STOPPED : TapingStatus.STARTED));
  }, [selectedStream, tapedStreams]);

  return {
    vehicleInfoList,
    startTapingTime,
    pauseTapingTime,
    tapedStreams,
    tapingStatus,
    streamUrl,
    fetchStreamInfo,
    startStreamTaping,
    pauseStreamTaping,
    stopStreamTaping,
    resumeStreamTaping,
    saveTaping,
    resetStore,
    fetchTapedStreams,
  };
};
