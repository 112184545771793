import { useDispatch, useSelector } from 'react-redux';
import { addToMarkers, addToSurfaceEvents } from './store';
import {
  Marker,
  RawSurfaceEvents,
  StoredSurfaceEvent,
  ShownSurfaceEvent,
  SurfaceEventSide,
  SurfaceEventType,
  SurfaceEvent,
} from '../../../interfaces';
import { getTimeFromTimestamp } from '../../../helpers/common';
import { EventType, MarkerType } from './interfaces';
import { IIconClassService } from '../../services/IconClassService';
import { container } from '../../../configs/inversify';
import { surfacePopupFieldsTitleToEventFields } from '../../../constants/symbology';
import { DataState } from './state';
import { Draft, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { MAX_STORED_EVENTS_NUMBER } from '../../../constants/common';
import { RootState } from '../../store';
import { setLastDataSignalTimeAsNow } from './signalsState';

const iconClassService: IIconClassService = container.get('IconClassService');

export const useSurfaceEventsData = (): UseSurfaceEvents => {
  const surfaceEvents = useSelector(surfaceEventsSelector);

  const dispatch = useDispatch();

  const addSurfaceEvent = (event: SurfaceEvent): void => {
    dispatch(addToSurfaceEvents(event));
  };

  const addMarker = (marker: Marker): void => {
    dispatch(addToMarkers(marker));
  };

  const updateSurfaceEventsState = (e: RawSurfaceEvents): void => {
    const event = { ...e };

    if (!event.events.length) {
      return;
    }

    const firstRawEvent = event.events[0];

    const firstStoredEvent: StoredSurfaceEvent = {
      ...event.events[0],
      generation_time: getTimeFromTimestamp(firstRawEvent.counter),
    };
    if (firstStoredEvent.type === SurfaceEventType.UNKNOWN) {
      return;
    }

    // TODO: change after discussing later
    const normalizedItem: SurfaceEvent = {
      ...firstStoredEvent,
      lon_deg: event.lon_deg,
      lat_deg: event.lat_deg,
      front_type: EventType.SURFACE_EVENT,
      received_time: Date.now(),
    };

    addSurfaceEvent(normalizedItem);

    const item = {
      lat_deg: normalizedItem.lat_deg,
      lon_deg: normalizedItem.lon_deg,
      marker: iconClassService.getIconClass(normalizedItem),
      markerIcon: iconClassService.getEventIconName(normalizedItem),
      markerType: MarkerType.SURFACE_EVENT,
      eventType: EventType.SURFACE_EVENT,
      popupInfo: [] as {
        key: string;
        value: number | string | boolean | SurfaceEventSide | SurfaceEventType[];
      }[],
      received_time: Date.now(),
    };

    //Places only the necessary data in the pop-up window
    const shownSurfaceEvent: (keyof ShownSurfaceEvent)[] = [
      'magnitude',
      'side',
      'type',
      'in_rough_road',
      'generation_time',
    ];

    Object.entries(normalizedItem).forEach(([key, value]) => {
      if (shownSurfaceEvent.indexOf(key as keyof ShownSurfaceEvent) + 1) {
        item.popupInfo.push({ key: surfacePopupFieldsTitleToEventFields[key], value });
      }
    });

    addMarker(item);
  };

  return {
    surfaceEvents,
    updateSurfaceEventsState,
  };
};

export interface UseSurfaceEvents {
  surfaceEvents: SurfaceEvent[];
  updateSurfaceEventsState(e: RawSurfaceEvents): void;
}

export const initialSurfaceEventsState: Pick<DataState, 'surfaceEvents'> = {
  surfaceEvents: [],
};

export const surfaceEventsReducers: ValidateSliceCaseReducers<DataState, SurfaceEventsReducers> = {
  setSurfaceEvents: (state: Draft<DataState>, action: PayloadAction<SurfaceEvent[]>) => {
    state.surfaceEvents = action.payload;
  },
  addToSurfaceEvents: (state: Draft<DataState>, action: PayloadAction<SurfaceEvent>) => {
    if (!state.surfaceEvents.length) {
      setLastDataSignalTimeAsNow(state);
      state.surfaceEvents = [...state.surfaceEvents, action.payload];
      return;
    }

    const { counter } = action.payload;
    const lastEvent = state.surfaceEvents[state.surfaceEvents.length - 1];

    if (lastEvent.counter >= counter) return;

    setLastDataSignalTimeAsNow(state);
    state.surfaceEvents = [...state.surfaceEvents, action.payload].slice(-MAX_STORED_EVENTS_NUMBER);
  },
};

export interface SurfaceEventsReducers extends SliceCaseReducers<DataState> {
  setSurfaceEvents(state: Draft<DataState>, action: PayloadAction<SurfaceEvent[]>): void;
  addToSurfaceEvents(state: Draft<DataState>, action: PayloadAction<SurfaceEvent>): void;
}

export function resetSurfaceEventsToInitialValue(state: Draft<DataState>): void {
  state.surfaceEvents = initialSurfaceEventsState.surfaceEvents;
}

export function resetLastSurfaceEventsCounter(state: Draft<DataState>): void {
  if (state.surfaceEvents.length) {
    state.surfaceEvents[state.surfaceEvents.length - 1].counter = -1;
  }
}

export function surfaceEventsSelector(state: RootState): SurfaceEvent[] {
  return state.data.surfaceEvents;
}
