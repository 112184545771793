import React, { useEffect } from 'react';
import Routing from '../routing';
import { container } from '../configs/inversify';
import { IPerformanceLoggerService } from './modules/metrics/PerformanceLoggerService';
import { ContextInitComponent } from './contexts/ContextInitComponent';

const performanceLoggingService: IPerformanceLoggerService = container.get('PerformanceLoggerService');

export const App: React.FC = () => {
  useEffect(() => {
    performanceLoggingService.enableLoggingMemoryMetric();
    return () => performanceLoggingService.disableLoggingMemoryMetric();
  }, []);

  return (
    <ContextInitComponent>
      <Routing />
    </ContextInitComponent>
  );
};
