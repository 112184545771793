import { combineReducers, configureStore, DeepPartial, PreloadedState } from '@reduxjs/toolkit';
import { LocalStorageService } from '../services/LocalStorageService';
import UserReducer from '../modules/user/store';
import FilterReducer from '../modules/filters/store';
import SelectionsReducer from '../modules/selections/store';
import MapReducer from '../modules/map/map.store';
import DataReducer from '../modules/data/store';
import RecordingsReducer from '../modules/recordings/store';
import StreamReducer from '../modules/stream/store';
import SettingsReducer from '../modules/settings/store';
import BookmarkReducer from '../modules/bookmark/store';

// Change store version if you change fields in saved slices
const STORE_VERSION = 'stream_frontend-1';

const STORE_VERSION_FIELD = 'store-version';
const STATE_FIELD = 'state';

const localStorageService = new LocalStorageService();

const getSavedState = () => {
  const storeVersion = localStorageService.getItem<string>(STORE_VERSION_FIELD);

  if (!storeVersion || storeVersion !== STORE_VERSION) {
    return {};
  }

  return localStorageService.getItem(STATE_FIELD) || {};
};

const rootReducer = combineReducers({
  user: UserReducer,
  filter: FilterReducer,
  map: MapReducer,
  selections: SelectionsReducer,
  data: DataReducer,
  recordings: RecordingsReducer,
  stream: StreamReducer,
  settings: SettingsReducer,
  bookmark: BookmarkReducer,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

const store = setupStore(getSavedState() as DeepPartial<any>);

store.subscribe(() => {
  localStorageService.setItem(STORE_VERSION_FIELD, STORE_VERSION);

  const state = store.getState();
  localStorageService.setItem(STATE_FIELD, {
    user: state.user,
  });
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof setupStore>;

export default store;
