import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { HookContext } from '../../../contexts/HookContext';
import { setAvailableStreams } from '../store';
import { ISocketSubscriptionService } from '../../../services/SocketSubscriptionService';
import { container } from '../../../../configs/inversify';
import { fetchAvailableStreams } from '../thunks';
import { streamsSelector } from '../../user/store';
import { getIntersection } from '../../../../helpers/common';
import { ALL_KEY, ALL_KEY_STAR } from '../../../../constants/common';
import './_vehicle-dropdown.scss';

const socketSubscriptionService: ISocketSubscriptionService = container.get('SocketSubscriptionService');

export const StreamSelector: React.FC = () => {
  const [isDropdownActive, setIsDropdownActive] = useState(false);

  const { useRecordings, useSelections, useStream } = useContext(HookContext);
  const { availableStreams, selectedStream, selectStream } = useSelections;
  const { vehicleInfoList, fetchStreamInfo } = useStream;
  const { playingRecording } = useRecordings;
  const allowedStreams = useSelector(streamsSelector);
  const dispatch = useDispatch();

  const toggleDropdown = () => {
    setIsDropdownActive(!isDropdownActive);
  };

  const handleSelectStream = (stream: string) => {
    selectStream(stream);
    setIsDropdownActive(false);
  };

  useEffect(() => {
    if (availableStreams.length && !selectedStream && !playingRecording) {
      selectStream(availableStreams[0]);
    }
  }, [availableStreams, selectedStream, playingRecording]);

  useEffect(() => {
    if (!allowedStreams || !allowedStreams.length) return;

    dispatch(fetchAvailableStreams(allowedStreams));

    fetchStreamInfo();

    socketSubscriptionService.subscribeToAvailableStreams((list) => {
      if (allowedStreams[0] === ALL_KEY || allowedStreams[0] === ALL_KEY_STAR) {
        dispatch(setAvailableStreams(list));
      } else {
        dispatch(setAvailableStreams(getIntersection<string>(allowedStreams, list)));
      }
    });
  }, [allowedStreams]);

  return (
    <div className={cn('dropdown header-dropdown ', { active: isDropdownActive })}>
      <button className="dropdown-btn" onClick={toggleDropdown} disabled={!availableStreams.length}>
        <span className="dropdown-text">{selectedStream ? `selected ${selectedStream}` : 'Select a vehicle'}</span>
        <i className="dropdown-btn-icon icon icon-arrow" />
      </button>
      <div className={cn('vehicle-dropdown-container', { active: isDropdownActive })}>
        <nav className="dropdown-settings">
          <ul className="vehicle-dropdown-list">
            {availableStreams.map((streamId) => {
              const vehicleInfo = vehicleInfoList.find((item) => item.stream_id === streamId);

              return (
                <li className="vehicle-dropdown-item" key={streamId} onClick={handleSelectStream.bind(this, streamId)}>
                  {vehicleInfo ? (
                    <>
                      <span className="vehicle-dropdpwn-title">
                        {`${vehicleInfo.stream_id} (${vehicleInfo.fleet})`}
                      </span>
                      <span className="vehicle-dropdpwn-title">
                        {`${vehicleInfo.make} ${vehicleInfo.model}, ${vehicleInfo.year}`}
                      </span>
                    </>
                  ) : (
                    <span className="vehicle-dropdpwn-title">{streamId}</span>
                  )}
                  {selectedStream === streamId && <i className="vehicle-dropdpwn-check icon icon-checkmark isActive" />}
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
};
