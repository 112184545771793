import React, { useContext } from 'react';
import DashboardCard from '../widgets/DashboardCard';
import GripDataWidget from '../widgets/GripDataWidget';
import TireRadiiConvergenceWidget, { ConvergenceType } from '../widgets/TireRadiiConvergenceWidget';
import TireDataWidget from '../widgets/TireDataWidget';
import { AvailableWidgets, DashboardContext } from '../index';
import { HookContext } from '../../../../contexts/HookContext';
import VehicleSignals from '../widgets/VehicleSignals';
import { filterGrips } from '../../../filters/gripFilter';

const TiresPanel: React.FC = () => {
  const { isMini, isTablet, isLaptop } = useContext(DashboardContext);

  const { useData, useFilters } = useContext(HookContext);

  const { isDataReset, gripEvents, tiresRadiiChangeEvents, tiresStiffnessEvents, tireRadiiConvergence, steeringAngle } =
    useData;

  const { filtersState } = useFilters;

  return (
    <>
      {!isTablet && (
        <DashboardCard
          id={AvailableWidgets.VEHICLE_SIGNALS_PANEL}
          className={'col col-lg col-xl-4 dash-vehicle-panel ' + (isMini ? 'col-xxl-7' : 'col-xxl-8')}
        >
          <VehicleSignals
            isMini={isMini || isLaptop}
            displayBreakTorqueHeatBar
            displayAccelerometer
            displaySpeedometer
            displayAbs
          />
        </DashboardCard>
      )}
      <DashboardCard id={AvailableWidgets.GRIP_DATA} className="col dash-graph-panel">
        {/* TODO: 0.3(30% from screen width) and 0.35(35% from grip data widget width) need to change on needed percents; */}
        <GripDataWidget
          key={isMini ? window.innerWidth + 1 : window.innerWidth}
          gripEvents={filterGrips(gripEvents, filtersState)}
          width={isMini ? window.innerWidth * 0.15 : isLaptop ? window.innerWidth * 0.28 : window.innerWidth * 0.2}
          height={isMini ? 60 : window.innerWidth * 0.3 * 0.35}
          linesCount={isMini ? 5 : 8}
          timeOfObserve={35}
          gripWidth={10}
          isDataReset={isDataReset}
          isMini={isMini}
        />
      </DashboardCard>
      <DashboardCard
        id={AvailableWidgets.TIRE_RADII_CONVERGENCE}
        className="col-content col-xl-3 col-xxl-1 dash-convergence-panel"
      >
        <TireRadiiConvergenceWidget
          convergenceType={ConvergenceType.ABSOLUTE}
          convergenceValue={!!tireRadiiConvergence?.absolute_converged}
        />
        <TireRadiiConvergenceWidget
          convergenceType={ConvergenceType.RELATIVE}
          convergenceValue={!!tireRadiiConvergence?.relative_converged}
        />
      </DashboardCard>
      <DashboardCard
        id={AvailableWidgets.TIRE_DATA}
        className={'col dash-tire-panel ' + (isMini ? 'col-xxl-8' : 'col-xxl-7')}
      >
        <TireDataWidget
          tireRadiiChangeEvent={tiresRadiiChangeEvents[tiresRadiiChangeEvents.length - 1] || null}
          tiresStiffnessEvent={tiresStiffnessEvents[tiresStiffnessEvents.length - 1] || null}
          steeringAngle={steeringAngle}
          isMini={isMini}
        />
      </DashboardCard>
    </>
  );
};

export default React.memo(TiresPanel);
