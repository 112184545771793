import React from 'react';
import { getShortTimeFromTimestamp } from '../../../../../helpers/common';

interface Props {
  durationMilliseconds: number;
  timeLeftMilliseconds: number;
}

const BarTime: React.FC<Props> = (props: Props) => {
  const { durationMilliseconds, timeLeftMilliseconds } = props;

  return (
    <div className="playbar-time">
      {getShortTimeFromTimestamp(timeLeftMilliseconds)} - {getShortTimeFromTimestamp(durationMilliseconds)}
    </div>
  );
};

export default React.memo(BarTime);
