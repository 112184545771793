import React, { ChangeEvent, FormEvent, MouseEventHandler, useContext, useEffect, useRef, useState } from 'react';
import { checkClickLocation, ErrorModal } from 'shared';
import { HookContext } from '../../../contexts/HookContext';
import { MAX_RECORDING_FILE_SIZE } from '../../../../constants/common';
import './_recording.scss';

interface Props {
  closeModal: () => void;
}

interface RecordingUploadForm {
  recordingName: string;
  directoryName: string;
  recordingFile?: File;
  videoFile?: File;
}

const initialRecordingUploadForm: RecordingUploadForm = {
  recordingName: '',
  directoryName: '',
  recordingFile: undefined,
  videoFile: undefined,
};

export const RecordingModal: React.FC<Props> = (props: Props) => {
  const { closeModal } = props;
  const formRef = useRef<HTMLFormElement>(null);
  const recordingFileRef = useRef<HTMLInputElement>(null);

  const [form, setForm] = useState<RecordingUploadForm>(initialRecordingUploadForm);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const { useRecordings } = useContext(HookContext);
  const { uploadRecording } = useRecordings;

  const onHandleChangeTextInput = (e: ChangeEvent<HTMLInputElement>): void => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value.trim(),
    }));
  };

  const onHandleChangeFileInput = (e: ChangeEvent<HTMLInputElement>): void => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.files ? e.target.files[0] : undefined,
    }));
  };

  const validate = (uploadForm: RecordingUploadForm): boolean => {
    const { recordingFile } = uploadForm;
    const errors: string[] = [];

    if (recordingFile?.size && recordingFile.size > MAX_RECORDING_FILE_SIZE) {
      errors.push(`${recordingFile.name} is larger than the allowed size of ${MAX_RECORDING_FILE_SIZE / 1000000}MB`);
    }

    setValidationErrors(errors);

    return !errors.length;
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (validate(form)) {
      uploadRecording({
        recordingName: form.recordingName,
        directoryName: form.directoryName,
        recordingFile: form.recordingFile as File,
        videoFile: form.videoFile as File,
      });

      closeModal();
    }
  };

  const close = (e: MouseEvent) => {
    if (checkClickLocation(e, formRef)) {
      closeModal();
    }
  };

  const onOkay: MouseEventHandler = (e) => {
    e.stopPropagation();

    setForm({ ...form, recordingFile: undefined });
    setValidationErrors([]);

    if (recordingFileRef.current) recordingFileRef.current['value'] = '';
  };

  useEffect(() => {
    document.addEventListener('click', close);

    return () => document.removeEventListener('click', close);
  }, []);

  return (
    <>
      <form ref={formRef} className="recording" onSubmit={onSubmit}>
        <i className="recording-icon icon icon-save" />
        <span className="recording-title">Upload a recording</span>
        <button className="recording-close" onClick={closeModal}>
          <i className="recording-close-icon icon icon-cancel" />
        </button>
        <div className="recording-form">
          <label htmlFor="title" className="recording-label">
            Name
          </label>
          <input
            onChange={onHandleChangeTextInput}
            type="text"
            id="title"
            name="recordingName"
            className={`recording-input ${!form.recordingName ? 'isIncorrect' : ''}`}
            placeholder="Enter recording title"
          />
        </div>
        <div className="recording-form">
          <label htmlFor="directory" className="recording-label">
            Directory
          </label>
          <input
            onChange={onHandleChangeTextInput}
            type="text"
            id="directory"
            name="directoryName"
            className={`recording-input ${!form.directoryName ? 'isIncorrect' : ''}`}
            placeholder="Enter directory name"
          />
        </div>
        <div className="recording-form">
          <span className="recording-label">Recording file</span>
          <div className="recording-file-container">
            <label className="recording-btn" htmlFor="recordingText">
              Choose a file
            </label>
            <input
              onChange={onHandleChangeFileInput}
              ref={recordingFileRef}
              type="file"
              id="recordingText"
              name="recordingFile"
              className="hidden"
              accept="text/csv"
            />
            <span className={`recording-input file ${!form.recordingFile ? 'isIncorrect' : ''}`}>
              {form.recordingFile ? form.recordingFile.name : 'No file was chosen yet'}
            </span>
          </div>
        </div>
        <div className="recording-form">
          <span className="recording-label">Recording file</span>
          <div className="recording-file-container">
            <label htmlFor="videoText" className="recording-btn">
              Video file
            </label>
            <input
              onChange={onHandleChangeFileInput}
              type="file"
              id="videoText"
              name="videoFile"
              className="hidden"
              accept="video/mp4"
            />
            <span className="recording-input file">
              {form.videoFile ? form.videoFile.name : 'No file was chosen yet'}
            </span>
          </div>
        </div>
        <div className="recording-btn-container">
          <button
            className="recording-btn fill"
            type="submit"
            disabled={!form.recordingName || !form.directoryName || !form.recordingFile}
          >
            Upload
          </button>
          <button className="recording-btn" type="button" onClick={closeModal}>
            Close
          </button>
        </div>
      </form>
      <div className="recording-back" />
      <ErrorModal errors={validationErrors} onOkay={onOkay} />
    </>
  );
};
