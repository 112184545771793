import { Group } from '../app/modules/user/store';

export enum Feature {
  PLAY_RECORDINGS = 'Play recordings',
  UPLOAD_RECORDINGS = 'Upload recordings',
  UPDATE_RECORDINGS = 'Update recordings',
  DELETE_RECORDINGS = 'Delete recordings',
  LIVE_STREAM = 'Live stream',
  ACC_PANEL = 'ACC panel',
  STREAM_TAPING = 'Stream taping',
  PLAYING_BAR = 'Playing bar',
}

export const featureConfig: Record<Group, Feature[]> = {
  [Group.STREAM_TM]: [
    Feature.PLAY_RECORDINGS,
    Feature.UPLOAD_RECORDINGS,
    Feature.UPDATE_RECORDINGS,
    Feature.DELETE_RECORDINGS,
    Feature.LIVE_STREAM,
    Feature.ACC_PANEL,
    Feature.STREAM_TAPING,
    Feature.PLAYING_BAR,
  ],
  [Group.STREAM_PARTNER]: [
    Feature.PLAY_RECORDINGS,
    Feature.UPDATE_RECORDINGS,
    Feature.DELETE_RECORDINGS,
    Feature.LIVE_STREAM,
    Feature.STREAM_TAPING,
    Feature.PLAYING_BAR,
  ],
  [Group.STREAM_CUSTOMER]: [Feature.LIVE_STREAM],
  [Group.ADMIN]: Object.values(Feature),
};
