import { LoginCredentials } from '../../../interfaces';
import { Group } from './store';

export interface LoginFormFields extends LoginCredentials {
  saveCredentials: boolean;
}

export interface LoginFieldErrors {
  username: string;
  password: string;
}

export enum LoginState {
  LOGGED_IN = 'logged_in',
  LOGGING_IN = 'logging_in',
  FAILED_LOGGING_IN = 'failed_logging_in',
  LOGGED_OUT = 'logged_out',
}

export enum UserType {
  SAILS = 'sails',
  ADMIN = 'admin',
  ADMIN_RAM = 'admin-ram',
  REGULAR_RAM = 'regular-ram',
}

export enum UserDataState {
  OK = 'OK',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
}

export enum Units {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

export interface AccountConfig {
  title: string;
  units: Units;
  regions: number[];
  streams: string[];
  logo: string | null;
  groups: Group[];
}

export interface UserConfig {
  id: number;
  account_id: number;
  name: string;
  username: string;
  custom_regions: number[];
  streams: [];
  units: Units;
}
