import React from 'react';
import { ISocketSubscriptionService } from '../services/SocketSubscriptionService';
import { container } from '../../configs/inversify';

export interface Services {
  socketSubscriptionService: ISocketSubscriptionService;
}

export const ServiceContext = React.createContext<Services>({
  socketSubscriptionService: container.get('SocketSubscriptionService'),
});

export const ServiceContextProvider = ServiceContext.Provider;
