import { injectable } from 'inversify';
import 'reflect-metadata';
import {
  Grip,
  StoredRelativeRollingRadius,
  SurfaceEvent,
  StoredTireRadiiChange,
  StoredTiresStiffness,
  StoredVehicleWeight,
} from '../../interfaces';
import { EventType } from '../modules/data/interfaces';

export interface IIconClassService {
  getIconClass(event: SupportedEventType): string;
  getEventIconName(event: SupportedEventType): string;
}

export type SupportedEventType =
  | SurfaceEvent
  | Grip
  | StoredTireRadiiChange
  | StoredTiresStiffness
  | StoredVehicleWeight
  | StoredRelativeRollingRadius;

@injectable()
export class IconClassService implements IIconClassService {
  public getIconClass(event: SupportedEventType): string {
    if (!event.front_type) return '';

    const iconFunctions: { [key in EventType]: (event: any) => string } = {
      [EventType.GRIP]: getGripClass,
      [EventType.SURFACE_EVENT]: getSurfaceClass,
      [EventType.TIRE_CHANGE]: getTireChangeClass,
      [EventType.ALERT]: getAlert,
      [EventType.TIRES_STIFFNESS]: getAlert,
      [EventType.VEHICLE_WEIGHT]: getAlert,
      [EventType.ROLLING_RADIUS]: getRollingRadiusClass,
    };

    return iconFunctions[event.front_type as EventType](event);
  }

  public getEventIconName(event: SupportedEventType): string {
    const iconFunctions: { [key in EventType]: (event: any) => string } = {
      [EventType.GRIP]: IconClassService.getGripEventIcon,
      [EventType.SURFACE_EVENT]: IconClassService.getSurfaceEventIcon,
      [EventType.TIRE_CHANGE]: IconClassService.getAlertEventIcon,
      [EventType.ALERT]: IconClassService.getAlertEventIcon,
      [EventType.TIRES_STIFFNESS]: IconClassService.getAlertEventIcon,
      [EventType.VEHICLE_WEIGHT]: IconClassService.getAlertEventIcon,
      [EventType.ROLLING_RADIUS]: IconClassService.getAlertEventIcon,
    };

    return iconFunctions[event.front_type as EventType](event);
  }

  private static getGripEventIcon(event: Grip): string {
    const { mean_g } = event;
    let icon = '';

    if (mean_g >= 0.7) {
      icon = 'grip_high';
    }

    if (mean_g >= 0.4 && mean_g < 0.7) {
      icon = 'grip_medium';
    }

    if (mean_g < 0.4) {
      icon = 'grip_low';
    }

    return icon;
  }

  private static getSurfaceEventIcon(event: SurfaceEvent): string {
    const { magnitude, type } = event;
    let icon = '';

    if (magnitude >= 80) {
      icon = 'surface_high';
    }

    if (magnitude >= 40 && magnitude < 80) {
      icon = 'surface_medium';
    }

    if (magnitude < 40) {
      icon = 'surface_low';
    }

    icon += `_${IconClassService.setSurfaceIcon(type)}`;

    return icon;
  }

  private static setSurfaceIcon(type: string): string {
    switch (type) {
      case 'STEP_UP':
        return 'step_up';
      case 'STEP_DOWN':
        return 'step_down';
      case 'ROUGH_ROAD':
        return 'rough_road';
      case 'CONTINUAL':
        return 'rough_road';
      case 'SPIN':
        return 'spin';
      case 'SKID':
        return 'skid';
      default:
        return '';
    }
  }

  private static getAlertEventIcon(): string {
    return 'alert_low_change';
  }
}

const getGripClass = (event: Grip) => {
  const { mean_g } = event;
  let gripClass = 'grip grip-';

  if (mean_g >= 0.7) {
    gripClass = `${gripClass}high`;
  }

  if (mean_g >= 0.4 && mean_g < 0.7) {
    gripClass = `${gripClass}medium`;
  }

  if (mean_g < 0.4) {
    gripClass = `${gripClass}low`;
  }

  return gripClass;
};

const getSurfaceClass = (event: SurfaceEvent) => {
  const { magnitude, type } = event;
  let surfaceClass = 'surface surface-';

  if (magnitude >= 80) {
    surfaceClass = `${surfaceClass}low ${setSurfaceIcon(type)}`;
  }

  if (magnitude >= 40 && magnitude < 80) {
    surfaceClass = `${surfaceClass}medium ${setSurfaceIcon(type)}`;
  }

  if (magnitude < 40) {
    surfaceClass = `${surfaceClass}high ${setSurfaceIcon(type)}`;
  }

  return surfaceClass;
};

const setSurfaceIcon = (type: string): string => {
  switch (type) {
    case 'STEP_UP':
      return 'step-up';
    case 'STEP_DOWN':
      return 'step-down';
    case 'ROUGH_ROAD':
      return 'rough-road';
    case 'CONTINUAL':
      return 'rough-road';
    case 'SPIN':
      return 'spin';
    case 'SKID':
      return 'skid';
    default:
      return '';
  }
};

const getTireChangeClass = (event: StoredTireRadiiChange) => {
  const { reason } = event;

  if (reason === 'TIRE_CHANGE') {
    return 'alert alert-high tire-change';
  }

  return '';
};

const getRollingRadiusClass = () => {
  return 'alert alert-high tire-change';
};

const getAlert = () => {
  return 'alert alert-high tire-change';
};
