import React, { useEffect, useRef } from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import 'video.js/dist/video-js.css';
import './style.scss';

interface Props {
  options: VideoJsPlayerOptions;
}

const VideoPlayer: React.FC<Props> = (props: Props) => {
  const { options } = props;

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const playerRef = React.useRef<VideoJsPlayer | null>(null);

  useEffect(() => {
    let player: VideoJsPlayer | null;

    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      player = videojs(videoElement, options, function () {
        if (options.playbackRates && options.playbackRates.length) {
          this.playbackRate(options.playbackRates[0]);
        }
      });

      playerRef.current = player;
    }

    return () => {
      player?.reset();
    };
  }, [options, videoRef]);

  return (
    <div data-vjs-player="">
      <video ref={videoRef} className="video-js" width="100%" height="100%" />
    </div>
  );
};

export default React.memo(VideoPlayer);
