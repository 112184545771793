import React from 'react';
import { Toggler } from '../Toggler';
import cn from 'classnames';

interface Props {
  name: string;
  title: string;
  isActive: boolean;
  options?: { [key: string]: boolean }[];
  onToggle: (name: string) => void;
  disabled?: boolean;
}

export const Filter: React.FC<Props> = (props: Props) => {
  const { name, title, isActive, onToggle, disabled = false } = props;

  const onToggleHandler = () => onToggle(name);

  return (
    <div className="map-layers">
      <div className="map-layers-title controls-title">
        {title}
        <div className="d-flex a-center">
          {name === 'locationTrail' ? (
            <span className={cn('map-layers-trail', { isDisabled: !isActive })} />
          ) : (
            <i className={cn(`map-layers-icon icon-${name}-event icon`, { isDisabled: !isActive })} />
          )}
          <Toggler
            id={name}
            name={name}
            disabled={disabled}
            checked={isActive}
            title={isActive ? 'On' : 'Off'}
            onClick={onToggleHandler}
            className="map-layers-toggler"
          />
        </div>
      </div>
    </div>
  );
};
