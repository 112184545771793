const { REACT_APP_API_GW_ROUTE, REACT_APP_SOCKET_ROUTE } = process.env;

const AUTH_SERVICE_PREFIX = '/auth';
const RECORDING_SERVICE_PREFIX = '/recording';
const USER_CONFIG_SERVICE_PREFIX = '/user';
const STREAM_SERVICE_PREFIX = '/stream';

export const WEB_SOCKET_URL = `${REACT_APP_SOCKET_ROUTE}/stream_ws`;

export const API_LOGIN = `${REACT_APP_API_GW_ROUTE}${AUTH_SERVICE_PREFIX}/login`;
export const API_REFRESH = `${REACT_APP_API_GW_ROUTE}${AUTH_SERVICE_PREFIX}/refresh`;
export const API_REQUEST_PASSWORD_RESET = `${REACT_APP_API_GW_ROUTE}${AUTH_SERVICE_PREFIX}/reset_password`;
export const API_UPDATE_PASSWORD = `${REACT_APP_API_GW_ROUTE}${AUTH_SERVICE_PREFIX}/update_password`;

export const API_FETCH_STREAMS = `${REACT_APP_API_GW_ROUTE}${STREAM_SERVICE_PREFIX}/streams`;
export const API_FETCH_STREAM_INFO = `${REACT_APP_API_GW_ROUTE}${STREAM_SERVICE_PREFIX}/streams-info`;
export const API_FETCH_RECORDINGS = `${REACT_APP_API_GW_ROUTE}${RECORDING_SERVICE_PREFIX}/records`;
export const API_DELETE_RECORDINGS = `${API_FETCH_RECORDINGS}?recording_name=:recording_name&directory_name=:directory_name`;
export const API_PLAY_RECORDING = `${REACT_APP_API_GW_ROUTE}${RECORDING_SERVICE_PREFIX}/start`;
export const API_DUMP_RECORDING = `${API_PLAY_RECORDING}/snapshot`;
export const API_REWIND_RECORDING = `${REACT_APP_API_GW_ROUTE}${RECORDING_SERVICE_PREFIX}/rewind/:stream_id`;

export const API_USER_CONFIG = `${REACT_APP_API_GW_ROUTE}${USER_CONFIG_SERVICE_PREFIX}/users/`;
export const API_ACCOUNT_CONFIG = `${REACT_APP_API_GW_ROUTE}${USER_CONFIG_SERVICE_PREFIX}/accounts/`;
export const API_SETTINGS = `${REACT_APP_API_GW_ROUTE}${USER_CONFIG_SERVICE_PREFIX}/settings/`;

export const API_START_TAPING = `${REACT_APP_API_GW_ROUTE}${RECORDING_SERVICE_PREFIX}/taping/start/:streamId`;
export const API_STOP_TAPING = `${REACT_APP_API_GW_ROUTE}${RECORDING_SERVICE_PREFIX}/taping/stop/:streamId`;
export const API_SAVE_TAPING = `${REACT_APP_API_GW_ROUTE}${RECORDING_SERVICE_PREFIX}/taping/save/:streamId`;
export const API_FETCH_TAPING_LIST = `${REACT_APP_API_GW_ROUTE}${RECORDING_SERVICE_PREFIX}/taping/streams`;

export const API_BOOKMARKS_TAPING = `${REACT_APP_API_GW_ROUTE}${RECORDING_SERVICE_PREFIX}/taping/bookmarks/:streamId`;
export const API_BOOKMARKS = `${REACT_APP_API_GW_ROUTE}${RECORDING_SERVICE_PREFIX}/bookmarks?is_private=:is_private&recording_name=:recording_name&directory_name=:directory_name`;

export const API_TAPING_BOOKMARKS_DELETE = `${REACT_APP_API_GW_ROUTE}${RECORDING_SERVICE_PREFIX}/taping/bookmarks/:streamId?name=:name`;
export const API_RECORDING_BOOKMARKS_DELETE = `${REACT_APP_API_GW_ROUTE}${RECORDING_SERVICE_PREFIX}/bookmarks?bookmark_id=:bookmarkId&is_private=:isPrivate&recording_name=:recordingName&directory_name=:directoryName`;
