import { Draft, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { DashboardStatistics } from './interfaces';
import { DataState } from './state';
import { calculateDistanceByMapCoordsPath } from '../../../helpers/common';
import { MapCoords } from '../map/interfaces';
import { MAX_STORED_EVENTS_NUMBER } from '../../../constants/common';
import { RootState } from '../../store';

export const initDashboardStatistics = (): DashboardStatistics => ({
  resetTimestampMilliseconds: Date.now(),
  gripDataPointsCounter: 0,
  estimationsPerSecond: 0,
  averageDistanceBetweenPointsInMeters: 0,
});

export function initialStatisticsState(): Pick<DataState, 'dashboardStatistics' | 'isPausedStatisticsCounting'> {
  return {
    dashboardStatistics: initDashboardStatistics(),
    isPausedStatisticsCounting: false,
  };
}

export function addGripPointIfNotPaused(state: Draft<DataState>): void {
  if (!hasStatistics(state) || state.isPausedStatisticsCounting) {
    return;
  }

  const newPointsCounter = state.dashboardStatistics.gripDataPointsCounter + 1;
  const timeFromResetInSeconds = calculateSecondsFromLastReset(state.dashboardStatistics);
  const estimationPerSecond = newPointsCounter / timeFromResetInSeconds;

  const averageDistanceBetweenPointsInMeters = getAverageDistanceBetweenPointsInMeters(state);

  state.dashboardStatistics = {
    ...state.dashboardStatistics,
    gripDataPointsCounter: newPointsCounter,
    estimationsPerSecond: estimationPerSecond,
    averageDistanceBetweenPointsInMeters: averageDistanceBetweenPointsInMeters,
  };
}

export const statisticsReducers: ValidateSliceCaseReducers<DataState, StatisticsReducers> = {
  togglePausedStatisticsCounting: (state: Draft<DataState>) => {
    state.isPausedStatisticsCounting = !state.isPausedStatisticsCounting;
  },
  resetDashboardStatistics: (state: Draft<DataState>) => {
    resetDashboardStatisticsToInitialState(state, { full: true });
  },
};

export interface StatisticsReducers extends SliceCaseReducers<DataState> {
  togglePausedStatisticsCounting(state: Draft<DataState>): void;
  resetDashboardStatistics(state: Draft<DataState>): void;
}

export function resetDashboardStatisticsToInitialState(state: Draft<DataState>, { full }: ResetOptions): void {
  if (full) {
    state.dashboardStatistics = initDashboardStatistics();
  }
}

export interface ResetOptions {
  full: boolean;
}

export function dashboardStatisticsSelector(state: RootState): DashboardStatistics | null {
  return state.data.dashboardStatistics;
}

export function isPausedStatisticsCountingSelector(state: RootState): boolean {
  return state.data.isPausedStatisticsCounting;
}

function hasStatistics(state: Draft<DataState>): state is DataStateWithStats {
  return !!state.dashboardStatistics;
}

function getCoordinatesFromGripPointsAfterReset(state: DataStateWithStats): MapCoords[] {
  const resetTime = state.dashboardStatistics.resetTimestampMilliseconds;

  const gripListAfterReset = state.gripEvents
    .filter((item) => item.received_time && item.received_time > resetTime)
    .slice(-MAX_STORED_EVENTS_NUMBER);

  return gripListAfterReset.map((item) => {
    return {
      lat: item.lat_deg,
      lng: item.lon_deg,
    };
  });
}

function calculateSecondsFromLastReset(dashboardStatistics: DashboardStatistics): number {
  const resetTimeMilliseconds = dashboardStatistics.resetTimestampMilliseconds;
  const currentTimeMilliseconds = Date.now();

  return (currentTimeMilliseconds - resetTimeMilliseconds) / 1000;
}

interface DataStateWithStats extends Omit<Draft<DataState>, 'dashboardStatistics'> {
  dashboardStatistics: Draft<DashboardStatistics>;
}

function getAverageDistanceBetweenPointsInMeters(state: DataStateWithStats): number {
  const path = getCoordinatesFromGripPointsAfterReset(state);

  if (path.length === 0) {
    return state.dashboardStatistics.averageDistanceBetweenPointsInMeters;
  }

  const entireDistance = calculateDistanceByMapCoordsPath(path);
  return entireDistance / path.length;
}
