import { useContext } from 'react';
import { HookContext } from '../contexts/HookContext';

export const useResetStore = (): (() => void) => {
  const { useData, useUser, useStream, useSelections, useFilters, useRecordings } = useContext(HookContext);

  return () => {
    useData.resetStore();
    useUser.resetStore();
    useStream.resetStore();
    useSelections.resetStore();
    useFilters.resetStore();
    useRecordings.resetStore();
  };
};
