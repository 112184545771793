import { useDispatch, useSelector } from 'react-redux';
import { AppSettings } from './interfaces';
import * as settingsThunks from './thunks';
import { settingsSelector } from './store';

interface ReturnValue {
  settings: AppSettings;
  fetchSettings: () => void;
  updateSettings: (params: AppSettings) => void;
}

export const useSettings = (): ReturnValue => {
  const settings = useSelector(settingsSelector);

  const dispatch = useDispatch();

  const fetchSettings = (): void => {
    dispatch(settingsThunks.fetchSettings());
  };

  const updateSettings = (params: AppSettings): void => {
    dispatch(settingsThunks.updateSettings(params));
  };

  return {
    settings,
    fetchSettings,
    updateSettings,
  };
};
