import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  dumpRecording,
  playRecording,
  getRecordings,
  uploadRecording,
  updateRecording,
  rewindRecording,
} from './thunks';
import {
  AvailableRecordings,
  Playable,
  PlayableDump,
  PlayableRecording,
  PlayableType,
  RecordingRewindParams,
  RecordingUpdateParams,
  SetOfSignals,
  Snapshot,
} from '../../../interfaces';
import { setSelectedStream } from '../selections/store';
import { RecordingPlayParams, RecordingUploadParams } from '../../../interfaces';
import { UPLOAD_RECORDING_ERROR } from '../../../constants/errors';

export enum Error {
  GET_RECORDINGS_ERROR = 'getRecordings',
  UPLOAD_RECORDING_ERROR = 'uploadRecordings',
  UPDATE_RECORDING_ERROR = 'updateRecordings',
  PLAY_RECORDING_ERROR = 'playRecordings',
  DUMP_RECORDING_ERROR = 'dumpRecordings',
  REWIND_RECORDING_ERROR = 'rewindRecordings',
}

export type ThunkArguments =
  | (RecordingPlayParams & RecordingUploadParams & RecordingUpdateParams & RecordingRewindParams)
  | null;

export const thunkByError = {
  [Error.GET_RECORDINGS_ERROR]: getRecordings,
  [Error.PLAY_RECORDING_ERROR]: playRecording,
  [Error.DUMP_RECORDING_ERROR]: dumpRecording,
  [Error.UPLOAD_RECORDING_ERROR]: uploadRecording,
  [Error.UPDATE_RECORDING_ERROR]: updateRecording,
  [Error.REWIND_RECORDING_ERROR]: rewindRecording,
};

export type RejectedRequest = {
  error: Error | null;
  arguments: ThunkArguments;
};

export interface RecordingsState {
  playingRecording: Playable | null;
  rejectedRequest: RejectedRequest;
  availableRecordings: AvailableRecordings;
  snapshot: Snapshot;
  errors: string[];
  isLoading: boolean;
  isError: boolean;
  isRecordingTimelinePaused: boolean;
  isRecordingEnded: boolean;
  isRecordingLoading: boolean;
  autoReplay: boolean;
}

export const initialState: RecordingsState = {
  isLoading: false,
  isError: false,
  playingRecording: null,
  rejectedRequest: {
    error: null,
    arguments: null,
  },
  snapshot: [],
  availableRecordings: {
    public: {},
    private: {},
  },
  errors: [],
  isRecordingTimelinePaused: false,
  isRecordingEnded: false,
  isRecordingLoading: false,
  autoReplay: false,
};

export const recordingsSlice = createSlice({
  name: 'recordings',
  initialState,
  reducers: {
    setIsError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
    },
    clearErrors: (state) => {
      state.errors = [];
    },
    removeRecording: (state) => {
      state.playingRecording = null;
    },
    pauseRecordingTimeline: (state) => {
      state.isRecordingTimelinePaused = true;
    },
    continueRecordingTimeline: (state) => {
      state.isRecordingTimelinePaused = false;
    },
    setIsRecordingEnded: (state, action: PayloadAction<boolean>) => {
      state.isRecordingEnded = action.payload;
    },
    setIsRecordingLoading: (state, action: PayloadAction<boolean>) => {
      state.isRecordingLoading = action.payload;
    },
    toggleAutoReplay: (state) => {
      state.autoReplay = !state.autoReplay;
    },
    reset: (state) => {
      state = initialState;
      return state;
    },
  },
  extraReducers: {
    [setSelectedStream.type]: (state) => {
      state.playingRecording = null;
    },
    [getRecordings.pending.type]: (state) => {
      state.isError = false;
      state.isLoading = true;
    },
    [getRecordings.fulfilled.type]: (state, action: PayloadAction<AvailableRecordings>) => {
      state.availableRecordings = action.payload;
      state.isError = false;
      state.isLoading = false;
    },
    [getRecordings.rejected.type]: (state) => {
      state.isError = true;
      state.isLoading = false;
      state.rejectedRequest = { error: Error.GET_RECORDINGS_ERROR, arguments: null };
    },
    [playRecording.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.playingRecording = null;
      state.isRecordingTimelinePaused = true;
      state.isRecordingLoading = true;
    },
    [playRecording.rejected.type]: (state, action) => {
      state.isError = true;
      state.isLoading = false;
      state.rejectedRequest = { error: Error.PLAY_RECORDING_ERROR, arguments: action.meta.arg };
    },
    [playRecording.fulfilled.type]: (state, action: PayloadAction<PlayableRecording>) => {
      state.playingRecording = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.isRecordingTimelinePaused = true;
      state.isRecordingLoading = true;
    },
    [dumpRecording.pending.type]: (state, action) => {
      const streamName = action.meta.arg.recordingName + ' - Dump';

      state.playingRecording = {
        type: PlayableType.DUMP,
        title: streamName,
      };
      state.isLoading = true;
      state.isError = false;
    },
    [dumpRecording.fulfilled.type]: (state, action: PayloadAction<{ signals: SetOfSignals }[]>) => {
      state.isLoading = false;
      state.isError = false;
      state.snapshot = action.payload;
    },
    [dumpRecording.rejected.type]: (state, action) => {
      state.isError = true;
      state.isLoading = false;
      state.rejectedRequest = { error: Error.DUMP_RECORDING_ERROR, arguments: action.meta.arg };
    },
    [uploadRecording.fulfilled.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [uploadRecording.rejected.type]: (state, action) => {
      if (action.error.message === UPLOAD_RECORDING_ERROR) {
        state.errors = [UPLOAD_RECORDING_ERROR];
      } else {
        state.isError = true;
      }

      state.isLoading = false;
      state.rejectedRequest = { error: Error.UPLOAD_RECORDING_ERROR, arguments: action.meta.arg };
    },
    [uploadRecording.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [updateRecording.fulfilled.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [updateRecording.rejected.type]: (state, action) => {
      state.isError = true;
      state.isLoading = false;
      state.rejectedRequest = { error: Error.UPDATE_RECORDING_ERROR, arguments: action.meta.arg };
    },
    [updateRecording.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [rewindRecording.rejected.type]: (state, action) => {
      state.isError = true;
      state.isLoading = false;
      state.rejectedRequest = { error: Error.REWIND_RECORDING_ERROR, arguments: action.meta.arg };
    },
    [rewindRecording.pending.type]: (state) => {
      state.isError = false;
      state.isLoading = true;
    },
    [rewindRecording.fulfilled.type]: (state) => {
      state.isError = false;
      state.isLoading = false;
    },
  },
});

export const {
  setIsError,
  clearErrors,
  removeRecording,
  pauseRecordingTimeline,
  continueRecordingTimeline,
  setIsRecordingEnded,
  setIsRecordingLoading,
  toggleAutoReplay,
  reset,
} = recordingsSlice.actions;

export function playingRecordingSelector(state: RootState): PlayableRecording | PlayableDump | null {
  return state.recordings.playingRecording;
}

export function errorsSelector(state: RootState): string[] {
  return state.recordings.errors;
}

export function isLoadingSelector(state: RootState): boolean {
  return state.recordings.isLoading;
}

export function isErrorSelector(state: RootState): boolean {
  return state.recordings.isError;
}

export function rejectedRequestSelector(state: RootState): RejectedRequest {
  return state.recordings.rejectedRequest;
}

export function availableRecordingsSelector(state: RootState): AvailableRecordings {
  return state.recordings.availableRecordings;
}

export function snapshotSelector(state: RootState): { signals: SetOfSignals }[] {
  return state.recordings.snapshot;
}

export function isRecordingTimelinePausedSelector(state: RootState): boolean {
  return state.recordings.isRecordingTimelinePaused;
}

export function isRecordingEndedSelector(state: RootState): boolean {
  return state.recordings.isRecordingEnded;
}

export function isRecordingLoadingSelector(state: RootState): boolean {
  return state.recordings.isRecordingLoading;
}

export function autoReplaySelector(state: RootState): boolean {
  return state.recordings.autoReplay;
}

export default recordingsSlice.reducer;
