import { createContext } from 'react';

interface Resolutions {
  width: number;
}

export const WindowSizeContext = createContext<Resolutions>({
  width: window.innerWidth,
});

export const WindowSizeContextProvider = WindowSizeContext.Provider;
