import { Grip } from '../map/interfaces';

export interface SearchingArea {
  minCoordinates: number[];
  maxCoordinates: number[];
}

export type SortedData = Record<string, Grip[]>;

export interface SortedByGripRating extends SortedData {
  high: Grip[];
  medium: Grip[];
  low: Grip[];
}

export interface SortedByFunctionalRoadClass extends SortedData {
  arterial: Grip[];
  collector: Grip[];
  local: Grip[];
}

export interface SortedGrips {
  gripRating: SortedByGripRating;
  functionalRoadClassRating: {
    high: SortedByFunctionalRoadClass;
    medium: SortedByFunctionalRoadClass;
    low: SortedByFunctionalRoadClass;
  };
}

export enum FilterName {
  TM_GRIP = 'tm_grip',
  OEM_GRIP = 'oem_grip',
  ARBITRATED_GRIP = 'arbitrated_grip',
  SURFACE_EVENTS = 'surface_events',
  NOTIFICATIONS = 'alerts',
  LOCATION_TRAIL = 'locationTrail',
  DATA_TRAIL = 'dataTrail',
}
