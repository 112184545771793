import axios, { AxiosRequestConfig, AxiosResponse, CancelToken, CancelTokenSource } from 'axios';
import { injectable } from 'inversify';
import { request } from '../../helpers/request';

@injectable()
abstract class RequestService {
  protected REPEAT_REQUEST_INTERVAL = 3000;

  protected cancelRequestSources: CancelTokenSource[] = [];

  protected rejectSentRequests(): void {
    while (this.cancelRequestSources.length) {
      this.cancelRequestSources.pop()?.cancel();
    }
  }

  protected newCancelToken(): CancelToken {
    const cancelTokenSource = axios.CancelToken.source();
    this.cancelRequestSources.push(cancelTokenSource);

    return cancelTokenSource.token;
  }

  protected async sendRequestWithRepeat<T>(
    method: AxiosRequestConfig['method'],
    url: string,
    options: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return new Promise(async (resolve, reject) => {
      let triesCounter = 0;
      let interval: NodeJS.Timeout | null = null;

      try {
        interval = setInterval(async () => {
          const res = await request.request({
            url,
            method,
            ...options,
          });

          if (res) {
            interval && clearInterval(interval);

            resolve(res);
          }
        }, this.REPEAT_REQUEST_INTERVAL);

        const res = await request.request({
          url,
          method,
          ...options,
        });

        if (res) {
          clearInterval(interval);

          resolve(res);
        }
      } catch (error) {
        if (triesCounter >= 3) {
          interval && clearInterval(interval);

          reject('Error');
        }

        triesCounter++;
      }
    });
  }
}

export default RequestService;
