import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import 'reflect-metadata';
import { SaveTapingFields, TapedStream, VehicleInfo } from './interfaces';
import { request } from '../../../helpers/request';
import {
  API_FETCH_STREAM_INFO,
  API_FETCH_TAPING_LIST,
  API_SAVE_TAPING,
  API_START_TAPING,
  API_STOP_TAPING,
} from '../../../constants/api';
import { makeUrl } from '../../../helpers/common';

export interface IStreamService {
  fetchVehicleInfoList(): Promise<VehicleInfo[]>;
  fetchTapedStreams(): Promise<TapedStream[]>;
  startTaping(streamId: string): Promise<{ start_time_ms: number }>;
  stopTaping(streamId: string, timeFromStart: number): Promise<void>;
  saveTaping(streamId: string, fields: SaveTapingFields): Promise<void>;
}

@injectable()
export class StreamService implements IStreamService {
  async fetchVehicleInfoList(): Promise<VehicleInfo[]> {
    const res = (await request.get(API_FETCH_STREAM_INFO)) as AxiosResponse<VehicleInfo[]>;

    return res.data;
  }

  async fetchTapedStreams(): Promise<TapedStream[]> {
    const res = await request.get<{ taping_streams: TapedStream[] }>(API_FETCH_TAPING_LIST);

    return res.data.taping_streams;
  }

  async startTaping(streamId: string): Promise<{ start_time_ms: number }> {
    const res = await request.post<{ start_time_ms: number }>(makeUrl(API_START_TAPING, { streamId }));
    return res.data;
  }

  async stopTaping(streamId: string, timeFromStart: number): Promise<void> {
    const url = makeUrl(API_STOP_TAPING, { streamId });

    await request.post(url, {
      stream_id: streamId,
      time_from_start: timeFromStart,
    });
  }

  async saveTaping(streamId: string, fields: SaveTapingFields): Promise<void> {
    let url = makeUrl(API_SAVE_TAPING, { streamId });

    if (fields.time) {
      url = `${url}?time=${fields.time}`;
    }

    await request.post(url, {
      recording_name: fields.recording_name,
      directory_name: fields.directory_name,
    });
  }
}
