import { Grip, isArbitratedGrip, isOEMGrip, isTmGrip } from '../../../interfaces';

export enum MarkerType {
  TM_GRIP = 'tm_grip',
  OEM_GRIP = 'oem_grip',
  ARBITRATED_GRIP = 'arbitrated_grip',
  SURFACE_EVENT = 'surface_event',
  ALERT = 'alert',
}

export enum EventType {
  GRIP = 'grip',
  SURFACE_EVENT = 'surface_event',
  ALERT = 'alert',
  TIRE_CHANGE = 'tire_change',
  TIRES_STIFFNESS = 'tire_stiffness',
  VEHICLE_WEIGHT = 'vehicle_weight',
  ROLLING_RADIUS = 'rolling_radius',
}

export interface DashboardStatistics {
  gripDataPointsCounter: number;
  estimationsPerSecond: number;
  averageDistanceBetweenPointsInMeters: number;
  resetTimestampMilliseconds: number;
}

export function getGripMarkerType(grip: Grip): MarkerType {
  if (isTmGrip(grip)) {
    return MarkerType.TM_GRIP;
  }

  if (isArbitratedGrip(grip)) {
    return MarkerType.ARBITRATED_GRIP;
  }

  if (isOEMGrip(grip)) {
    return MarkerType.OEM_GRIP;
  }

  return MarkerType.TM_GRIP;
}
