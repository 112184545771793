import React from 'react';
import { Tooltip, TooltipSide } from 'shared';
import { LayerControl } from '../../filters/LayerControl';
import { ResetControl } from '../../data/ResetControl';
import VideoControl from './VideoControl';
import './_map-layers.scss';

export const GlobalControls: React.FC = () => {
  return (
    <div className="controls-btn-container global-controls">
      <Tooltip text="Video feed on/off" side={TooltipSide.RIGHT}>
        <VideoControl />
      </Tooltip>
      <Tooltip text="Display settings" side={TooltipSide.RIGHT}>
        <LayerControl />
      </Tooltip>
      <Tooltip text="Reset display" side={TooltipSide.RIGHT}>
        <ResetControl />
      </Tooltip>
    </div>
  );
};
