export interface IMapCoords {
  lat: number;
  lon: number;
}

export interface MapCoords {
  lat: number;
  lng: number;
}

export interface MapViewPort extends MapCoords {
  zoom: number;
  pitch: number;
}

export interface Polygon {
  id: number;
  polygon: string;
  title: string;
}

export interface Grip {
  id: string;
  data: {
    error: number;
    functional_road_class: number;
    geometry: string;
    quality: number;
    timestamp: number;
    value: number;
  };
}

export interface Coverage {
  id: string;
  data: {
    error: number;
    max_lat: string;
    max_lon: string;
    min_lat: string;
    min_lon: string;
    quality: number;
    timestamp: number;
    value: number;
  };
}

export interface CoverageData {
  last_modified: number;
  tiles: Coverage[];
}

export interface PavementReport {
  id: string;
  report_name: string;
}

export interface FetchPavementDataType {
  polygonId: number;
  reportId: string;
}

export type Filters = Record<string, FilterOption>;

export type FilterOption = Record<string, boolean>;

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}
