import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ceil } from 'lodash';
import { between, getRoundedFloat } from '../../../../../../helpers/common';
import { Widget } from '../Widget';
import { Units } from '../../../../user/types';
import { transformNmToLbf } from '../../../../../../helpers/units';
import './_brake-torque.scss';

interface Props {
  value: number;
  maxValue?: number;
  minValue?: number;
  minValidValue?: number;
  maxValidValue?: number;
  step?: number;
  isMini?: boolean;
  units?: Units;
}

const MAX_VALUE = 1500;
const MIN_VALUE = 0;

const MAX_VALID_VALUE = 100;
const MIN_VALID_VALUE = 0;

const DEFAULT_STEP = 500;

const VALUE_POINTERS_NUMBER = 8;

const unitNames = {
  [Units.METRIC]: 'Nm',
  [Units.IMPERIAL]: 'lbf',
};

const BrakeTorqueHeatBar: React.FC<Props> = (props: Props) => {
  const {
    value,
    maxValue = MAX_VALUE,
    minValue = MIN_VALUE,
    minValidValue = MIN_VALID_VALUE,
    maxValidValue = MAX_VALID_VALUE,
    step = DEFAULT_STEP,
    isMini,
    units = Units.METRIC,
  } = props;

  const [pointersNumber, setPointersNumber] = useState(0);

  const getValidValue = (value: number) => {
    if (value < minValue) return minValue;
    if (value > maxValue) return maxValue;
    return value;
  };

  const getFormattedValue = (value: number) => {
    const validValue = getValidValue(value);

    return units === Units.METRIC ? validValue : Math.round(transformNmToLbf(validValue));
  };

  useEffect(() => {
    setPointersNumber(ceil(getValidValue(value) / ((maxValue - minValue) / VALUE_POINTERS_NUMBER)));
  }, [value]);

  const getMeasurementValues = () => {
    const res: number[] = [];
    const fullRangeDif = maxValue - minValue;
    const fullRangeSteps = fullRangeDif / step + 1;

    for (let i = 0; i < fullRangeSteps; i++) {
      res.push(getRoundedFloat(maxValue - step * i, 1));
    }

    return res;
  };

  const isValueInRange = between(value, minValidValue, maxValidValue);

  return (
    <Widget
      title="Brake Torque"
      value={getFormattedValue(value).toPrecision(4)}
      afterValueText={`(${unitNames[units]})`}
      className="accelerator accelerator-torque"
    >
      {isMini ? (
        <div className="car-dash-line">
          <span
            className={classNames('car-dash-line-value', { inRange: isValueInRange })}
            style={{ width: value * (78 / 1500) }}
          />
        </div>
      ) : (
        <>
          <div className="accelerator-value-bar torque-value-bar">
            {Array.from({ length: VALUE_POINTERS_NUMBER - pointersNumber }).map((_, i) => (
              <span key={i} className="accelerator-pointer hidden" />
            ))}
            {Array.from({ length: pointersNumber }).map((_, i) => (
              <span key={i} className="accelerator-pointer" />
            ))}
          </div>
          <div className="accelerator-measurement">
            <ul className="accelerator-measurement-values">
              <span className={classNames('accelerator-measurement-range', { isInvalid: !isValueInRange })} />
              {getMeasurementValues().map((item) => (
                <li key={item} className="accelerator-measurement-value">
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </Widget>
  );
};

export default React.memo(BrakeTorqueHeatBar);
