import { useDispatch, useSelector } from 'react-redux';
import { Marker, RawTiresStiffness, ShownTiresStiffness, StoredTiresStiffness } from '../../../interfaces';
import { addToMarkers, addToTiresStiffnessEvents } from './store';
import { getTimeFromTimestamp } from '../../../helpers/common';
import { EventType, MarkerType } from './interfaces';
import { IIconClassService } from '../../services/IconClassService';
import { container } from '../../../configs/inversify';
import { stiffnessPopupFieldsTitleToEventFields } from '../../../constants/symbology';
import { DataState } from './state';
import { Draft, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { MAX_STORED_EVENTS_NUMBER } from '../../../constants/common';
import { RootState } from '../../store';
import { setLastDataSignalTimeAsNow } from './signalsState';

const iconClassService: IIconClassService = container.get('IconClassService');

export const useTireStiffnessData = (): UseTireStiffness => {
  const tiresStiffnessEvents = useSelector(tiresStiffnessEventsSelector);

  const dispatch = useDispatch();

  const addTiresStiffnessEvent = (event: StoredTiresStiffness): void => {
    dispatch(addToTiresStiffnessEvents(event));
  };

  const addMarker = (marker: Marker): void => {
    dispatch(addToMarkers(marker));
  };

  const updateTiresStiffness = (e: RawTiresStiffness) => {
    const event: StoredTiresStiffness = {
      ...e,
      generation_time: getTimeFromTimestamp(e.counter),
    };

    const normalizedItem: StoredTiresStiffness = {
      ...event,
      front_type: EventType.TIRES_STIFFNESS,
      received_time: Date.now(),
    };

    addTiresStiffnessEvent(event);

    const item = {
      ...normalizedItem,
      popupInfo: [] as { key: string; value: string | number }[],
      marker: iconClassService.getIconClass(normalizedItem),
      markerIcon: iconClassService.getEventIconName(normalizedItem),
      markerType: MarkerType.ALERT,
      eventType: EventType.TIRES_STIFFNESS,
      received_time: Date.now(),
    };

    const shownStiffnessEvent: (keyof ShownTiresStiffness)[] = ['front_left', 'front_right', 'generation_time'];

    Object.entries(normalizedItem).forEach(([key, value]) => {
      if (shownStiffnessEvent.indexOf(key as keyof ShownTiresStiffness) + 1) {
        item.popupInfo.push({ key: stiffnessPopupFieldsTitleToEventFields[key], value });
      }
    });

    addMarker(item);
  };

  return {
    tiresStiffnessEvents,
    updateTiresStiffness,
  };
};

export interface UseTireStiffness {
  tiresStiffnessEvents: StoredTiresStiffness[];
  updateTiresStiffness(e: RawTiresStiffness): void;
}

export const initialTireStiffnessState: Pick<DataState, 'tiresStiffnessEvents'> = {
  tiresStiffnessEvents: [],
};

export const tireStiffnessReducers: ValidateSliceCaseReducers<DataState, TireStiffnessReducers> = {
  setTiresStiffnessEvents: (state: Draft<DataState>, action: PayloadAction<StoredTiresStiffness[]>) => {
    state.tiresStiffnessEvents = action.payload;
  },
  addToTiresStiffnessEvents: (state: Draft<DataState>, action: PayloadAction<StoredTiresStiffness>) => {
    if (!state.tiresStiffnessEvents.length) {
      setLastDataSignalTimeAsNow(state);
      state.tiresStiffnessEvents = [...state.tiresStiffnessEvents, action.payload];
      return;
    }

    const { counter } = action.payload;
    const lastEvent = state.tiresStiffnessEvents[state.tiresStiffnessEvents.length - 1];

    if (lastEvent.counter >= counter) return;

    setLastDataSignalTimeAsNow(state);
    state.tiresStiffnessEvents = [...state.tiresStiffnessEvents, action.payload].slice(-MAX_STORED_EVENTS_NUMBER);
  },
};

export interface TireStiffnessReducers extends SliceCaseReducers<DataState> {
  setTiresStiffnessEvents(state: Draft<DataState>, action: PayloadAction<StoredTiresStiffness[]>): void;
  addToTiresStiffnessEvents(state: Draft<DataState>, action: PayloadAction<StoredTiresStiffness>): void;
}

export function resetTireStiffnessToInitialValue(state: Draft<DataState>): void {
  state.tiresStiffnessEvents = initialTireStiffnessState.tiresStiffnessEvents;
}

export function resetTireStiffnessCounters(state: Draft<DataState>): void {
  if (state.tiresStiffnessEvents.length) {
    state.tiresStiffnessEvents[state.tiresStiffnessEvents.length - 1].counter = -1;
  }
}

export function tiresStiffnessEventsSelector(state: RootState): StoredTiresStiffness[] {
  return state.data.tiresStiffnessEvents;
}
