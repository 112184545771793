import React, { useContext, useEffect, useRef, useState } from 'react';
import { HookContext } from '../../../contexts/HookContext';
import { MapContext } from '../../../contexts/MapContext';
import { MAX_BOOKMARKS_COUNT, MAX_RECORDING_TIME } from '../../../../constants/common';
import { BarTimeline } from '../../../components/BarTimeline';
import { TapingStatus } from '../../stream/interfaces';
import { RecordingStatusModal } from '../RecordingStatusModal';
import BookmarkPopupList from '../BookmarkPopupList';
import BarControls from '../PlayingBar/BarControls';
import RecordingBarActions from './RecordingBarActions';
import BarTime from '../PlayingBar/BarTime';
import './style.scss';
import { getBookmarkText } from '../bookmarkText';

const TIME_LINE_PROGRESS_INTERVAL = 1000;

const RecordingBar: React.FC = () => {
  const [isAddingBookmarkBlocked, setIsAddingBookmarkBlocked] = useState(false);
  const [currentPlayTimeMilliseconds, setCurrentPlayTimeMilliseconds] = useState(0);

  const { useStream, useSelections, useBookmark, useUser, useData } = useContext(HookContext);
  const { mapInstance } = useContext(MapContext);
  const {
    startTapingTime,
    tapingStatus,
    startStreamTaping,
    pauseStreamTaping,
    resumeStreamTaping,
    stopStreamTaping,
    fetchTapedStreams,
  } = useStream;
  const { selectedStream } = useSelections;
  const { temporaryBookmarks, bookmarks, addBookmark, deleteTapingBookmark } = useBookmark;

  const timer = useRef<NodeJS.Timeout | null>(null);
  const bookmarksCount = bookmarks.length + temporaryBookmarks.length;

  const clearTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
  };

  const toggleTaping = () => {
    if (!selectedStream) return;

    if (tapingStatus !== TapingStatus.STARTED) {
      startStreamTaping(selectedStream);
    } else {
      pauseStreamTaping();
    }
  };

  const onFinishTaping = () => {
    if (!selectedStream) return;

    stopStreamTaping(selectedStream);
  };

  const addBookmarkHandler = () => {
    if (
      isAddingBookmarkBlocked ||
      !mapInstance ||
      !startTapingTime ||
      !selectedStream ||
      bookmarksCount >= MAX_BOOKMARKS_COUNT
    ) {
      return;
    }

    const currentTime = currentPlayTimeMilliseconds;
    const imageInDataUrl = mapInstance.getCanvas().toDataURL('image/jpeg', 0.3);
    const bookmarksCounter = bookmarksCount + 1;
    const bookmarkName = `Bookmark_${bookmarksCounter}`;
    const bookmarkText = getBookmarkText(useSelections, useData, useUser);

    addBookmark({
      id: Date.now().toString(),
      name: bookmarkName,
      position_ms: currentTime,
      text: bookmarkText,
      thumbnail: imageInDataUrl,
    });
  };

  const onDeleteBookmark = (bookmarkName: string) => {
    const bookmark = bookmarks.find((item) => item.name === bookmarkName);

    if (selectedStream && bookmark) {
      deleteTapingBookmark({
        streamId: selectedStream,
        name: bookmark.name,
      });
    }
  };

  useEffect(() => {
    if (!startTapingTime || tapingStatus !== TapingStatus.STARTED) {
      clearTimer();
      return;
    }

    timer.current = setInterval(() => {
      const currentTime = Date.now();
      let newTime = currentTime - startTapingTime;

      if (newTime >= MAX_RECORDING_TIME) {
        clearTimer();
        fetchTapedStreams();
        newTime = MAX_RECORDING_TIME;
      }

      setCurrentPlayTimeMilliseconds(newTime);
    }, TIME_LINE_PROGRESS_INTERVAL);

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = null;
      }
    };
  }, [tapingStatus]);

  useEffect(() => {
    const nearBookmark = [...bookmarks, ...temporaryBookmarks].find(
      (bookmark) =>
        currentPlayTimeMilliseconds > bookmark.position_ms - 5000 &&
        currentPlayTimeMilliseconds < bookmark.position_ms + 5000,
    );

    const isBlocked = !!nearBookmark || tapingStatus !== TapingStatus.STARTED;

    setIsAddingBookmarkBlocked(isBlocked);
  }, [currentPlayTimeMilliseconds, tapingStatus]);

  return (
    <>
      <div className="playbar-wrapper">
        <div className="playbar-bar">
          <RecordingBarActions
            isActive={tapingStatus === TapingStatus.STARTED}
            isDisabled={tapingStatus === TapingStatus.PAUSED || tapingStatus === TapingStatus.STOPPED}
            onToggle={toggleTaping}
          />
          <BarTimeline
            durationMilliseconds={MAX_RECORDING_TIME}
            timeLeftMilliseconds={currentPlayTimeMilliseconds}
            bookmarks={[...temporaryBookmarks, ...bookmarks]}
          />
          {tapingStatus ? (
            <BarTime timeLeftMilliseconds={currentPlayTimeMilliseconds} durationMilliseconds={MAX_RECORDING_TIME} />
          ) : (
            <div className="playbar-time">Live stream</div>
          )}
          <BarControls
            bookmarks={[...temporaryBookmarks, ...bookmarks]}
            isAddBookmarkDisabled={
              isAddingBookmarkBlocked || tapingStatus !== TapingStatus.STARTED || bookmarksCount >= MAX_BOOKMARKS_COUNT
            }
            onAddBookmark={addBookmarkHandler}
            onDeleteBookmark={onDeleteBookmark}
          />
          {/* <div>{`Bookmark length: ${bookmarks.length}`}</div> */}
        </div>
      </div>
      {/* TODO: Use this shared modal after discuss with Shael */}
      {/*<ConfirmModal*/}
      {/*  isOpen={tapingStatus === TapingStatus.PAUSED}*/}
      {/*  title="Are you sure you want to end recording?"*/}
      {/*  cancelBtnText="Continue recording"*/}
      {/*  confirmBtnText="End recording"*/}
      {/*  onCancel={resumeStreamTaping}*/}
      {/*  onConfirm={onFinishTaping}*/}
      {/*  reverseButtons*/}
      {/*/>*/}
      <RecordingStatusModal
        isOpen={tapingStatus === TapingStatus.PAUSED}
        onConfirm={onFinishTaping}
        onCancel={resumeStreamTaping}
      />
      <BookmarkPopupList />
    </>
  );
};

export default React.memo(RecordingBar);
