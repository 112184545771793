import React from 'react';
import { EventType } from '../../data/interfaces';
import { iconClassToEventType } from '../../../../constants/symbology';
import { getRoundedFloat, makeFirstLatterUppercase, replaceUnderscore } from '../../../../helpers/common';
import './_map-tooltip.scss';

export interface InfoListItemProps {
  title: string;
  value: string;
}

const InfoListItem: React.FC<InfoListItemProps> = ({ title, value }: InfoListItemProps) => {
  const normalizeValue = (value: any): string => {
    if (typeof value === 'number') {
      value = getRoundedFloat(value, 4);
    } else if (typeof value === 'boolean') {
      value = value ? 'true' : 'false';
    }
    return value.toString();
  };

  return (
    <>
      <span className="map-tooltip-label">{replaceUnderscore(makeFirstLatterUppercase(title))}</span>
      <span className="map-tooltip-value">{normalizeValue(value)}</span>
    </>
  );
};

interface Props {
  title: string;
  infoList: InfoListItemProps[];
  eventType: EventType;
  onClickPin: () => void;
}

export const MapTooltip: React.FC<Props> = (props: Props) => {
  const { title, infoList, eventType, onClickPin } = props;

  const setIcon = (type: EventType) => {
    return iconClassToEventType[type];
  };

  return (
    <div className="map-tooltip">
      <span className="map-tooltip-title">
        <i className={`map-tooltip-icon icon ${setIcon(eventType)}`} />
        {title}
        <button className="map-tooltip-pin" onClick={onClickPin}>
          <i className="map-pin-icon icon icon-pushpin" />
        </button>
      </span>
      <div className="map-tooltip-container">
        {infoList.map((item) => (
          <InfoListItem title={item.title} value={item.value} key={item.title} />
        ))}
      </div>
    </div>
  );
};
