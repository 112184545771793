import React, { useState } from 'react';
import { isNumber } from 'lodash';
import { Units } from '../../../../user/types';
import { getRoundedFloat } from '../../../../../../helpers/common';
import { transformTonsToLb } from '../../../../../../helpers/units';
import { LastVehicleWeight } from '../../../../data/state';
import { RadioGroup, RadioGroupOption } from '../../../../../components/RadioGroup';
import '../../AccPanel/_acc-panel.scss';
import './_vehicle_weight.scss';

interface Props {
  weight: LastVehicleWeight;
  units?: Units;
}

const unitNames = {
  [Units.METRIC]: 'kg',
  [Units.IMPERIAL]: 'lb',
};

const VehicleWeightWidget: React.FC<Props> = (props: Props) => {
  const { weight, units = Units.METRIC } = props;

  const [weightKey, changeWeightKey] = useState<keyof LastVehicleWeight>('tmWeight');

  const value = weight[weightKey]?.weight_kg;
  const qualifier = weight[weightKey]?.qualifier;

  const onWeightProviderChange = (option: WeightOption) => {
    changeWeightKey(option.weightKey);
  };

  const weightProviders: WeightOption[] = [
    { id: 'tm-weight-provider', label: 'TM', weightKey: 'tmWeight' },
    { id: 'oem-weight-provider', label: 'OEM', weightKey: 'oemWeight' },
    { id: 'arbitrated-weight-provider', label: 'Arbitrated', weightKey: 'arbiterWeight' },
  ];

  return (
    <div className="vehicle-weight-widget widget-heading">
      <div className="d-flex a-center">
        <span className="widget-title">Vehicle Weight</span>
        <RadioGroup
          className="weight-provider-selector"
          options={weightProviders}
          initialSelectedOption={0}
          onOptionChange={onWeightProviderChange}
        />
      </div>
      {renderWidget(value, units, qualifier)}
    </div>
  );
};

export default React.memo(VehicleWeightWidget);

function renderWidget(value: number | undefined, units: Units, qualifier: string | undefined) {
  if (value && qualifier) {
    return widgetWithData(value, units, qualifier);
  }

  return widgetLoading();
}

function widgetLoading() {
  return (
    <div className="widget-loading">
      <i className="icon-tonne icon widget-loading-icon" />
      <div className="widget-loading-text">
        Calculating Weight
        <ul className="widget-loading-dots">
          <li className="widget-loading-dot">.</li>
          <li className="widget-loading-dot">.</li>
          <li className="widget-loading-dot">.</li>
        </ul>
      </div>
    </div>
  );
}

function widgetWithData(value: number, units: Units, qualifier: string) {
  return (
    <>
      <div className="d-flex a-center">
        <span className="weight-value">{showFormattedValue(value, units)}</span>
        &nbsp;
        <span className="weight-unit">({unitNames[units]})</span>
      </div>
      <div className="weight-container">
        <span className="weight-subtitle">Loaded</span>
        <div className="weight">
          <ul className="weight-list">
            <li className="weight-item">Empty</li>
            <li className="weight-item">25%</li>
            <li className="weight-item">50%</li>
            <li className="weight-item">75%</li>
            <li className="weight-item">100%</li>
          </ul>
          <div className="weight-fill" style={{ width: qualifier === 'ROUGH' ? '50%' : '100%' }} />
        </div>
      </div>
    </>
  );
}

interface WeightOption extends RadioGroupOption {
  weightKey: keyof LastVehicleWeight;
}

function showFormattedValue(value: number | undefined, units: Units): number | string {
  if (!isNumber(value)) {
    return '-';
  }

  return getRoundedFloat(units === Units.METRIC ? value : transformTonsToLb(value / 1000), 1);
}
