export enum MapType {
  STREET = 'street',
  SATELLITE = 'satellite',
}

export interface AppSettings {
  grip_fade_time_ms: number;
  surface_event_fade_time_ms: number;
  alert_fade_time_ms: number;
  map_type: MapType;
  surface_events_magnitude_threshold: number;
}
