import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountConfig, LoginState, UserConfig, UserDataState, UserType } from './types';
import { RootState } from '../../store';
import { fetchAccountConfig, fetchUserConfig, loginUser } from './thunks';
import { Units } from './types';
import { UserCredentialsError } from './errors';

export enum Group {
  STREAM_TM = 'StreamTM',
  STREAM_PARTNER = 'StreamPartner',
  STREAM_CUSTOMER = 'StreamCustomer',
  ADMIN = 'AdminTM',
}

export interface AccountData {
  title: string;
  allowedPolygons: number[];
  logo?: string;
  groups: Group[];
}

export interface UserData {
  id?: number;
  name?: string;
  username?: string;
  units?: Units;
  accountId?: number;
  type?: UserType;
  streams: string[];
}

interface UserState {
  isError: boolean;
  loginState: LoginState;
  userDataState: UserDataState;
  user?: UserData;
  account?: AccountData;
}

export const initialState: UserState = {
  isError: false,
  loginState: LoginState.LOGGED_OUT,
  userDataState: UserDataState.LOADING,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserState: (state, action: PayloadAction<LoginState>) => {
      state.loginState = action.payload;
    },
    setUser: (state, action: PayloadAction<UserData>) => {
      state.user = action.payload;
    },
    clearState: (state) => {
      state.isError = false;
      state.loginState = LoginState.LOGGED_OUT;
    },
    setIsError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
    },
    reset: (state) => {
      state = initialState;
      return state;
    },
  },
  extraReducers: {
    [loginUser.pending.type]: (state) => {
      state.isError = false;
      state.loginState = LoginState.LOGGING_IN;
    },
    [loginUser.fulfilled.type]: (state, action: PayloadAction<{ userId: number; accountId: number }>) => {
      const { userId, accountId } = action.payload;

      state.loginState = LoginState.LOGGED_IN;

      state.user = {
        id: userId,
        accountId,
        streams: [],
      };
    },
    [loginUser.rejected.type]: (state, action) => {
      if (action.error && action.error.name === UserCredentialsError.name) {
        state.loginState = LoginState.FAILED_LOGGING_IN;
      } else {
        state.loginState = LoginState.LOGGED_OUT;
        state.isError = true;
      }
    },
    [fetchUserConfig.pending.type]: (state) => {
      state.userDataState = UserDataState.LOADING;
    },
    [fetchUserConfig.fulfilled.type]: (state, action: PayloadAction<UserConfig>) => {
      const { units, streams, username } = action.payload;

      state.userDataState = UserDataState.OK;

      if (state.user) {
        state.user = {
          ...state.user,
          units,
          streams,
          username,
        };
      }
    },
    [fetchUserConfig.rejected.type]: (state) => {
      state.userDataState = UserDataState.ERROR;
      if (state.user) {
        state.user = {
          ...state.user,
        };
      }
    },
    [fetchAccountConfig.fulfilled.type]: (state, action: PayloadAction<AccountConfig>) => {
      const { title, regions, logo, groups } = action.payload;

      state.account = {
        title,
        allowedPolygons: regions,
        logo: logo || undefined,
        groups,
      };
    },
  },
});

export const { setUserState, clearState, setIsError, reset } = userSlice.actions;

export function userStateSelector(state: RootState): LoginState {
  return state.user.loginState;
}

export function isErrorSelector(state: RootState): boolean {
  return state.user.isError;
}

export function userDataSelector(state: RootState): UserData | undefined {
  return state.user.user;
}

export function accountDataSelector(state: RootState): AccountData | undefined {
  return state.user.account;
}

export function allowedPolygonsSelector(state: RootState): number[] | undefined {
  return state.user.account?.allowedPolygons;
}

export function nameSelector(state: RootState): string | undefined {
  return state.user.user?.name;
}

export function logoSelector(state: RootState): string | undefined {
  return state.user.account?.logo;
}

export function userUnitsSelector(state: RootState): Units | undefined {
  return state.user.user?.units;
}

export function streamsSelector(state: RootState): string[] {
  return state.user.user ? state.user.user.streams : [];
}

export default userSlice.reducer;
