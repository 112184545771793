import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppSettings } from './interfaces';
import { RootState } from '../../store';
import { fetchSettings, updateSettings } from './thunks';
import { defaultSettings } from '../../../constants/settings';

interface SettingsState {
  isLoading: boolean;
  isError: boolean;
  settings: AppSettings;
}

export const initialState: SettingsState = {
  isLoading: false,
  isError: false,
  settings: defaultSettings,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSettings.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [fetchSettings.fulfilled.type]: (state, action: PayloadAction<AppSettings>) => {
      state.isLoading = false;
      state.settings = action.payload;
    },
    [fetchSettings.rejected.type]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    [updateSettings.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [updateSettings.fulfilled.type]: (state, action: PayloadAction<AppSettings>) => {
      state.isLoading = false;
      state.settings = action.payload;
    },
    [updateSettings.rejected.type]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
  },
});

export function settingsSelector(state: RootState): AppSettings {
  return state.settings.settings;
}

export default settingsSlice.reducer;
