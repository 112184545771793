import React from 'react';

interface Props {
  isActive: boolean;
  isToggleDisabled: boolean;
  isRewindDisabled: boolean;
  onTogglePause: () => void;
  onRewind: () => void;
}

const PlayingBarActions: React.FC<Props> = (props: Props) => {
  const { isActive, isToggleDisabled, isRewindDisabled, onTogglePause, onRewind } = props;

  return (
    <div className="playbar-actions">
      <button className="playbar-btn playbar-btn-play" onClick={onTogglePause} disabled={isToggleDisabled}>
        <i className={`playbar-icon icon ${isActive ? 'icon-play' : 'icon-pause'}`} />
      </button>
      <button className="playbar-btn playbar-btn-rewind" onClick={onRewind} disabled={isRewindDisabled}>
        <i className="playbar-icon icon icon-rewind" />
      </button>
    </div>
  );
};

export default React.memo(PlayingBarActions);
