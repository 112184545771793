import React, { useEffect, useState } from 'react';
import {
  ApplicableWheel,
  StoredTireRadiiChange,
  TireRadiiChangeReason,
  StoredTiresStiffness,
} from '../../../../../../interfaces';
import WheelIndicator from './WheelIndicator';
import { isNumber } from 'lodash';
import car from '../../../../../../assets/img/dashboard-car.png';
import WheelPicture from './WheelIndicator/wheel_picture';

import './_wheel.scss';

interface Props {
  tireRadiiChangeEvent: StoredTireRadiiChange | null;
  tiresStiffnessEvent: StoredTiresStiffness | null;
  steeringAngle?: number;
  isMini?: boolean;
}

type WheelsState = Record<ApplicableWheel, number | null>;

const INIT_WHEELS_STATE = {
  [ApplicableWheel.FRONT_LEFT]: null,
  [ApplicableWheel.FRONT_RIGHT]: null,
  [ApplicableWheel.REAR_LEFT]: null,
  [ApplicableWheel.REAR_RIGHT]: null,
};

const TireDataWidget: React.FC<Props> = (props: Props) => {
  const { tireRadiiChangeEvent, tiresStiffnessEvent, steeringAngle, isMini } = props;
  const [deltaOfWheels, setDeltaOfWheels] = useState<WheelsState>(INIT_WHEELS_STATE);
  const [stiffnessOfWheels, setStiffnessOfWheels] = useState<WheelsState>(INIT_WHEELS_STATE);
  const [changedWheel, setChangedWheel] = useState<Record<ApplicableWheel, boolean | null>>(INIT_WHEELS_STATE);
  const imageSteeringAngle = steeringAngle && steeringAngle * -1;

  const getTextData = (tireRadiiChangeValue: null | number, annex: string): string => {
    if (tireRadiiChangeValue || tireRadiiChangeValue === 0) {
      return tireRadiiChangeValue + annex;
    }

    return '-';
  };

  useEffect(() => {
    if (tireRadiiChangeEvent) {
      setDeltaOfWheels((prev) => ({
        ...prev,
        [tireRadiiChangeEvent.applicable_wheel]: tireRadiiChangeEvent.tire_tread_depth_delta_centi_mm / 100,
      }));

      const isTireChange = tireRadiiChangeEvent.reason === TireRadiiChangeReason.TIRE_CHANGE;
      setChangedWheel({ ...changedWheel, [tireRadiiChangeEvent.applicable_wheel]: isTireChange });
    } else {
      setDeltaOfWheels(INIT_WHEELS_STATE);
      setChangedWheel(INIT_WHEELS_STATE);
    }
  }, [tireRadiiChangeEvent]);

  useEffect(() => {
    if (tiresStiffnessEvent) {
      if (Number.isInteger(tiresStiffnessEvent.front_left)) {
        setStiffnessOfWheels((prev) => ({ ...prev, [ApplicableWheel.FRONT_LEFT]: tiresStiffnessEvent.front_left }));
      }

      if (Number.isInteger(tiresStiffnessEvent.front_right)) {
        setStiffnessOfWheels((prev) => ({ ...prev, [ApplicableWheel.FRONT_RIGHT]: tiresStiffnessEvent.front_right }));
      }

      if (Number.isInteger(tiresStiffnessEvent.rear_left)) {
        setStiffnessOfWheels((prev) => ({ ...prev, [ApplicableWheel.REAR_LEFT]: tiresStiffnessEvent.rear_left }));
      }

      if (Number.isInteger(tiresStiffnessEvent.rear_right)) {
        setStiffnessOfWheels((prev) => ({ ...prev, [ApplicableWheel.REAR_RIGHT]: tiresStiffnessEvent.rear_right }));
      }
    } else {
      setStiffnessOfWheels(INIT_WHEELS_STATE);
    }
  }, [tiresStiffnessEvent]);

  return (
    <div className="wheel">
      <WheelIndicator
        tireTitle="Front L"
        tireDepthDeltaValue={getTextData(deltaOfWheels.FRONT_LEFT, 'mm')}
        tiresStiffnessValue={getTextData(stiffnessOfWheels.FRONT_LEFT, '%')}
        tireDepthDanger={false}
        tireStiffnessDanger={false}
        tireWarning={false}
        tireChangeIndication={!!changedWheel.FRONT_LEFT}
        wheelShowing={!!isMini}
        isActive={!!changedWheel.FRONT_LEFT}
        steeringAngle={imageSteeringAngle}
      />
      <WheelIndicator
        tireTitle="Front R"
        tireDepthDeltaValue={getTextData(deltaOfWheels.FRONT_RIGHT, 'mm')}
        tiresStiffnessValue={getTextData(stiffnessOfWheels.FRONT_RIGHT, '%')}
        tireChangeIndication={!!changedWheel.FRONT_RIGHT}
        wheelShowing={!!isMini}
        isActive={!!changedWheel.FRONT_RIGHT}
        steeringAngle={imageSteeringAngle}
      />
      {!isMini && (
        <div className="wheel-car-container">
          {/* TODO: change to icons after design appear */}
          <WheelPicture
            steeringAngle={imageSteeringAngle}
            isActive={!!changedWheel.FRONT_LEFT}
            extraClasses="top left"
          ></WheelPicture>
          <WheelPicture
            steeringAngle={imageSteeringAngle}
            isActive={!!changedWheel.FRONT_RIGHT}
            extraClasses="top right"
          ></WheelPicture>
          <span className="wheel-tire-angle">{isNumber(steeringAngle) ? steeringAngle : '-'}</span>
          <img src={car} alt="car" draggable={false} className="wheel-car" />
          <WheelPicture steeringAngle={0} isActive={!!changedWheel.REAR_LEFT} extraClasses="bottom left"></WheelPicture>
          <WheelPicture
            steeringAngle={0}
            isActive={!!changedWheel.REAR_RIGHT}
            extraClasses="bottom right"
          ></WheelPicture>
        </div>
      )}
      <WheelIndicator
        tireTitle="Rear L"
        tireDepthDeltaValue={getTextData(deltaOfWheels.REAR_LEFT, 'mm')}
        tiresStiffnessValue={getTextData(stiffnessOfWheels.REAR_LEFT, '%')}
        tireChangeIndication={!!changedWheel.REAR_LEFT}
        wheelShowing={!!isMini}
        isActive={!!changedWheel.REAR_LEFT}
      />
      <WheelIndicator
        tireTitle="Rear R"
        tireDepthDeltaValue={getTextData(deltaOfWheels.REAR_RIGHT, 'mm')}
        tiresStiffnessValue={getTextData(stiffnessOfWheels.REAR_RIGHT, '%')}
        tireChangeIndication={!!changedWheel.REAR_RIGHT}
        wheelShowing={!!isMini}
        isActive={!!changedWheel.REAR_RIGHT}
      />
    </div>
  );
};

export default React.memo(TireDataWidget);
