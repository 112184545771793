import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userStateSelector } from '../../modules/user/store';
import { LOGIN } from '../../../constants/pathnames';
import { LoginState } from '../../modules/user/types';
import { useResetStore } from '../../store/useResetStore';

type Props = RouteProps;

const AuthenticatedRoute: React.FC<Props> = (props: Props) => {
  const userState = useSelector(userStateSelector);
  const dispatch = useDispatch();
  const resetStore = useResetStore();

  useEffect(() => {
    const forceLogoutCallback = () => {
      dispatch(resetStore());
    };

    document.addEventListener('forceLogout', forceLogoutCallback);

    return () => {
      document.removeEventListener('forceLogout', forceLogoutCallback);
    };
  }, []);

  return userState === LoginState.LOGGED_IN ? <Route {...props} /> : <Redirect to={LOGIN} />;
};

export default AuthenticatedRoute;
