import React, { ReactElement, useState } from 'react';
import Tabs, { TabItem } from './Tabs';
import { DashboardTab } from '../../modules/main/CarDashboard';

export interface Tab extends TabItem {
  content: ReactElement;
}

interface Props {
  tabs: Tab[];
  onChangeTab: (id: DashboardTab) => void;
}

const TabsWrapper: React.FC<Props> = (props: Props) => {
  const { tabs, onChangeTab } = props;

  const [activeTab, setActiveTab] = useState<Tab>(tabs[0]);

  const setActiveTabHandler = (id: DashboardTab) => {
    onChangeTab(id);

    const selectedTab = tabs.find((tab) => tab.id === id);

    if (selectedTab) {
      setActiveTab(selectedTab);
    }
  };

  return (
    <>
      <Tabs tabs={props.tabs} activeTab={activeTab.id} onChange={setActiveTabHandler} />
      {activeTab.content}
    </>
  );
};

export default React.memo(TabsWrapper);
