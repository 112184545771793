import { injectable } from 'inversify';
import axios, { AxiosResponse } from 'axios';
import {
  AvailableRecordings,
  PlayableRecording,
  PlayableType,
  RecordingPlayParams,
  RecordingPlayResponse,
  RecordingRewindParams,
  RecordingUpdateParams,
  RecordingUploadParams,
  SelectableRecording,
  Snapshot,
} from '../../../interfaces';
import { request } from '../../../helpers/request';
import {
  API_DELETE_RECORDINGS,
  API_DUMP_RECORDING,
  API_FETCH_RECORDINGS,
  API_PLAY_RECORDING,
  API_REWIND_RECORDING,
} from '../../../constants/api';
import { makeUrl } from '../../../helpers/common';
import { UPLOAD_RECORDING_ERROR } from '../../../constants/errors';
import RequestService from '../../services/RequestService';

export interface IRecordingService {
  getRecordings(): Promise<AvailableRecordings>;
  playRecording(params: RecordingPlayParams): Promise<PlayableRecording>;
  uploadRecording(params: RecordingUploadParams): Promise<void>;
  updateRecording(params: RecordingUpdateParams): Promise<void>;
  dumpRecording(params: RecordingPlayParams): Promise<Snapshot>;
  deleteRecording(recording: SelectableRecording): Promise<void>;
  rewindRecording(params: RecordingRewindParams): Promise<string>;
}

@injectable()
export class RecordingService extends RequestService implements IRecordingService {
  async getRecordings(): Promise<AvailableRecordings> {
    const res = await this.sendRequestWithRepeat<AvailableRecordings>('get', API_FETCH_RECORDINGS, {
      timeout: this.REPEAT_REQUEST_INTERVAL,
    });

    return res.data;
  }

  async playRecording(params: RecordingPlayParams): Promise<PlayableRecording> {
    const { recordingName, directoryName, isPrivate, speed } = params;

    const res = await this.sendRequestWithRepeat<RecordingPlayResponse>('post', API_PLAY_RECORDING, {
      data: {
        recording_name: recordingName,
        directory_name: directoryName,
        is_private: isPrivate,
        speed,
      },
      timeout: this.REPEAT_REQUEST_INTERVAL,
    });

    return {
      type: PlayableType.RECORDING,
      streamId: res.data.stream_id,
      title: recordingName,
      directory: directoryName,
      isPrivate,
      speed,
      videoPath: res.data.video_path,
      duration: res.data.duration_ms,
    };
  }

  async uploadRecording(params: RecordingUploadParams): Promise<void> {
    const { recordingName, directoryName, recordingFile, videoFile } = params;

    const data = new FormData();
    data.append('recording_name', recordingName);
    data.append('directory_name', directoryName);
    data.append('record_file', recordingFile);

    const resUploadRecording = await request.post<{ status: string; video_presigned: string }>(
      API_FETCH_RECORDINGS,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    if (resUploadRecording.status === 422) throw Error(UPLOAD_RECORDING_ERROR);
    if (resUploadRecording.status !== 201) throw Error();

    if (videoFile) {
      const resUploadVideo = await axios.put(resUploadRecording.data.video_presigned, videoFile, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (resUploadVideo.status !== 200) throw Error();
    }
  }

  async updateRecording(params: RecordingUpdateParams): Promise<void> {
    const { recordingName, directoryName, newRecordingName } = params;

    await request.patch(API_FETCH_RECORDINGS, {
      recording_name: recordingName,
      directory_name: directoryName,
      new_recording_name: newRecordingName,
    });
  }

  async dumpRecording(params: RecordingPlayParams): Promise<Snapshot> {
    const { recordingName, directoryName, isPrivate, speed } = params;
    const res = (await request.post(API_DUMP_RECORDING, {
      recording_name: recordingName,
      directory_name: directoryName,
      is_private: isPrivate,
      speed,
    })) as AxiosResponse<Snapshot>;

    return res.data;
  }

  async deleteRecording(recording: SelectableRecording): Promise<void> {
    const { recordingName, directoryName } = recording;
    await request.delete(
      makeUrl(API_DELETE_RECORDINGS, { recording_name: recordingName, directory_name: directoryName }),
    );
  }

  async rewindRecording(params: RecordingRewindParams): Promise<string> {
    const { streamId, recordingName, directoryName, speed, isPrivate, timestamp } = params;

    const res = await request.post<{ stream_id: string }>(makeUrl(API_REWIND_RECORDING, { stream_id: streamId }), {
      recording_name: recordingName,
      directory_name: directoryName,
      is_private: isPrivate,
      speed,
      time_shift_ms: timestamp,
    });

    return res.data.stream_id;
  }
}
