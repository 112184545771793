import { createContext } from 'react';
import { useRecordings } from '../modules/recordings/useRecordings';
import { useUser } from '../modules/user/useUser';
import { useData } from '../modules/data/useData';
import { useFilters } from '../modules/filters/useFilters';
import { useSelections } from '../modules/selections/useSelections';
import { useStream } from '../modules/stream/useStream';
import { useSettings } from '../modules/settings/useSettings';
import { useBookmark } from '../modules/bookmark/useBookmark';

export interface Hooks {
  useRecordings: ReturnType<typeof useRecordings>;
  useUser: ReturnType<typeof useUser>;
  useData: ReturnType<typeof useData>;
  useFilters: ReturnType<typeof useFilters>;
  useSelections: ReturnType<typeof useSelections>;
  useStream: ReturnType<typeof useStream>;
  useSettings: ReturnType<typeof useSettings>;
  useBookmark: ReturnType<typeof useBookmark>;
}

export const HookContext = createContext<Hooks>({
  useRecordings: {},
  useUser: {},
  useData: {},
  useFilters: {},
  useSelections: {},
  useStream: {},
  useSettings: {},
} as Hooks);

export const HooksContextProvider = HookContext.Provider;
