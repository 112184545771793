import { useContext, useRef } from 'react';
import { HookContext } from '../../contexts/HookContext';
import { useDispatch } from 'react-redux';
import { resetCounters } from './store';
import { TIME_TO_LIFE_STREAM } from '../../../constants/common';

export const useStreamData = (): UseStreamData => {
  const streamResetCountersTimer = useRef<NodeJS.Timer | null>(null);

  const {
    useSelections: { selectedStream },
  } = useContext(HookContext);

  const dispatch = useDispatch();

  const resetTimerForStoppingRunningStream = () => {
    // This is need to do if real car stops and counters of events will be reset on the next drive
    if (streamResetCountersTimer.current) {
      clearTimeout(streamResetCountersTimer.current);
    }

    streamResetCountersTimer.current = setTimeout(() => {
      dispatch(resetCounters());
    }, TIME_TO_LIFE_STREAM);
  };

  return {
    selectedStream,
    resetTimerForStoppingRunningStream,
  };
};

export interface UseStreamData {
  selectedStream: string | null;
  resetTimerForStoppingRunningStream(): void;
}
