import { useDispatch, useSelector } from 'react-redux';
import { filterSelector, FiltersState, reset, toggleFilter } from './store';
import { FilterName } from '../main/interfaces';

interface ReturnValue {
  filtersState: FiltersState;
  selectFilter: (filter: FilterName) => void;
  resetStore: () => void;
}

export const useFilters = (): ReturnValue => {
  const filtersState = useSelector(filterSelector);

  const dispatch = useDispatch();

  const selectFilter = (filter: FilterName): void => {
    dispatch(toggleFilter(filter));
  };

  const resetStore = () => {
    dispatch(reset());
  };

  return {
    filtersState,
    selectFilter,
    resetStore,
  };
};
