import React, { useState } from 'react';
import { ConfirmModal } from 'shared';
import { Bookmark } from '../../../bookmark/interfaces';
import BookmarkItem from '../../SaveRecordingDialog/BookmarkList/BookmarkItem';

interface Props {
  bookmarks: Bookmark[];
  isAddBookmarkDisabled: boolean;
  onAddBookmark: () => void;
  onDeleteBookmark: (bookmarkId: string) => void;
}

const BarControls: React.FC<Props> = (props: Props) => {
  const { bookmarks, isAddBookmarkDisabled, onAddBookmark, onDeleteBookmark } = props;

  const [isBookmarkListOpened, setIsBookmarkListOpened] = useState(false);
  const [bookmarkToDelete, setBookmarkToDelete] = useState<string | null>(null);

  const toggleBookmarkList = (): void => {
    setIsBookmarkListOpened(!isBookmarkListOpened);
  };

  const onConfirmDeleteBookmark = (): void => {
    bookmarkToDelete && onDeleteBookmark(bookmarkToDelete);

    setBookmarkToDelete(null);
  };

  const onCancelRemove = (): void => {
    setBookmarkToDelete(null);
  };

  const onDeleteBookmarkHandler = (bookmarkName: string): void => {
    setBookmarkToDelete(bookmarkName);
  };

  return (
    <>
      <ConfirmModal
        title={`You are about to delete "${bookmarkToDelete}" bookmark?`}
        isOpen={!!bookmarkToDelete}
        cancelBtnText="Cancel"
        confirmBtnText="OK"
        onCancel={onCancelRemove}
        onConfirm={onConfirmDeleteBookmark}
      />
      <div className="playbar-controls">
        <button className="playbar-btn" disabled={isAddBookmarkDisabled} onClick={onAddBookmark}>
          <i className="playbar-icon icon icon-bookmark" />
          <span className="playbar-btn-sub">+</span>
        </button>
        <button className="playbar-btn" disabled={bookmarks.length === 0} onClick={toggleBookmarkList}>
          <i className="playbar-icon icon icon-bookmark" />
          <span className="playbar-btn-sub">{bookmarks.length}</span>
          <i className="playbar-btn-sup icon icon-arrow" />
          {isBookmarkListOpened && (
            <div className="playbar-bookmarks-popup">
              {bookmarks.map((item) => (
                <BookmarkItem
                  key={item.name}
                  bookmark={item}
                  onDelete={onDeleteBookmarkHandler}
                  preview={false}
                  editable={false}
                />
              ))}
            </div>
          )}
        </button>
        <button className="playbar-btn playbar-btn-share" disabled>
          <i className="playbar-icon icon icon-share" />
        </button>
        <button className="playbar-btn" disabled>
          <i className="playbar-icon icon icon-cut" />
        </button>
      </div>
    </>
  );
};

export default React.memo(BarControls);
