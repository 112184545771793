import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { playRecording } from '../recordings/thunks';
import { setSelectedStream } from '../selections/store';
import {
  initialStatisticsState,
  statisticsReducers,
  resetDashboardStatisticsToInitialState,
} from './dashboardStatistics';
import {
  initialTireRadiiState,
  resetTireRadiCounters,
  resetTireRadiiConvergence,
  resetTireRadiiState,
  tireRadiiReducers,
} from './tireRadiiData';
import { DataState } from './state';
import { initialMarkersState, markersReducers, resetMarkersData } from './markers';
import { absReducers, initialAbsState, resetAbsStateCounter, resetAbsStateToInitialValue } from './absData';
import {
  initVehicleState,
  resetVehicleStateCounters,
  resetVehicleStateToInitialValue,
  vehicleStateReducers,
} from './vehicleState';
import { accReducers, initialAccState, resetAccCounter } from './accData';
import { gripReducers, initialGripState, resetGripEventsToInitialValue } from './gripData';
import {
  initialTemperatureState,
  resetTemperatureCounter,
  resetTemperatureToInitialValue,
  temperatureReducers,
} from './temperatureData';
import { gpsReducers, initialGpsState, resetGpsList, resetLastGpsCounter } from './gpsData';
import {
  initialSurfaceEventsState,
  resetLastSurfaceEventsCounter,
  resetSurfaceEventsToInitialValue,
  surfaceEventsReducers,
} from './surfaceEventsData';
import {
  initialTireStiffnessState,
  resetTireStiffnessCounters,
  resetTireStiffnessToInitialValue,
  tireStiffnessReducers,
} from './tireStiffnessData';
import { initialVehicleWeightState, resetVehicleWeightCounters, vehicleWeightReducers } from './vehicleWeightData';
import { initialSignalsState, markDataAsReset, signalsStateReducers } from './signalsState';
import {
  initialSteeringAngleState,
  resetSteeringAngleToInitialValue,
  steeringAngleReducers,
} from './steeringAngleData';

export const initialState: DataState = {
  ...initVehicleState,
  ...initialTireRadiiState,
  ...initialAbsState,
  ...initialAccState,
  ...initialTemperatureState,
  ...initialGpsState,
  ...initialGripState,
  ...initialSurfaceEventsState,
  ...initialTireStiffnessState,
  ...initialMarkersState,
  ...initialVehicleWeightState,
  ...initialStatisticsState(),
  ...initialSignalsState,
  ...initialSteeringAngleState,
};

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    ...vehicleStateReducers,
    ...tireRadiiReducers,
    ...absReducers,
    ...accReducers,
    ...temperatureReducers,
    ...gpsReducers,
    ...gripReducers,
    ...surfaceEventsReducers,
    ...tireStiffnessReducers,
    ...vehicleWeightReducers,
    ...steeringAngleReducers,
    ...markersReducers,
    ...statisticsReducers,
    ...signalsStateReducers,
    resetVehicleState: (state) => {
      resetVehicleStateToInitialValue(state);
      resetTireRadiiConvergence(state);
      resetAbsStateToInitialValue(state);
      resetTemperatureToInitialValue(state);
    },
    resetInitialData: (state, action: PayloadAction<boolean>) => {
      markDataAsReset(state, { full: action.payload });
      resetVehicleStateToInitialValue(state);
      resetAbsStateToInitialValue(state);
      resetTemperatureToInitialValue(state);
      resetGpsList(state, { full: action.payload });
      resetGripEventsToInitialValue(state);
      resetSurfaceEventsToInitialValue(state);
      resetTireStiffnessToInitialValue(state);
      resetMarkersData(state);
      resetSteeringAngleToInitialValue(state);
      resetTireRadiiState(state, { full: action.payload });
      resetDashboardStatisticsToInitialState(state, { full: action.payload });
    },
    resetCounters: (state) => {
      resetVehicleStateCounters(state);
      resetAbsStateCounter(state);
      resetAccCounter(state);
      resetTemperatureCounter(state);
      resetTireRadiCounters(state);
      resetVehicleWeightCounters(state);
      resetLastGpsCounter(state);
      resetLastSurfaceEventsCounter(state);
      resetTireStiffnessCounters(state);
    },
    reset: (state) => {
      state = initialState;
      return state;
    },
  },
  extraReducers: {
    [setSelectedStream.type]: (state) => {
      state = initialState;
      return state;
    },
    [playRecording.pending.type]: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  setVehicleId,
  setVehicleSpeed,
  setBrakeTorque,
  setAcceleration,
  setAmbientTemperature,
  setAbsStateActive,
  resetAbsStateActive,
  setAccStateActive,
  setTireRadiiConvergence,
  setGpsList,
  setGripEvents,
  setSurfaceEvents,
  setTiresStiffnessEvents,
  setSteeringAngle,
  setMarkers,
  setVehicleWeight,
  setLoadTransfer,
  addToGpsList,
  addToGripEvents,
  addToMarkers,
  addToSurfaceEvents,
  addToTiresStiffnessEvents,
  setTireRadiiChanges,
  addToTireRadiiChanges,
  setRelativeEffectiveRollingRadius,
  setAbsoluteEffectiveRollingRadius,
  togglePausedStatisticsCounting,
  resetDashboardStatistics,
  resetInitialData,
  setIsDataReset,
  resetVehicleState,
  resetCounters,
  resetLastDataSignalTimestamp,
  reset,
} = dataSlice.actions;

export default dataSlice.reducer;
