import { getRoundedFloat } from '../../../helpers/common';
import { transformTonsToLb } from '../../../helpers/units';
import { StoredVehicleWeight } from '../../../interfaces';
import { UseData } from '../data/useData';
import { DashboardTab } from '../main/CarDashboard';
import { UseSelections } from '../selections/useSelections';
import { Units } from '../user/types';
import { UseUser } from '../user/useUser';

export function getBookmarkText(useSelections: UseSelections, useData: UseData, useUser: UseUser): string {
  const { selectedDashboard, selectedDashboardTab } = useSelections;
  const { vehicleWeight, gripEvents } = useData;
  const { userData } = useUser;

  if (selectedDashboard) {
    if (selectedDashboardTab === DashboardTab.TIRES && gripEvents.length) {
      const lastEvent = gripEvents[gripEvents.length - 1];
      const low = getRoundedFloat(lastEvent.mean_g - lastEvent.error_g, 2);
      const high = getRoundedFloat(lastEvent.mean_g + lastEvent.error_g, 2);
      return `Grip ${low}-${high} Mu`;
    }

    if (selectedDashboardTab === DashboardTab.WEIGHT) {
      const tmWeightText = weightText('TM', vehicleWeight.tmWeight, userData?.units);
      const oemWeightText = weightText('OEM', vehicleWeight.oemWeight, userData?.units);
      const arbitratedWeightText = weightText('Arbitrated', vehicleWeight.arbiterWeight, userData?.units);

      return [tmWeightText, oemWeightText, arbitratedWeightText].filter((t) => t.length > 0).join(', ');
    }
  }

  return '';
}

function weightText(provider: string, weight: StoredVehicleWeight | null, units?: Units): string {
  if (!weight) {
    return '';
  }

  if (units === Units.METRIC) {
    return `${provider} weight ${getRoundedFloat(transformTonsToLb(weight.weight_kg / 1000), 2)} lb`;
  } else {
    return `${provider} weight ${weight.weight_kg} kg`;
  }
}
