import { EventType, MarkerType } from '../app/modules/data/interfaces';

export interface LoginCredentials {
  username: string;
  password: string;
}

export interface UserMultipolygon {
  id: number;
  title: string;
}

export interface User {
  username: string;
  logo: string;
  multipolygon: UserMultipolygon[];
  name: string;
  realtime_grip_window_size: number;
}

export enum ResponseStatus {
  FAIL = 'FAIL',
  SUCCESS = 'SUCCESS',
}

export enum ResponseCodes {
  SUCCESS = 200,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
}

export type APIResponse<T> = {
  status: ResponseStatus;
  error: string;
  item: T;
  items: T;
  currentUser: T;
  data: T;
  result: T;
};

export type SocketDataUpdateMessage = {
  is_updated: boolean;
  layer: any;
  region_id: number;
  subject: 'updates';
};

export enum SurfaceEventSide {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
  BOTH = 'BOTH',
}

export enum SurfaceEventType {
  UNKNOWN = 'UNKNOWN',
  STEP_UP = 'STEP_UP',
  STEP_DOWN = 'STEP_DOWN',
  CONTINUAL = 'CONTINUAL',
  ROUGH_ROAD = 'ROUGH_ROAD',
  SPIN = 'SPIN',
  SKID = 'SKID',
}

export enum TireRadiiChangeReason {
  UNKNOWN = 'UNKNOWN',
  TIRE_CHANGE = 'TIRE_CHANGE',
}

export interface Gps {
  counter: number;
  lon_deg: number;
  lat_deg: number;
  front_type: string;
  received_time: number;
}

export interface VehicleSpeed {
  counter: number;
  speed_kmh: number;
}

export interface ABS {
  counter: number;
  is_on: boolean;
}

export interface Acceleration {
  counter: number;
  lon_g: number;
  lat_g: number;
}

export interface BrakeTorque {
  counter: number;
  brake_torque_Nm: number;
}

export interface AmbientTemperature {
  counter: number;
  temperature_celsius: number;
}

export enum GripEventSource {
  TACTILE_PROCESSOR = 'TACTILE_PROCESSOR',
  ARBITER = 'ARBITER',
  NEXTEER = 'NEXTEER',
  RENAULT = 'RENAULT',
}

export interface ShownGrip extends Event {
  generation_time: string;
  mean_g: number;
  error_g: number;
  quality: number;
  age_m: number;
  source: keyof typeof GripEventSource;
}

export function isTmGrip(grip: Grip): boolean {
  return grip.source == GripEventSource.TACTILE_PROCESSOR;
}

export function isArbitratedGrip(grip: Grip): boolean {
  return grip.source == GripEventSource.ARBITER;
}

export const OEMGripSources = [GripEventSource.NEXTEER, GripEventSource.RENAULT];

export function isOEMGrip(grip: Grip): boolean {
  return OEMGripSources.indexOf(grip.source as GripEventSource) !== -1;
}

export interface Grip {
  counter: number;
  lon_deg: number;
  lat_deg: number;
  mean_g: number;
  error_g: number;
  quality: number;
  age_m: number;
  source: keyof typeof GripEventSource;
  generation_time?: string;
  front_type?: string;
  received_time?: number;
  opacity?: number;
}

export interface VehicleState {
  vehicle_id: string;
  vehicle_speed: VehicleSpeed;
  acceleration: Acceleration;
  brake_torque: BrakeTorque;
}

export interface Marker {
  lon_deg: number;
  lat_deg: number;
  marker: string;
  markerIcon?: string;
  markerType: MarkerType;
  eventType: EventType;
  received_time?: number;
  popupInfo?: PopupItem[];
}

export type PopupInfo = PopupItem[];
export interface PopupItem {
  key: string;
  value: any;
}

export interface RawSurfaceEvents {
  lon_deg: number;
  lat_deg: number;
  events: RawSurfaceEvent[];
}

export interface RawSurfaceEvent {
  counter: number;
  magnitude: number;
  side: SurfaceEventSide;
  type: SurfaceEventType;
  in_rough_road: boolean;
}

export interface StoredSurfaceEvents {
  lon_deg: number;
  lat_deg: number;
  events: StoredSurfaceEvent[];
}

export interface StoredSurfaceEvent {
  counter: number;
  generation_time: string;
  magnitude: number;
  side: SurfaceEventSide;
  type: SurfaceEventType;
  in_rough_road: boolean;
}

export interface Event {
  [key: string]: any;
  generation_time: string;
}

export interface ShownSurfaceEvent extends Event {
  magnitude: number;
  side: SurfaceEventSide;
  type: SurfaceEventType;
  in_rough_road: boolean;
}

export interface SurfaceEvent extends ShownSurfaceEvent {
  lon_deg: number;
  lat_deg: number;
  counter: number;
  front_type?: string;
  received_time?: number;
}

export enum ApplicableWheel {
  FRONT_LEFT = 'FRONT_LEFT',
  FRONT_RIGHT = 'FRONT_RIGHT',
  REAR_LEFT = 'REAR_LEFT',
  REAR_RIGHT = 'REAR_RIGHT',
}

export interface ShownTireRadiiChange extends Event {
  tire_tread_depth_delta_centi_mm: number;
  applicable_wheel: ApplicableWheel;
  reason: TireRadiiChangeReason;
}

export interface StoredTireRadiiChange extends ShownTireRadiiChange {
  counter: number;
  lon_deg: number;
  lat_deg: number;
  front_type: string;
  received_time: number;
}

export interface RawTireRadiiChange {
  counter: number;
  lon_deg: number;
  lat_deg: number;
  tire_tread_depth_delta_centi_mm: number;
  applicable_wheel: ApplicableWheel;
  reason: TireRadiiChangeReason;
}

export interface TireRadiiConvergence {
  counter: number;
  relative_converged: boolean;
  absolute_converged: boolean;
}

export interface ShownTiresStiffness extends Event {
  front_left: number;
  front_right: number;
}

export interface StoredTiresStiffness extends ShownTiresStiffness {
  counter: number;
  lon_deg: number;
  lat_deg: number;
  rear_left: number;
  rear_right: number;
  front_type?: string;
  received_time?: number;
}

export interface RawTiresStiffness {
  counter: number;
  lon_deg: number;
  lat_deg: number;
  front_left: number;
  front_right: number;
  rear_left: number;
  rear_right: number;
}

export interface SteeringAngle {
  counter: number;
  steering_angle: number;
}

export interface Acc {
  counter: number;
  state: boolean;
}

export interface SetOfSignals extends Partial<VehicleState> {
  gps?: Gps;
  abs?: ABS;
  ambient_temperature?: AmbientTemperature;
  grip?: Grip;
  surface_event?: SurfaceEvent;
  surface_events?: RawSurfaceEvents;
  exceptional_tire_radii_change?: RawTireRadiiChange;
  tire_radii_convergence?: TireRadiiConvergence;
  tires_stiffness?: RawTiresStiffness;
  steering_angle?: SteeringAngle;
  acc?: Acc;
  weight?: RawVehicleWeight;
  load_transfer?: LoadTransferSensor;
  relative_effective_rolling_radius?: RawRelativeRollingRadius;
  absolute_effective_rolling_radius?: RawAbsoluteRollingRadius;
}

export interface ShownVehicleWeight {
  qualifier: 'ROUGH' | 'ACCURATE';
  weight_kg: number;
  generation_time: string;
  error_kg: number;
}

export interface StoredVehicleWeight extends ShownVehicleWeight {
  eventType: EventType;
  counter: number;
  lon_deg: number;
  lat_deg: number;
  front_type: string;
  received_time: number;
  source?: WeightSource;
}

export interface RawVehicleWeight {
  counter: number;
  lon_deg: number;
  lat_deg: number;
  weight_kg: number;
  error_kg: number;
  qualifier: 'ROUGH' | 'ACCURATE';
  source?: WeightSource;
}

export type WeightSource = 'TM' | 'RENAULT' | 'ARBITER';

export function isTmWeight(weight: StoredVehicleWeight): boolean {
  return !weight.source || weight.source == 'TM';
}

export function isOemWeight(weight: StoredVehicleWeight): boolean {
  return weight.source == 'RENAULT';
}

export function isArbitratedWeight(weight: StoredVehicleWeight): boolean {
  return weight.source == 'ARBITER';
}

export interface LoadTransferSensor {
  counter: number;
  frontLeftKg: number;
  frontRightKg: number;
  rearLeftKg: number;
  rearRightKg: number;
  // TODO: update this when the real interface is defined
}

export interface StoredRelativeEffectiveRollingRadiusForWheel extends ValueErrorNumbers {
  timestamp: number;
  counter: number;
  provider: RadiusProvider;
}

export interface StoredRelativeEffectiveRollingRadius {
  [ApplicableWheel.FRONT_RIGHT]: StoredRelativeEffectiveRollingRadiusForWheel[];
  [ApplicableWheel.REAR_RIGHT]: StoredRelativeEffectiveRollingRadiusForWheel[];
  [ApplicableWheel.REAR_LEFT]: StoredRelativeEffectiveRollingRadiusForWheel[];
}

export interface StoredAbsoluteEffectiveRollingRadiusForWheel extends ValueErrorNumbers {
  timestamp: number;
  counter: number;
  provider: RadiusProvider;
}

export interface StoredAbsoluteEffectiveRollingRadius {
  [ApplicableWheel.FRONT_LEFT]: StoredAbsoluteEffectiveRollingRadiusForWheel[];
  [ApplicableWheel.FRONT_RIGHT]: StoredAbsoluteEffectiveRollingRadiusForWheel[];
  [ApplicableWheel.REAR_RIGHT]: StoredAbsoluteEffectiveRollingRadiusForWheel[];
  [ApplicableWheel.REAR_LEFT]: StoredAbsoluteEffectiveRollingRadiusForWheel[];
}

export interface ShownRelativeRollingRadius {
  applicable_wheel: ApplicableWheel;
  source: string;
  relative_effective_radius_centi_percent: number; // -20000 to 20000 = -20% to 20%
  relative_effective_radius_error_centi_percent: number; // 10 = 0.1%
  quality_percent: number; // 100 = 100%
}

export interface StoredRelativeRollingRadius extends ShownRelativeRollingRadius {
  eventType: EventType;
  counter: number;
  lon_deg: number;
  lat_deg: number;
  front_type: string;
  received_time: number;
  provider?: RadiusProvider;
}

export interface RawRelativeRollingRadius {
  counter: number;
  lon_deg: number;
  lat_deg: number;
  applicable_wheel: ApplicableWheel;
  source: string;
  relative_effective_radius_centi_percent: number; // -20000 to 20000 = -20% to 20%
  relative_effective_radius_error_centi_percent: number; // 10 = 0.1%
  quality_percent: number; // 100 = 100%
  provider?: RadiusProvider;
}

export interface StoredAbsoluteRollingRadius {
  counter: number;
  lon_deg: number;
  lat_deg: number;
  applicable_wheel: ApplicableWheel;
  source: string;
  absolute_effective_radius_mm: number;
  absolute_effective_radius_error_mm: number;
  quality_percent: number;
  provider?: RadiusProvider;
  received_time: number;
  eventType: EventType;
}

export interface RawAbsoluteRollingRadius {
  counter: number;
  lon_deg: number;
  lat_deg: number;
  applicable_wheel: ApplicableWheel;
  source: string;
  absolute_effective_radius_mm: number;
  absolute_effective_radius_error_mm: number;
  quality_percent: number;
  provider?: RadiusProvider;
}

export type RadiusProvider = 'TM' | 'RENAULT';

export function isTmRadiusProvider(provider: RadiusProvider): boolean {
  return provider == 'TM';
}

export function isOemRadiusProvider(provider: RadiusProvider): boolean {
  return provider == 'RENAULT';
}

export const DEFAULT_RADIUS_PROVIDER: RadiusProvider = 'TM';

export enum SocketDataTypes {
  AVAILABLE_STREAMS = 'available_streams',
  VEHICLE_DATA = 'vehicle_data',
}

export interface SocketMessage {
  source: SocketDataTypes;
  data: SetOfSignals | string[];
}

export type RecordingDirectories = { [key: string]: { [key: string]: string[] } };

export interface AvailableRecordings {
  public: RecordingDirectories;
  private: RecordingDirectories;
}

export interface RecordingPlayResponse {
  stream_id: string;
  video_path: string | null;
  duration_ms: number;
}

export interface SelectableRecording {
  recordingName: string;
  directoryName: string;
}

export interface RecordingPlayParams extends SelectableRecording {
  isPrivate: boolean;
  speed: number;
}

export enum PlayableType {
  DUMP = 'dump',
  RECORDING = 'recording',
}

export interface PlayableDump {
  type: PlayableType.DUMP;
  title: string;
}

export interface PlayableRecording {
  type: PlayableType.RECORDING;
  streamId: string;
  title: string;
  directory: string;
  isPrivate: boolean;
  speed: number;
  videoPath: string | null;
  duration: number;
}

export type Playable = PlayableDump | PlayableRecording;

export type Snapshot = {
  signals: SetOfSignals;
}[];

export interface RecordingUploadParams extends SelectableRecording {
  recordingFile: File;
  videoFile: File;
}

export interface RecordingUpdateParams extends SelectableRecording {
  newRecordingName: string;
}

export interface RecordingRewindParams extends RecordingPlayParams {
  streamId: string;
  timestamp: number;
}

export interface Auth {
  status: string;
  access_token: string;
  refresh_token: string;
  user_id: number;
  error?: string;
}

export enum AppType {
  LIVE = 'live',
  RECORDING = 'recording',
}

export interface ValueErrorNumbers {
  value: number;
  error: number;
}

export interface RequestPasswordReset {
  status: string;
  errors?: Record<string, string[]>;
}

export interface UpdatePassword {
  status: string;
  errors?: Record<string, string[]>;
}
