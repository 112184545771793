import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DeleteTapingBookmarkParams,
  DeleteRecordingBookmarkParams,
  SaveTapingBookmarkParams,
  SaveRecordingBookmarkParams,
  UpdateTapingBookmarkParams,
} from './interfaces';
import { PlayableRecording } from '../../../interfaces';
import { container } from '../../../configs/inversify';
import { IBookmarkService } from './service';

const bookmarkService: IBookmarkService = container.get('BookmarkService');

export const fetchTapingBookmarks = createAsyncThunk('bookmark/fetchTapingBookmarks', async (streamId: string) => {
  return await bookmarkService.fetchTapingBookmarks(streamId);
});

export const fetchRecordingBookmarks = createAsyncThunk(
  'bookmark/fetchRecordingBookmarks',
  async (recording: PlayableRecording) => {
    return await bookmarkService.fetchRecordingBookmarks(recording);
  },
);

export const saveTapingBookmark = createAsyncThunk(
  'bookmark/saveTapingBookmark',
  async (params: SaveTapingBookmarkParams, thunkApi) => {
    await bookmarkService.saveTapingBookmark(params.streamId, params.fields);

    thunkApi.dispatch(fetchTapingBookmarks(params.streamId));

    return params;
  },
);

export const saveRecordingBookmark = createAsyncThunk(
  'bookmark/saveRecordingBookmark',
  async (params: SaveRecordingBookmarkParams, thunkApi) => {
    await bookmarkService.saveRecordingBookmark(params.recording, params.fields);

    thunkApi.dispatch(fetchRecordingBookmarks(params.recording));

    return params;
  },
);

export const updateTapingBookmark = createAsyncThunk(
  'bookmark/updateTapingBookmark',
  async (params: UpdateTapingBookmarkParams) => {
    await bookmarkService.updateTapingBookmark(params.streamId, params.fields);

    return params;
  },
);

export const deleteTapingBookmark = createAsyncThunk(
  'bookmark/deleteTapingBookmark',
  async (params: DeleteTapingBookmarkParams) => {
    await bookmarkService.deleteTapingBookmark(params.streamId, params.name);

    return params;
  },
);

export const deleteRecordingBookmark = createAsyncThunk(
  'bookmark/deleteRecordingBookmark',
  async (params: DeleteRecordingBookmarkParams) => {
    await bookmarkService.deleteRecordingBookmark(params.recording, params.bookmarkId);

    return params;
  },
);
