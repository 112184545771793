import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { HookContext } from '../../contexts/HookContext';
import CarDashboard, { AvailableWidgets, DashboardTab } from './CarDashboard';
import Map from '../map/map.component';
import { IMapService } from '../map/map.service';
import { container } from '../../../configs/inversify';
import Header from './Header';
import { AppType, PlayableType, SetOfSignals } from '../../../interfaces';
import { GlobalControls } from './GlobalControls';
import { IPerformanceLoggerService } from '../metrics/PerformanceLoggerService';
import { NotifModal } from '../../components/NotifModal';
import { ConnectivityStatus, socketClient } from 'shared';
import { Feature } from '../../../constants/featureConfig';
import { WEB_SOCKET_URL } from '../../../constants/api';
import { DashboardName } from '../selections/interfaces';
import { ServiceContext } from '../../contexts/ServiceContext';
import PlayingBar from '../recordings/PlayingBar';
import RecordingBar from '../recordings/RecordingBar';
import { MapContext } from '../../contexts/MapContext';
import DashboardCard from './CarDashboard/widgets/DashboardCard';
import VehicleSignals from './CarDashboard/widgets/VehicleSignals';
import { resolution } from '../../../constants/common';
import { WindowSizeContext } from '../../contexts/WindowSizeContext';
import { ILocalStorageService } from '../../services/LocalStorageService';

const mapService: IMapService = container.get('MapService');
const performanceLoggerService: IPerformanceLoggerService = container.get('PerformanceLoggerService');
const localStorageService: ILocalStorageService = container.get('LocalStorageService');

const MainPage: React.FC = () => {
  const [isMiniDashboard, setIsMiniDashboard] = useState(false);
  const [currentAppType, setCurrentAppType] = useState<AppType>(AppType.LIVE);
  const [mapInstance, setMapInstance] = useState<mapboxgl.Map | null>(null);

  const currentStream = useRef<string | null>(null);

  const { width } = useContext(WindowSizeContext);
  const { useRecordings, useData, useSelections, useUser, useSettings } = useContext(HookContext);
  const { socketSubscriptionService } = useContext(ServiceContext);
  const { snapshot, playingRecording, isRecordingEnded, isRecordingTimelinePaused, removeRecording } = useRecordings;
  const { onUpdateVehicleInfo, resetData, resetVehicleStateAfterThirtySeconds, vehicleState, lastDataSignalTimestamp } =
    useData;
  const {
    selectedStream,
    availableStreams,
    selectStream,
    clearStream,
    toggleDashboard,
    selectedDashboard,
    selectedDashboardTab,
  } = useSelections;
  const { isFeatureEnabled } = useUser;
  const { fetchSettings } = useSettings;

  const unsubscribeFromStreamAndClearData = (): void => {
    resetData(true);

    if (currentStream.current) {
      socketSubscriptionService.dataUnsubscribe(currentStream.current);

      currentStream.current = null;
    }
  };

  useEffect(() => {
    performanceLoggerService.enableLoggingAverageAmountOfMessages();

    fetchSettings();
  }, []);

  useEffect(() => {
    const connectToSocket = () => {
      const token = localStorageService.getItem<string>('Authorization');

      if (token) {
        socketClient.connect(WEB_SOCKET_URL, token);
      }
    };

    connectToSocket();

    socketSubscriptionService.setStreamDataListener(onUpdateVehicleInfo);

    document.addEventListener('reconnectToSocket', connectToSocket);

    return () => {
      socketClient.disconnect();
      document.removeEventListener('reconnectToSocket', connectToSocket);
    };
  }, []);

  useEffect(() => {
    unsubscribeFromStreamAndClearData();

    if (playingRecording?.type === PlayableType.DUMP) return;

    const stream = selectedStream || playingRecording?.streamId;

    if (stream) {
      currentStream.current = socketSubscriptionService.subscribeToStream(stream);
    }

    return () => unsubscribeFromStreamAndClearData();
  }, [selectedStream, playingRecording]);

  useEffect(() => {
    clearStream();
    removeRecording();
  }, [currentAppType]);

  useEffect(() => {
    if (currentAppType !== AppType.LIVE) return;

    if (availableStreams.length && !selectedStream && !playingRecording && isFeatureEnabled(Feature.LIVE_STREAM)) {
      selectStream(availableStreams[0]);
    } else if (
      selectedStream &&
      !availableStreams.includes(selectedStream) &&
      availableStreams.length &&
      !playingRecording
    ) {
      selectStream(availableStreams[0]);
    } else if (selectedStream && !availableStreams.includes(selectedStream) && !availableStreams.length) {
      clearStream();
    }
  }, [selectedStream, availableStreams, playingRecording]);

  const onSnapshot = (data: { signals: SetOfSignals }[]) => {
    data.forEach(({ signals }) => {
      onUpdateVehicleInfo(signals);
    });
  };

  useEffect(() => {
    if (snapshot.length) {
      onSnapshot(snapshot);
    }
  }, [snapshot]);

  useEffect(() => {
    mapService.getMap()?.resize();
  }, [isMiniDashboard]);

  const onMapStyleLoad = useCallback((map: mapboxgl.Map) => {
    mapService.setMap(map);
    setMapInstance(map);
  }, []);

  useEffect(() => {
    if (playingRecording && playingRecording.type === PlayableType.DUMP) return;

    resetVehicleStateAfterThirtySeconds();
  }, [vehicleState, playingRecording]);

  const onToggleDashboardSize = () => {
    setIsMiniDashboard(!isMiniDashboard);
  };

  const isTablet = width < resolution.SMALL_LAPTOP;

  return (
    <MapContext.Provider
      value={{
        mapInstance,
      }}
    >
      <div className="map-page">
        <Header currentAppType={currentAppType} setCurrentAppType={setCurrentAppType} />
        {lastDataSignalTimestamp &&
          (selectedStream || (playingRecording && playingRecording.type === PlayableType.RECORDING)) &&
          !isRecordingEnded &&
          !isRecordingTimelinePaused && <ConnectivityStatus lastSignalTimestamp={lastDataSignalTimestamp} />}
        <NotifModal />
        <GlobalControls />
        <div className={`map-grid ${selectedDashboard === DashboardName.NEW ? 'dash-open' : ''}`}>
          <div className="map-grid-col">
            <Map height="100vh" width="100vw" mapService={mapService} onStyleLoad={onMapStyleLoad} />
          </div>
          {selectedDashboard === DashboardName.NEW && isTablet && (
            <DashboardCard
              id={AvailableWidgets.VEHICLE_SIGNALS_PANEL}
              className="col col-lg col-xl-4 col-xxl-7 dash-vehicle-panel"
            >
              {selectedDashboardTab === DashboardTab.ACC && <VehicleSignals isMini={true} displayACCSpeedometer />}
              {(selectedDashboardTab === DashboardTab.TIRES ||
                selectedDashboardTab === DashboardTab.WEIGHT ||
                selectedDashboardTab === DashboardTab.ROLL_RADII) && (
                <VehicleSignals
                  isMini={true}
                  displayBreakTorqueHeatBar
                  displayAccelerometer
                  displaySpeedometer
                  displayAbs
                />
              )}
            </DashboardCard>
          )}
          <CarDashboard
            name={DashboardName.NEW}
            onToggle={toggleDashboard}
            onToggleDashboardSize={onToggleDashboardSize}
          />
          {isFeatureEnabled(Feature.STREAM_TAPING) && selectedStream && <RecordingBar />}
          {isFeatureEnabled(Feature.PLAYING_BAR) && playingRecording && playingRecording.type !== PlayableType.DUMP && (
            <PlayingBar recording={playingRecording} />
          )}
        </div>
      </div>
    </MapContext.Provider>
  );
};

export default MainPage;
