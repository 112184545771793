import React, { useContext, useEffect, useState } from 'react';
import { HookContext, Hooks, HooksContextProvider } from './HookContext';
import { useRecordings } from '../modules/recordings/useRecordings';
import { useUser } from '../modules/user/useUser';
import { useData } from '../modules/data/useData';
import { useFilters } from '../modules/filters/useFilters';
import { useSelections } from '../modules/selections/useSelections';
import { useStream } from '../modules/stream/useStream';
import { ISocketSubscriptionService } from '../services/SocketSubscriptionService';
import { container } from '../../configs/inversify';
import { ServiceContextProvider, Services } from './ServiceContext';
import { WindowSizeContextProvider } from './WindowSizeContext';
import { useSettings } from '../modules/settings/useSettings';
import { useBookmark } from '../modules/bookmark/useBookmark';

const socketSubscriptionService: ISocketSubscriptionService = container.get('SocketSubscriptionService');

const WindowSizeComponent: React.FC = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  const onWindowResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    return () => window.removeEventListener('resize', onWindowResize);
  }, []);

  return <WindowSizeContextProvider value={{ width }}>{props.children}</WindowSizeContextProvider>;
};

const DependentHooksComponent: React.FC = (props) => {
  const { children } = props;
  const hooks = useContext(HookContext);

  return <HooksContextProvider value={{ ...hooks, useData: useData() }}>{children}</HooksContextProvider>;
};

export const HooksContextInitComponent: React.FC = (props) => {
  const { children } = props;

  const hooksContextValue: Hooks = {
    useRecordings: useRecordings(),
    useUser: useUser(),
    useFilters: useFilters(),
    useSelections: useSelections(),
    useStream: useStream(),
    useSettings: useSettings(),
    useBookmark: useBookmark(),
  } as Hooks;

  return <HooksContextProvider value={hooksContextValue}>{children}</HooksContextProvider>;
};

export const ContextInitComponent: React.FC = (props) => {
  const { children } = props;

  const serviceContextValue: Services = {
    socketSubscriptionService,
  };

  return (
    <ServiceContextProvider value={serviceContextValue}>
      <HooksContextInitComponent>
        <DependentHooksComponent>
          <WindowSizeComponent>{children}</WindowSizeComponent>
        </DependentHooksComponent>
      </HooksContextInitComponent>
    </ServiceContextProvider>
  );
};
