// kilometers per hour to miles per hour
export const transformKphToMph = (value: number): number => {
  return value / 1.609;
};

// kilograms to pounds
export const transformTonsToLb = (value: number): number => {
  return value * 2000;
};

// nanometers to pound-feet
export const transformNmToLbf = (value: number): number => {
  return value / 1.356;
};
