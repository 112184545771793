import React, { Ref, useContext, useEffect, useRef, useState } from 'react';
import { DashboardStatistics } from './DashboardStatistics';
import { Tooltip, TooltipSide } from 'shared';
import { HookContext } from '../../../contexts/HookContext';
import { WindowSizeContext } from '../../../contexts/WindowSizeContext';
import TabsWrapper from '../../../components/TabsWrapper';
import TiresPanel from './TiresPanel';
import AccPanel from './AccPanel';
import WeightPanel from './WeightPanel';
import { resolution } from '../../../../constants/common';
import { Feature } from '../../../../constants/featureConfig';
import { DashboardName } from '../../selections/interfaces';
import { PlayableType } from '../../../../interfaces';
import cn from 'classnames';
import './_car_dashboard.scss';
import RollRadiiPanel from './RollRadiiPanel';
import RoadConditionsPanel from './RoadConditionsPanel';

export interface Props {
  name: DashboardName;
  onToggle?: (name: DashboardName) => void;
  onToggleDashboardSize?: () => void;
}

export enum AvailableWidgets {
  VEHICLE_SIGNALS_PANEL = 'measurement-panel',
  TIRE_DATA = 'tire-data',
  TIRE_RADII_CONVERGENCE = 'tire-radii-convergence',
  GRIP_DATA = 'grip-data',
}

export enum DashboardTab {
  TIRES = 'tires',
  ACC = 'acc',
  WEIGHT = 'weight',
  ROLL_RADII = 'roll_radii',
  ROAD_CONDITIONS = 'road_conditions',
}

const miniModeEnabledTabs = [DashboardTab.TIRES];

export const DashboardContext = React.createContext({
  isMini: false,
  isTablet: false,
  isLaptop: false,
  isStatisticsShowed: false,
  toggleStatistics: () => {
    return;
  },
});

const CarDashboard: React.FC<Props> = (props: Props) => {
  const { name, onToggle, onToggleDashboardSize } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [isMini, setIsMini] = useState(false);
  const [isStatisticsShowed, setIsStatisticsShowed] = useState(false);

  const wrapper: Ref<HTMLDivElement> = useRef(null);

  const { width } = useContext(WindowSizeContext);
  const { useRecordings, useUser, useSelections } = useContext(HookContext);
  const { playingRecording } = useRecordings;
  const { isFeatureEnabled } = useUser;
  const { selectedDashboard, selectedDashboardTab, selectDashboardTab } = useSelections;

  const toggleDashboard = () => {
    if (isOpen) {
      setIsMini(false);
    }

    setIsOpen(!isOpen);

    if (onToggle) {
      onToggle(name);
    }
  };

  const onToggleDashboardSizeHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    if (isOpen) {
      setIsMini(!isMini);
    }

    if (onToggleDashboardSize) {
      onToggleDashboardSize();
    }
  };

  useEffect(() => {
    if (width < resolution.BIG_LAPTOP) {
      setIsMini(false);
    }
  }, [width]);

  useEffect(() => {
    if (selectedDashboard !== name) {
      setIsOpen(false);
      setIsMini(false);
    }
  }, [selectedDashboard]);

  useEffect(() => {
    if (playingRecording && playingRecording.type === PlayableType.DUMP) {
      if (onToggle && selectedDashboard === name) {
        onToggle(name);
      }

      setIsOpen(false);
      setIsMini(false);
    }
  }, [playingRecording]);

  const handleChangeTab = (id: DashboardTab) => {
    setIsMini(false);

    selectDashboardTab(id);
  };

  // TODO: change to tablet after markup will be finalized
  const isTablet = width < resolution.SMALL_LAPTOP;
  const isLaptop = width < resolution.BIG_LAPTOP;

  const isDashboardDisabled = playingRecording?.type === PlayableType.DUMP;

  return (
    <span className={`car-dash-container ${isMini ? 'car-dash-mini' : ''}`}>
      <button
        className={cn({
          'car-dash-slide-btn': isLaptop,
          'car-dash-btn full-dash': !isLaptop,
          active: isOpen,
        })}
        onClick={toggleDashboard}
        disabled={isDashboardDisabled}
      >
        <Tooltip text="Dashboard on/off" side={isLaptop ? TooltipSide.LEFT : TooltipSide.RIGHT}>
          <i
            className={cn('car-dash-icon', 'icon', {
              'icon-arrow': isLaptop,
              'icon-dashboard': !isLaptop,
            })}
          />
        </Tooltip>
      </button>
      {!isLaptop && isOpen && (
        <button
          className={`car-dash-btn maximize ${isMini ? '' : 'active'}`}
          onClick={onToggleDashboardSizeHandler}
          disabled={!miniModeEnabledTabs.includes(selectedDashboardTab)}
        >
          <i className="car-dash-icon icon icon-maximize" />
        </button>
      )}
      <div className={`car-dash-graph ${isOpen ? 'isVisible' : 'isHidden'}`} ref={wrapper}>
        {isStatisticsShowed && <DashboardStatistics />}
        <DashboardContext.Provider
          value={{
            isMini,
            isTablet,
            isLaptop,
            isStatisticsShowed,
            toggleStatistics: () => setIsStatisticsShowed(!isStatisticsShowed),
          }}
        >
          <TabsWrapper
            tabs={[
              {
                id: DashboardTab.TIRES,
                title: 'Tires',
                content: <TiresPanel />,
              },
              {
                id: DashboardTab.ACC,
                title: 'ACC',
                content: <AccPanel />,
                disabled: !isFeatureEnabled(Feature.ACC_PANEL),
              },
              {
                id: DashboardTab.WEIGHT,
                title: 'Weight',
                content: <WeightPanel />,
              },
              {
                id: DashboardTab.ROLL_RADII,
                title: 'Roll radii',
                class: 'roll-radii-tab',
                content: <RollRadiiPanel />,
              },
              {
                id: DashboardTab.ROAD_CONDITIONS,
                title: 'Road cond.',
                class: 'road-conditions-tab',
                content: <RoadConditionsPanel />,
              },
            ]}
            onChangeTab={handleChangeTab}
          />
        </DashboardContext.Provider>
      </div>
    </span>
  );
};

export default React.memo(CarDashboard);
