import { useDispatch, useSelector } from 'react-redux';
import { AccountData, accountDataSelector, reset, UserData, userDataSelector, userStateSelector } from './store';
import { LoginState } from './types';
import { useEffect } from 'react';
import { fetchUserConfig } from './thunks';
import { Feature, featureConfig } from '../../../constants/featureConfig';

export interface UseUser {
  isFeatureEnabled: (feature: Feature) => boolean;
  userData?: UserData;
  account?: AccountData;
  resetStore: () => void;
}

export const useUser = (): UseUser => {
  const userData = useSelector(userDataSelector);
  const userLoginState = useSelector(userStateSelector);
  const account = useSelector(accountDataSelector);

  const dispatch = useDispatch();

  const isFeatureEnabled = (feature: Feature): boolean => {
    let isEnabled = false;

    if (account) {
      const { groups } = account;

      groups?.forEach((group) => {
        if (featureConfig[group].includes(feature)) isEnabled = true;
      });
    }

    return isEnabled;
  };

  const resetStore = () => {
    dispatch(reset());
  };

  useEffect(() => {
    if (userLoginState === LoginState.LOGGED_IN && userData && userData.id) {
      dispatch(fetchUserConfig());
    }
  }, [userLoginState]);

  return {
    isFeatureEnabled,
    userData,
    account,
    resetStore,
  };
};
