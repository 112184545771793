import { useDispatch, useSelector } from 'react-redux';
import { addToMarkers, setLoadTransfer, setVehicleWeight } from './store';
import { LastLoadTransfer } from './state';
import { LastVehicleWeight } from './state';
import {
  LoadTransferSensor,
  Marker,
  RawVehicleWeight,
  ShownVehicleWeight,
  StoredVehicleWeight,
  isArbitratedWeight,
  isOemWeight,
  isTmWeight,
} from '../../../interfaces';
import { EventType, MarkerType } from './interfaces';
import { getPercentFromNumber, getTimeFromTimestamp } from '../../../helpers/common';
import { MAX_VEHICLE_WEIGHT_ERROR_PERCENT } from '../../../constants/common';
import { IIconClassService } from '../../services/IconClassService';
import { container } from '../../../configs/inversify';
import { vehicleWeightPopupFieldsTitleToEventFields } from '../../../constants/symbology';
import { DataState } from './state';
import { Draft, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { setLastDataSignalTimeAsNow } from './signalsState';

const iconClassService: IIconClassService = container.get('IconClassService');

export const useVehicleWeightData = (): UseVehicleWeight => {
  const vehicleWeight = useSelector(vehicleWeightSelector);
  const loadTransfer = useSelector(loadTransferSelector);

  const dispatch = useDispatch();

  const addMarker = (marker: Marker): void => {
    dispatch(addToMarkers(marker));
  };

  const updateVehicleWeight = (vehicleWeight: RawVehicleWeight) => {
    const event: StoredVehicleWeight = {
      ...vehicleWeight,
      eventType: EventType.VEHICLE_WEIGHT,
      front_type: EventType.VEHICLE_WEIGHT,
      received_time: Date.now(),
      generation_time: getTimeFromTimestamp(vehicleWeight.counter),
      source: vehicleWeight.source || 'TM',
    };

    const maxAvailableError = getPercentFromNumber(event.weight_kg, MAX_VEHICLE_WEIGHT_ERROR_PERCENT);

    if (process.env.NODE_ENV !== 'test') {
      console.log(
        `[DEBUG]: Estimated weight is ${event.weight_kg} kg. and error is ${event.error_kg}. Max error is ${maxAvailableError} kg.`,
      );
    }

    if (event.error_kg > maxAvailableError) {
      if (process.env.NODE_ENV !== 'test') {
        console.log(`[DEBUG]: Too big error ${event.error_kg} kg. for estimated weight ${event.weight_kg} kg.`);
      }
      return;
    }

    dispatch(setVehicleWeight(event));

    // Make marker object
    const item = {
      ...event,
      popupInfo: [] as { key: string; value: string | number }[],
      marker: iconClassService.getIconClass(event),
      markerIcon: iconClassService.getEventIconName(event),
      markerType: MarkerType.ALERT,
      eventType: EventType.VEHICLE_WEIGHT,
    };

    // Keys for popup
    const shownVehicleWeightEvent: (keyof ShownVehicleWeight)[] = [
      'generation_time',
      'qualifier',
      'weight_kg',
      'error_kg',
    ];

    // Add popup
    Object.entries(event).forEach(([key, value]) => {
      if (shownVehicleWeightEvent.indexOf(key as keyof ShownVehicleWeight) + 1) {
        item.popupInfo.push({
          key: vehicleWeightPopupFieldsTitleToEventFields[key as keyof ShownVehicleWeight],
          value,
        });
      }
    });

    addMarker(item);
  };

  const updateLoadTransfer = (loadTransfer: LoadTransferSensor) => {
    dispatch(setLoadTransfer(loadTransfer));
  };

  return {
    vehicleWeight,
    updateVehicleWeight,

    loadTransfer,
    updateLoadTransfer,
  };
};

export interface UseVehicleWeight {
  vehicleWeight: LastVehicleWeight;
  updateVehicleWeight(vehicleWeight: RawVehicleWeight): void;

  loadTransfer: LastLoadTransfer | null;
  updateLoadTransfer(loadTransfer: LoadTransferSensor): void;
}

export const initialVehicleWeightState: Pick<DataState, 'vehicleWeight' | 'loadTransfer'> = {
  vehicleWeight: { tmWeight: null, oemWeight: null, arbiterWeight: null },
  loadTransfer: null,
};

export const vehicleWeightReducers: ValidateSliceCaseReducers<DataState, VehicleWeightReducers> = {
  setVehicleWeight: (state: Draft<DataState>, action: PayloadAction<StoredVehicleWeight>) => {
    let stateKey: keyof LastVehicleWeight;
    if (isTmWeight(action.payload)) {
      stateKey = 'tmWeight';
    } else if (isOemWeight(action.payload)) {
      stateKey = 'oemWeight';
    } else if (isArbitratedWeight(action.payload)) {
      stateKey = 'arbiterWeight';
    } else {
      console.log(`Unsupported weight source ${action.payload}`);
      return;
    }

    const existingState = state.vehicleWeight[stateKey];

    if (!existingState) {
      setLastDataSignalTimeAsNow(state);
      state.vehicleWeight[stateKey] = action.payload;
    } else if (action.payload.counter > existingState.counter) {
      setLastDataSignalTimeAsNow(state);
      state.vehicleWeight[stateKey] = action.payload;
    }
  },
  setLoadTransfer: (state: Draft<DataState>, action: PayloadAction<LastLoadTransfer>) => {
    if (state.loadTransfer && state.loadTransfer.counter >= action.payload.counter) {
      return;
    }

    setLastDataSignalTimeAsNow(state);
    state.loadTransfer = action.payload;
  },
};

export interface VehicleWeightReducers extends SliceCaseReducers<DataState> {
  setVehicleWeight(state: Draft<DataState>, action: PayloadAction<StoredVehicleWeight>): void;
  setLoadTransfer(state: Draft<DataState>, action: PayloadAction<LastLoadTransfer>): void;
}

export function resetVehicleWeightCounters(state: Draft<DataState>): void {
  if (state.vehicleWeight.oemWeight) {
    state.vehicleWeight.oemWeight.counter = -1;
  }
  if (state.vehicleWeight.tmWeight) {
    state.vehicleWeight.tmWeight.counter = -1;
  }
  if (state.vehicleWeight.arbiterWeight) {
    state.vehicleWeight.arbiterWeight.counter = -1;
  }

  if (state.loadTransfer) {
    state.loadTransfer.counter = -1;
  }
}

export function vehicleWeightSelector(state: RootState): LastVehicleWeight {
  return state.data.vehicleWeight;
}

export function loadTransferSelector(state: RootState): LastLoadTransfer | null {
  return state.data.loadTransfer;
}
