import React from 'react';
import { FilterName } from '../../main/interfaces';
import { ControlExpander } from '../../../components/ControlExpander';
import { Filter } from '../../../components/Filter';
import { useFilters } from '../useFilters';

export const LayerControl: React.FC = () => {
  const { selectFilter, filtersState } = useFilters();

  const onChangeFilter = (name: string) => {
    selectFilter(name as FilterName);
  };

  return (
    <ControlExpander iconClass="icon-settings">
      <Filter
        name={FilterName.LOCATION_TRAIL}
        title="Location trail"
        isActive={filtersState.locationTrail}
        onToggle={onChangeFilter}
      />
      <Filter name={FilterName.TM_GRIP} title="TM Grip" isActive={filtersState.tm_grip} onToggle={onChangeFilter} />
      <Filter name={FilterName.OEM_GRIP} title="OEM Grip" isActive={filtersState.oem_grip} onToggle={onChangeFilter} />
      <Filter
        name={FilterName.ARBITRATED_GRIP}
        title="Arbitrated Grip"
        isActive={filtersState.arbitrated_grip}
        onToggle={onChangeFilter}
      />
      <Filter
        name={FilterName.SURFACE_EVENTS}
        title="Surface events"
        isActive={filtersState.surface_events}
        onToggle={onChangeFilter}
      />
      <Filter
        name={FilterName.NOTIFICATIONS}
        title="Notifications"
        isActive={filtersState.alerts}
        onToggle={onChangeFilter}
      />
    </ControlExpander>
  );
};
