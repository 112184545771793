import React, { useContext } from 'react';
import IconButton from '../../../components/IconButton';
import { HookContext } from '../../../contexts/HookContext';

export const ResetControl: React.FC = () => {
  const { useData } = useContext(HookContext);
  const { resetData } = useData;

  const resetHandler = () => resetData();

  return (
    <IconButton
      buttonClassList="controls-btn controls-group"
      iconClassList="controls-icon icon icon-reset"
      onClickEvent={resetHandler}
    />
  );
};
