import React, { ReactNode } from 'react';

interface Props {
  children?: ReactNode | undefined;
}

export const MapTextTooltip: React.FC<Props> = ({ children }: Props) => {
  return (
    <div className="map-tooltip">
      <span className="map-tooltip-title">{children}</span>
    </div>
  );
};
