import { createAsyncThunk } from '@reduxjs/toolkit';
import { container } from '../../../configs/inversify';
import { IStreamService } from './service';
import { RootState } from '../../store';
import { SaveTapingParams } from './interfaces';
import { fetchTapingBookmarks } from '../bookmark/thunks';
import { getRecordings } from '../recordings/thunks';

const streamService: IStreamService = container.get('StreamService');

export const fetchVehicleInfoList = createAsyncThunk('stream/getInfoList', async () => {
  try {
    return await streamService.fetchVehicleInfoList();
  } catch (error) {
    throw new Error('Error while fetching info list');
  }
});

export const startTaping = createAsyncThunk('stream/startTaping', async (streamId: string) => {
  try {
    return await streamService.startTaping(streamId);
  } catch (error) {
    throw new Error('Unable to start taping');
  }
});

export const stopTaping = createAsyncThunk('stream/stopTaping', async (streamId: string, thunkApi) => {
  const state = thunkApi.getState() as RootState;

  const { startTapingTime, pauseTapingTime } = state.stream;

  if (!startTapingTime || !pauseTapingTime) {
    throw new Error('To stop recording there must be startTapingTime and pauseTapingTime');
  }

  const timeFromStart = Math.round((pauseTapingTime - startTapingTime) / 1000);

  try {
    return await streamService.stopTaping(streamId, timeFromStart);
  } catch (error) {
    throw new Error('Unable to stop taping');
  }
});

export const fetchTapedStreams = createAsyncThunk('stream/fetchTapedStreams', async (_, thunkApi) => {
  try {
    const tapedStreams = await streamService.fetchTapedStreams();

    const state = thunkApi.getState() as RootState;
    const selectedStream = state.selections.selectedStream;

    if (selectedStream && tapedStreams.find((item) => item.name === selectedStream)) {
      thunkApi.dispatch(fetchTapingBookmarks(selectedStream));
    }

    return tapedStreams;
  } catch (error) {
    throw new Error('Error while fetching taped streams');
  }
});

export const saveTaping = createAsyncThunk('stream/saveTaping', async (params: SaveTapingParams, thunkApi) => {
  try {
    await streamService.saveTaping(params.streamId, params.fields);

    thunkApi.dispatch(getRecordings());
  } catch (error) {
    throw new Error('Unable to save taping');
  }
});
