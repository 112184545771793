import { EventType } from '../app/modules/data/interfaces';
import {
  ShownGrip,
  ShownRelativeRollingRadius,
  ShownSurfaceEvent,
  ShownTireRadiiChange,
  ShownTiresStiffness,
  ShownVehicleWeight,
} from '../interfaces';

import grip from '../assets/img/events/grip.png';
import gripLow from '../assets/img/events/grip_low.png';
import gripMedium from '../assets/img/events/grip_medium.png';
import gripHigh from '../assets/img/events/grip_high.png';

import surface from '../assets/img/events/surface_low.png';
import surfaceLow from '../assets/img/events/surface_low.png';
import surfaceMedium from '../assets/img/events/surface_medium.png';
import surfaceHigh from '../assets/img/events/surface_high.png';

import surfaceLowSkid from '../assets/img/events/surface_low_skid.png';
import surfaceMediumSkid from '../assets/img/events/surface_medium_skid.png';
import surfaceHighSkid from '../assets/img/events/surface_high_skid.png';

import surfaceLowSpin from '../assets/img/events/surface_low_spin.png';
import surfaceMediumSpin from '../assets/img/events/surface_medium_spin.png';
import surfaceHighSpin from '../assets/img/events/surface_high_spin.png';

import surfaceLowContinual from '../assets/img/events/surface_low_continual.png';
import surfaceMediumContinual from '../assets/img/events/surface_medium_continual.png';
import surfaceHighContinual from '../assets/img/events/surface_high_continual.png';

import surfaceLowStepDown from '../assets/img/events/surface_low_stepdown.png';
import surfaceMediumStepDown from '../assets/img/events/surface_medium_stepdown.png';
import surfaceHighStepDown from '../assets/img/events/surface_high_stepdown.png';

import surfaceLowStepUp from '../assets/img/events/surface_low_stepup.png';
import surfaceMediumStepUp from '../assets/img/events/surface_medium_stepup.png';
import surfaceHighStepUp from '../assets/img/events/surface_high_stepup.png';

import alert from '../assets/img/events/alert_low.png';
import alertLow from '../assets/img/events/alert_low.png';
import alertMedium from '../assets/img/events/alert_medium.png';
import alertHigh from '../assets/img/events/alert_high.png';

import alertLowAqua from '../assets/img/events/alert_low_aqua.png';
import alertMediumAqua from '../assets/img/events/alert_medium_aqua.png';
import alertHighAqua from '../assets/img/events/alert_high_aqua.png';

import alertLowChange from '../assets/img/events/alert_low_change.png';
import alertMediumChange from '../assets/img/events/alert_medium_change.png';
import alertHighChange from '../assets/img/events/alert_high_change.png';

import alertLowJump from '../assets/img/events/alert_low_jump.png';
import alertMediumJump from '../assets/img/events/alert_medium_jump.png';
import alertHighJump from '../assets/img/events/alert_high_jump.png';

import alertLowPothole from '../assets/img/events/alert_low_pothole.png';
import alertMediumPothole from '../assets/img/events/alert_medium_pothole.png';
import alertHighPothole from '../assets/img/events/alert_high_pothole.png';

export const iconClassToEventType = {
  [EventType.GRIP]: 'icon-grip',
  [EventType.SURFACE_EVENT]: 'icon-surface',
  [EventType.ALERT]: 'icon-alert',
  [EventType.TIRES_STIFFNESS]: 'icon-alert',
  [EventType.TIRE_CHANGE]: 'icon-alert',
  [EventType.VEHICLE_WEIGHT]: 'icon-alert',
  [EventType.ROLLING_RADIUS]: 'icon-alert',
};

export const eventTitleToEventType = {
  [EventType.GRIP]: 'grip',
  [EventType.SURFACE_EVENT]: 'surface event',
  [EventType.ALERT]: 'alert',
  [EventType.TIRE_CHANGE]: 'tire change indication',
  [EventType.TIRES_STIFFNESS]: 'tire stiffness',
  [EventType.VEHICLE_WEIGHT]: 'vehicle weight',
  [EventType.ROLLING_RADIUS]: 'rolling radius',
};

export const surfacePopupFieldsTitleToEventFields: { [key in keyof ShownSurfaceEvent]: string } = {
  ['generation_time']: 'Generation time',
  ['type']: 'Type',
  ['side']: 'Side',
  ['magnitude']: 'Magnitude [0-255]',
  ['in_rough_road']: 'In rough road',
};

export const stiffnessPopupFieldsTitleToEventFields: { [key in keyof ShownTiresStiffness]: string } = {
  ['generation_time']: 'Generation time',
  ['front_left']: 'Front left [%]',
  ['front_right']: 'Front right [%]',
};

export const vehicleWeightPopupFieldsTitleToEventFields: { [key in keyof ShownVehicleWeight]: string } = {
  ['generation_time']: 'Generation time',
  ['qualifier']: 'Qualifier',
  ['weight_kg']: 'Weight [kg]',
  ['error_kg']: 'Error [kg]',
};

export const tireRadiiPopupFieldsTitleToEventFields: { [key in keyof ShownTireRadiiChange]: string } = {
  ['generation_time']: 'Generation time',
  ['tire_tread_depth_delta_centi_mm']: 'Tread depth delta [mm]',
  ['applicable_wheel']: 'Wheel',
  ['reason']: 'Reason',
};

export const gripPopupFieldsTitleToEventFields: { [key in keyof ShownGrip]: string } = {
  ['generation_time']: 'Generation time',
  ['mean_g']: 'Mean [mu]',
  ['error_g']: 'Error [+-mu]',
  ['quality']: 'Quality [%]',
  ['source']: 'Source',
  ['age_m']: 'Age [m]',
};

export const rollingRadiusPopupFieldsTitleToEventFields: {
  [key in keyof ShownRelativeRollingRadius]: string;
} = {
  ['applicable_wheel']: 'Applicable wheel',
  ['source']: 'Source',
  ['relative_effective_radius_centi_percent']: 'Relative effective radius [%]',
  ['relative_effective_radius_error_centi_percent']: 'Relative effective radius error [%]',
  ['quality_percent']: 'Quality [%]',
};

export const eventIconNameToImage: { [key: string]: string } = {
  grip: grip,
  grip_high: gripHigh,
  grip_medium: gripMedium,
  grip_low: gripLow,

  surface: surface,
  surface_high: surfaceHigh,
  surface_medium: surfaceMedium,
  surface_low: surfaceLow,

  surface_high_step_up: surfaceHighStepUp,
  surface_medium_step_up: surfaceMediumStepUp,
  surface_low_step_up: surfaceLowStepUp,

  surface_high_step_down: surfaceHighStepDown,
  surface_medium_step_down: surfaceMediumStepDown,
  surface_low_step_down: surfaceLowStepDown,

  surface_high_rough_road: surfaceHighContinual,
  surface_medium_rough_road: surfaceMediumContinual,
  surface_low_rough_road: surfaceLowContinual,

  surface_high_spin: surfaceHighSpin,
  surface_medium_spin: surfaceMediumSpin,
  surface_low_spin: surfaceLowSpin,

  surface_high_skid: surfaceHighSkid,
  surface_medium_skid: surfaceMediumSkid,
  surface_low_skid: surfaceLowSkid,

  alert: alert,
  alert_low: alertLow,
  alert_medium: alertMedium,
  alert_high: alertHigh,

  alert_low_aqua: alertLowAqua,
  alert_medium_aqua: alertMediumAqua,
  alert_high_aqua: alertHighAqua,

  alert_low_change: alertLowChange,
  alert_medium_change: alertMediumChange,
  alert_high_change: alertHighChange,

  alert_low_jump: alertLowJump,
  alert_medium_jump: alertMediumJump,
  alert_high_jump: alertHighJump,

  alert_low_pothole: alertLowPothole,
  alert_medium_pothole: alertMediumPothole,
  alert_high_pothole: alertHighPothole,
};
