import React from 'react';
import ReactDOM from 'react-dom';
import mapboxgl, { EventData, GeoJSONSource, MapMouseEvent, Popup } from 'mapbox-gl';
import { Marker } from '../../../interfaces';
import { EventType, MarkerType } from '../data/interfaces';
import { MapTooltip } from './MapTooltip';
import { makeFirstLatterUppercase, replaceUnderscore } from '../../../helpers/common';
import { eventTitleToEventType } from '../../../constants/symbology';
import { defaultSettings } from '../../../constants/settings';
import { AppSettings } from '../settings/interfaces';

export class Markers {
  private readonly map: mapboxgl.Map;

  private MARKERS_LAYER_ID = 'markers';

  private tooltips: mapboxgl.Popup[] = [];

  private markerList: Marker[] = [];

  private timeToHideByMarkerType: Record<MarkerType, number> = {
    [MarkerType.TM_GRIP]: defaultSettings.grip_fade_time_ms,
    [MarkerType.OEM_GRIP]: defaultSettings.grip_fade_time_ms,
    [MarkerType.ARBITRATED_GRIP]: defaultSettings.grip_fade_time_ms,
    [MarkerType.SURFACE_EVENT]: defaultSettings.surface_event_fade_time_ms,
    [MarkerType.ALERT]: defaultSettings.alert_fade_time_ms,
  };

  private visibleMarkerTypes: MarkerType[] = [
    MarkerType.ALERT,
    MarkerType.SURFACE_EVENT,
    MarkerType.TM_GRIP,
    MarkerType.OEM_GRIP,
    MarkerType.ARBITRATED_GRIP,
  ];

  constructor(map: mapboxgl.Map) {
    this.map = map;

    this.init();
    this.update();
  }

  public closePopups(): void {
    this.tooltips.forEach((tooltip) => tooltip.remove());
    this.tooltips = [];
  }

  public setSettings(
    settings: Pick<AppSettings, 'grip_fade_time_ms' | 'surface_event_fade_time_ms' | 'alert_fade_time_ms'>,
  ): void {
    this.timeToHideByMarkerType = {
      tm_grip: settings.grip_fade_time_ms,
      oem_grip: settings.grip_fade_time_ms,
      arbitrated_grip: settings.grip_fade_time_ms,
      surface_event: settings.surface_event_fade_time_ms,
      alert: settings.alert_fade_time_ms,
    };
  }

  public setMarkerList(markerList: Marker[]): void {
    this.markerList = markerList;

    this.update();
  }

  public setMarkerTypeVisibility(markerType: MarkerType, state: boolean): void {
    if (state) {
      this.visibleMarkerTypes.push(markerType);
    } else {
      this.visibleMarkerTypes = this.visibleMarkerTypes.filter((item) => item !== markerType);
    }

    this.map.setFilter(this.MARKERS_LAYER_ID, ['in', ['get', 'markerType'], ['literal', [...this.visibleMarkerTypes]]]);
  }

  public rebuild(): void {
    this.init();
    this.update();
  }

  private init(): void {
    this.map.addSource(this.MARKERS_LAYER_ID, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [],
            },
            properties: {},
          },
        ],
      },
    });

    this.map.addLayer({
      id: this.MARKERS_LAYER_ID,
      source: this.MARKERS_LAYER_ID,
      type: 'symbol',
      layout: {
        'icon-image': ['get', 'markerIcon'],
        'icon-size': 0.7,
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
      },
      filter: ['in', ['get', 'markerType'], ['literal', [...this.visibleMarkerTypes]]],
      paint: {
        'icon-opacity': 1,
        'icon-halo-width': 1,
        'icon-halo-color': '#000',
      },
    });

    this.map.on('click', this.MARKERS_LAYER_ID, (e) => {
      this.onShowPopup(e);
    });
  }

  private onShowPopup(e: MapMouseEvent & EventData): void {
    const feature = e.features[0];

    if (feature) {
      const popupInfo = JSON.parse(feature.properties.popupInfo);
      const eventType = feature.properties.eventType;

      const popupNode = document.createElement('div');

      const tooltip = new Popup({ closeButton: false, closeOnClick: false });
      tooltip.on('click', () => tooltip.remove());

      const removeTooltip = () => {
        tooltip.remove();

        this.map.off('mousedown', removeTooltip);
      };

      ReactDOM.render(
        React.createElement(MapTooltip, {
          title: replaceUnderscore(makeFirstLatterUppercase(eventTitleToEventType[eventType as unknown as EventType])),
          infoList: popupInfo.map(({ key, value }: Record<string, string>) => ({ title: key, value })),
          eventType,
          onClickPin: () => {
            if (this.tooltips.includes(tooltip)) {
              this.map.on('mousedown', removeTooltip);
              this.tooltips.splice(this.tooltips.indexOf(tooltip), 1);
              tooltip.removeClassName('isPinned');
            } else {
              this.map.off('mousedown', removeTooltip);
              this.tooltips.push(tooltip);
              tooltip.addClassName('isPinned');

              this.tooltips.length > 10 && this.tooltips.shift()?.remove();
            }
          },
        }),
        popupNode,
      );

      tooltip.setLngLat(e.lngLat).setDOMContent(popupNode).addTo(this.map);

      this.map.on('mousedown', removeTooltip);
    }
  }

  private update(): void {
    const source = this.map.getSource(this.MARKERS_LAYER_ID) as GeoJSONSource;

    if (!source) return;

    const data = {
      type: 'FeatureCollection',
      features: this.markerList.map((item) => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [item.lon_deg, item.lat_deg],
          },
          properties: {
            eventType: item.eventType,
            markerType: item.markerType,
            markerIcon: item.markerIcon,
            timestamp: item.received_time,
            timeToHide: this.timeToHideByMarkerType[item.markerType],
            popupInfo: item.popupInfo,
          },
        };
      }),
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    source.setData(data);

    this.map.setPaintProperty(this.MARKERS_LAYER_ID, 'icon-opacity', [
      '/',
      ['-', ['get', 'timeToHide'], ['-', Date.now(), ['get', 'timestamp']]],
      ['get', 'timeToHide'],
    ]);
  }
}
