import { useDispatch, useSelector } from 'react-redux';
import {
  StoredAbsoluteRollingRadius,
  DEFAULT_RADIUS_PROVIDER,
  Marker,
  RawTireRadiiChange,
  StoredRelativeRollingRadius,
  ShownRelativeRollingRadius,
  ShownTireRadiiChange,
  StoredAbsoluteEffectiveRollingRadius,
  StoredRelativeEffectiveRollingRadius,
  StoredTireRadiiChange,
  TireRadiiConvergence,
  RawRelativeRollingRadius,
  RawAbsoluteRollingRadius,
  ApplicableWheel,
} from '../../../interfaces';
import {
  addToMarkers,
  addToTireRadiiChanges,
  setAbsoluteEffectiveRollingRadius,
  setRelativeEffectiveRollingRadius,
  setTireRadiiConvergence,
} from './store';
import { DataState } from './state';
import { EventType, MarkerType } from './interfaces';
import { getRoundedFloat, getTimeFromTimestamp } from '../../../helpers/common';
import { IIconClassService } from '../../services/IconClassService';
import { container } from '../../../configs/inversify';
import {
  rollingRadiusPopupFieldsTitleToEventFields,
  tireRadiiPopupFieldsTitleToEventFields,
} from '../../../constants/symbology';
import { Draft, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { initDashboardStatistics } from './dashboardStatistics';
import { MAX_STORED_EVENTS_NUMBER } from '../../../constants/common';
import { RootState } from '../../store';
import { setLastDataSignalTimeAsNow } from './signalsState';

const iconClassService: IIconClassService = container.get('IconClassService');

export const useTireRadiiData = (): UseTireRadiiData => {
  const tiresRadiiChangeEvents = useSelector(tiresRadiiChangeEventsSelector);
  const tireRadiiConvergence = useSelector(tireRadiiConvergenceSelector);
  const relativeEffectiveRollingRadius = useSelector(relativeEffectiveRollingRadiusSelector);
  const absoluteEffectiveRollingRadius = useSelector(absoluteEffectiveRollingRadiusSelector);

  const dispatch = useDispatch();

  const addTireRadiiChangeEvent = (event: StoredTireRadiiChange): void => {
    dispatch(addToTireRadiiChanges(event));
  };

  const addMarker = (marker: Marker): void => {
    dispatch(addToMarkers(marker));
  };

  const updateTireRadiiChangeState = (e: RawTireRadiiChange) => {
    const event: StoredTireRadiiChange = {
      ...e,
      front_type: EventType.TIRE_CHANGE,
      received_time: Date.now(),
      generation_time: getTimeFromTimestamp(e.counter),
    };

    addTireRadiiChangeEvent(event);

    const item = {
      lat_deg: event.lat_deg,
      lon_deg: event.lon_deg,
      popupInfo: [] as {
        key: string;
        value: string | number;
      }[],
      marker: iconClassService.getIconClass(event),
      markerIcon: iconClassService.getEventIconName(event),
      markerType: MarkerType.ALERT,
      eventType: EventType.TIRE_CHANGE,
      received_time: event.received_time,
    };

    const shownTireRadiiChange: (keyof ShownTireRadiiChange)[] = ['applicable_wheel', 'reason', 'generation_time'];

    Object.entries(event).forEach(([key, value]) => {
      if (shownTireRadiiChange.indexOf(key as keyof ShownTireRadiiChange) + 1) {
        item.popupInfo.push({ key: tireRadiiPopupFieldsTitleToEventFields[key], value });
      }
    });
    // TODO: Temporary hidden
    // item.popupInfo.push({ key: 'tread depth delta [mm]', value: event.tire_tread_depth_delta_centi_mm / 100 });

    addMarker(item);
  };

  const updateTireRadiiConvergence = (e: TireRadiiConvergence) => {
    dispatch(setTireRadiiConvergence(e));
  };

  const updateRelativeRollingRadius = (e: RawRelativeRollingRadius): void => {
    const event: StoredRelativeRollingRadius = {
      ...e,
      front_type: EventType.ROLLING_RADIUS,
      received_time: Date.now(),
      eventType: EventType.ROLLING_RADIUS,
      provider: e.provider || DEFAULT_RADIUS_PROVIDER,
    };

    dispatch(setRelativeEffectiveRollingRadius(event));

    const item = {
      lon_deg: event.lon_deg,
      lat_deg: event.lat_deg,
      marker: iconClassService.getIconClass(event),
      markerIcon: iconClassService.getEventIconName(event),
      markerType: MarkerType.ALERT,
      eventType: EventType.ROLLING_RADIUS,
      received_time: Date.now(),
      popupInfo: [] as { key: string; value: string | number }[],
    };

    // Keys for popup
    const shownRollingRadiusEvent: (keyof ShownRelativeRollingRadius)[] = [
      'applicable_wheel',
      'source',
      'relative_effective_radius_centi_percent',
      'relative_effective_radius_error_centi_percent',
      'quality_percent',
    ];

    // Add popup
    Object.entries(event).forEach(([key, value]) => {
      if (
        key === 'relative_effective_radius_centi_percent' ||
        key === 'relative_effective_radius_error_centi_percent'
      ) {
        value = (value as number) / 100;
      }

      if (shownRollingRadiusEvent.indexOf(key as keyof ShownRelativeRollingRadius) + 1) {
        item.popupInfo.push({
          key: rollingRadiusPopupFieldsTitleToEventFields[key as keyof ShownRelativeRollingRadius],
          value,
        });
      }
    });

    addMarker(item);
  };

  const updateAbsoluteRollingRadius = (e: RawAbsoluteRollingRadius): void => {
    const event: StoredAbsoluteRollingRadius = {
      ...e,
      received_time: Date.now(),
      eventType: EventType.ROLLING_RADIUS,
      provider: e.provider || DEFAULT_RADIUS_PROVIDER,
    };

    dispatch(setAbsoluteEffectiveRollingRadius(event));
  };

  return {
    tiresRadiiChangeEvents,
    updateTireRadiiChangeState,

    tireRadiiConvergence,
    updateTireRadiiConvergence,

    relativeEffectiveRollingRadius,
    updateRelativeRollingRadius,

    absoluteEffectiveRollingRadius,
    updateAbsoluteRollingRadius,
  };
};

export interface UseTireRadiiData {
  tiresRadiiChangeEvents: StoredTireRadiiChange[];
  updateTireRadiiChangeState(e: RawTireRadiiChange): void;

  tireRadiiConvergence: TireRadiiConvergence | null;
  updateTireRadiiConvergence(e: TireRadiiConvergence): void;

  relativeEffectiveRollingRadius: StoredRelativeEffectiveRollingRadius;
  updateRelativeRollingRadius(e: RawRelativeRollingRadius): void;

  absoluteEffectiveRollingRadius: StoredAbsoluteEffectiveRollingRadius;
  updateAbsoluteRollingRadius(e: RawAbsoluteRollingRadius): void;
}

export const tireRadiiReducers: ValidateSliceCaseReducers<DataState, TireRadiiReducers> = {
  setTireRadiiChanges: (state: Draft<DataState>, action: PayloadAction<StoredTireRadiiChange[]>) => {
    state.tireRadiiChanges = action.payload;
  },
  setTireRadiiConvergence: (state, action: PayloadAction<TireRadiiConvergence>) => {
    if (state.tireRadiiConvergence && state.tireRadiiConvergence.counter >= action.payload.counter) return;

    // TODO: clarify logic of statistics counting beginning
    if (state.dashboardStatistics === null) {
      state.dashboardStatistics = initDashboardStatistics();
    }

    state.tireRadiiConvergence = action.payload;
    setLastDataSignalTimeAsNow(state);
  },
  addToTireRadiiChanges: (state: Draft<DataState>, action: PayloadAction<StoredTireRadiiChange>) => {
    if (!state.tireRadiiChanges.length) {
      setLastDataSignalTimeAsNow(state);
      state.tireRadiiChanges = [...state.tireRadiiChanges, action.payload];
      return;
    }

    const { counter } = action.payload;
    const lastEvent = state.tireRadiiChanges[state.tireRadiiChanges.length - 1];

    if (lastEvent.counter >= counter) return;

    setLastDataSignalTimeAsNow(state);
    state.tireRadiiChanges = [...state.tireRadiiChanges, action.payload].slice(-MAX_STORED_EVENTS_NUMBER);
  },
  setRelativeEffectiveRollingRadius: (state: Draft<DataState>, action: PayloadAction<StoredRelativeRollingRadius>) => {
    const {
      counter,
      applicable_wheel,
      relative_effective_radius_centi_percent,
      relative_effective_radius_error_centi_percent,
      received_time,
      provider,
    } = action.payload;

    if (applicable_wheel === ApplicableWheel.FRONT_LEFT) {
      return;
    }

    const itemWithBigCounter = state.relativeEffectiveRollingRadius[applicable_wheel].find(
      (item) => item.counter >= counter,
    );

    if (itemWithBigCounter) return;

    setLastDataSignalTimeAsNow(state);

    const value = getRoundedFloat(relative_effective_radius_centi_percent / 100, 2);
    const error = getRoundedFloat(relative_effective_radius_error_centi_percent / 100, 2);

    state.relativeEffectiveRollingRadius[applicable_wheel].push({
      value,
      error,
      timestamp: received_time,
      counter,
      provider: provider || DEFAULT_RADIUS_PROVIDER,
    });
  },
  setAbsoluteEffectiveRollingRadius: (state: Draft<DataState>, action: PayloadAction<StoredAbsoluteRollingRadius>) => {
    const {
      counter,
      applicable_wheel,
      absolute_effective_radius_mm: value,
      absolute_effective_radius_error_mm: error,
      received_time,
      provider,
    } = action.payload;

    const itemWithBiggerCounter = state.absoluteEffectiveRollingRadius[applicable_wheel].find(
      (item) => item.counter >= counter,
    );

    if (itemWithBiggerCounter) return;

    setLastDataSignalTimeAsNow(state);

    state.absoluteEffectiveRollingRadius[applicable_wheel].push({
      value,
      error,
      timestamp: received_time,
      counter,
      provider: provider || DEFAULT_RADIUS_PROVIDER,
    });
  },
};

export interface TireRadiiReducers extends SliceCaseReducers<DataState> {
  setTireRadiiChanges(state: Draft<DataState>, action: PayloadAction<StoredTireRadiiChange[]>): void;
  setTireRadiiConvergence(state: Draft<DataState>, action: PayloadAction<TireRadiiConvergence>): void;
  addToTireRadiiChanges(state: Draft<DataState>, action: PayloadAction<StoredTireRadiiChange>): void;
  setRelativeEffectiveRollingRadius(state: Draft<DataState>, action: PayloadAction<StoredRelativeRollingRadius>): void;
  setAbsoluteEffectiveRollingRadius(state: Draft<DataState>, action: PayloadAction<StoredAbsoluteRollingRadius>): void;
}

const initialRelativeEffectiveRollingRadius: StoredRelativeEffectiveRollingRadius = {
  [ApplicableWheel.FRONT_RIGHT]: [],
  [ApplicableWheel.REAR_RIGHT]: [],
  [ApplicableWheel.REAR_LEFT]: [],
};

const initialAbsoluteEffectiveRollingRadius: StoredAbsoluteEffectiveRollingRadius = {
  [ApplicableWheel.FRONT_LEFT]: [],
  [ApplicableWheel.FRONT_RIGHT]: [],
  [ApplicableWheel.REAR_RIGHT]: [],
  [ApplicableWheel.REAR_LEFT]: [],
};

export const initialTireRadiiState: Pick<
  DataState,
  'tireRadiiChanges' | 'tireRadiiConvergence' | 'absoluteEffectiveRollingRadius' | 'relativeEffectiveRollingRadius'
> = {
  tireRadiiConvergence: null,
  tireRadiiChanges: [],
  relativeEffectiveRollingRadius: initialRelativeEffectiveRollingRadius,
  absoluteEffectiveRollingRadius: initialAbsoluteEffectiveRollingRadius,
};

export function resetTireRadiCounters(state: Draft<DataState>): void {
  if (state.tireRadiiConvergence) {
    state.tireRadiiConvergence.counter = -1;
  }

  if (state.tireRadiiChanges.length) {
    state.tireRadiiChanges[state.tireRadiiChanges.length - 1].counter = -1;
  }
}

export function resetTireRadiiConvergence(state: Draft<DataState>): void {
  state.tireRadiiConvergence = null;
}

export function resetTireRadiiState(state: Draft<DataState>, { full }: ResetStateOptions): void {
  state.tireRadiiChanges = [];
  state.relativeEffectiveRollingRadius = initialRelativeEffectiveRollingRadius;
  state.absoluteEffectiveRollingRadius = initialAbsoluteEffectiveRollingRadius;

  if (full) {
    resetTireRadiiConvergence(state);
  }
}

export interface ResetStateOptions {
  full: boolean;
}

export function tireRadiiConvergenceSelector(state: RootState): TireRadiiConvergence | null {
  return state.data.tireRadiiConvergence;
}

export function tiresRadiiChangeEventsSelector(state: RootState): StoredTireRadiiChange[] {
  return state.data.tireRadiiChanges;
}

export function relativeEffectiveRollingRadiusSelector(state: RootState): StoredRelativeEffectiveRollingRadius {
  return state.data.relativeEffectiveRollingRadius;
}

export function absoluteEffectiveRollingRadiusSelector(state: RootState): StoredAbsoluteEffectiveRollingRadius {
  return state.data.absoluteEffectiveRollingRadius;
}
