import { useDispatch, useSelector } from 'react-redux';
import { Grip, Marker, ShownGrip } from '../../../interfaces';
import { addToGripEvents, addToMarkers } from './store';
import { EventType, getGripMarkerType } from './interfaces';
import { getTimeFromTimestamp } from '../../../helpers/common';
import { IIconClassService } from '../../services/IconClassService';
import { container } from '../../../configs/inversify';
import { gripPopupFieldsTitleToEventFields } from '../../../constants/symbology';
import { DataState } from './state';
import { Draft, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { addGripPointIfNotPaused } from './dashboardStatistics';
import { MAX_STORED_EVENTS_NUMBER } from '../../../constants/common';
import { setLastDataSignalTimeAsNow } from './signalsState';

const iconClassService: IIconClassService = container.get('IconClassService');

export const useGripData = (): UseGripData => {
  const gripEvents = useSelector(gripEventsSelector);

  const dispatch = useDispatch();

  const addMarker = (marker: Marker): void => {
    dispatch(addToMarkers(marker));
  };

  const addGripEvent = (event: Grip): void => {
    dispatch(addToGripEvents(event));
  };

  const updateGripEventState = (e: Grip): void => {
    const event = { ...e };

    event.front_type = EventType.GRIP;
    event.received_time = Date.now();
    event.generation_time = getTimeFromTimestamp(event.counter);

    addGripEvent(event);

    const item = {
      lat_deg: event.lat_deg,
      lon_deg: event.lon_deg,
      marker: iconClassService.getIconClass(event),
      markerIcon: iconClassService.getEventIconName(event),
      markerType: getGripMarkerType(e),
      eventType: EventType.GRIP,
      received_time: Date.now(),
      popupInfo: [] as { key: string; value: string | number | undefined }[],
    };

    //Places only the necessary data in the pop-up window
    const shownGrip: (keyof ShownGrip)[] = ['mean_g', 'age_m', 'error_g', 'quality', 'source', 'generation_time'];

    Object.keys(event).forEach((key) => {
      if (shownGrip.indexOf(key as keyof ShownGrip) + 1) {
        item.popupInfo.push({
          key: gripPopupFieldsTitleToEventFields[key],
          value: event[key as keyof Grip],
        });
      }
    });

    addMarker(item);
  };

  return {
    gripEvents,
    updateGripEventState,
  };
};

export interface UseGripData {
  gripEvents: Grip[];
  updateGripEventState(e: Grip): void;
}

export const initialGripState: Pick<DataState, 'gripEvents'> = {
  gripEvents: [],
};

export const gripReducers: ValidateSliceCaseReducers<DataState, GripReducers> = {
  setGripEvents: (state: Draft<DataState>, action: PayloadAction<Grip[]>) => {
    state.gripEvents = action.payload;
  },
  addToGripEvents: (state: Draft<DataState>, action: PayloadAction<Grip>) => {
    if (!state.gripEvents.length) {
      setLastDataSignalTimeAsNow(state);
      state.gripEvents = [action.payload];
    } else {
      const { counter } = action.payload;
      const lastEvent = state.gripEvents[state.gripEvents.length - 1];

      if (lastEvent.counter >= counter) return;

      setLastDataSignalTimeAsNow(state);
      state.gripEvents = [...state.gripEvents, action.payload].slice(-MAX_STORED_EVENTS_NUMBER);
    }

    addGripPointIfNotPaused(state);
  },
};

export interface GripReducers extends SliceCaseReducers<DataState> {
  setGripEvents(state: Draft<DataState>, action: PayloadAction<Grip[]>): void;
  addToGripEvents(state: Draft<DataState>, action: PayloadAction<Grip>): void;
}

export function resetGripEventsToInitialValue(state: Draft<DataState>): void {
  state.gripEvents = initialGripState.gripEvents;
}

export function gripEventsSelector(state: RootState): Grip[] {
  return state.data.gripEvents;
}
