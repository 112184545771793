import React from 'react';
import './_replay.scss';

interface Props {
  onToggle: () => void;
  isToggled: boolean;
}

export const ReplaySwitch: React.FC<Props> = ({ isToggled, onToggle }: Props) => {
  return (
    <label className="switch-toggle">
      <input className="switch-checkbox" type="checkbox" checked={isToggled} onChange={onToggle} />
      <span className="switch" />
    </label>
  );
};
