import React, { useContext } from 'react';
import DashboardCard from '../widgets/DashboardCard';
import { AvailableWidgets, DashboardContext } from '../index';
import Speedometer from '../widgets/Speedometer';
import GripDataWidget from '../widgets/GripDataWidget';
import TireRadiiConvergenceWidget, { ConvergenceType } from '../widgets/TireRadiiConvergenceWidget';
import TireDataWidget from '../widgets/TireDataWidget';
import { AccSpeed } from './AccSpeed';
import AccControl from './AccControl';
import { HookContext } from '../../../../contexts/HookContext';
import { WindowSizeContext } from '../../../../contexts/WindowSizeContext';
import { resolution } from '../../../../../constants/common';
import './_acc-panel.scss';
import { filterGrips } from '../../../filters/gripFilter';
import CenterOfGravity from '../widgets/CenterOfGravity';
import VehicleWeightWidget from '../widgets/VehicleWeightWidget';

const AccPanel: React.FC = () => {
  const { isMini, isLaptop } = useContext(DashboardContext);

  const { useUser, useData, useFilters } = useContext(HookContext);
  const { userData } = useUser;

  const { width } = useContext(WindowSizeContext);

  const {
    isDataReset,
    vehicleState: { vehicle_speed },
    gripEvents,
    tiresRadiiChangeEvents,
    tiresStiffnessEvents,
    tireRadiiConvergence,
    steeringAngle,
    accStateActive,
    vehicleWeight,
    loadTransfer,
  } = useData;

  const { filtersState } = useFilters;

  const isTablet = width < resolution.SMALL_LAPTOP;

  return (
    <>
      {!isTablet && (
        <DashboardCard id={AvailableWidgets.VEHICLE_SIGNALS_PANEL} className="col-content dash-acc-panel">
          <AccSpeed>
            <div className="acc-speed-container widget-heading">
              <Speedometer value={vehicle_speed.speed_kmh} units={userData?.units} isMini />
            </div>
            <AccControl isActive={accStateActive} />
          </AccSpeed>
        </DashboardCard>
      )}
      <DashboardCard id={AvailableWidgets.VEHICLE_SIGNALS_PANEL} className="col dash-acc-panel dash-vehicle-weight">
        {/* TODO: find out name of signal from vehicle */}
        <div className="acc-speed widget weight-widget">
          <VehicleWeightWidget weight={vehicleWeight} units={userData?.units}></VehicleWeightWidget>
          <CenterOfGravity loadTransfer={loadTransfer} units={userData?.units}></CenterOfGravity>
        </div>
      </DashboardCard>
      <DashboardCard id={AvailableWidgets.GRIP_DATA} className="col dash-graph-panel">
        {/* TODO: 0.3(30% from screen width) and 0.35(35% from grip data widget width) need to change on needed percents; */}
        <GripDataWidget
          key={isMini ? window.innerWidth + 1 : window.innerWidth}
          gripEvents={filterGrips(gripEvents, filtersState)}
          width={isMini ? window.innerWidth * 0.15 : isLaptop ? window.innerWidth * 0.28 : window.innerWidth * 0.2}
          height={isMini ? 60 : window.innerWidth * 0.3 * 0.35}
          linesCount={isMini ? 5 : 8}
          timeOfObserve={35}
          gripWidth={10}
          isDataReset={isDataReset}
          isMini={isMini}
        />
      </DashboardCard>
      <DashboardCard id={AvailableWidgets.TIRE_RADII_CONVERGENCE} className="col-content">
        <TireRadiiConvergenceWidget
          convergenceType={ConvergenceType.ABSOLUTE}
          convergenceValue={!!tireRadiiConvergence?.absolute_converged}
        />
        <TireRadiiConvergenceWidget
          convergenceType={ConvergenceType.RELATIVE}
          convergenceValue={!!tireRadiiConvergence?.relative_converged}
        />
      </DashboardCard>
      <DashboardCard id={AvailableWidgets.TIRE_DATA} className="col col-xxl-8">
        <TireDataWidget
          tireRadiiChangeEvent={tiresRadiiChangeEvents[tiresRadiiChangeEvents.length - 1] || null}
          tiresStiffnessEvent={tiresStiffnessEvents[tiresStiffnessEvents.length - 1] || null}
          steeringAngle={steeringAngle}
          isMini={isMini}
        />
      </DashboardCard>
    </>
  );
};

export default React.memo(AccPanel);
