import { Draft, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { DataState } from './state';
import { Marker } from '../../../interfaces';
import { TIME_TO_HIDE_EVENT } from '../../../constants/common';
import { RootState } from '../../store';

export const initialMarkersState: Pick<DataState, 'markers'> = {
  markers: [],
};

export const markersReducers: ValidateSliceCaseReducers<DataState, MarkersReducers> = {
  setMarkers: (state, action: PayloadAction<Marker[]>) => {
    state.markers = action.payload;
  },
  addToMarkers: (state, action: PayloadAction<Marker>) => {
    const storedMarkers = state.markers.filter((item) => {
      if (!item.received_time) return false;
      const visibilityPercent = (TIME_TO_HIDE_EVENT - (Date.now() - item.received_time)) / TIME_TO_HIDE_EVENT;
      return visibilityPercent > 0;
    });

    if (action.payload.received_time) {
      storedMarkers.push(action.payload);
    }

    state.markers = storedMarkers;
  },
};

export interface MarkersReducers extends SliceCaseReducers<DataState> {
  setMarkers(state: Draft<DataState>, action: PayloadAction<Marker[]>): void;
  addToMarkers(state: Draft<DataState>, action: PayloadAction<Marker>): void;
}

export function resetMarkersData(state: Draft<DataState>): void {
  state.markers = [];
}

export function markersSelector(state: RootState): Marker[] {
  return state.data.markers;
}
