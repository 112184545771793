import { useDispatch, useSelector } from 'react-redux';
import { Gps } from '../../../interfaces';
import { addToGpsList } from './store';
import { DataState } from './state';
import { Draft, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { setLastDataSignalTimeAsNow } from './signalsState';

export const useGpsData = (): UseGpsData => {
  const gpsList = useSelector(gpsListSelector);

  const dispatch = useDispatch();

  const addGps = (gps: Gps): void => {
    dispatch(addToGpsList(gps));
  };

  const updateGpsEventState = (e: Gps): void => {
    const gps = { ...e };

    if (!gps.received_time) {
      gps.received_time = Date.now();
    }

    addGps(gps);
  };

  return {
    gpsList,
    updateGpsEventState,
  };
};

export interface UseGpsData {
  gpsList: Gps[];
  updateGpsEventState(gps: Gps): void;
}

export const initialGpsState: Pick<DataState, 'gpsList'> = {
  gpsList: [],
};

export const gpsReducers: ValidateSliceCaseReducers<DataState, GpsReducers> = {
  setGpsList: (state: Draft<DataState>, action: PayloadAction<Gps[]>) => {
    state.gpsList = action.payload;
  },
  addToGpsList: (state: Draft<DataState>, action: PayloadAction<Gps>) => {
    if (!state.gpsList.length) {
      state.gpsList = [...state.gpsList, action.payload];
      setLastDataSignalTimeAsNow(state);
      return;
    }

    const { counter } = action.payload;
    const lastEvent = state.gpsList[state.gpsList.length - 1];

    if (lastEvent.counter >= counter) return;

    setLastDataSignalTimeAsNow(state);
    state.gpsList = [...state.gpsList, action.payload];
  },
};

export interface GpsReducers extends SliceCaseReducers<DataState> {
  setGpsList(state: Draft<DataState>, action: PayloadAction<Gps[]>): void;
  addToGpsList(state: Draft<DataState>, action: PayloadAction<Gps>): void;
}

export function resetGpsList(state: Draft<DataState>, { full }: ResetOptions): void {
  state.gpsList = full ? [] : state.gpsList.slice(-1);
}

export interface ResetOptions {
  full: boolean;
}

export function resetLastGpsCounter(state: Draft<DataState>): void {
  if (state.gpsList.length) {
    state.gpsList[state.gpsList.length - 1].counter = -1;
  }
}

export function gpsListSelector(state: RootState): Gps[] {
  return state.data.gpsList;
}
