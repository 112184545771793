import { injectable } from 'inversify';
import 'reflect-metadata';
import axios from 'axios';
import {
  Auth,
  LoginCredentials,
  ResponseCodes,
  ResponseStatus,
  RequestPasswordReset,
  UpdatePassword,
} from '../../../interfaces';
import { AccountConfig, LoginFormFields, UserConfig } from './types';
import { request } from '../../../helpers/request';
import {
  API_ACCOUNT_CONFIG,
  API_LOGIN,
  API_REQUEST_PASSWORD_RESET,
  API_UPDATE_PASSWORD,
  API_USER_CONFIG,
} from '../../../constants/api';
import { UserCredentialsError } from './errors';

export interface IUserService {
  validateFields(form: LoginFormFields): boolean;
  login(credentials: LoginCredentials): Promise<Auth>;
  fetchAccountConfig(): Promise<AccountConfig>;
  fetchUserConfig(): Promise<UserConfig>;
  requestPasswordReset(email: string): Promise<void>;
  updatePassword(params: UpdatePasswordParams): Promise<void>;
}

interface AccountConfigResponse {
  status: ResponseStatus;
  account: AccountConfig;
}

interface UserConfigResponse {
  status: ResponseStatus;
  user: UserConfig;
}

@injectable()
export class UserService implements IUserService {
  public async login(credentials: LoginCredentials): Promise<Auth> {
    try {
      const res = await axios.post<Auth>(API_LOGIN, credentials);

      return res.data;
    } catch (error: any) {
      if (error.response.status === ResponseCodes.NOT_FOUND || error.response.status === ResponseCodes.UNAUTHORIZED) {
        throw new UserCredentialsError('Invalid user credentials');
      }

      throw new Error('Unable to login');
    }
  }

  public validateFields(form: LoginFormFields): boolean {
    const { username, password } = form;
    let isUsernameError = false;
    let isPasswordError = false;

    if (username.trim() === '') {
      isUsernameError = true;
    }

    if (password.trim() === '') {
      isPasswordError = true;
    }

    return !isPasswordError && !isUsernameError;
  }

  public async fetchAccountConfig(): Promise<AccountConfig> {
    const res = await request.get<AccountConfigResponse>(API_ACCOUNT_CONFIG);

    return res.data.account;
  }

  public async fetchUserConfig(): Promise<UserConfig> {
    const res = await request.get<UserConfigResponse>(API_USER_CONFIG);

    return res.data.user;
  }

  public async requestPasswordReset(email: string): Promise<void> {
    try {
      await axios.post<RequestPasswordReset>(API_REQUEST_PASSWORD_RESET, { email: email });
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (!errors) {
        throw 'some error occured';
      }

      const errorMessage = Object.entries(errors)
        .map(([field, errors]) => {
          return `${field}: ${fieldErrorsToString(errors as string[])}`;
        })
        .join('. ');

      throw errorMessage;
    }
  }

  public async updatePassword({ password, token }: UpdatePasswordParams): Promise<void> {
    try {
      await axios.post<UpdatePassword>(API_UPDATE_PASSWORD, { password: password, token: token });
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (!errors) {
        throw 'some error occured';
      }

      const errorMessage = Object.entries(errors || {})
        .map(([field, errors]) => {
          return `${field}: ${fieldErrorsToString(errors as string[])}`;
        })
        .join('. ');

      throw errorMessage;
    }
  }
}

export interface UpdatePasswordParams {
  password: string;
  token: string;
}

function fieldErrorsToString(errors: string[]): string {
  return errors.join(', ');
}
