import React from 'react';
import cn from 'classnames';
import WheelPicture from './wheel_picture';

export interface WheelIndicatorProps {
  tireTitle: string;
  tireDepthDeltaValue: string;
  tiresStiffnessValue: string;
  wheelShowing: boolean;
  isActive?: boolean;
  steeringAngle?: number;
  tireDepthDanger?: boolean;
  tireStiffnessDanger?: boolean;
  tireWarning?: boolean;
  tireChangeIndication?: boolean;
}

const WheelIndicator: React.FC<WheelIndicatorProps> = (props: WheelIndicatorProps) => {
  const {
    tireTitle,
    tireDepthDeltaValue,
    tiresStiffnessValue,
    wheelShowing,
    isActive,
    steeringAngle,
    tireDepthDanger,
    tireStiffnessDanger,
    tireWarning,
    tireChangeIndication,
  } = props;

  return (
    <>
      <div className="wheel-info">
        <span className="wheel-title" data-testid="wheel-title">
          {tireTitle}
          {tireChangeIndication && <span className="wheel-indication">New</span>}
        </span>
        <span className={cn('wheel-wear', { danger: tireDepthDanger })} data-testid="wheel-wear">
          {tireDepthDeltaValue}
          {tireWarning && <i className="wheel-warn-icon icon icon-warning isDanger" data-testid="icon-danger" />}
        </span>
        <span className="wheel-stiffness" data-testid="wheel-stiffness">
          Stiffness
          <span className={cn('stiffness-value', { danger: tireStiffnessDanger })} data-testid="stiffness-value">
            {tiresStiffnessValue}
          </span>
        </span>
      </div>
      {wheelShowing && (
        <WheelPicture
          isActive={isActive || false}
          steeringAngle={steeringAngle}
          data-testid="wheel-picture"
        ></WheelPicture>
      )}
    </>
  );
};

export default React.memo(WheelIndicator);
