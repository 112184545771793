import React from 'react';
import classnames from 'classnames';

interface Props {
  isActive: boolean;
}

const AccControl: React.FC<Props> = ({ isActive }: Props) => {
  return (
    <div className="acc-speed-container centered">
      <i className={classnames('acc-speed-icon icon icon-cruise-control', { isActive })} />
    </div>
  );
};

export default React.memo(AccControl);
