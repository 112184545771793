import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FilterName } from '../main/interfaces';

export interface FiltersState {
  tm_grip: boolean;
  oem_grip: boolean;
  arbitrated_grip: boolean;
  surface_events: boolean;
  alerts: boolean;
  locationTrail: boolean;
  dataTrail: boolean;
}

export const initialState: FiltersState = {
  tm_grip: true,
  oem_grip: true,
  arbitrated_grip: true,
  surface_events: true,
  alerts: true,
  locationTrail: true,
  dataTrail: true,
};

export const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    toggleFilter: (state, action: PayloadAction<FilterName>) => {
      state[action.payload] = !state[action.payload];
    },
    reset: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { toggleFilter, reset } = filterSlice.actions;

export function filterSelector(state: RootState): FiltersState {
  return state.filter;
}

export default filterSlice.reducer;
