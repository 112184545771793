import React, { useContext, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { HookContext } from '../../../contexts/HookContext';
import BookmarkList from './BookmarkList';
import { getRecordingDefaultName, getShortTimeFromTimestamp } from '../../../../helpers/common';
import RecordingEditName from './RecordingEditName';
import './_recording-dialog.scss';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
}

const SaveRecordingDialog: React.FC<Props> = (props: Props) => {
  const { isOpen, onCancel } = props;
  const [recordingName, setRecordingName] = useState('');

  const recordingNameInputRef = useRef<HTMLInputElement>(null);

  const { useBookmark, useStream, useSelections, useUser } = useContext(HookContext);
  const { saveTaping, startTapingTime, pauseTapingTime } = useStream;
  const { bookmarks, deleteTapingBookmark, updateTapingBookmark } = useBookmark;
  const { selectedStream } = useSelections;
  const { userData } = useUser;

  useEffect(() => {
    if (selectedStream && pauseTapingTime) {
      setRecordingName(getRecordingDefaultName(selectedStream, pauseTapingTime));
    }
  }, [selectedStream, pauseTapingTime]);

  useEffect(() => {
    if (isOpen) {
      recordingNameInputRef.current?.focus();
    }
  }, [isOpen, recordingNameInputRef.current]);

  const onSaveTaping = () => {
    if (!selectedStream || !userData || !userData.username) return;

    saveTaping({
      streamId: selectedStream,
      fields: {
        recording_name: recordingName,
        directory_name: userData.username,
      },
    });
  };

  const onDeleteBookmarkHandler = (name: string): void => {
    if (!selectedStream) return;

    deleteTapingBookmark({
      streamId: selectedStream,
      name,
    });
  };

  const onUpdateBookmarkHandler = (oldName: string, newName: string): void => {
    if (!selectedStream) return;

    updateTapingBookmark({
      streamId: selectedStream,
      fields: {
        name: oldName,
        new_name: newName,
      },
    });
  };

  const getRecordingTime = (): string => {
    if (!startTapingTime || !pauseTapingTime) {
      return '0:00';
    }

    return getShortTimeFromTimestamp(pauseTapingTime - startTapingTime);
  };

  return (
    <div className={cn('rec-dialog-container', { hidden: !isOpen })}>
      <button className="recording-close" onClick={onCancel}>
        <i className="recording-close-icon icon icon-cancel" />
      </button>
      <div className="rec-dialog">
        <div className="rec-dialog-header">
          <h2 className="rec-dialog-subtitle">Save Recording</h2>
          <RecordingEditName name={recordingName} onChange={setRecordingName} ref={recordingNameInputRef} />
          <h3 className="rec-dialog-length">Recording Length: {getRecordingTime()} Min</h3>
        </div>
        <BookmarkList bookmarks={bookmarks} onDelete={onDeleteBookmarkHandler} onRename={onUpdateBookmarkHandler} />
      </div>
      <button className="rec-dialog-confirm" onClick={onSaveTaping}>
        Save Recording
      </button>
    </div>
  );
};

export default React.memo(SaveRecordingDialog);
