export interface VehicleInfo {
  stream_id: string;
  fleet: string;
  make: string;
  model: string;
  year: number;
}

export interface TapedStream {
  name: string;
  start_time_ms: number;
  duration_ms?: number;
}

export interface SaveTapingFields {
  recording_name: string;
  directory_name: string;
  time?: number;
}

export interface SaveTapingParams {
  streamId: string;
  fields: SaveTapingFields;
}

export enum TapingStatus {
  STARTED = 'started',
  PAUSED = 'paused',
  STOPPED = 'stopped',
}
