import { useDispatch, useSelector } from 'react-redux';
import { setSteeringAngle } from './store';
import { SteeringAngle } from '../../../interfaces';
import { DataState, EventWithCounter } from './state';
import { Draft, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { setLastDataSignalTimeAsNow } from './signalsState';
import { RootState } from '../../store';

export const useSteeringAngleData = (): UseSteeringAngleData => {
  const steeringAngle = useSelector(steeringAngleSelector);

  const dispatch = useDispatch();

  const updateSteeringAngle = (steeringAngle: SteeringAngle) => {
    dispatch(setSteeringAngle({ value: steeringAngle.steering_angle, counter: steeringAngle.counter }));
  };

  return {
    steeringAngle,
    updateSteeringAngle,
  };
};

export interface UseSteeringAngleData {
  steeringAngle: number | undefined;
  updateSteeringAngle(steeringAngle: SteeringAngle): void;
}

export const initialSteeringAngleState: Pick<DataState, 'steeringAngle'> = {
  steeringAngle: undefined,
};

export const steeringAngleReducers: ValidateSliceCaseReducers<DataState, SteeringAngleReducers> = {
  setSteeringAngle: (state: Draft<DataState>, action: PayloadAction<EventWithCounter<number>>) => {
    const { value, counter } = action.payload;

    if (state.steeringAngle && state.steeringAngle.counter >= counter) {
      return;
    }

    const steeringAngle = Math.round((value * 180) / Math.PI);
    const bounded_angle = Math.min(Math.max(steeringAngle, -30), 30);

    state.steeringAngle = { value: bounded_angle, counter };
    setLastDataSignalTimeAsNow(state);
  },
};

export interface SteeringAngleReducers extends SliceCaseReducers<DataState> {
  setSteeringAngle(state: Draft<DataState>, action: PayloadAction<EventWithCounter<number>>): void;
}

export function resetSteeringAngleToInitialValue(state: Draft<DataState>): void {
  state.steeringAngle = initialSteeringAngleState.steeringAngle;
}

export function steeringAngleSelector(state: RootState): number | undefined {
  return state.data.steeringAngle?.value;
}
